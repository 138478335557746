const formList = {
    formlist: [
      {
        id: 1,
        title: "页面表格01",
      },
      {
        id: 2,
        title: "页面表格02",
      },
      {
        id: 3,
        title: "页面表格03",
      },
      {
        id: 4,
        title: "页面表格04",
      },
      {
        id: 5,
        title: "页面表格05",
      },
      {
        id: 6,
        title: "页面表格06",
      },
    ],
    getList: function () {
      return (
        (localStorage.getItem("theList") &&
          JSON.parse(localStorage.getItem("theList"))) ||
        this.formlist
      );
    },
    saveList: (formlist) => {
      localStorage.setItem("theList", JSON.stringify(formlist));
    },
  };
  
  export default formList;
  