import React, { useEffect, useState } from "react";
import baseUrl from "../../configs/baseurl";
import axios from "axios";
import { Link } from "react-router-dom";
import "./usertable.scss";

//basictable
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//editor
import Preview from "../../img/preview_black_24dp.svg";
import Edit from "../../img/edit_black_24dp.svg";
import Delete from "../../img/delete_black_24dp.svg";

const UserTable = () => {
  //hook to store our data from DB
  const [users, setUsers] = useState([]);

  //method to get all data using Axios
  const endpoint = `${baseUrl}/users/getall`;

  const getAllData = async () => {
    await axios.get(endpoint).then((response) => {
      const data = response.data;
      console.log(data);
      setUsers(data);
    });
  };

  //use effect hook to keep track of our component and update it
  useEffect(() => {
    getAllData();
  }, []);

  //delete contact function to delete a contact
  const deleteContact = (id) => {
    if (window.confirm("Are you sure you want to delete this contact")) {
      axios
        .delete(`${baseUrl}/users/delete/${id}`)
        .then(() => {
          // toast.success("Successfully deleted this contact");
          setTimeout(() => {
            getAllData();
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="usertable">
      <div className="table-top">
        {/* <div className="table-name">用户</div> */}
        <div className="adduser">add用户</div>
      </div>

      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">ID</TableCell>
              <TableCell className="tableCell">AVATAR</TableCell>
              {/* <TableCell className="tableCell">USER</TableCell> */}
              {/* <TableCell className="tableCell">PASSWORD</TableCell> */}
              <TableCell className="tableCell">EMAIL</TableCell>
              <TableCell className="tableCell">PHONE</TableCell>
              <TableCell className="tableCell">DESCRIPTION</TableCell>
              <TableCell className="tableCell">VIP</TableCell>
              <TableCell className="tableCell">DATE</TableCell>
              <TableCell className="tableCell">STATUS</TableCell>
              <TableCell className="tableCell">ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell className="tableCell">{user.id}</TableCell>
                <TableCell className="tableCell">
                  <div className="cellWrapper">
                    <img src={user.pic_url} alt="" className="image" />
                    {user.name}
                  </div>
                </TableCell>
                <TableCell className="tableCell">{user.email}</TableCell>
                <TableCell className="tableCell">{user.phone_no}</TableCell>
                <TableCell className="tableCell">{user.desc_user}</TableCell>
                <TableCell className="tableCell">{user.vip_no}</TableCell>
                <TableCell className="tableCell">{user.date_user}</TableCell>
                <TableCell className="tableCell">
                  <span className={`status ${user.status_user}`}>
                    {user.status_user}
                  </span>
                </TableCell>
                <TableCell className="tableCell">
                  <ul>
                    <li class="icon-table" id="preview">
                      <img src={Preview} alt="" />
                    </li>
                    <li class="icon-table" id="edit">
                      <img src={Edit} alt="" />
                    </li>
                    <li
                      class="icon-table"
                      id="delete"
                      onClick={() => deleteContact(user.id)}
                    >
                      <img src={Delete} alt="" />
                    </li>
                  </ul>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserTable;
