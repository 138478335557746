import React, { useContext, useEffect, useState } from "react";
import baseUrl from "../../configs/baseurl";
import { useLocation } from "react-router-dom";
import ModelDiagramNewFile from "../project01/adminModelEditNewFile";
import ModelDiagramNewFile2 from "../project02/adminModelEditNewFile";
import ModelDiagramNewFile3 from "../project03/adminModelEditNewFile";

import axios from "axios";
import { AuthContext } from "../../context/authContext";
import NewLoader from "../../components/loaders/newloader";
import { useTranslation } from "react-i18next";
function NewFile() {
	const { t } = useTranslation();
  const location = useLocation();
  const [fileStatus, setFileStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //diagramModel select
  const [diagramModel, setDiagramModel] = useState(null);
  //获得fileid
  const params = new URLSearchParams(location.search);
  const fileid = params.get("fileid");

  useEffect(() => {
    // 在此处使用 fetch 或其他适当的方法从后端获取 diagrammodel 数据
    const fetchDiagramModel = async () => {
      const response = await fetch(
        `${baseUrl}/fileapi/getFileDiagramModel/${fileid}`
      );
      if (response.ok) {
        const data = await response.json();
        const diagramModel = data.diagrammodel || "001";
       // console.log("diagrammodel xxxx ok", diagramModel);
        setDiagramModel(diagramModel);
      } else {
        console.error("Failed to fetch diagrammodel.");
      }
    };

    fetchDiagramModel();
  }, []);

//isOriguser
  const [isOriguser, setIsOriguser] = useState(false);

  //vipgrade
  const { userVipGrade } = useContext(AuthContext);
 // console.log("N1新建文件VipGrade", userVipGrade.vip_no);
  const isVip = userVipGrade.vip_no > 1;

//loading userid
  const userid = userVipGrade.userid;

  //isLoading user is file origuser
  useEffect(() => {
    // 获取文件的fileid，你可以根据你的路由设置获取它
   // const params = new URLSearchParams(window.location.search);
   // const fileid = params.get("fileid");
    if (fileid) {
      // 发起请求获取origuser
      axios
        .get(`${baseUrl}/fileapi/getFileOrigUser/${fileid}`)
        .then((response) => {
          const origuser = response.data.origuser;
          // console.log("origuser 1", origuser);
          // 判断origuser是否等于userid，并更新状态
          setIsOriguser(origuser === userid);
        })
        .catch((error) => {
          console.error(error);
          // 处理错误
        });
    }
  }, [userid]);

  useEffect(() => {
const startTime = Date.now();
    const getFileStatus = async () => {
      try {
       // const params = new URLSearchParams(location.search);
       // const fileid = params.get("fileid");
       // console.log("fileopenstatus:", fileid);
        const response = await fetch(
          `${baseUrl}/fileapi/checkfilestatus/${fileid}`
        );
        const data = await response.json();
        setFileStatus(data.status);
       const minLoadingTime = 500;

        const loadingTime = Date.now() - startTime;
        const waitTime = Math.max(0, minLoadingTime - loadingTime);

        const timer = setTimeout(() => {
          setIsLoading(false);
        }, waitTime);

        return () => clearTimeout(timer);
      } catch (error) {
        console.error("Error while checking file status:", error);
        setIsLoading(false);  
    }
    };

    getFileStatus();
  }, [location.search]);

   if (isLoading) {
    return <NewLoader />;
  }

  if (fileStatus > 0 && isOriguser) {
    return (
      <div>
        {isVip && diagramModel === "001" && <ModelDiagramNewFile />}
        {isVip && diagramModel === "002" && <ModelDiagramNewFile2 />}
        {isVip && diagramModel === "003" && <ModelDiagramNewFile3 />}
      </div>
    );
  } else {
    return <div>{t("newfile.newfile-show")}</div>;
  }
}

export default NewFile;
