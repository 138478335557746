import DataModel11 from "../../pages/project01/dataModel11";
import InfoDraggable from "../draggables/infodraggable";
const MainLayoutData = () => {
  return (
    <div className="mainlayout">
      <DataModel11 />
      <InfoDraggable />
    </div>
  );
};

export default MainLayoutData;
