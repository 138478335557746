import React, { useContext, useEffect, useState } from "react";
import baseUrl from "../../configs/baseurl";
import { useLocation } from "react-router-dom";

import NewLoader from "../../components/loaders/newloader";
import ModelDiagramGroupViewNewFile from "../project01/adminModelGroupViewNewFile";
import { useTranslation } from "react-i18next";
function ShowGroupView() {
	const { t } = useTranslation();
  const location = useLocation();
  const [fileStatus, setFileStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const startTime = Date.now();

    const getFileStatus = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const fileid = params.get("fileid");
        // console.log("fileopenstatus:", fileid);
        const response = await fetch(
          `${baseUrl}/fileapi/checkfilestatus/${fileid}`
        );
        const data = await response.json();
        setFileStatus(data.status);

        const minLoadingTime = 500;

        const loadingTime = Date.now() - startTime;
        const waitTime = Math.max(0, minLoadingTime - loadingTime);

        const timer = setTimeout(() => {
          setIsLoading(false);
        }, waitTime);

        return () => clearTimeout(timer);
      } catch (error) {
        console.error("Error while checking file status:", error);
        setIsLoading(false);
      }
    };

    getFileStatus();
  }, [location.search]);

  if (isLoading) {
    return <NewLoader />;
  }

  // if (fileStatus === null) {
  //   return <div>Loading...</div>;
  // }

  if (fileStatus > 0) {
    return (
      <div>
        <ModelDiagramGroupViewNewFile />
      </div>
    );
  } else {
    return <div>{t("newfile.showgroupview-show")}</div>;
  }
}

export default ShowGroupView;
