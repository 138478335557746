import React, { useState, useEffect, useContext } from "react";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
// import { SketchPicker } from "react-color";
import { ChromePicker } from "react-color";
import "./editdraggable.scss";
import Draggable from "react-draggable";
import photocamera from "../../img/photo_camera48.svg";
import locklock from "../../img/lock_FILL20.svg";
import unlock from "../../img/lock_open_FILL20.svg";
import boldT from "../../img/format_bold48true.svg";
import boldF from "../../img/format_bold48.svg";
import italicT from "../../img/format_italic48true.svg";
import italicF from "../../img/format_italic48.svg";
import alignleftT from "../../img/format_align_left48true.svg";
import alignleftF from "../../img/format_align_left48.svg";
import aligncenterT from "../../img/format_align_center48true.svg";
import aligncenterF from "../../img/format_align_center48.svg";
import alignrightT from "../../img/format_align_right48true.svg";
import alignrightF from "../../img/format_align_right48.svg";
import closebt from "../../img/close_black_24dp.svg";
import morebt from "../../img/more_horiz48.svg";

import linespace from "../../img/start_opsz24.svg";
import sidelink from "../../img/sidelink.svg";
import spotlink from "../../img/spotlink.svg";
import nulllink from "../../img/nulllink.svg";

import { AuthContext } from "../../context/authContext";
import { DraggableActiveContext } from "../../context/draggableActiveContext";
// import ColorPicker from "../colorpicker/colorpicker01";
import { useTranslation } from "react-i18next";
export default function EditDraggable(props) {
	const { t } = useTranslation();
  const { draggableActive1 } = useContext(DraggableActiveContext);

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  //获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);
  //loading userid
  //   const vipno = userVipGrade.vip_no;
  const userid = userVipGrade.userid;
  //   console.log("userid11", userid);

  //showcontent
  const [showContent, setShowContent] = useState(true);

  //node width and height、hllink
  //nodetype set
  const [nodeType, setNodeType] = useState("");
  const [showNodeTypeOptions, setShowNodeTypeOptions] = useState(false);

  const nodeTypeOptions = ["Text", "Picture", "StaticPicture"];
  const handleNodeTypeClick = () => {
    setShowNodeTypeOptions(true);
  };

  const handleNodeTypeOptionSelect = (type) => {
    setNodeType(type);
    setShowNodeTypeOptions(false);
    // console.log("上传node type!", type);
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;

    // 发送 POST 请求更新节点字体大小到数据库
    fetch(`${baseUrl}/datasql/setnodetype/${fileid}/${nodekey}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nodetype: type,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message);
      })
      .catch((error) => console.error(error));

    // 发送 PUT 请求更新节点字体color到json
    fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
      .then((response) => response.json())
      .then((nodesJson) => {
        const updatedNodesJson = nodesJson.map((node) => {
          if (node.key === nodekey) {
            const modifiedType = `${type}Node`;
            return {
              ...node,
              category: modifiedType,
            };
          } else {
            return node;
          }
        });

        fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedNodesJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              // console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  };

  const [nodeFontType, setNodeFontType] = useState("");
  const [nodeFontSize, setNodeFontSize] = useState(0);

  const [nodeFontColor, setNodeFontColor] = useState("");
  const [nodeFillColor, setNodeFillColor] = useState("");
  const [nodeStrokeColor, setNodeStrokeColor] = useState("");
  const [nodeStrokeSize, setNodeStrokeSize] = useState(0);
  const [nodeWidth, setNodeWidth] = useState(0);
  const [nodeHeight, setNodeHeight] = useState(0);
  const [nodeLink, setNodeLink] = useState("");

  //link thickness and color text properties
  const [linkThickness, setLinkThickness] = useState(0);
  const [linkStrokeColor, setLinkStrokeColor] = useState("");
  const [linkDesText, setLinkDesText] = useState("");
  const [linkFromSpot, setLinkFromSpot] = useState("");
  const [linkToSpot, setLinkToSpot] = useState("");

  // state
  const [nodeFontBold, setNodeFontBold] = useState(false);
  const [nodeFontItalic, setNodeFontItalic] = useState(false);
  const [nodeFontAlignLeft, setNodeFontAlignLeft] = useState(false);
  const [nodeFontAlignCenter, setNodeFontAlignCenter] = useState(false);
  const [nodeFontAlignRight, setNodeFontAlignRight] = useState(false);
  const [nodeFontAlign, setNodeFontAlign] = useState("");

  const [lockState, setLockState] = useState(false);

  //set nodebackgroundimage
  const [backgroundImageNodeUrl, setBackgroundImageNodeUrl] = useState("");
  const [sourceNode, setSourceNode] = useState("");
  const [userNodeImages, setUserNodeImages] = useState([]);

  //user node pic selection
  const [selectedNode, setSelectedNode] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState(null);

  const handleNodeClick = (id) => {
    // 切换选中状态
    if (selectedNode === id) {
      setSelectedNode(false); // 如果已经选中，取消选择
      setSelectedNodeId(null);
    } else {
      setSelectedNode(id); // 否则，选中它
      setSelectedNodeId(id);
    }
  };

  const handleInputClick = () => {
    setSelectedNode(false);
  };

  // useEffect(() => {
  //   console.log(
  //     "selectedNode  useroldpic  & selectedNodeId & nodekey",
  //     selectedNode,
  //     selectedNodeId,
  //     props.selectedNode.key
  //   );
  // });

  //set font style
  const [showFontOptions, setShowFontOptions] = useState(false);

  const fontOptions = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Courier New",
    "Microsoft YaHei",
    "-apple-system",
    "DFKai-SB",
    "KaiTi",
    "SimSun",
    "FangSong",
    "KaiTi_GB2312",
    "system-ui",
    "Segoe UI Symbol",
    // 添加其他可选择的字体
  ];

  //set font color
  const [colorPickerPosition, setColorPickerPosition] = useState({
    x: 0,
    y: 0,
  });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showFillColorPicker, setShowFillColorPicker] = useState(false);
  const [showStrokeColorPicker, setShowStrokeColorPicker] = useState(false);
  const [showLinkStrokeColorPicker, setShowLinkStrokeColorPicker] =
    useState(false);

  //node or link selection judgment
  const nodeselect = props.selectedNode.nodeselected;
  const nodeisgroup = props.selectedNode.isgroup;
  const linkselect = props.selectedLink.linkselected;

  //showcontent
  const handleDoubleClick = () => {
    setShowContent(!showContent);
  };

  const handleEditClick = () => {
    setShowContent(true);
  };

  //
  const getDraggableTitleText = () => {
    if (nodeselect && !nodeisgroup) {
      return t("editdraggable.draggable-title2");
    } else if (nodeselect && nodeisgroup) {
      return t("editdraggable.draggable-title3");
    } else if (linkselect) {
      return t("editdraggable.draggable-title4");
    }
    return t("editdraggable.draggable-title1"); // 默认值
  };

  // //select none selected
  // useEffect(() => {
  //   if (!props.selectedNode) {
  //     nodeselect = false;
  //     linkselect = false;
  //   }
  //   console.log("node/linkselect:", nodeselect, linkselect);
  // }, [props.selectedNode]);

  // useEffect(() => {
  //   console.log("props.selectedNode.key:", props.selectedNode.key);
  //   console.log("nodeselect:", nodeselect);
  //   console.log("props.selectedLink.key:", props.selectedLink.key);
  //   console.log("linkselect:", linkselect);
  // }, [props.selectedNode, props.selectedLink]);

  const handleColorChange = (color) => {
    setNodeFontColor(color.hex);
    if (nodeselect) {
      uploadNodeFontColor(color.hex);
    }
  };

  const handleFillColorChange = (color) => {
    setNodeFillColor(color.hex);
    if (nodeselect) {
      uploadNodeFillColor(color.hex);
    }
  };

  const handleStrokeColorChange = (color) => {
    setNodeStrokeColor(color.hex);
  };

  const handleLinkStrokeColorChange = (color) => {
    setLinkStrokeColor(color.hex);
    if (linkselect) {
      uploadLinkStrokeColor(color.hex);
    }
  };

  const handleColorPickerToggle = (event) => {
    const { clientX, clientY } = event;
    setColorPickerPosition({ x: clientX, y: clientY });
    setShowColorPicker(!showColorPicker);
  };

  const handleFillColorPickerToggle = (event) => {
    const { clientX, clientY } = event;
    setColorPickerPosition({ x: clientX, y: clientY });
    setShowFillColorPicker(!showFillColorPicker);
  };

  const handleStrokeColorPickerToggle = (event) => {
    const { clientX, clientY } = event;
    setColorPickerPosition({ x: clientX, y: clientY });
    setShowStrokeColorPicker(!showStrokeColorPicker);
  };

  const handleLinkStrokeColorPickerToggle = (event) => {
    const { clientX, clientY } = event;
    setColorPickerPosition({ x: clientX, y: clientY });
    setShowLinkStrokeColorPicker(!showLinkStrokeColorPicker);
  };

  const handleColorPickerHide = () => {
    setShowColorPicker(false);
  };

  const handleFillColorPickerHide = () => {
    setShowFillColorPicker(false);
  };
  const handleStrokeColorPickerHide = () => {
    setShowStrokeColorPicker(false);
  };
  const handleLinkStrokeColorPickerHide = () => {
    setShowLinkStrokeColorPicker(false);
  };

  //property 01 node FontType  settings
  const handleFontTypeClick = () => {
    setShowFontOptions(true);
  };

  //node FontType selection settings
  const handleFontOptionSelect = (font) => {
    setNodeFontType(font);
    setShowFontOptions(false);

    if (nodeselect) {
      // console.log("上传字体!", font);
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;

      // 发送 POST 请求更新节点字体到数据库
      fetch(`${baseUrl}/datasql/setnodefonttype/${fileid}/${nodekey}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nodefonttype: font,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.message);
        })
        .catch((error) => console.error(error));

      // 发送 PUT 请求更新节点字体到json
      fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
        .then((response) => response.json())
        .then((nodesJson) => {
          const updatedNodesJson = nodesJson.map((node) => {
            if (node.key === nodekey) {
              // 解析当前节点的 fonttext
              const fontParts = node.font.split(" ");
              const isItalic = fontParts.includes("italic");
              const isBold = fontParts.includes("bold");
              const fontSize = parseInt(
                fontParts.find((part) => part.endsWith("pt"))
              );
              const fontType =
                "Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif";

              // console.log("default fontType:", fontType);

              // 将 nodefonttype 添加到 fontType 的前面，并用逗号分隔
              const newFontType = `${font}, ${fontType}`;

              // 更新节点的 font 值
              const newFont = `${isItalic ? "italic" : ""} ${
                isBold ? "bold" : ""
              } ${fontSize}pt ${newFontType}`;

              // console.log("newFont:", newFont);

              return {
                ...node,
                font: newFont,
              };
            } else {
              return node;
            }
          });

          fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedNodesJson),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                // console.log("nodes.json updated successfully");
              } else {
                // console.log("Failed to update nodes.json");
              }
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
  };

  //property 02 font size enter settings
  const handleFontSizeChange = (event) => {
    setNodeFontSize(event.target.value);
  };

  const handleFontSizeKeyDown = (event) => {
    if (event.key === "Enter" && nodeselect) {
      setNodeFontSize(event.target.value);

      const nodefontsize = event.target.value;
      // console.log("上传字体大小!", nodefontsize);
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;

      // 发送 POST 请求更新节点字体大小到数据库
      fetch(`${baseUrl}/datasql/setnodefontsize/${fileid}/${nodekey}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nodefontsize: nodefontsize,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.message);
        })
        .catch((error) => console.error(error));

      // 发送 PUT 请求更新节点字体大小到json
      fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
        .then((response) => response.json())
        .then((nodesJson) => {
          const updatedNodesJson = nodesJson.map((node) => {
            if (node.key === nodekey) {
              // 解析当前节点的 fonttext
             // const fontParts = node.font.split(" ");
             const fontParts = node.font
                ? node.font.split("")
                : [
                    "Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
                  ]; //xxxx
              const isItalic = fontParts.includes("italic");
              const isBold = fontParts.includes("bold");
              const ptIndex = fontParts.findIndex((part) =>
                part.endsWith("pt")
              );
              const fontType = fontParts.slice(ptIndex + 1).join(" ");
              // 更新节点的 font 值
              const newFont = `${isItalic ? "italic" : ""} ${
                isBold ? "bold" : ""
              } ${nodefontsize}pt ${fontType}`;

              // console.log("newFont:", newFont);

              return {
                ...node,
                font: newFont,
              };
            } else {
              return node;
            }
          });

          fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedNodesJson),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                // console.log("nodes.json updated successfully");
              } else {
                // console.log("Failed to update nodes.json");
              }
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
  };

  //property 05 node font color  enter settings
  const handleFontColorChange = (event) => {
    setNodeFontColor(event.target.value);
    if (nodeselect) {
      uploadNodeFontColor(event.target.value);
    }
  };

  const handleFontColorKeyDown = (event) => {
    if (event.key === "Enter" && nodeselect) {
      const inputColor = event.target.value.trim();

      // 检查输入值是否符合十六进制颜色值的格式
      const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      if (colorRegex.test(inputColor)) {
        setNodeFontColor(inputColor); // 输入值符合格式，赋值给 nodeFontColor
        uploadNodeFontColor(inputColor);
      } else {
        setNodeFontColor(t("editdraggable.draggable-fun-colorinput")); // 输入值不符合格式，设置 nodeFontColor 为错误提示
      }
    }
  };

  //上传字体颜色函数 function
  function uploadNodeFontColor(nodeFontColor) {
    // console.log("上传字体颜色!", nodeFontColor);
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;

    // 发送 POST 请求更新节点字体大小到数据库
    fetch(`${baseUrl}/datasql/setnodefontcolor/${fileid}/${nodekey}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nodefontcolor: nodeFontColor,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message);
      })
      .catch((error) => console.error(error));

    // 发送 PUT 请求更新节点字体color到json
    fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
      .then((response) => response.json())
      .then((nodesJson) => {
        const updatedNodesJson = nodesJson.map((node) => {
          if (node.key === nodekey) {
            return {
              ...node,
              color: nodeFontColor,
            };
          } else {
            return node;
          }
        });

        fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedNodesJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              // console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  //property 06 fill color enter settings
  const handleFillColorInputChange = (event) => {
    setNodeFillColor(event.target.value);
    if (nodeselect) {
      uploadNodeFillColor(event.target.value);
    }
  };

  const handleFillColorInputKeyDown = (event) => {
    if (event.key === "Enter" && nodeselect) {
      const inputColor = event.target.value.trim();

      // 检查输入值是否符合十六进制颜色值的格式
      const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      if (colorRegex.test(inputColor)) {
        setNodeFillColor(inputColor); // 输入值符合格式，赋值给 nodeFontColor
        uploadNodeFillColor(inputColor);
      } else {
        setNodeFillColor(t("editdraggable.draggable-fun-colorinput")); // 输入值不符合格式，设置 nodeFontColor 为错误提示
      }
    }
  };

  //上传字体颜色函数 function
  function uploadNodeFillColor(nodeFillColor) {
    // console.log("上传字体颜色!", nodeFillColor);
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;

    // 发送 POST 请求更新节点字体大小到数据库
    fetch(`${baseUrl}/datasql/setnodefillcolor/${fileid}/${nodekey}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nodefillcolor: nodeFillColor,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message);
      })
      .catch((error) => console.error(error));

    // 发送 PUT 请求更新节点字体color到json
    fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
      .then((response) => response.json())
      .then((nodesJson) => {
        const updatedNodesJson = nodesJson.map((node) => {
          if (node.key === nodekey) {
            return {
              ...node,
              fill: nodeFillColor,
            };
          } else {
            return node;
          }
        });

        fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedNodesJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              // console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  //property 07 stroke color enter settings    same fontcolor
  const handleStrokeColorInputChange = (event) => {
    setNodeStrokeColor(event.target.value);
  };

  const handleStrokeColorInputKeyDown = (event) => {
    if (event.key === "Enter" && nodeselect) {
      const inputColor = event.target.value.trim();

      // 检查输入值是否符合十六进制颜色值的格式
      const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      if (colorRegex.test(inputColor)) {
        setNodeStrokeColor(inputColor); // 输入值符合格式，赋值给 nodeFontColor
      } else {
        setNodeStrokeColor(t("editdraggable.draggable-fun-colorinput")); // 输入值不符合格式，设置 nodeFontColor 为错误提示
      }
    }
  };

  //property 08 node stroke size enter settings
  const handleStrokeSizeChange = (event) => {
    const nodestrokesize = parseInt(event.target.value);
    setNodeStrokeSize(nodestrokesize);
  };

  const handleStrokeSizeKeyDown = (event) => {
    if (event.key === "Enter" && nodeselect) {
      const nodestrokesize = parseInt(event.target.value);
      setNodeStrokeSize(nodestrokesize);
      // console.log("上传node stroke!", nodestrokesize);
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;

      // 发送 POST 请求更新节点字体大小到数据库
      fetch(`${baseUrl}/datasql/setnodestrokesize/${fileid}/${nodekey}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nodestrokesize: nodestrokesize,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.message);
        })
        .catch((error) => console.error(error));

      // 发送 PUT 请求更新节点字体color到json
      fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
        .then((response) => response.json())
        .then((nodesJson) => {
          const updatedNodesJson = nodesJson.map((node) => {
            if (node.key === nodekey) {
              return {
                ...node,
                thickness: nodestrokesize,
              };
            } else {
              return node;
            }
          });

          fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedNodesJson),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                // console.log("nodes.json updated successfully");
              } else {
                // console.log("Failed to update nodes.json");
              }
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
      //---------
    }
  };

  //property 11 link thickness enter settings
  const handleLinkThicknessChange = (event) => {
    const value = event.target.value;
    if (value === "" || (value && parseInt(value) <= 8)) {
      setLinkThickness(value || 0);
    } else {
      setLinkThickness(t("editdraggable.draggable-fun-linkinput"));
    }
  };

  const handleLinkThicknessKeyDown = (event) => {
    if (event.key === "Enter" && linkselect) {
      const linkthickness = parseInt(event.target.value);
      setLinkThickness(linkthickness);
      const fileid = props.selectedLink.fileid;
      const linkkey = props.selectedLink.key;

      // 发送 POST 请求更新节点字体大小到数据库
      fetch(`${baseUrl}/datasql/setlinkthickness/${fileid}/${linkkey}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          linkthickness: linkthickness,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.message);
        })
        .catch((error) => console.error(error));

      // 发送 PUT 请求更新节点字体color到json
      fetch(`${baseUrl}/fileapi/${fileid}/links`)
        .then((response) => response.json())
        .then((linksJson) => {
          const updatedLinksJson = linksJson.map((link) => {
            if (link.key === linkkey) {
              return {
                ...link,
                thickness: linkthickness,
              };
            } else {
              return link;
            }
          });

          fetch(`${baseUrl}/fileapi/updatelinksjson/${fileid}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedLinksJson),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                // console.log("nodes.json updated successfully");
              } else {
                // console.log("Failed to update nodes.json");
              }
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
      //---------
    }
  };

  //property 12 link description text enter settings
  const handleLinkDesTextChange = (event) => {
    setLinkDesText(event.target.value);
  };

  const handleLinkDesTextKeyDown = (event) => {
    if (event.key === "Enter" && linkselect) {
      setLinkDesText(event.target.value);
      const linkdestext = event.target.value;
      const fileid = props.selectedLink.fileid;
      const linkkey = props.selectedLink.key;

      // 发送 POST 请求更新节点字体大小到数据库
      fetch(`${baseUrl}/datasql/setlinkdestext/${fileid}/${linkkey}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          linkdestext: linkdestext,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.message);
        })
        .catch((error) => console.error(error));

      // 发送 PUT 请求更新节点字体color到json
      fetch(`${baseUrl}/fileapi/${fileid}/links`)
        .then((response) => response.json())
        .then((linksJson) => {
          const updatedLinksJson = linksJson.map((link) => {
            if (link.key === linkkey) {
              return {
                ...link,
                text: linkdestext,
              };
            } else {
              return link;
            }
          });

          fetch(`${baseUrl}/fileapi/updatelinksjson/${fileid}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedLinksJson),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                // console.log("nodes.json updated successfully");
              } else {
                // console.log("Failed to update nodes.json");
              }
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
      //---------
    }
  };

  //property 13 LinkStrokeColor input enter settings
  const handleLinkStrokeColorInputChange = (event) => {
    setLinkStrokeColor(event.target.value);
    if (linkselect) {
      uploadLinkStrokeColor(event.target.value);
    }
  };

  const handleLinkStrokeColorInputKeyDown = (event) => {
    if (event.key === "Enter" && linkselect) {
      const inputColor = event.target.value.trim();

      // 检查输入值是否符合十六进制颜色值的格式
      const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      if (colorRegex.test(inputColor)) {
        setLinkStrokeColor(inputColor); // 输入值符合格式，赋值
        uploadLinkStrokeColor(inputColor);
      } else {
        setLinkStrokeColor(t("editdraggable.draggable-fun-colorinput")); // 输入值不符合格式，
      }
    }
  };

  //上传link颜色函数 function
  function uploadLinkStrokeColor(linkStrokeColor) {
    // console.log("上传link颜色!", linkStrokeColor);
    const fileid = props.selectedLink.fileid;
    const linkkey = props.selectedLink.key;

    // 发送 POST 请求更新节点字体大小到数据库
    fetch(`${baseUrl}/datasql/setlinkstrokecolor/${fileid}/${linkkey}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        linkstrokecolor: linkStrokeColor,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message);
      })
      .catch((error) => console.error(error));

    // 发送 PUT 请求更新节点字体color到json
    fetch(`${baseUrl}/fileapi/${fileid}/links`)
      .then((response) => response.json())
      .then((linksJson) => {
        const updatedLinksJson = linksJson.map((link) => {
          if (link.key === linkkey) {
            return {
              ...link,
              color: linkStrokeColor,
            };
          } else {
            return link;
          }
        });

        fetch(`${baseUrl}/fileapi/updatelinksjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedLinksJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              // console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
    //---------
  }

  // 根据node selected key 和 basepicurl 更新 backgroundImageNodeUrl
  useEffect(() => {
    const fileInput = document.getElementById("picfile2");
   // const pichex2 = document.querySelector("#pichex2");
    if (nodeselect && props.selectedNode.key && props.selectedNode.basepicurl) {
      //clear basepic input
      fileInput.value = "";
     // pichex2.value = "";
      const imageUrl = props.selectedNode.basepicurl;
      // console.log("upload pic node fff1", imageUrl);
      const publicIndex = imageUrl.indexOf("public");
      const relativePath = imageUrl.slice(publicIndex + "public".length);
      const imagePath = relativePath.replace(/\\/g, "/");
      // console.log("upload pic node fffxx2", imagePath);
      setBackgroundImageNodeUrl(imagePath);
    } else {
      setBackgroundImageNodeUrl("");
      //clear basepic input
     // fileInput.value = "";
     // pichex2.value = "";
    }
  }, [props.selectedNode.key, props.selectedNode.basepicurl]);

  // //set ImageNodeUrl
  // useEffect(() => {
  //   console.log("upload pic ImageNodeUrl", backgroundImageNodeUrl);
  // }, [backgroundImageNodeUrl]);

  // //set sourcenode
  // useEffect(() => {
  //   console.log("upload pic source", sourceNode);
  // }, [sourceNode]);

  //update node background pic
  const handlePicNodeUpdate = async () => {
    const fileInput = document.getElementById("picfile2");
    // const pichex2 = document.querySelector("#pichex2");
    const selectedFile = fileInput.files[0];

    if (selectedFile) {
      const maxSize = 800 * 1024; // 800KB
      if (selectedFile.size > maxSize) {
        alert(t("editdraggable.draggable-fun-picalert"));
        return;
      }

      setSourceNode("");
      setBackgroundImageNodeUrl("");
      const formData = new FormData();
      formData.append("image", fileInput.files[0]);
      // const params = new URLSearchParams(window.location.search);
      // const fileid = params.get("fileid");
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      // console.log("upload storage ed itff1", fileid, nodekey);
      try {
        const response = await fetch(
          //save pic information api url
          `${baseUrl}/datasql/uploadpicnode/${fileid}/${nodekey}/${userid}`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();

        if (data.success) {
          const fileid = data.fileid;
          const picurl = data.picurl;
          // console.log("upload storage f url", fileid, picurl);
          // 保存图片地址到后端数据库
          const result = await fetch(
            //save basepicurl to nodes table
            `${baseUrl}/datasql/setbasepicurlnode/${fileid}/${nodekey}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ basepicurl: picurl }),
            }
          );

          const resultData = await result.json();
          // console.log("upload storage r", resultData);
          if (resultData.success) {
            // alert("图片上传成功并保存到后端数据库！");

            //update basepic show
            const imageUrl = picurl;
            const publicIndex = imageUrl.indexOf("public");
            const relativePath = imageUrl.slice(publicIndex + "public".length);
            const imagePath = relativePath.replace(/\\/g, "/");
            const newimagePath = `${backUrl}${imagePath}`;  //vps need
           // const newimagePath = `${imagePath}`;
            // console.log("upload pic fff2 node", newimagePath);
            setSourceNode(newimagePath);

            setBackgroundImageNodeUrl(imagePath);
            // console.log("upload pic fff1 xx", imagePath);

            //show basepic input
            // pichex2.value = imagePath;
            //refresh usernode picture
            fetchUserImages();
          } else {
            alert(t("editdraggable.draggable-fun-picalert1"));
          }
        } else {
          alert(t("editdraggable.draggable-fun-picalert2"));
        }
      } catch (error) {
        console.error(error);
        alert(t("editdraggable.draggable-fun-picalert2"));
      }
    } else {
      alert(t("editdraggable.draggable-fun-picalert3"));
      return;
    }
  };

  //update node background pic from old user pic
  const handlePicNodeUpdate2 = async () => {
    const pichex2 = document.querySelector("#pichex2");
    if (selectedNode) {
      const id = selectedNode;

      setSourceNode("");
      setBackgroundImageNodeUrl("");

      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;

      // console.log("upload storage ed 999", fileid, nodekey);

      try {
        // 发送 PUT 请求，触发更新操作
        const response = await fetch(
          `${baseUrl}/userapi/updateNodesAndReturnBasepicurl/${id}/${fileid}/${nodekey}`,
          {
            method: "PUT",
          }
        );
        const resultData = await response.json();
        const basepicurl = resultData.basepicurl;
        // console.log("Basepicurl  xxx:", basepicurl);
        if (resultData.success) {
          // 更新成功，可以进行其他操作
          //update basepic show
          const imageUrl = basepicurl;
          const publicIndex = imageUrl.indexOf("public");
          const relativePath = imageUrl.slice(publicIndex + "public".length);
          const imagePath = relativePath.replace(/\\/g, "/");
          const newimagePath = `${backUrl}${imagePath}`;  //vps need
          // const newimagePath = `${imagePath}`;
          // console.log("upload pic fff2 node xxx", newimagePath);
          setSourceNode(newimagePath);

          setBackgroundImageNodeUrl(imagePath);
          // console.log("upload pic fff1 xxx", imagePath);

          //show basepic input
          pichex2.value = imagePath;
        } else {
          // 处理错误情况
          console.error("Failed to update basepicurl");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  //update node baseimages url to json

  useEffect(() => {
    const updateNodesJson = async () => {
      try {
        const fileid = props.selectedNode.fileid;
        const nodekey = props.selectedNode.key;

        const responseSize = await fetch(
          `${baseUrl}/datasql/getnodesize/${fileid}/${nodekey}`
        );
        const sizeData = await responseSize.json();
        const newsize = sizeData.newsize;

        const response = await fetch(`${baseUrl}/fileapi/${fileid}/nodes`);
        const nodesJson = await response.json();
        // console.log("upload picimages json", nodesJson);

        const updatedNodesJson = nodesJson.map((node) => {
          if (node.key === nodekey) {
            return {
              ...node,
              category: "PictureNode",
              fill: null,
              color: null,
              thickness: 0,
              imageurl: sourceNode,
              size: newsize,
            };
          } else {
            return node;
          }
        });
        // console.log("upload picimages updated json", updatedNodesJson);

        const result = await fetch(
          `${baseUrl}/fileapi/updatenodesjson/${fileid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedNodesJson),
          }
        );

        const resultData = await result.json();

        if (resultData.success) {
          // console.log("nodes.json updated successfully");
        } else {
          console.log("Failed to update nodes.json");
        }
      } catch (error) {
        console.log("Error while updating nodes.json", error);
      }
    };

    if (sourceNode) {
      updateNodesJson();
    }
  }, [sourceNode]);

  //click node image settings  or set user_node_pic to node
  const handlePicUpdateAndJsonUpdate = async () => {
    if (nodeselect && !nodeisgroup && !selectedNode) {
      await handlePicNodeUpdate();
    } else if (selectedNode && nodeselect && !nodeisgroup) {
      await handlePicNodeUpdate2();
    }
  };

  //delete node image settings
  const handlePicNodeDelete = async () => {
    if (nodeselect && !nodeisgroup) {
      // const params = new URLSearchParams(window.location.search);
      // const fileid = params.get("fileid");
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      // console.log("upload delete ed itff1", fileid, nodekey);
      try {
        const response = await fetch(
          `${baseUrl}/datasql/deletebasepicnode/${fileid}/${nodekey}`,
          {
            method: "DELETE",
          }
        );
        const data = await response.json();

        if (data.success) {
          // 将节点的图片信息在 nodesJson 中删除，并更新节点的 size
          const response2 = await fetch(`${baseUrl}/fileapi/${fileid}/nodes`);
          const nodesJson = await response2.json();

          const updatedNodesJson = nodesJson.map((node) => {
            if (node.key === nodekey) {
              return {
                ...node,
                category: "TextNode",
                fill: "#ffffff",
                figure: "RoundedRectangle",
                thickness: 1,
                color: "#1B54D9", //恢复默认样式
                imageurl: null,
                size: "120 50",
              };
            } else {
              return node;
            }
          });

          // console.log("upload picimages updated json2", updatedNodesJson);
          const response3 = await fetch(
            `${baseUrl}/fileapi/updatenodesjson/${fileid}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(updatedNodesJson),
            }
          );
          const resultData = await response3.json();

          if (resultData.success) {
            setBackgroundImageNodeUrl(null);
            // 清空图片显示和输入框的值
            const pichex2 = document.querySelector("#pichex2");
            const picfile2 = document.querySelector("#picfile2");
            pichex2.value = "";
            picfile2.value = "";
            // alert("删除背景图片成功！");
            // console.log("Successfully updated nodes.json");
          } else {
            console.log("Failed to update nodes.json");
          }
        } else {
          // alert("删除背景图片失败！");
        }
      } catch (error) {
        console.error(error);
        // alert("删除背景图片失败！");
      }
    }
  };

  //lockstate change
  function handleLockClick() {
    setLockState(true);
  }

  function handleUnlockClick() {
    setLockState(false);
  }

  //property 03 node FontBold  settings
  function handleBoldClick() {
    setNodeFontBold(!nodeFontBold);
    if (nodeselect) {
      uploadNodeFontBold(!nodeFontBold);
    }
  }

  function uploadNodeFontBold(nodeFontBold) {
    const nodefontbold = nodeFontBold;
    // console.log("上传字体粗体!", nodeFontBold);
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;

    // 发送 POST 请求更新节点字体大小到数据库
    fetch(`${baseUrl}/datasql/setnodefontbold/${fileid}/${nodekey}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nodefontbold: nodefontbold,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message);
      })
      .catch((error) => console.error(error));

    // 发送 PUT 请求更新节点字体粗体到json
    fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
      .then((response) => response.json())
      .then((nodesJson) => {
        const updatedNodesJson = nodesJson.map((node) => {
          if (node.key === nodekey) {
            // 解析当前节点的 fonttext
            const fontParts = node.font.split(" ");
            const isItalic = fontParts.includes("italic");
            const isBold = nodefontbold;
            const fontSize = parseInt(
              fontParts.find((part) => part.endsWith("pt"))
            );
            const ptIndex = fontParts.findIndex((part) => part.endsWith("pt"));
            const fontType = fontParts.slice(ptIndex + 1).join(" ");
            // 更新节点的 font 值
            const newFont = `${isItalic ? "italic " : ""}${
              isBold ? "bold " : ""
            }${fontSize}pt ${fontType}`;

            // console.log("newFont:", newFont);

            return {
              ...node,
              font: newFont,
            };
          } else {
            return node;
          }
        });

        fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedNodesJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  //property 04 node FontItalic  settings
  function handleItalicClick() {
    setNodeFontItalic(!nodeFontItalic);
    if (nodeselect) {
      uploadNodeFontItalic(!nodeFontItalic);
    }
  }

  function uploadNodeFontItalic(nodeFontItalic) {
    const nodefontitalic = nodeFontItalic;
    // console.log("上传字体粗体!", nodeFontItalic);
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;

    // 发送 POST 请求更新节点字体大小到数据库
    fetch(`${baseUrl}/datasql/setnodefontitalic/${fileid}/${nodekey}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nodefontitalic: nodefontitalic,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message);
      })
      .catch((error) => console.error(error));

    // 发送 PUT 请求更新节点字体粗体到json
    fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
      .then((response) => response.json())
      .then((nodesJson) => {
        const updatedNodesJson = nodesJson.map((node) => {
          if (node.key === nodekey) {
            // 解析当前节点的 fonttext
            const fontParts = node.font.split(" ");
            const isItalic = nodefontitalic;
            const isBold = fontParts.includes("bold");
            const fontSize = parseInt(
              fontParts.find((part) => part.endsWith("pt"))
            );
            const ptIndex = fontParts.findIndex((part) => part.endsWith("pt"));
            const fontType = fontParts.slice(ptIndex + 1).join(" ");
            // 更新节点的 font 值
            const newFont = `${isItalic ? "italic " : ""}${
              isBold ? "bold " : ""
            }${fontSize}pt ${fontType}`;

            // console.log("newFont:", newFont);

            return {
              ...node,
              font: newFont,
            };
          } else {
            return node;
          }
        });

        fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedNodesJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  //property 09 node AlignLeft/ AlignCenter/AlignRight settings
  function handleAlignLeftClick() {
    setNodeFontAlignLeft(!nodeFontAlignLeft);
    setNodeFontAlign("left");
    if (nodeselect) {
      saveNodeTextAlign("left");
    }
  }
  function handleAlignCenterClick() {
    setNodeFontAlignCenter(!nodeFontAlignCenter);
    setNodeFontAlign("center");
    if (nodeselect) {
      saveNodeTextAlign("center");
    }
  }
  function handleAlignRightClick() {
    setNodeFontAlignRight(!nodeFontAlignRight);
    setNodeFontAlign("right");
    if (nodeselect) {
      saveNodeTextAlign("right");
    }
  }

  useEffect(() => {
    // 根据当前的对齐选项设置其他对齐选项为false
    if (nodeFontAlign === "left") {
      setNodeFontAlignLeft(true);
      setNodeFontAlignCenter(false);
      setNodeFontAlignRight(false);
    } else if (nodeFontAlign === "center") {
      setNodeFontAlignLeft(false);
      setNodeFontAlignCenter(true);
      setNodeFontAlignRight(false);
    } else if (nodeFontAlign === "right") {
      setNodeFontAlignLeft(false);
      setNodeFontAlignCenter(false);
      setNodeFontAlignRight(true);
    }
  }, [nodeFontAlign]);

  // useEffect(() => {
  //   if (nodeselect) {
  //     const fileid = props.selectedNode.fileid;
  //     const nodekey = props.selectedNode.key;
  //     saveNodeTextAlign(fileid, nodekey, nodeFontAlign);
  //   }
  // }, [nodeFontAlign]);

  //saveNodeTextAlign api
  async function saveNodeTextAlign(nodetextalign) {
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;
    const url = `${baseUrl}/datasql/setnodetextalign/${fileid}/${nodekey}`;
    const data = { nodetextalign };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // console.log("Text align saved successfully");
      } else {
        throw new Error("Failed to save text align");
      }
    } catch (error) {
      console.error("Error saving text align:", error);
    }

    // 发送 PUT 请求更新节点text align到json
    fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
      .then((response) => response.json())
      .then((nodesJson) => {
        const updatedNodesJson = nodesJson.map((node) => {
          if (node.key === nodekey) {
            return {
              ...node,
              textalign: nodetextalign,
            };
          } else {
            return node;
          }
        });

        fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedNodesJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  //node des properties change
  useEffect(() => {
    // console.log(
    //   "nodeselect/nodeisgroup/linkselect:",
    //   nodeselect,
    //   nodeisgroup,
    //   linkselect
    // );
    if (!nodeselect) {
      setNodeType("");
      setNodeFontType("");
      setNodeFontSize("");
      setNodeFontColor("");
      setNodeFillColor("");
      setNodeStrokeColor("");
      setNodeStrokeSize("");
      setNodeWidth("");
      setNodeHeight("");
      setNodeLink("");
      setNodeFontBold(false);
      setNodeFontItalic(false);
      setNodeFontAlignLeft(false);
      setNodeFontAlignCenter(false);
      setNodeFontAlignRight(false);
      setNodeFontAlign("");
      return;
    } else if (nodeselect && !nodeisgroup) {
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      //clear inputed properties
      setNodeType("");
      setNodeFontType("");
      setNodeFontSize("");
      setNodeFontColor("");
      setNodeFillColor("");
      setNodeStrokeColor("");
      setNodeStrokeSize("");
      setNodeWidth("");
      setNodeHeight("");
      setNodeLink(t("editdraggable.draggable-setNodeLink"));
      setNodeFontBold(false);
      setNodeFontItalic(false);
      setNodeFontAlignLeft(false);
      setNodeFontAlignCenter(false);
      setNodeFontAlignRight(false);
      setNodeFontAlign("");

      // 发送 GET 请求获取节点属性
      fetch(`${baseUrl}/datasql/getnodeproperties/${fileid}/${nodekey}`)
        .then((response) => response.json())
        .then((data) => {
          // 更新节点属性状态
          setNodeType(data.NodeType);
          setNodeFontType(data.NodeFontType);
          setNodeFontSize(data.NodeFontSize);
          setNodeFontColor(data.NodeFontColor);
          setNodeFillColor(data.NodeFillColor);
          setNodeStrokeColor(data.NodeStrokeColor);
          setNodeStrokeSize(data.NodeStrokeSize);

          const textalign = data.NodeFontAlign;
          // console.log("textalign  xxx:", textalign);
          //更新节点textalign
          setNodeFontAlign(textalign);
          setNodeFontAlignLeft(nodeFontAlign === "left");
          setNodeFontAlignCenter(nodeFontAlign === "center");
          setNodeFontAlignRight(nodeFontAlign === "right");

          // 解析并更新节点大小
          const [width, height] = data.NodeNewSize.split(" ");
          setNodeWidth(Number(width));
          setNodeHeight(Number(height));
          //
          setNodeFontBold(data.NodeFontBold);
          setNodeFontItalic(data.NodeFontItalic);

          // 更新节点链接
          setNodeLink(data.NodeLink);
        })
        .catch((error) => console.error(error));
    } else if (nodeselect && nodeisgroup) {
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      //clear inputed properties
      setNodeType("");
      setNodeFontType("");
      setNodeFontSize("");
      setNodeFontColor("");
      setNodeFillColor("");
      setNodeStrokeColor("");
      setNodeStrokeSize("");
      setNodeWidth("");
      setNodeHeight("");
      setNodeLink(t("editdraggable.draggable-setNodeLink"));
      setNodeFontBold(false);
      setNodeFontItalic(false);

      setNodeFontAlignLeft(false);
      setNodeFontAlignCenter(false);
      setNodeFontAlignRight(false);
      setNodeFontAlign("");

      // 发送 GET 请求获取节点属性
      fetch(`${baseUrl}/datasql/getnodegroupproperties/${fileid}/${nodekey}`)
        .then((response) => response.json())
        .then((data) => {
          // 更新节点属性状态
          setNodeType("");
          setNodeFontType(data.NodeFontType);
          setNodeFontSize(data.NodeFontSize);
          setNodeFontColor(data.NodeFontColor);
          setNodeFillColor(data.NodeFillColor);
          setNodeStrokeColor(data.NodeStrokeColor);
          setNodeStrokeSize(data.NodeStrokeSize);

          //
          setNodeFontBold(data.NodeFontBold);
          setNodeFontItalic(data.NodeFontItalic);

          const textalign = data.NodeFontAlign || "center";

          //更新group节点textalign
          setNodeFontAlign(textalign);
          setNodeFontAlignLeft(nodeFontAlign === "left");
          setNodeFontAlignCenter(nodeFontAlign === "center");
          setNodeFontAlignRight(nodeFontAlign === "right");
        })
        .catch((error) => console.error(error));
    }
  }, [props.selectedNode]);

  //link des properties change
  useEffect(() => {
    if (!linkselect) {
      setLinkThickness("");
      setLinkStrokeColor("");
      setLinkDesText("");
      setLinkFromSpot("");
      setLinkToSpot("");
      return;
    } else if (linkselect) {
      // 获取fileid
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid");

      const linkfrom = props.selectedLink.linkfrom;
      const linkto = props.selectedLink.linkto;
      //clear inputed properties
      setLinkThickness("");
      setLinkStrokeColor("");
      setLinkDesText("");
      setLinkFromSpot("");
      setLinkToSpot(""); 
      // 发送 GET 请求获取link属性
      fetch(
        `${baseUrl}/datasql/getlinkproperties/${fileid}/${linkfrom}/${linkto}`
      )
        .then((response) => response.json())
        .then((data) => {
          // 更新link属性状态
          setLinkDesText(data.LinkDesText);
          setLinkFromSpot(data.LinkFromSpot);
          setLinkToSpot(data.LinkToSpot);
          setLinkStrokeColor(data.LinkStrokeColor);
          setLinkThickness(data.LinkThickness);
        })
        .catch((error) => console.error(error));
    }
  }, [props.selectedLink]);

  // // handle node width change
  // const handleNodeWidthChange = (event) => {
  //   setNodeWidth(Number(event.target.value));
  // };

  // // handle node height change
  // const handleNodeHeightChange = (event) => {
  //   setNodeHeight(Number(event.target.value));
  // };

  //handle nodelink change
  const handleNodeLinkChange = (event) => {
    setNodeLink(event.target.value);
  };

  //update nodelink entered data
  const handleNodeLinkKeyDown = (event) => {
    if (event.key === "Enter" && nodeselect) {
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;

      // 发送 POST 请求更新节点链接
      fetch(`${baseUrl}/datasql/sethltext/${fileid}/${nodekey}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nodelink: nodeLink,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.message);
        })
        .catch((error) => console.error(error));

      // 发送 PUT 请求更新节点大小和链接
      fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
        .then((response) => response.json())
        .then((nodesJson) => {
          const updatedNodesJson = nodesJson.map((node) => {
            if (node.key === nodekey) {
              return {
                ...node,
                hltext: nodeLink,
              };
            } else {
              return node;
            }
          });

          fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedNodesJson),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                // console.log("nodes.json updated successfully");
              } else {
                console.log("Failed to update nodes.json");
              }
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
  };

  //width and height change events
  const handleSizeChange = (event) => {
    const { value, id } = event.target;
    if (id === "nodeWidthInput") {
      setNodeWidth(Number(value));
      if (!lockState) {
        setNodeHeight(Number((value * (nodeHeight / nodeWidth)).toFixed(1)));
      }
    } else if (id === "nodeHeightInput") {
      setNodeHeight(Number(value));
      if (!lockState) {
        setNodeWidth(Number((value * (nodeWidth / nodeHeight)).toFixed(1)));
      }
    }
  };

  const handleSizeKeyDown = (event) => {
    if (event.key === "Enter" && nodeselect) {
      handleSizeChange(event);
      // 将当前节点的尺寸信息更新到服务器
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      fetch(`${baseUrl}/datasql/setsizenode/${fileid}/${nodekey}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ newsize: `${nodeWidth} ${nodeHeight}` }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            // console.log("Node size updated successfully");

            // 更新 nodes.json 中对应节点的大小信息
            fetch(`${baseUrl}/fileapi/${fileid}/nodes`)
              .then((response) => response.json())
              .then((nodesJson) => {
                const updatedNodesJson = nodesJson.map((node) => {
                  if (node.key === nodekey) {
                    return {
                      ...node,
                      size: `${nodeWidth} ${nodeHeight}`,
                    };
                  } else {
                    return node;
                  }
                });

                fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(updatedNodesJson),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.success) {
                      // console.log("nodes.json updated successfully");
                    } else {
                      console.log("Failed to update nodes.json");
                    }
                  })
                  .catch((error) => console.error(error));
              })
              .catch((error) => console.error(error));
          } else {
            // console.log("Failed to update node size");
          }
        })
        .catch((error) => console.error(error));
    }
  };

  // get user nodes pics
  // 在组件加载时，查询数据库并获取用户图片链接
  const fetchUserImages = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/userapi/getUserNodesPic/${userid}`
      ); // 替换为实际的后端API端点
      if (response.ok) {
        const data = await response.json();
        // console.log("data5566", data);
        const transformedImages = data.map((item) => {
          const imageUrl = item.basepicurl;
          const publicIndex = imageUrl.indexOf("public");
          const relativePath = imageUrl.slice(publicIndex + "public".length);
          const imagePath = relativePath.replace(/\\/g, "/");

          const picname = item.basepicname.split(".")[0];

          return {
            id: item.id,
            picname: picname,
            imagePath: imagePath,
          };
        });
        // console.log("transformedImages8881", transformedImages);
        setUserNodeImages(transformedImages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // if (draggableActive1) {
    fetchUserImages();
    // };
  }, [userid]);

  //处理删除图像的函数，传递索引
  const handlePicNodeDelete2 = async (id) => {
    // console.log("id handlePicNodeDelete2", id);
    try {
      const response = await fetch(
        `${baseUrl}/userapi/deleteUserNodePic/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setUserNodeImages((prevImages) =>
          prevImages.filter((item) => item.id !== id)
        );
      } else {
        // 处理错误情况
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteClick = (index) => {
    if (!selectedNode && nodeselect) {
      // console.log(" node selected");
      handlePicNodeDelete(); // 调用第一个函数
    } else if (selectedNode && !nodeselect) {
      // console.log(" user node pic selected");
      handlePicNodeDelete2(index); // 调用第二个函数，并传递 index 参数
      setSelectedNode(false);
    } else if (selectedNode && nodeselect) {
      // console.log(" two selected");
    } else {
      // console.log(" no selected");
    }
  };

//LinkFromSpot changes
  function handleLinkFromSpotClick() {
    if (linkFromSpot !== null) {
      if (linkFromSpot.includes("Side")) {
        const newLink = linkFromSpot.replace("Side", "");
        setLinkFromSpot(newLink);
        if (linkselect) {
          saveLinkFromSpot(newLink);
        }
      } else {
        const newLink = linkFromSpot + "Side";
        setLinkFromSpot(newLink);
        if (linkselect) {
          saveLinkFromSpot(newLink);
        }
      }
    }
  }

  //saveLinkFromSpot api
  async function saveLinkFromSpot(linkFromSpot) {
    const fileid = props.selectedLink.fileid;
    const linkkey = props.selectedLink.key;
    const url = `${baseUrl}/datasql/setlinkfromspot/${fileid}/${linkkey}`;
    const data = { linkFromSpot };
    // console.log("data", fileid, linkkey, linkFromSpot);
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // console.log("Text align saved successfully");
      } else {
        throw new Error("Failed to save text align");
      }
    } catch (error) {
      console.error("Error saving text align:", error);
    }

    // 发送 PUT 请求更新节点linkFromSpot到json
    fetch(`${baseUrl}/fileapi/${fileid}/links`)
      .then((response) => response.json())
      .then((linksJson) => {
        const updatedLinksJson = linksJson.map((link) => {
          if (link.key === linkkey) {
            return {
              ...link,
              fromSpot: linkFromSpot,
            };
          } else {
            return link;
          }
        });

        fetch(`${baseUrl}/fileapi/updatelinksjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedLinksJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              //console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  //LinkFromSpot changes
  function handleLinkToSpotClick() {
    if (linkToSpot !== null) {
      if (linkToSpot.includes("Side")) {
        const newLink = linkToSpot.replace("Side", "");
        setLinkToSpot(newLink);
        if (linkselect) {
          saveLinkToSpot(newLink);
        }
      } else {
        const newLink = linkToSpot + "Side";
        setLinkToSpot(newLink);
        if (linkselect) {
          saveLinkToSpot(newLink);
        }
      }
    }
  }

  //saveLinkToSpot api
  async function saveLinkToSpot(linkToSpot) {
    const fileid = props.selectedLink.fileid;
    const linkkey = props.selectedLink.key;
    const url = `${baseUrl}/datasql/setlinktospot/${fileid}/${linkkey}`;
    const data = { linkToSpot };
    // console.log("data", fileid, linkkey, linkToSpot);
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // console.log("Text align saved successfully");
      } else {
        throw new Error("Failed to save text align");
      }
    } catch (error) {
      console.error("Error saving text align:", error);
    }

    // 发送 PUT 请求更新节点linkToSpot到json
    fetch(`${baseUrl}/fileapi/${fileid}/links`)
      .then((response) => response.json())
      .then((linksJson) => {
        const updatedLinksJson = linksJson.map((link) => {
          if (link.key === linkkey) {
            return {
              ...link,
              toSpot: linkToSpot,
            };
          } else {
            return link;
          }
        });

        fetch(`${baseUrl}/fileapi/updatelinksjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedLinksJson),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // console.log("nodes.json updated successfully");
            } else {
              //console.log("Failed to update nodes.json");
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  //edit node content
  const [editableText, setEditableText] = useState(props.selectedNode.text);
  const [isEditing, setIsEditing] = useState(false);
  const [handleNodekey, setHandleNodeKey] = useState(props.selectedNode.key);

  useEffect(() => {
    setEditableText(props.selectedNode.text);
    setHandleNodeKey(props.selectedNode.key);
  }, [props.selectedNode.text, props.selectedNode.key]);

  useEffect(() => {
    // 当 nodekey 改变时，将编辑状态设置为 false
    setIsEditing(false);
  }, [handleNodekey]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && nodeselect) {
      // 保存数据的逻辑，可以调用父组件传递的回调函数
      // props.onSave(editableText);
      saveNodeText();
      setIsEditing(false);
    }
  };

  const saveNodeText = async () => {
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;
    try {
      const response = await fetch(
        `${baseUrl}/datasql/uploadnodetext/${fileid}/${nodekey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: editableText,
          }),
        }
      );

      if (response.ok) {
        // console.log('Node text saved successfully');
        // 更新 nodes.json 中对应节点的 text 信息
        const updatedNodesJson = await fetch(
          `${baseUrl}/fileapi/${fileid}/nodes`
        )
          .then((response) => response.json())
          .then((nodesJson) => {
            return nodesJson.map((node) => {
              if (node.key === nodekey) {
                return {
                  ...node,
                  text: editableText,
                };
              } else {
                return node;
              }
            });
          });

        await fetch(`${baseUrl}/fileapi/updatenodesjson/${fileid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedNodesJson),
        });
      } else {
        console.error("Failed to save node text");
        // 处理保存失败的情况，如果需要的话
      }
    } catch (error) {
      console.error("Error during node text saving:", error);
    }
  };

  return (
    <>
      <Draggable cancel=".draggabletitle,.subitem input, .subitem img,.uploadedpics,.backpicitems img,.picinput input,.picbuttons button,.subcolor,.nodecentent,.bottombotton">
        <div
          className={draggableActive1 ? "editinfocard active" : "editinfocard"}
        >
          <div className="edithead">
            <p className="draggabletitle" onDoubleClick={handleDoubleClick}>
              {getDraggableTitleText()}
            </p>
            <img
              className="closeicon"
              src={closebt}
              onClick={() => dispatch05({ type: "EDITDRAGGABLE" })}
            />
          </div>
          {showContent ? (
            <>
              <div className="nodecentent" onClick={() => setIsEditing(true)}>
                {isEditing ? (
                  <textarea
                    type="text"
                    className="editable-input"
                    value={editableText}
                    onChange={(e) => setEditableText(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoFocus
                  />
                ) : (
                  <p className="nodedesc">{editableText}</p>
                )}
              </div>

              <div className="nodestyleitems">
                {linkselect ? null : (
                  <>
                    <div className="styleitem">
                      <label className="styletitle">{t("editdraggable.draggable-styletitle1")}</label>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle1")}</div>
                        <div className="subitem">
                          <input
                            id="nodetypeInput"
                            value={nodeType}
                            autoComplete="off"
                            onClick={handleNodeTypeClick}
                            readOnly
                          />
                          {showNodeTypeOptions && (
                            <div
                              className="nodetypeoptions"
                              style={{ position: "absolute", zIndex: 1 }}
                              onMouseLeave={() => setShowNodeTypeOptions(false)}
                            >
                              {nodeTypeOptions.map((type) => (
                                <div
                                  key={type}
                                  onClick={() =>
                                    handleNodeTypeOptionSelect(type)
                                  }
                                  onMouseEnter={() =>
                                    setShowNodeTypeOptions(true)
                                  }
                                  className={`nodetypeoption ${
                                    nodeType === type ? "selected" : ""
                                  }`}
                                >
                                  {type}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                     <div className="styleitem">
                      <label className="styletitle">{t("editdraggable.draggable-styletitle2")}</label>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle2")}</div>
                        <div className="subitem">
                          <input
                            id="nodeFontTypeInput"
                            value={nodeFontType}
                            onClick={handleFontTypeClick}
                            autoComplete="off"
                            readOnly
                          />
                          {showFontOptions && (
                            <div
                              className="fontoptions"
                              style={{ position: "absolute", zIndex: 1 }}
                              onMouseLeave={() => setShowFontOptions(false)}
                            >
                              {fontOptions.map((font) => (
                                <div
                                  key={font}
                                  onClick={() => handleFontOptionSelect(font)}
                                  onMouseEnter={() => setShowFontOptions(true)}
                                  className={`fontoption ${
                                    nodeFontType === font ? "selected" : ""
                                  }`}
                                >
                                  {font}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle21")}</div>
                        <div className="subitem">
                          <input
                            id="nodeFontSizeInput"
                            value={nodeFontSize}
                            autoComplete="off"
                            onChange={handleFontSizeChange}
                            onKeyDown={handleFontSizeKeyDown}
                          />
                        </div>
                        <div className="subdes">px</div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle22")}</div>
                        <div className="subitem">
                          {nodeFontBold ? (
                            <img
                              className="stypeicon"
                              src={boldT}
                              onClick={handleBoldClick}
                            />
                          ) : (
                            <img
                              className="stypeicon"
                              src={boldF}
                              onClick={handleBoldClick}
                            />
                          )}
                          {nodeFontItalic ? (
                            <img
                              className="stypeicon1"
                              src={italicT}
                              onClick={handleItalicClick}
                            />
                          ) : (
                            <img
                              className="stypeicon1"
                              src={italicF}
                              onClick={handleItalicClick}
                            />
                          )}
                          <div className="itemalign">
                            {nodeFontAlignLeft ? (
                              <img
                                className="stypeicon2"
                                src={alignleftT}
                                onClick={handleAlignLeftClick}
                              />
                            ) : (
                              <img
                                className="stypeicon2"
                                src={alignleftF}
                                onClick={handleAlignLeftClick}
                              />
                            )}
                            {nodeFontAlignCenter ? (
                              <img
                                className="stypeicon2"
                                src={aligncenterT}
                                onClick={handleAlignCenterClick}
                              />
                            ) : (
                              <img
                                className="stypeicon2"
                                src={aligncenterF}
                                onClick={handleAlignCenterClick}
                              />
                            )}
                            {nodeFontAlignRight ? (
                              <img
                                className="stypeicon2"
                                src={alignrightT}
                                onClick={handleAlignRightClick}
                              />
                            ) : (
                              <img
                                className="stypeicon2"
                                src={alignrightF}
                                onClick={handleAlignRightClick}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle23")}</div>
                        <div className="subitem">
                          <input
                            id="nodeFontColorInput"
                            value={nodeFontColor}
                            autoComplete="off"
                            onChange={handleFontColorChange}
                            onKeyDown={handleFontColorKeyDown}
                          />
                        </div>
                        <div
                          className="subcolor"
                          style={{ backgroundColor: nodeFontColor }}
                          onClick={handleColorPickerToggle}
                        ></div>
                      </div>
                    </div>
                    <div className="styleitem">
                      <label className="styletitle">{t("editdraggable.draggable-styletitle3")}</label>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle3")}</div>
                        <div className="subitem">
                          <input
                            id="nodeFillColorInput"
                            value={nodeFillColor}
                            autoComplete="off"
                            onChange={handleFillColorInputChange}
                            onKeyDown={handleFillColorInputKeyDown}
                          />
                        </div>
                        <div
                          className="subcolor"
                          style={{ backgroundColor: nodeFillColor }}
                          onClick={handleFillColorPickerToggle}
                        ></div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle31")}</div>
                        <div className="subitem">
                          <input
                            id="nodeStrokeColorInput"
                            value={nodeStrokeColor}
                            autoComplete="off"
                            onChange={handleStrokeColorInputChange}
                            onKeyDown={handleStrokeColorInputKeyDown}
                          />
                        </div>
                        <div
                          className="subcolor"
                          style={{ backgroundColor: nodeStrokeColor }}
                          onClick={handleStrokeColorPickerToggle}
                        ></div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle32")}</div>
                        <div className="subitem">
                          <input
                            id="nodeStrokeSizeInput"
                            className="linethickness"
                            value={nodeStrokeSize}
                            autoComplete="off"
                            onChange={handleStrokeSizeChange}
                            onKeyDown={handleStrokeSizeKeyDown}
                          />
                        </div>
                        <div className="subdes">px</div>
                      </div>
                    </div>
                  </>
                )}

                {linkselect ? (
                  <>
                    <div className="styleitem">
                      <label className="styletitle">{t("editdraggable.draggable-styletitle4")}</label>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle4")}</div>
                        <div className="subitem">
                          <input
                            id="linkThicknessInput"
                            className="linethickness"
                            value={linkThickness}
                            autoComplete="off"
                            onChange={handleLinkThicknessChange}
                            onKeyDown={handleLinkThicknessKeyDown}
                          />
                        </div>
                        <div className="subdes">px</div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle41")}</div>
                        <div className="subitem">
                          <input
                            id="linkStrokeColorInput"
                            value={linkStrokeColor}
                            autoComplete="off"
                            onChange={handleLinkStrokeColorInputChange}
                            onKeyDown={handleLinkStrokeColorInputKeyDown}
                          />
                        </div>
                        <div
                          className="subcolor"
                          style={{ backgroundColor: linkStrokeColor }}
                          onClick={handleLinkStrokeColorPickerToggle}
                        ></div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle42")}</div>
                        <div className="subitem">
                          <input
                            id="linkDesTextInput"
                            value={linkDesText}
                            autoComplete="off"
                            onChange={handleLinkDesTextChange}
                            onKeyDown={handleLinkDesTextKeyDown}
                          />
                        </div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle43")}</div>
                        <div className="subitem">
                          <input
                            id="linkarrowInput"
                            // value={linkarrow}
                            autoComplete="off"
                            // onChange={handleLinkDesTextChange}
                            // onKeyDown={handleLinkDesTextKeyDown}
                          />
                        </div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle44")}</div>
                        <div className="subitem">
                          {linkFromSpot !== null &&
                          !linkFromSpot.includes("Side") ? (
                            <img
                              className="stypeicon"
                              src={spotlink}
                              onClick={handleLinkFromSpotClick}
                            />
                          ) : linkFromSpot !== null &&
                            linkFromSpot.includes("Side") ? (
                            <img
                              className="stypeicon"
                              src={sidelink}
                              onClick={handleLinkFromSpotClick}
                            />
                          ) : (
                            <img className="stypeicon" src={nulllink} />
                          )}

                          <img className="stypeicon11" src={linespace} />

                          {linkToSpot !== null &&
                          !linkToSpot.includes("Side") ? (
                            <img
                              className="stypeicon"
                              src={spotlink}
                              onClick={handleLinkToSpotClick}
                            />
                          ) : linkToSpot !== null &&
                            linkToSpot.includes("Side") ? (
                            <img
                              className="stypeicon"
                              src={sidelink}
                              onClick={handleLinkToSpotClick}
                            />
                          ) : (
                            <img className="stypeicon" src={nulllink} />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {/* group update */}
                {nodeisgroup && (
                  <>
                    <div className="propertyinput">
                      <div className="propertybuttons">
                        <button
                          title={t("editdraggable.draggable-btntitle1")}
                          className="propertyupdate"
                          onClick={props.onRefresh}
                        >
                          {t("editdraggable.draggable-btn1")}
                        </button>

                        <button
                          title={t("editdraggable.draggable-btntitle2")}
                          className="propertycancel"
                          // onClick={handleCancelClick}
                        >
                          {t("editdraggable.draggable-btn2")}
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {/* link update */}
                {linkselect && (
                  <>
                    <div className="propertyinput">
                      <div className="propertybuttons">
                        <button
                          title={t("editdraggable.draggable-btntitle1")}
                          className="propertyupdate"
                          onClick={props.onRefresh}
                        >
                          {t("editdraggable.draggable-btn1")}
                        </button>

                        <button
                          title={t("editdraggable.draggable-btntitle2")}
                          className="propertycancel"
                          // onClick={handleCancelClick}
                        >
                          {t("editdraggable.draggable-btn2")}
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {!linkselect && !nodeisgroup && (
                  <>
                    <div className="styleitem">
                      <label className="styletitle">{t("editdraggable.draggable-styletitle5")}</label>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle5")}</div>
                        <div className="subitem">
                          <input
                            id="nodeWidthInput"
                            value={nodeWidth}
                            autoComplete="off"
                            onChange={handleSizeChange}
                            onKeyDown={handleSizeKeyDown}
                          />
                        </div>
                        {lockState ? (
                          <img
                            className="lockicon"
                            src={unlock}
                            alt="unlock"
                            onClick={handleUnlockClick}
                          />
                        ) : (
                          <img
                            className="lockicon"
                            src={locklock}
                            alt="lock"
                            onClick={handleLockClick}
                          />
                        )}
                        <div className="subtitle">{t("editdraggable.draggable-subtitle51")}</div>
                        <div className="subitem">
                          <input
                            id="nodeHeightInput"
                            value={nodeHeight}
                            autoComplete="off"
                            onChange={handleSizeChange}
                            onKeyDown={handleSizeKeyDown}
                          />
                        </div>
                        <div className="subdes">px</div>
                      </div>
                    </div>
                    <div className="styleitem">
                      <label className="styletitle">{t("editdraggable.draggable-styletitle6")}</label>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle6")}</div>
                        <div className="subitem">
                          <input
                            value={nodeLink}
                            onChange={handleNodeLinkChange}
                            onKeyDown={handleNodeLinkKeyDown}
                          />
                        </div>
                      </div>
                      <div className="itemsub">
                        <div className="subtitle">{t("editdraggable.draggable-subtitle61")}</div>
                        <div className="subitem">
                          <input></input>
                        </div>
                      </div>
                    </div>

                    <div className="propertyinput">
                      <div className="propertybuttons">
                        <button
                          title={t("editdraggable.draggable-btntitle1")}
                          className="propertyupdate"
                          onClick={props.onRefresh}
                        >
                          {t("editdraggable.draggable-btn1")}
                        </button>

                        <button
                          title={t("editdraggable.draggable-btntitle2")}
                          className="propertycancel"
                          // onClick={handleCancelClick}
                        >
                          {t("editdraggable.draggable-btn2")}
                        </button>
                      </div>
                    </div>

                    <div className="backpic">
                      <label>{t("editdraggable.draggable-backpic-label1")}</label>
                      {/* xx20231010 */}
                      <div className="picsubtitle">{t("editdraggable.draggable-backpic-title1")}</div>
                      <div className="uploadedpics">
                        <div className="nodepiclayout">
                          {userNodeImages.map((usernodeImage) => (
                            <div
                              key={usernodeImage.id}
                              className="nodepicitems"
                            >
                              <img
                                className={`nodepicitem ${
                                  selectedNode === usernodeImage.id
                                    ? "selected"
                                    : ""
                                }`}
                                src={`${baseUrl}${usernodeImage.imagePath}`}
                                alt="user_node_pic"
                                onClick={() =>
                                  handleNodeClick(usernodeImage.id)
                                }
                              />
                              <div
                                className="nodepicname"
                                title={usernodeImage.picname}
                              >
                                {usernodeImage.picname}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="picsubtitle">{t("editdraggable.draggable-backpic-title2")}</div>
                      <div className="backpicitems">
                        {backgroundImageNodeUrl ? (
                          <img
                            // src={backgroundImageNodeUrl}
                            src={`${baseUrl}${backgroundImageNodeUrl}`}
                            // src={`${baseUrl}${backgroundImageNodeUrl}`}    //vps need
                            alt={t("editdraggable.draggable-backpic-alt1")}
                          />
                        ) : (
                          <img src={photocamera} alt={t("editdraggable.draggable-backpic-alt2")} />
                        )}
                      </div>

                      <div className="picinput">
                        <input
                          id="pichex2"
                          className="pichex"
                          autoComplete="off"
                          type="text"
                          onClick={handleInputClick} //clear selection user pic
                        />
						<label className="piclabel" htmlFor="picfile2">
                          {t("editdraggable.draggable-backpiclabel")}
                        </label>
                        <input
                          id="picfile2"
                          className="picfile"
                          type="file"
                          // accept=".svg"
                          accept=".png, .jpg, .jpeg, .gif, .svg"
                          onClick={handleInputClick} //clear selection user pic
                        />
                        <div className="picbuttons">
                          <button
                            title={t("editdraggable.draggable-backpic-btntitle1")}
                            className="picupdate"
                            onClick={handlePicUpdateAndJsonUpdate}
                          >
                            {t("editdraggable.draggable-backpic-btn1")}
                          </button>
                          <button
                            title={t("editdraggable.draggable-backpic-btntitle1")}
                            className="picdisable"
                            // onClick={handlePicNodeUpdateJson}
                          >
                            {t("editdraggable.draggable-backpic-btn2")}
                          </button>
                          <button
                            title={t("editdraggable.draggable-backpic-btntitle1")}
                            className="picdelete"
                            onClick={
                              () => handleDeleteClick(selectedNode)
                              // handleDeleteClick(userNodeImages[0].id)
                            }
                          >
                            {t("editdraggable.draggable-backpic-btn3")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="bottombotton" onClick={handleEditClick}>
              <img
                className="morebtn"
                src={morebt}
                // onClick={handleMoreToggle}
              />
            </div>
          )}
        </div>
      </Draggable>

      {showColorPicker && (
        <div
          className="colorPickerContainer"
          onMouseLeave={handleColorPickerHide}
          style={{ top: colorPickerPosition.y, left: colorPickerPosition.x }}
        >
          <ChromePicker
            color={nodeFontColor}
            onChange={handleColorChange}
            onHide={handleColorPickerHide}
          />
        </div>
      )}
      {showFillColorPicker && (
        <div
          className="fillcolorPickerContainer"
          onMouseLeave={handleFillColorPickerHide}
          style={{ top: colorPickerPosition.y, left: colorPickerPosition.x }}
        >
          <ChromePicker
            color={nodeFillColor}
            onChange={handleFillColorChange}
            onHide={handleFillColorPickerHide}
          />
        </div>
      )}
      {showStrokeColorPicker && (
        <div
          className="strokecolorPickerContainer"
          onMouseLeave={handleStrokeColorPickerHide}
          style={{ top: colorPickerPosition.y, left: colorPickerPosition.x }}
        >
          <ChromePicker
            color={nodeStrokeColor}
            onChange={handleStrokeColorChange}
            onHide={handleStrokeColorPickerHide}
          />
        </div>
      )}
      {showLinkStrokeColorPicker && (
        <div
          className="linkStrokeColorPickerContainer"
          onMouseLeave={handleLinkStrokeColorPickerHide}
          style={{ top: colorPickerPosition.y, left: colorPickerPosition.x }}
        >
          <ChromePicker
            color={linkStrokeColor}
            onChange={handleLinkStrokeColorChange}
            onHide={handleLinkStrokeColorPickerHide}
          />
        </div>
      )}
    </>
  );
}
