const pageList = {
    pagelist: [
      {
        id: 1,
        title: "首页标题",
      },
      {
        id: 2,
        title: "第二页标题",
      },
      {
        id: 3,
        title: "第三页标题",
      },
      {
        id: 4,
        title: "第四页标题",
      },
      {
        id: 5,
        title: "第五页标题",
      },
      {
        id: 6,
        title: "第六页标题",
      },
    ],
    getList: function () {
      return (
        (localStorage.getItem("theList") &&
          JSON.parse(localStorage.getItem("theList"))) ||
        this.pagelist
      );
    },
    saveList: (pagelist) => {
      localStorage.setItem("theList", JSON.stringify(pagelist));
    },
  };
  
  export default pageList;
  