import React, { useContext, useEffect, useState } from "react";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
import { useLocation } from "react-router-dom";
import frontUrl from "../../configs/fronturl";
import NewLoader from "../../components/loaders/newloader";
import axios from "axios";
import { PopupActiveContext } from "../../context/popupActiveContext";
import { AuthContext } from "../../context/authContext";
import "./groupfileview.scss";

import strangeuserSvg from "../../images/userimages/strangeuser.svg";
import registereduserSvg from "../../images/userimages/registereduser.svg";
import menuSvg from "../../img/menu_opsz24.svg";
import UserPopup from "../../components/popupfiles/userpopup/userPopup";
import LoginPopup from "../login/Login";
import { useTranslation } from "react-i18next";
function GroupFileView() {
	const { t } = useTranslation();
  const location = useLocation();
  const [fileStatus, setFileStatus] = useState(null);
  const [groupStatus, setGroupStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultSrc, setDefaultSrc] = useState("");

  //popupActiveContext
  const { dispatch3 } = useContext(PopupActiveContext);

  const [userInfo, setUserInfo] = useState({
    picurl: null,
  });
  const [userImage, setUserImage] = useState(null);
  const [usertitle, setUsertitle] = useState("New user");

  const [dropdownOptions, setDropdownOptions] = useState([t("groupfileview.option")]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [groupname, setGroupname] = useState("");

  //获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);
  const userid = userVipGrade.userid;

  const [origuser, setOrigUser] = useState("");
  const [isGroupUser, setIsGroupUser] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");

  const params = new URLSearchParams(location.search);
  const groupid = params.get("groupid");
  // console.log("filegroupgroupid:", groupid);

  //title = currentUser
  useEffect(() => {
    if (currentUser) {
      setUsertitle(currentUser);
    } else {
      setUsertitle("New user");
    }
  }, [currentUser]);

  //userInfo gets updated
  useEffect(() => {
    if (userid) {
      fetch(`${baseUrl}/userapi/getUserInfo/${userid}`)
        .then((response) => response.json())
        .then((data) => {
          setUserInfo({
            picurl: data.pic_url,
          });
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    }
  }, [userid]);

  //load user pic
  useEffect(() => {
    if (userInfo.picurl) {
      const imageUrl = userInfo.picurl;
      const publicIndex = imageUrl.indexOf("public");
      const relativePath = imageUrl.slice(publicIndex + "public".length);
      const imagePath = relativePath.replace(/\\/g, "/");
      // const newimagePath = `${backUrl}${imagePath}`;
      // const newimagePath = `${baseUrl}${imagePath}`;    //vps need
      // console.log("upload pic user11", imagePath);

      setUserImage(imagePath, () => {
        // console.log("upload pic ff2", userImage);
      });
    } else {
      setUserImage(null);
    }
  }, [userInfo.picurl]);

  const handleEditClick = () => {
    if (isGroupUser) {
      // 进入编辑模式
      setIsEditing(true);
      // 将当前 groupname 设置为新的 groupname
      setNewGroupName(groupname);
    }
  };

  const handleSaveClick = () => {
    if (isGroupUser) {
      // 发送请求保存新的 groupname 到后端
      axios
        .put(`${baseUrl}/userapi/updategroupname/${groupid}`, {
          groupname: newGroupName,
        })
        .then((response) => {
          // 保存成功后更新状态
          setIsEditing(false);
          setGroupname(newGroupName);
        })
        .catch((error) => {
          console.error("Error updating groupname:", error);
        });
    }
  };

  const handleInputChange = (e) => {
    if (isGroupUser) {
      // 更新新的 groupname
      setNewGroupName(e.target.value);
    }
  };

  const handleInputKeyUp = (e) => {
    // 当用户按下 Enter 键时，保存新的 groupname
    if (e.key === "Enter") {
      handleSaveClick();
    }
  };

  useEffect(() => {
    // 发送请求获取 origuser
    axios
      .get(`${baseUrl}/userapi/origuser/${groupid}`) // 替换成实际的后端路由
      .then((response) => {
        const data = response.data;
        if (data.origuser) {
          // 设置 origuser 状态
          setOrigUser(data.origuser);
          //   console.log("data.origuser222", data.origuser);
          // 检查是否为 group 用户
          setIsGroupUser(data.origuser === userid);
        }
      })
      .catch((error) => {
        console.error("Error fetching origuser:", error);
      });
  }, [userid, groupid]);

  //get group name
  useEffect(() => {
    // 在此处发送请求到后端以获取 groupname
    axios
      .get(`${baseUrl}/userapi/groupname/${groupid}`)
      .then((response) => {
        const data = response.data;
        if (data.groupname) {
          setGroupname(data.groupname);
        }
      })
      .catch((error) => {
        console.error("Error fetching groupname:", error);
      });
  }, [groupid]);

  // 获取 groupfiles 的值并 show files
useEffect(() => {
    if (groupid) {
      axios
        .get(`${baseUrl}/userapi/getGroupFiles2/${groupid}`)
        .then((response) => {
          const data = response.data;
          // 检查数据是否为空
          if (!data || !data.groupfiles) {
            return;
          }

          // 解析 groupfiles 为文件 ID 数组
          const fileIds = data.groupfiles.split(",");
          // 创建一个 Promise 数组来按顺序获取文件名和对应的 fileid
          const promises = fileIds.map((fileId) => {
            return Promise.all([
              axios.get(`${baseUrl}/fileapi/getfilename/${fileId}`),
              Promise.resolve(fileId), // 将 fileid 包装为 Promise
            ])
              .then(([filenameResponse, fileId]) => {
                // 提取文件名
                const filename = filenameResponse.data.filename;
                return { filename, value: fileId };
              })
              .catch((error) => {
                console.error(
                  `Error fetching filename for file ${fileId}:`,
                  error
                );
                return { filename: "Unknown", value: fileId }; // 返回默认值 "Unknown" 或其他您选择的默认值
              });
          });

          Promise.all(promises)
            .then((fileInfoArray) => {
              // 创建一个新的选项数组
              const newDropdownOptions = fileInfoArray.map(
                ({ filename, value }, index) => ({
                  label: filename,
                  value,
                  isSelected: index === 0,
                })
              );

              setDropdownOptions(newDropdownOptions);

              // 设置 selectedOption 为第一个文件名
              setSelectedOption(newDropdownOptions[0].label);
            })
            .catch((error) => {
              console.error("Error fetching filenames:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching groupfiles:", error);
        });
    }
  }, [groupid]);

  const handleOptionClick = (label, fileid) => {
    setSelectedOption(label);
    setDefaultSrc(`${frontUrl}/groupview1?fileid=${fileid}`);
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 1000);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  //获取 groupfiles 数据
  useEffect(() => {
    axios
      .get(`${baseUrl}/userapi/getGroupFiles2/${groupid}`)
      .then((response) => {
        const data = response.data;
        if (data.groupfiles) {
          // 解析 groupfiles 为文件 ID 数组
          const fileIds = data.groupfiles.split(",");
          //   console.log("fileIds11", fileIds);

          //   // 设置 groupfiles 中的 fileid 数据集
          //   setGroupfiles(fileIds);

          // 如果至少有一个 fileid，则将默认的 src 设置为第一个 fileid
          if (fileIds.length > 0) {
            setDefaultSrc(`${frontUrl}/groupview1?fileid=${fileIds[0]}`);
          }
        } else {
          // 如果未找到匹配的 groupfiles，则可以处理相应的逻辑
          console.log("No groupfiles found for groupid:", groupid);
        }
        setIsLoading(false); // 停止加载
      })
      .catch((error) => {
        console.error("Error fetching groupfiles:", error);
        setIsLoading(false); // 停止加载
      });
  }, [groupid]);

  //
  useEffect(() => {
    const startTime = Date.now();

    const getFileStatus = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/userapi/checkgroupstatus/${groupid}`
        );
        const data = await response.json();
        // console.log("groupstatus", data.status);
        setGroupStatus(data.status);

        const minLoadingTime = 500;

        const loadingTime = Date.now() - startTime;
        const waitTime = Math.max(0, minLoadingTime - loadingTime);

        const timer = setTimeout(() => {
          setIsLoading(false);
        }, waitTime);

        return () => clearTimeout(timer);
      } catch (error) {
        console.error("Error while checking file status:", error);
        setIsLoading(false);
      }
    };

    getFileStatus();
  }, [location.search]);

  if (isLoading) {
    return <NewLoader />;
  }

  // if (fileStatus === null) {
  //   return <div>Loading...</div>;
  // }

  if (groupStatus > 0) {
    return (
      <div className="groupfilelayout">
        <div className="loaduser">
          {currentUser ? (
            /*注册用户*/
            <div
              className="useritem"
              title={usertitle}
              // onClick={() => dispatch3({ type: "USERS" })}
            >
              <img
                src={
                  userImage
                    ? `${baseUrl}${userImage}?${Math.random()}`
                    : registereduserSvg
                }
                alt=""
                className="userimage"
              />
            </div>
          ) : (
            /*未注册用户*/
            <div
              className="useritem"
              title={t("groupfileview.user-title")}
              onClick={() => dispatch3({ type: "LOGIN" })}
            >
              <img src={strangeuserSvg} className="userimage" alt="" />
            </div>
          )}
        </div>
        <div className="groupname">
          {isEditing ? (
            <input
              type="text"
              className="groupnameedit"
              value={newGroupName}
              onChange={handleInputChange}
              onKeyUp={handleInputKeyUp}
              onBlur={handleSaveClick} // 用户在编辑状态下失去焦点时也触发保存操作
              autoFocus // 自动获取焦点
            />
          ) : (
            <div className="showname" onClick={handleEditClick}>
              {groupname}
            </div>
          )}
        </div>
        <div className="groupmenuicon">
          <img className="grouplisticon" src={menuSvg} />
          {/* <img className="groupmenuicon" src={menuSvg} /> */}
        </div>
        {/* groupfilelist */}
        <div className="custom-dropdown" onMouseLeave={handleMouseLeave}>
          <div
            className="custom-dropdown-toggle"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {selectedOption || t("groupfileview.option-default")}
          </div>
          <div className="dropdownlayout">
          {isDropdownOpen && (
            <div className="custom-dropdown-options">
              {dropdownOptions.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleOptionClick(option.label, option.value)}
                  className={`custom-dropdown-option ${
                    selectedOption === option.label ? "selected" : ""
                  }`}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
       </div>

        <div className="groupfileitem">
          <iframe
            src={defaultSrc}
            frameBorder="0"
            width="100%"
            height="100%"
            scrolling="no"
          ></iframe>
        </div>
        <LoginPopup />
        <UserPopup />
      </div>
    );
  } else {
    return <div>{t("groupfileview.groupfile-show")}</div>;
  }
}

export default GroupFileView;
