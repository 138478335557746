import AdminModel11 from "../../pages/project01/adminModel11";
const MainLayout = () => {
  return (
    <div className="mainlayout">
      <AdminModel11 />
    </div>
  );
};

export default MainLayout;
