import React, { useState } from "react";
import "./home.scss";
import Navbar from "../../components/navbar/Navbar";
import Leftlayout from "../../components/leftlayout/Leftlayout";
import Sidebar from "../../components/sidebar/Sidebar";
import LoginPopup from "../login/Login";
import MainLayout from "../../components/mainlayout/mainlayout";
import NoticePopup from "../../components/popupfiles/noticepopup/noticePopup";
import ChatPopup from "../../components/popupfiles/chatpopup/chatPopup";
import UserPopup from "../../components/popupfiles/userpopup/userPopup";
import FileSearch from "../../components/filesearch/FileSearch";
import ToolPopup from "../../components/popupfiles/toolpopup/toolPopup";
// import NewLoader from "../../components/loaders/newloader";

const Home = () => {
  const [isFileSearchVisible, setIsFileSearchVisible] = useState(true);
  const handleFileSearchClick = () => {
    setIsFileSearchVisible(!isFileSearchVisible);
  };

  // 处理点击 overlayfile
  const handleOverlayClick = () => {
    setIsFileSearchVisible(!isFileSearchVisible);
  };

  const handleCloseFileSearch = () => {
    setIsFileSearchVisible(false);
  };


  return (
    <div className="home">
      <Navbar onFileSearchClick={handleFileSearchClick} onCloseFileSearch={handleCloseFileSearch} />
      <Sidebar />
      {isFileSearchVisible && <FileSearch onOverlayClick={handleOverlayClick} />}
      {/* <NewLoader /> */}
      <Leftlayout />
      <LoginPopup />
      <NoticePopup />
      <ChatPopup />
      <ToolPopup />
      <UserPopup />
      <MainLayout />
    </div>
  );
};

export default Home;
