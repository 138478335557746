import React, { useState, useContext, useEffect } from "react";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
import axios from "axios";
import "./infodraggable.scss";
import { ChatBubble, Folder, WarningTriangleOutline } from "@plansplan/icons";
import Draggable from "react-draggable";
import Carousel from "./wrapperpic";
import aiAuto from "../../img/motion_auto24.svg";
import closebt from "../../img/close_black_24dp.svg";
import morebt from "../../img/more_horiz48.svg";
import audiobt from "../../img/volume_opsz24.svg";
import videobt from "../../img/videocam_opsz24.svg";
import playbt from "../../img/play_opsz48.svg";

import { AuthContext } from "../../context/authContext";
import { DraggableActiveContext } from "../../context/draggableActiveContext";
import { useTranslation } from "react-i18next";
export default function InfoDraggable(props) {
  const { t } = useTranslation();
  const { draggableActive2 } = useContext(DraggableActiveContext);

//获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);
  //loading userid
  //   const vipno = userVipGrade.vip_no;
  const userid = userVipGrade.userid;

//show model selection
  const [showModelOptions, setShowModelOptions] = useState(false);
  const [modelSet, setModelSet] = useState("001");

  const modelOptions = [
    { label: "Model 1", value: "001" },
    { label: "Model 2", value: "002" },
    { label: "Model 3", value: "003" },
    // 添加其他可选择的模型
  ];

  const [selectedModel, setSelectedModel] = useState("Model 1");

  const handleModelClick = () => {
    setShowModelOptions(!showModelOptions);
  };

  const handleModelOptionSelect = (model) => {
    setSelectedModel(model.label);
    setModelSet(model.value);
    setShowModelOptions(false);
    // 在这里可以根据选择的模型执行相应的操作
  };

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  //showcontent
  const [showInfodragContent, setShowInfodragContent] = useState(true);

  // const [acttitle, setActTitle] = useState([]);
  // const [actdetail, setActDetail] = useState([]);

  // console.log("props.selectedNode", props.selectedNode.title);
  // console.log("props.selectedNode", props.selectedNode.detail);

  // console.log("nodedesc",props.selectedNode.nodedesc);
  const [items, setItems] = useState([
    { titleId: "titles", detailId: "details" },
  ]);

  const [title, setTitle] = useState([]);
  const [detail, setDetail] = useState([]);

  //filekeywords
  const [keywords, setKeywords] = useState("");

  const handleKeywordsChange = (event) => {
    setKeywords(event.target.value);
    // console.log("handleKeywordsChange", event.target.value);
  };

  //update filekeywords
  const handleSaveKeywords = () => {
    const nodekey = props.selectedNode.key;
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
    // console.log("nodekey,fileid", nodekey, fileid);
    // 发送 HTTP 请求将关键字保存到后端
    if (fileid && !nodekey) {
      axios
        .put(`${baseUrl}/fileapi/saveKeywords/${fileid}`, { keywords })
        .then((response) => {
          // 处理保存成功的情况
          // console.log("关键字已保存", keywords);
        })
        .catch((error) => {
          // 处理保存失败的情况
          console.error("保存失败", error);
        });
    }
  };

  //update file model
  const handleSaveModel = () => {
    const nodekey = props.selectedNode.key;
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    if (fileid && !nodekey) {
      axios
        .put(`${baseUrl}/fileapi/saveModelSet/${fileid}`, { modelSet })
        .then((response) => {
          // 处理保存成功的情况
        })
        .catch((error) => {
          // 处理保存失败的情况
          console.error("保存失败", error);
        });
    }
  };

  //cancel filekeywords
  const handleCancelKeywords = () => {
    // 清空 keywordsinput
    setKeywords("");

    // 重新加载 filekeywords 数据
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    if (fileid) {
      axios
        .get(`${baseUrl}/fileapi/getFileKeywords/${fileid}`)
        .then((response) => {
          // 从后端获取新的 filekeywords 数据
          const fileKeywordsData = response.data;
          // 将新数据设置到 keywords 状态中
          setKeywords(fileKeywordsData.filekeywords);
        })
        .catch((error) => {
          console.error("获取关键字失败", error);
        });
    }
  };

 //cancel filekeywords
  const handleCancelModel = () => {
    // 在组件加载时获取 diagrammodel 数据
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    // 发送请求获取 diagrammodel
    fetch(`${baseUrl}/fileapi/getFileDiagramModel/${fileid}`)
      .then((response) => response.json())
      .then((data) => {
        const foundModel = modelOptions.find(
          (model) => model.value === data.diagrammodel
        );
        if (foundModel) {
          setSelectedModel(foundModel.label);
          setModelSet(foundModel.value);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch diagrammodel: ", error);
      });
  };

//loading file kewords and diagrammodel
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
    const nodekey = props.selectedNode.key;

    // 加载关键字和模型数据
    if (fileid && !nodekey) {
      // 加载关键字数据
      axios
        .get(`${baseUrl}/fileapi/getFileKeywords/${fileid}`)
        .then((response) => {
          const fileKeywordsData = response.data;
          setKeywords(fileKeywordsData.filekeywords);
        })
        .catch((error) => {
          console.error("获取关键字失败", error);
        });

      // 加载模型数据
      fetch(`${baseUrl}/fileapi/getFileDiagramModel/${fileid}`)
        .then((response) => response.json())
        .then((data) => {
          const foundModel = modelOptions.find(
            (model) => model.value === data.diagrammodel
          );
          if (foundModel) {
            setSelectedModel(foundModel.label);
            setModelSet(foundModel.value);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch diagrammodel: ", error);
        });
    }
  }, []);

  //show filekeyword edit
  const showFileKeyword = !props.selectedNode.key;

  //showcontent
  const handleInfoDoubleClick = () => {
    setShowInfodragContent(!showInfodragContent);
  };

  const handleInfoClick = () => {
    setShowInfodragContent(true);
  };

  const handleAddItem = () => {
    setItems((prevItems) => [
      ...prevItems,
      {
        titleId: prevItems.length,
        detailId: prevItems.length,
      },
    ]);
    setTitle((prevTitle) => [...prevTitle, ""]);
    setDetail((prevDetail) => [...prevDetail, ""]);
  };

  //save node title
  const handleSaveItem = () => {
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;
    const modifydate = new Date().toISOString();
    // console.log("SaveItem", fileid, nodekey, modifydate);
    axios
      .post(`${baseUrl}/titlesapi/saveNodeTitle`, {
        fileid,
        nodekey,
        titles: JSON.stringify(title),
        details: JSON.stringify(detail),
        modifydate: modifydate,
      })
      .then((response) => {
        console.log(response.data.message);

        //Refresh display data after adding new data
        props.handleSelectionChanged();
        handleSaveInfoItem();

        setTitle([]);
        setDetail([]);

        //clear input fields
        const titleInputs = document.querySelectorAll(".titlesinput");
        const detailInputs = document.querySelectorAll(".detailsinput");
        titleInputs.forEach((input) => {
          input.value = "";
        });
        detailInputs.forEach((input) => {
          input.value = "";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //save node title and detail to fileid json
  const handleSaveInfoItem = () => {
    // 获取当前节点的fileid
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;

    // 调用后端接口保存信息
    return fetch(`${baseUrl}/infojsonapi/infojson`, {
      method: "POST",
      body: JSON.stringify({ fileid, nodekey }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          // alert("保存成功！");
        } else {
          alert("保存失败！");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  //upload excel file

  const [file, setFile] = useState(null);
  const [updateFilename, setUpdateFilename] = useState("");

  const handleclearfile = () => {
    setFile(null);
  };

  const handleExcelFileChange = (event) => {
    setFile(event.target.files[0]);
    setSelectedFilename(null);
  };

  const handleExcelUpload = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    if (fileid && userid) {
      const formData = new FormData();
      formData.append("excelFile", file);

      try {
        const response = await fetch(
          `${baseUrl}/titlesapi/excelUpload/${fileid}/${userid}`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // console.log("File uploaded successfully");
          setFile(null);

          const fileInput = document.getElementById("excelFileInputId");
          if (fileInput) {
            fileInput.value = "";
          }

          // Fetch and set the updateFilename after successful upload
          setTimeout(() => {
            fetchUpdateFilename(fileid);
          }, 1000);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Error during file upload:", error);
      }
    }
  };

  //getupdatefilename
  const fetchUpdateFilename = async (fileid) => {
    try {
      const response = await fetch(
        `${baseUrl}/titlesapi/getUpdateFilename/${fileid}`
      );

      if (response.ok) {
        const data = await response.json();
        // console.log("data.updateFilenames", data.updateFilenames);
        setUpdateFilename(data.updateFilenames.split(","));
      } else {
        console.error("Error fetching updateFilename");
      }
    } catch (error) {
      console.error("Error fetching updateFilename:", error);
    }
  };

  // Fetch the updateFilename on component mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    if (fileid) {
      fetchUpdateFilename(fileid);
    }
  }, []);

  //delete the excelfile data
  const handleFileDelete = async (filename) => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    try {
      const response = await fetch(
        `${baseUrl}/titlesapi/deleteUpdateFilename/${fileid}/${filename}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        // console.log("File deleted successfully");
        // Fetch and set the updateFilename after successful delete
        fetchUpdateFilename(fileid);
        fetchDataExcelFile(fileid);
        setSelectedFilename(null);
      } else {
        console.error("File deletion failed");
      }
    } catch (error) {
      console.error("Error during file deletion:", error);
    }
  };

  //selected file settings are saved
  const [selectedFilename, setSelectedFilename] = useState(null);

  const handleFilenameClick = (filename) => {
    // 切换选择状态
    setSelectedFilename((prevSelected) =>
      prevSelected === filename ? null : filename
    );
  };

  //save selected to file settings
  const handleSetButtonClick = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    if (fileid && selectedFilename) {
      try {
        const response = await fetch(
          `${baseUrl}/titlesapi/savefiledata/${fileid}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ selectedFilename }),
          }
        );

        if (response.ok) {
          // console.log("Selected filename saved successfully!");
          setSelectedFilename(null);
          fetchDataExcelFile(fileid);
        } else {
          console.error("Failed to save selected filename");
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    } else {
      // console.log("No filename selected");
    }
  };

  //get file nodedata
  const [fileExcelFile, setFileExcelFile] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    if (fileid) {
      fetchDataExcelFile(fileid);
    }
  }, []);

  const fetchDataExcelFile = async (fileid) => {
    try {
      const response = await fetch(
        `${baseUrl}/titlesapi/getFileExcelFile/${fileid}`
      );

      if (response.ok) {
        const data = await response.json();
       // console.log(data.fileexcelfile);
        setFileExcelFile(data.fileexcelfile);
      } else {
        console.error("Error fetching fileexcelfile");
      }
    } catch (error) {
      console.error("Error fetching fileexcelfile:", error);
    }
  };
  
  //get filename
  const [fileNameText, setFileNameText] = useState("");

  //loading fileName from sql by ID
  useEffect(() => {
    async function fetchFileName() {
      try {
        const params = new URLSearchParams(window.location.search);
        const fileid = params.get("fileid");
        if (!fileid) {
          return;
        }
        // console.log("fileid X", fileid);
        const response = await axios.get(
          `${baseUrl}/fileapi/getfilename/${fileid}`
        );
        if (response.data.filename === null) {
          // console.log("Filename is null");
          return;
        } else {
          setFileNameText(response.data.filename);
          // console.log("filename X", response.data.filename);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchFileName();
  }, []);

  //download the file nodefile
  const handleNodeFileDownload = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    if (fileid) {
      try {
        const response = await fetch(
          `${baseUrl}/titlesapi/downloadNodeFileData/${fileid}`
        );

        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileNameText}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error during download:", error);
      }
    }
  };
  
  //upload audio files
  const params = new URLSearchParams(window.location.search);
  const fileid = params.get("fileid");
  const nodekey = props.selectedNode.key;
  const [audioList, setAudioList] = useState([]);

  useEffect(() => {
    if (fileid && nodekey) {
      // Fetch audio list when the component mounts
      fetchAudioList();
    } else if (fileid && !nodekey) {
      setAudioList([]);
    }
  }, [fileid, nodekey]);

  const handleFileChange = (event) => {
    if (fileid && nodekey) {
      const file = event.target.files[0];
      // console.log("Selected File:", file);

      if (file) {
        setTimeout(() => {
          uploadAudio(file);
        }, 300);
      }
    }
  };

  const isFileValid = (file) => {
    const allowedMimes = ["audio/mpeg", "audio/wav", "audio/mp3"];
    return allowedMimes.includes(file.type);
  };

  const uploadAudio = async (file) => {
    try {
      if (!file || !isFileValid(file)) {
        console.error("Invalid file type. Please select a valid audio file.");
        return;
      }

      const formData = new FormData();
      formData.append("audio", file, encodeURIComponent(file.name));

      // console.log("Upload:", fileid, nodekey, formData);

      const response = await axios.post(
        `${baseUrl}/datasql/uploadaudio/${fileid}/${nodekey}`,
        formData
      );

      // Update audio list after successful upload
      fetchAudioList();
    } catch (error) {
      console.error("Error uploading audio file:", error);
    }
  };

  const fetchAudioList = async () => {
    if (fileid && nodekey) {
      try {
        const response = await axios.get(
          `${baseUrl}/datasql/getaudiolist/${fileid}/${nodekey}`
        );
        // console.log("audioList", response.data);
        // 直接使用后端返回的 id
        setAudioList(response.data.audioList);
      } catch (error) {
        console.error("Error fetching audio list:", error);
      }
    }
  };

  const handleAudioDeleteClick = async (id) => {
    console.log("handleAudioDeleteClick", id);
    if (id === currentlyPlaying) {
      handleStopAudio();
    }
    try {
      // 在这里执行删除操作，删除 nodes_audio_table 表中的数据
      await axios.delete(`${baseUrl}/datasql/deleteaudio/${id}`);

      // 更新界面上的 audioList，过滤掉被删除的项
      setAudioList((prevAudioList) =>
        prevAudioList.filter((audio) => audio.aud_id !== id)
      );
    } catch (error) {
      console.error("Error deleting audio:", error);
    }
  };

  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [audioElements, setAudioElements] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayAudio = (audioUrl, aud_id) => {
    const publicIndex = audioUrl.indexOf("public");
    const relativePath = audioUrl.slice(publicIndex + "public".length);
    const newAudioPath = relativePath.replace(/\\/g, "/");
    //const fullAudioPath = `${backUrl}${newAudioPath}`;
    const fullAudioPath = `${baseUrl}${newAudioPath}`;
	
    // 暂停当前正在播放的音频
    if (currentlyPlaying && currentlyPlaying !== aud_id) {
      const currentlyPlayingAudio = audioElements[currentlyPlaying];
      if (currentlyPlayingAudio) {
        currentlyPlayingAudio.pause();
      }
    }

    // 如果点击的是当前正在播放的音频，则暂停播放
    if (currentlyPlaying === aud_id) {
      const currentAudioElement = audioElements[aud_id];
      if (currentAudioElement) {
        currentAudioElement.pause();
        setCurrentlyPlaying(null);
        setIsPlaying(false);
      }
    } else {
      // 创建新的 Audio 元素并播放
      const newAudioElement = new Audio(fullAudioPath);

      // 添加 ended 事件监听器
      newAudioElement.addEventListener("ended", () => {
        handleStopAudio(aud_id);
        setCurrentlyPlaying(null);
        setIsPlaying(false);
      });

      // 使用 setTimeout 添加延迟
      setTimeout(() => {
        newAudioElement.play();
        setAudioElements((prevAudioElements) => ({
          ...prevAudioElements,
          [aud_id]: newAudioElement,
        }));
        setCurrentlyPlaying(aud_id);
        setIsPlaying(true);
      }, 300);
    }
  };

  const handleStopAudio = (aud_id) => {
    // 停止当前正在播放的音频
    if (currentlyPlaying === aud_id) {
      const currentAudioElement = audioElements[aud_id];
      if (currentAudioElement) {
        currentAudioElement.pause();
        setCurrentlyPlaying(null);
        setIsPlaying(false);
      }
    }
  };

  return (
    <>
       <Draggable cancel=".infodraggabletitle,.nodetitles input,.keywordsinput,.modelsetlayout,.excelfileselect,.aipic,.audiopic,.audiolist, .inforenewbutton,.infosetbutton, .infocancelbutton, .nodedetails input ,.nodeaiinfo input,.infohead img,.icons3,.bottombotton">
        <div className={draggableActive2 ? "infocard active" : "infocard"}>
          <div className="infohead">
            <p
              className="infodraggabletitle"
              onDoubleClick={handleInfoDoubleClick}
            >
              {t("infodraggable.info-headtitle")}
            </p>
            <img
              className="closeicon"
              src={closebt}
              onClick={() => dispatch05({ type: "DATADRAGGABLE" })}
            />
          </div>

          {/* <div className="bottombotton">
            <img
              className="morebtn"
              src={morebt}
              // onClick={handleMoreToggle}
            />
          </div> */}

          {showInfodragContent ? (
            <div className="infoitems">
              {showFileKeyword && (
                <div className="filekeyword">
                  <label>{t("infodraggable.info-label1")}</label>
                  <textarea
                    className="keywordsinput"
                    placeholder={t("infodraggable.info-inputplaceholder1")}
                    rows="2"
                    cols="25"
                    autoComplete="off"
                    value={keywords}
                    onChange={handleKeywordsChange}
                  />
 
                  <label>{t("infodraggable.info-label2")}</label>
                  <div
                    className="modelsetlayout"
                    onMouseLeave={() => setShowModelOptions(false)}
                  >
                    <div className="modelselect" onClick={handleModelClick}>
                      {selectedModel}
                    </div>
                    {showModelOptions && (
                      <div
                        className="modeloptions"
                        // style={{ position: "absolute", zIndex: 1 }}
                        onMouseLeave={() => setShowModelOptions(false)}
                      >
                        {modelOptions.map((model) => (
                          <div
                            key={model.value}
                            onClick={() => handleModelOptionSelect(model)}
                            onMouseEnter={() => setShowModelOptions(true)}
                            className={`modeloption ${
                              selectedModel === model.label ? "selected" : ""
                            }`}
                          >
                            {model.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="button-group">
                    <button
                      className="inforenewbutton"
                      title={t("infodraggable.info-btntitle1")}
                      onClick={() => {
                        handleSaveKeywords();
                        handleSaveModel();
                      }}
                    >
                      {t("infodraggable.info-btn1")}
                    </button>
                    <button
                      className="infocancelbutton"
                      title={t("infodraggable.info-btntitle2")}
                      onClick={() => {
                        handleCancelKeywords();
                        handleCancelModel();
                      }}
                    >
                      {t("infodraggable.info-btn2")}
                    </button>
                  </div>
                  {/* data_update */}
                  <label>{t("infodraggable.info-label20")}</label>
                  <div className="button-group">
                    <button
                      className="inforenewbutton"
                      title={t("infodraggable.btntitle20")}
                    >
                      {t("infodraggable.info-btn20")}
                    </button>
                    <button
                      className="infosetbutton"
                      title={t("infodraggable.info-btntitle201")}
                      onClick={handleNodeFileDownload}
                    >
                      {t("infodraggable.info-btn201")}
                    </button>
                  </div>
                  <label> {t("infodraggable.info-label21")}</label>
                  <div className="excelfileselect">
                  <div className="dataexcelname">
                      {fileExcelFile
                        ? fileExcelFile
                        : t("infodraggable.info-dataexcelname")}
                    </div>
                    {updateFilename.length > 0 && (
                      <div className="excelnamelayout">
                        <ul>
                          {[
                            ...(updateFilename.length > 0
                              ? [t("infodraggable.info-dataexcelnamelist")]
                              : []),
                            ...updateFilename,
                          ].map((filename, index) => (
                            <li
                              key={index}
                              className={`excelnameitem${
                                selectedFilename === filename ? " selected" : ""
                              }`}
                              onClick={() => handleFilenameClick(filename)}
                            >
                              <span className="showfilename">{filename}</span>
                              {index > 0 && filename && (
                                <img
                                  className="closeicon"
                                  src={closebt}
                                  onClick={() => handleFileDelete(filename)}
                                />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <label
                      className="excelfilelabel"
                      htmlFor="excelFileInputId"
                      title={t("infodraggable.info-excelfilelabeltitle")}
                    >
                      {t("infodraggable.info-label22")}
                    </label>
                    {file && (
                      <div className="selectdatafile">
                        <span className="uploadedFileName">{file.name}</span>
                        <img
                          className="closeicon2"
                          src={closebt}
                          onClick={handleclearfile}
                        />
                      </div>
                    )}
                    <input
                      type="file"
                      name="excelFile"
                      className="handlefileinput"
                      accept=".xls, .xlsx"
                      onChange={handleExcelFileChange}
                      id="excelFileInputId"
                    />
                  </div>
                  <div className="button-group">
                    <button
                      className="inforenewbutton"
                      onClick={handleExcelUpload}
                      title={t("infodraggable.info-btntitle21")}
                    >
                      {t("infodraggable.info-btn21")}
                    </button>
                    <button
                      className="infosetbutton"
                      title={t("infodraggable.info-btntitle22")}
                      onClick={handleSetButtonClick}
                    >
                      {t("infodraggable.info-btn22")}
                    </button>
                  </div>
		</div>
              )}

              {!showFileKeyword && (
                <>
                  <p className="nodedesc">{props.selectedNode.text}</p>
                  <div className="infoshow">
                    {props.selectedNode.title.map((_, index) => (
                      <React.Fragment key={index}>
                        <label className="nodetitles">
                          <input
                            className="titlesshow"
                            type="text"
                            id={`titles${index}`}
                           // placeholder="新维度"
                           placeholder={t(
                              "infodraggable.info-inputplaceholder2"
                            )}
                            autoComplete="off"
                            value={props.selectedNode.title[index] + " :"}
                            onChange={(e) => {
                              const newTitle = [...props.selectedNode.title];
                              newTitle[index] = e.target.value;
                              props.setSelectedNode({
                                ...props.selectedNode,
                                title: newTitle,
                              });
                            }}
                          />
                        </label>
                        <p className="nodedetails">
                          <input
                            className="detailsshow"
                            type="text"
                            id={`details${index}`}
                           //placeholder="信息内容"
                           placeholder={t(
                              "infodraggable.info-inputplaceholder3"
                            )}
                            autoComplete="off"
                            value={props.selectedNode.detail[index]}
                            onChange={(e) => {
                              const newDetail = [...props.selectedNode.detail];
                              newDetail[index] = e.target.value;
                              props.setSelectedNode({
                                ...props.selectedNode,
                                detail: newDetail,
                              });
                            }}
                          />
                        </p>
                      </React.Fragment>
                    ))}
                  </div>

                  <div className="nodeinfo">
                    {items.map(({ titleId, detailId }, index) => (
                      <React.Fragment key={titleId}>
                        <label className="nodetitles">
                          <input
                            className="titlesinput"
                            type="text"
                            id={`titles${titleId}`}
                           // placeholder="新维度"
                           placeholder={t(
                              "infodraggable.info-inputplaceholder2"
                            )}
                            autoComplete="off"
                            value={title[index]}
                            onChange={(e) => {
                              setTitle((prevTitle) => {
                                const newTitle = [...prevTitle];
                                newTitle[index] = e.target.value;
                                return newTitle;
                              });
                            }}
                          />
                        </label>
                        <p className="nodedetails">
                          <input
                            className="detailsinput"
                            type="text"
                            id={`details${detailId}`}
                           //placeholder="信息内容"
                           placeholder={t(
                              "infodraggable.info-inputplaceholder3"
                            )}
                            autoComplete="off"
                            value={detail[index]}
                            onChange={(e) => {
                              setDetail((prevDetail) => {
                                const newDetail = [...prevDetail];
                                newDetail[index] = e.target.value;
                                return newDetail;
                              });
                            }}
                          />
                        </p>
                      </React.Fragment>
                    ))}
                    <div className="button-group">
                      <button
                        className="infoaddbutton"
                        title={t("infodraggable.info-btntitle3")}
                        onClick={(e) => {
                          e.stopPropagation(); // 阻止事件冒泡
                          e.preventDefault(); // 阻止默认行为
                          handleAddItem();
                        }}
                      >
                        {t("infodraggable.info-btn3")}
                      </button>
                      <button
                        className="infosavebutton"
                        title={t("infodraggable.info-btntitle4")}
                        onClick={(e) => {
                          e.stopPropagation(); // 阻止事件冒泡
                          e.preventDefault(); // 阻止默认行为
                          handleSaveItem();
                        }}
                      >
                        {t("infodraggable.info-btn4")}
                      </button>
                    </div>
                  </div>
                </>
              )}

              <div className="nodeimage">
                <label>{t("infodraggable.info-label3")}</label>
                <div className="nodepic">
                  <Carousel />
                </div>
              </div>
              <div className="nodeaiinfo">
                <label>{t("infodraggable.info-label4")}</label>
                <div className="aipic" title={t("infodraggable.info-ai-title")}>
                  <img src={aiAuto} alt="" />
                </div>
                <input className="aiinput" placeholder={t("infodraggable.info-ai-placeholder")}/>
              </div>
 	      <div className="nodeaudioinfo">
              <label>{t("infodraggable.info-label5")}</label>
                <div
                  className="audiopic"
                  title={t("infodraggable.info-audio-title")}
                >
                  <label>
                    <img src={audiobt} alt="" />
                    <input
                      type="file"
                      accept="audio/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
                {/* audiolist */}
                {audioList.length > 0 && (
                  <div className="audiolist">
                    <ul className="listitems">
                      {audioList.map((audio) => (
                        <li className="listitem" key={audio.aud_id}>
                          <div
                            className="audiotitle"
                            onClick={() =>
                              handlePlayAudio(audio.aud_url, audio.aud_id)
                            }
                          >
                            {audio.aud_title}
                          </div>
                          {!isPlaying && (
                            <img
                              className="closeicon2"
                              src={closebt}
                              onClick={() =>
                                handleAudioDeleteClick(audio.aud_id)
                              }
                            />
                          )}
                          {isPlaying && currentlyPlaying === audio.aud_id && (
                            <img className="closeicon3" src={playbt} alt="" />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="speak-bottom">
                <div className="bottom-left">
                  <div className="speakicons" title={t("infodraggable.info-icon-title1")}>
                    <ChatBubble className="icons3" />
                  </div>
                  <span className="number">15</span>
                  <div className="speakicons" title={t("infodraggable.info-icon-title2")}>
                    <Folder className="icons3" />
                  </div>
                  <span className="number">5</span>
                  <div className="speakicons" title={t("infodraggable.info-icon-title3")}>
                    <WarningTriangleOutline className="icons3" />
                  </div>
                  <span className="number">10</span>
                </div>
              </div>
              {/* ---- */}
            </div>
          ) : (
            <div className="bottombotton" onClick={handleInfoClick}>
              <img
                className="morebtn"
                src={morebt}
                // onClick={handleMoreToggle}
              />
            </div>
          )}
        </div>
      </Draggable>
    </>
  );
}
