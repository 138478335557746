import "./noticepopup.scss";
import Alarm from "../../../img/error_outline_color.svg";
import Pass from "../../../img/check_circle_outline_color.svg";
import Fail from "../../../img/highlight_off_color.svg";
import Help from "../../../img/help_outline_color.svg";
import Info from "../../../img/info_color.svg";

import { useContext } from "react";
import baseUrl from "../../../configs/baseurl";
import { PopupActiveContext } from "../../../context/popupActiveContext";

import { useTranslation } from "react-i18next";

const NoticePopup = () => {
  const { t } = useTranslation();

  const { popupActive1 } = useContext(PopupActiveContext);
  //popupActiveContext
  const { dispatch3 } = useContext(PopupActiveContext);
  return (
    <>
      <div
        className={
          popupActive1 ? "popuplayout-notice active" : "popuplayout-notice"
        }
      >
        <div className="popupbody notice">
          <div className="notice-title">
            <span className="title-name">
              {t("noticePopup.notice-titlename")}
            </span>
            <span className="clear">{t("noticePopup.notice-clear")}</span>
          </div>
          <div className="notice-lists">
            <div className="lists-content">
              <div className="notice-list">
                <div className="list-left">
                  <img src={Alarm} alt="" />
                </div>
                <div className="list-right">
                  <div className="right-up">注意事项</div>
                  <div className="right-down">1小时</div>
                </div>
              </div>
              <div className="notice-list">
                <div className="list-left">
                  <img src={Pass} alt="" />
                </div>
                <div className="list-right">
                  <div className="right-up">通过事项</div>
                  <div className="right-down">1小时</div>
                </div>
              </div>
              <div className="notice-list">
                <div className="list-left">
                  <img src={Fail} alt="" />
                </div>
                <div className="list-right">
                  <div className="right-up">错误事项</div>
                  <div className="right-down">1小时</div>
                </div>
              </div>
              <div className="notice-list">
                <div className="list-left">
                  <img src={Help} alt="" />
                </div>
                <div className="list-right">
                  <div className="right-up">帮助事项</div>
                  <div className="right-down">1小时</div>
                </div>
              </div>
              <div className="notice-list">
                <div className="list-left">
                  <img src={Info} alt="" />
                </div>
                <div className="list-right">
                  <div className="right-up">信息事项</div>
                  <div className="right-down">1小时</div>
                </div>
              </div>
            </div>
          </div>
          <div className="notice-footer">
            <span>{t("noticePopup.notice-footer")}</span>
          </div>
        </div>
      </div>
      <div
        className={popupActive1 ? "overlay active" : "overlay"}
        onClick={() => dispatch3({ type: "NOTICE" })}
      ></div>
    </>
  );
};

export default NoticePopup;
