import Leftlayout from "../leftlayout/Leftlayout";
import UserTable from "../usertable/UserTable";
// import NodeTable1 from "../nodetable/NodeTable1";

const MainUserTable= () => {
    return ( 
        <div className="mainusertable">
            <div className="layouttable-left">
            <Leftlayout/>
            </div>
            <div className="layouttable-right">
            <UserTable/>
            </div>
        </div>
        );
}
 
export default MainUserTable;