const chartList = {
  chartlist: [
    {
      id: 1,
      title: "分析图表01",
    },
    {
      id: 2,
      title: "分析图表02",
    },
    {
      id: 3,
      title: "分析图表03",
    },
    {
      id: 4,
      title: "分析图表04",
    },
    {
      id: 5,
      title: "分析图表05",
    },
    {
      id: 6,
      title: "分析图表06",
    },
  ],
  getList: function () {
    return (
      (localStorage.getItem("theList") &&
        JSON.parse(localStorage.getItem("theList"))) ||
      this.chartlist
    );
  },
  saveList: (chartlist) => {
    localStorage.setItem("theList", JSON.stringify(chartlist));
  },
};

export default chartList;
