import React, { useState } from "react";
import frontUrl from "../../configs/fronturl";

const ImageModal = ({ item, onClose }) => {
  const [isExiting, setIsExiting] = useState(false);
  if (!item) return null;
  const handleDoubleClick = () => {
    setIsExiting(true);
    setTimeout(() => {
      console.log("im",item);
      onClose(); // 退出动画完成后关闭
      setIsExiting(false); // 重置状态
    }, 500); // 动画时间与 scaleOut 的时间一致
  };
  return (
    <div
      className={`tanchuang ${item ? "show" : ""} ${isExiting ? "exit" : ""}`}
      onDoubleClick={handleDoubleClick}
    >
      <img src={`${frontUrl}/images/${item}.jpg`} alt={item} />
    </div>
  );
};

export default ImageModal;
