import * as go from "gojs/release/go-module";
import { GuidedDraggingTool } from "gojs/extensionsJSM/GuidedDraggingTool";
import { DrawCommandHandler } from "gojs/extensionsJSM/DrawCommandHandler";

// import * as go from "../modules/sysmodules/release/go-module";
// import { GuidedDraggingTool } from "../modules/sysmodules/extensionsJSM/GuidedDraggingTool";
// import { DrawCommandHandler } from "../modules/sysmodules/extensionsJSM/DrawCommandHandler";

import { FigureButton } from "gojs/extensionsJSM/Figures";

// node selection style
// function onSelectionChanged(node) {
//   var icon = node.findObject("SHAPE");
//   if (icon !== null) {
//     //判断node的选择状态
//     if (node.isSelected) {
//       icon.fill = "#ECF2FF"; //选择状态的颜色
//     } else {
//       icon.fill = "#f9fbff"; //未选择状态的颜色
//     }
//   }
// }
//--------------------------------------

// export function handleNodeClick(e, obj, props, callback) {
//   if (typeof props === 'undefined') {
//     console.log('props is undefined');
//     return;
//   }
//   var node = obj.part;
//   if (node !== null) {
//     var key = node.key;
//     var nodedesc = node.data.text;
//     console.log(key);
//     console.log(nodedesc);
//     props.setState(
//       {
//         selectedNode: {
//           key: key,
//           nodedesc: nodedesc,
//         },
//       },
//       () => {
//         callback(key, nodedesc);
//       }
//     );
//   }
// }

// export function handleNodeClick(e, obj, props) {
//   if (typeof props === 'undefined') {
//     console.log('props is undefined');
//     return;
//   }
//   var node = obj.part;
//   if (node !== null) {
//     var key = node.key;
//     var nodedesc = node.data.text;
//     console.log(key);
//     console.log(nodedesc);
//     props.setState(
//       {
//         selectedNode: {
//           key: key,
//           nodedesc: nodedesc,
//           // add key and nodedesc as properties of selectedNode object
//           keyProp: "key",
//           nodeDescProp: "nodedesc"
//         },
//       },
//       () => {
//         props.onNodeSelect(props.selectedNode);
//       }
//     );
//   }
// }

export function diagram() {
  // console.log("初始化");
  const $ = go.GraphObject.make;

  const diagram = $(go.Diagram, {
    // have mouse wheel events zoom in and out instead of scroll up and down
    "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
    initialAutoScale: go.Diagram.Uniform,
    "linkingTool.direction": go.LinkingTool.ForwardsOnly,
    layout: $(go.LayeredDigraphLayout, {
      isInitial: false,
      isOngoing: false,
      layerSpacing: 50,
    }),
    //-----
    scrollMode: go.Diagram.InfiniteScroll, // allow the diagram to be scrolled beyond content
    padding: 20, // extra space when scrolled all the way
    //grid settings
    // grid: $(
    //   go.Panel,
    //   "Grid", // a simple 10x10 grid
    //   $(go.Shape, "LineH", {
    //     stroke: "rgba(140,140,140,0.2)",
    //     strokeWidth: 0.5,
    //   }),
    //   $(go.Shape, "LineV", {
    //     stroke: "rgba(140,140,140,0.2)",
    //     strokeWidth: 0.5,
    //   })
    // ),            //grid line
    "draggingTool.isGridSnapEnabled": true,
    handlesDragDropForTopLevelParts: true,

    mouseDrop: (e) => {
      // when the selection is dropped in the diagram's background,
      // make sure the selected Parts no longer belong to any Group
      var ok = e.diagram.commandHandler.addTopLevelParts(
        e.diagram.selection,
        true
      );
      if (!ok) e.diagram.currentTool.doCancel();
    },
    commandHandler: $(DrawCommandHandler), // support offset copy-and-paste

    "undoManager.isEnabled": false, // enable undo & redo  xx

    // //add node by click
    // PartCreated: (e) => {
    //   var node = e.subject; // the newly inserted Node -- now need to snap its location to the grid
    //   node.location = node.location
    //     .copy()
    //     .snapToGridPoint(
    //       e.diagram.grid.gridOrigin,
    //       e.diagram.grid.gridCellSize
    //     );
    //   setTimeout(() => {
    //     // and have the user start editing its text
    //     e.diagram.commandHandler.editTextBlock();
    //   }, 20);
    // },

    //New Group
    "commandHandler.archetypeGroupData": { isGroup: true, text: "新组合" },
    SelectionGrouped: (e) => {
      var group = e.subject;
      setTimeout(() => {
        // and have the user start editing its text
        e.diagram.commandHandler.editTextBlock();
      });
    },

    //linkRelinked
    LinkRelinked: (e) => {
      // re-spread the connections of other links connected with both old and new nodes
      var oldnode = e.parameter.part;
      oldnode.invalidateConnectedLinks();
      var link = e.subject;
      if (e.diagram.toolManager.linkingTool.isForwards) {
        link.toNode.invalidateConnectedLinks();
      } else {
        link.fromNode.invalidateConnectedLinks();
      }
    },

    //savedModel toJson
    // ModelChanged: function (e) {
    //   if (e.isTransactionFinished) {
    //     document.getElementById("savedModel").textContent =
    //       diagram.model.toJson();
    //   }
    // },
    //----------------------------------------------------------------
    model: $(go.GraphLinksModel, {
      linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    }),
  });

  // //background picture set
  // diagram.add(
  //   $(
  //     go.Part, // this Part is not bound to any model data
  //     {
  //       layerName: "Background",
  //       position: new go.Point(0, -100),
  //       selectable: false,
  //       pickable: false,
  //     },
  //     $(go.Picture, {
  //       // width: 360,
  //       // height: 380,
  //       // source: backgroundImage,
  //       // source: "http://localhost:8800/uploads/backgroudimge001.svg" ,
  //       source: "http://localhost:8800/uploads/backgroudimge001.jpg",
  //       // source: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Apple_Computer_Logo_rainbow.svg/800px-Apple_Computer_Logo_rainbow.svg.png",
  //     })
  //   )
  // );

  //-----------------------------------

  // //handle node selection key
  // const handleNodeClick = (e, obj) => {
  //   var node = obj.part;
  //   if (node !== null) {
  //     var key = node.key;
  //     var nodedesc = node.data.text;
  //     console.log(key);
  //     console.log(nodedesc);
  //     this.setState({
  //       selectedNode: {
  //         key: key,
  //         nodedesc: nodedesc,
  //       },
  //     });
  //   }
  // };

  // function handleNodeClick(e, obj) {
  //   var node = obj.part;
  //   if (node !== null) {
  //     var key = node.key;
  //     var nodedesc = node.data.text;
  //     console.log(key);
  //     console.log(nodedesc);
  //     this.setState({
  //       selectedNode: {
  //         key: key,
  //         nodedesc: nodedesc,
  //       },
  //     });
  //   }
  // }

  // tooltip hovered node
  var nodeContextMenu = $(
    go.Adornment,
    "Spot",
    { background: "transparent" }, // to help detect when the mouse leaves the area
    $(go.Placeholder),
    $(
      go.Panel,
      "Auto",
      // { alignment: go.Spot.Right, alignmentFocus: new go.Spot(0, 1, 0, 10) , width: 160},
      { alignment: go.Spot.Right, alignmentFocus: go.Spot.Left },
      $(go.Shape, "RoundedRectangle", {
        fill: "#ffffffeb",
        // fill: "#aab8c2",
        stroke: "#9ca2b598",
        strokeWidth: 0.5,
        parameter1: 5,
      }),
      $(
        go.Panel,
        "Vertical",
        {
          maxSize: new go.Size(130, NaN),
          defaultAlignment: go.Spot.Left,
          // background: "#ffffff",
          margin: 10,
        },

        $(
          go.TextBlock,
          {
            font: "8pt sans-serif",
            textAlign: "center",
            stroke: "#393c48",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part.adornedPart;
            if (
              node.data.titles1 ||
              node.data.details1 ||
              node.data.titles2 ||
              node.data.details2 ||
              node.data.titles3 ||
              node.data.details3 ||
              node.data.titles4 ||
              node.data.details4
            ) {
              return node.data.text;
            } else {
              return "";
            }
          })
        ),
        //title
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#1876f2",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            // return nodeData.titles1;
            var title = nodeData.titles1 || "";
            return title + " : ";
          }),
          //show visibility
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles1;
          })
        ),
        //details
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#6c757d",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details1;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details1;
          })
        ),
        //title
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#1876f2",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            var title = nodeData.titles2 || "";
            return title + " : ";
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles2;
          })
        ),
        //details
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#6c757d",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details2;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details2;
          })
        ),
        //title
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#1876f2",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            var title = nodeData.titles3 || "";
            return title + " : ";
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles3;
          })
        ),
        //details
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#6c757d",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details3;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details3;
          })
        ),
        //title
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#1876f2",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            var title = nodeData.titles4 || "";
            return title + " : ";
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles4;
          })
        ),
        //details
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#6c757d",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details4;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details4;
          })
        )
      )
    )
  );

  // define a simple Node template
  //001 define a simple text Node template
  diagram.nodeTemplateMap.add(
    "TextNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        movable: false, //xx
        selectable: true, //xx   xx
        resizable: false, //xx
        resizeCellSize: new go.Size(10, 10),
        // //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave,
        contextMenu: createNodeContextMenu(), // 添加 contextMenu 函数   xxx
      },

      //--------hover node context
      {
        mouseEnter: (e, node) => {
          var key = node.data.key;
         // console.log("Hovered node key: " + key);
          if (
            node.data.titles1 ||
            node.data.details1 ||
            node.data.titles2 ||
            node.data.details2 ||
            node.data.titles3 ||
            node.data.details3 ||
            node.data.titles4 ||
            node.data.details4
          ) {
            nodeContextMenu.adornedObject = node;
            nodeContextMenu.mouseLeave = (ev, cm) => {
              node.removeAdornment("ContextMenuOver");
            };
            node.addAdornment("ContextMenuOver", nodeContextMenu);
          }
        },
      },

      //-----------

      // //picture
      // $(
      //   go.Picture,
      //   { source: null },
      //   new go.Binding("source", "imageurl")
      //   // new go.Binding("source", "imageurl", function(url) {
      //   //   return url || null; // 将imageurl属性的值转换为null，如果它是undefined或空字符串
      //   // })
      //   // new go.Binding("source", "modelData.backgroundImageUrl")
      // ),

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),
      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",

            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
        $(go.Shape, {
          width: 100,
          height: 40,
          strokeWidth: 0,
          fill: "transparent",
          cursor: "grab",
        }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 20,
            wrap: go.TextBlock.WrapFit,
            cursor: "pointer", //xx
            editable: false, //xx
            // cursor: "pointer",
            // //hltext functions
            // click: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     // window.location.href = hltext;
            //     // window.open(hltext, "_blank");
            //     window.open("http://" + hltext, "_blank");
            //   }
            // },
            // mouseEnter: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     obj.cursor = "pointer";
            //     obj.isUnderline = true;
            //   }
            // },
            // mouseLeave: function (e, obj) {
            //   obj.isUnderline = false;
            // },
            // show the Adornment when a mouseHover event occurs
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        )
        // //contextMenu
        // {
        //   // define a context menu for each node
        //   contextMenu: $(
        //     "ContextMenu", // that has one button
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),

        //         // margin: new go.Margin(6, 10, 6, 3),
        //         // defaultAlignment: go.Spot.Left,
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "状态")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "详细信息")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "上传文件")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "咨询交流")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "资料库")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "AI资讯")
        //     )
        //     // more ContextMenuButtons would go here
        //   ), // end Adornment
        // }
        //--------
        // {
        //   // show the Adornment when a mouseHover event occurs
        //   mouseHover: function (e, obj) {
        //     var node = obj.part;
        //     var adornment = node.findAdornment("mouseHover");
        //     if (adornment) {
        //       adornment.adornedObject = node;
        //       node.addAdornment("mouseHover", adornment);
        //     } else {
        //       node.addAdornment("mouseHover", nodetoolTip);
        //     }
        //   },
        //   // hide the Adornment when the mouse leaves the node
        //   mouseLeave: function (e, obj) {
        //     var node = obj.part;
        //     node.removeAdornment("mouseHover");
        //   },
        // }
      ),
      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  //002 define a simple picture Node template
  diagram.nodeTemplateMap.add(
    "PictureNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        movable: false, //xx
        selectable: true, //xx   xx
        resizable: false, //xx
        resizeCellSize: new go.Size(10, 10),
        //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave
      },

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),

      //--------hover node context
      {
        mouseEnter: (e, node) => {
          var key = node.data.key;
          // console.log("Hovered node key: " + key);
          if (
            node.data.titles1 ||
            node.data.details1 ||
            node.data.titles2 ||
            node.data.details2 ||
            node.data.titles3 ||
            node.data.details3 ||
            node.data.titles4 ||
            node.data.details4
          ) {
            nodeContextMenu.adornedObject = node;
            nodeContextMenu.mouseLeave = (ev, cm) => {
              node.removeAdornment("ContextMenuOver");
            };
            node.addAdornment("ContextMenuOver", nodeContextMenu);
          }
        },
      },

      //picture
      $(
        go.Picture,
        { name: "SHAPE", source: null },
        new go.Binding("source", "imageurl", function (imageurl) {
         return imageurl.replace("https://api.xxplans.com/uploads", "https://api.xxplans.com/api/uploads");
        })
      ),

      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",

            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
        $(go.Shape, {
          width: 100,
          height: 40,
          strokeWidth: 0,
          fill: "transparent",
          cursor: "grab",
        }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 10,
            wrap: go.TextBlock.WrapFit,
            cursor: "pointer", //xx
            editable: false, //xx
            // cursor: "pointer",
            // //hltext functions
            // click: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     // window.location.href = hltext;
            //     // window.open(hltext, "_blank");
            //     window.open("http://" + hltext, "_blank");
            //   }
            // },
            // mouseEnter: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     obj.cursor = "pointer";
            //     obj.isUnderline = true;
            //   }
            // },
            // mouseLeave: function (e, obj) {
            //   obj.isUnderline = false;
            // },
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        )
        // //contextMenu
        // {
        //   // define a context menu for each node
        //   contextMenu: $(
        //     "ContextMenu", // that has one button
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),

        //         // margin: new go.Margin(6, 10, 6, 3),
        //         // defaultAlignment: go.Spot.Left,
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "状态")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "详细信息")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "上传文件")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "咨询交流")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "资料库")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "AI资讯")
        //     )
        //     // more ContextMenuButtons would go here
        //   ), // end Adornment
        // }
      ),
      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  //003 define a simple picture Node template
  diagram.nodeTemplateMap.add(
    "StaticPictureNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        movable: false, //xx
        selectable: true, //xx   xx
        resizable: false, //xx
        resizeCellSize: new go.Size(10, 10),
        //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave
      },

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),

      //--------hover node context
      {
        mouseEnter: (e, node) => {
          var key = node.data.key;
          // console.log("Hovered node key: " + key);
          if (
            node.data.titles1 ||
            node.data.details1 ||
            node.data.titles2 ||
            node.data.details2 ||
            node.data.titles3 ||
            node.data.details3 ||
            node.data.titles4 ||
            node.data.details4
          ) {
            nodeContextMenu.adornedObject = node;
            nodeContextMenu.mouseLeave = (ev, cm) => {
              node.removeAdornment("ContextMenuOver");
            };
            node.addAdornment("ContextMenuOver", nodeContextMenu);
          }
        },
      },

      //picture
      $(
        go.Picture,
        { name: "SHAPE", source: null },
        new go.Binding("source", "imageurl", function (imageurl) {
         return imageurl.replace("https://api.xxplans.com/uploads", "https://api.xxplans.com/api/uploads");
        })
      ),

      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",

            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
        $(go.Shape, {
          width: 100,
          height: 40,
          strokeWidth: 0,
          fill: "transparent",
          cursor: "grab",
        }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 10,
            wrap: go.TextBlock.WrapFit,
            cursor: "pointer", //xx
            editable: false, //xx
            // cursor: "pointer",
            // //hltext functions
            // click: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     // window.location.href = hltext;
            //     // window.open(hltext, "_blank");
            //     window.open("http://" + hltext, "_blank");
            //   }
            // },
            // mouseEnter: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     obj.cursor = "pointer";
            //     obj.isUnderline = true;
            //   }
            // },
            // mouseLeave: function (e, obj) {
            //   obj.isUnderline = false;
            // },
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        )
        // //contextMenu
        // {
        //   // define a context menu for each node
        //   contextMenu: $(
        //     "ContextMenu", // that has one button
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),

        //         // margin: new go.Margin(6, 10, 6, 3),
        //         // defaultAlignment: go.Spot.Left,
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "状态")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "详细信息")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "上传文件")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "咨询交流")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "资料库")
        //     ),
        //     $(
        //       "ContextMenuButton",
        //       {
        //         "ButtonBorder.fill": "white",
        //         _buttonFillOver: "#F3FBFF",
        //         width: 260,
        //         maxSize: new go.Size(90, 30),
        //       },
        //       $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "AI资讯")
        //     )
        //     // more ContextMenuButtons would go here
        //   ), // end Adornment
        // }
      ),
      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: false, //xxx
        toLinkable: false, //xx
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  // // define a simple Node template
  // diagram.nodeTemplate = $(
  //   go.Node,
  //   "Auto",
  //   {
  //     locationSpot: go.Spot.Center,
  //     locationObjectName: "SHAPE",
  //     desiredSize: new go.Size(120, 50),
  //     minSize: new go.Size(5, 5),
  //     movable: false, //xx
  //     selectable: true, //xx   xx
  //     resizable: false, //xx
  //     resizeCellSize: new go.Size(10, 10),
  //     //shadow
  //     // isShadowed: true,
  //     // shadowBlur: 2,
  //     // shadowOffset: new go.Point(0, 3),
  //     // shadowColor: "rgba(0, 0, 0, .14)",
  //     // mouseEnter: mouseEnter,
  //     // mouseLeave: mouseLeave
  //   },

  //   // //picture
  //   // $(
  //   //   go.Picture,
  //   //   { source: null },
  //   //   new go.Binding("source", "imageurl")
  //   //   // new go.Binding("source", "imageurl", function(url) {
  //   //   //   return url || null; // 将imageurl属性的值转换为null，如果它是undefined或空字符串
  //   //   // })
  //   //   // new go.Binding("source", "modelData.backgroundImageUrl")
  //   // ),

  //   // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
  //   new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
  //     go.Point.stringify
  //   ),
  //   new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
  //     go.Size.stringify
  //   ),
  //   $(
  //     go.Panel,
  //     "Auto",
  //     $(
  //       go.Shape,
  //       {
  //         // the border
  //         name: "SHAPE",
  //         fill: "white",
  //         portId: "",

  //         // fromLinkable: true,
  //         // toLinkable: true,
  //         // fromLinkableDuplicates: true,
  //         // toLinkableDuplicates: true, //du node link
  //         // fromSpot: go.Spot.AllSides,
  //         // toSpot: go.Spot.AllSides,
  //         cursor: "grab",
  //       },
  //       new go.Binding("figure"),
  //       new go.Binding("fill"),
  //       new go.Binding("stroke", "color"),
  //       new go.Binding("strokeWidth", "thickness"),
  //       new go.Binding("strokeDashArray", "dash")
  //       // gold if highlighted, white otherwise
  //       // new go.Binding("fill", "isHighlighted", (h) =>
  //       //   h ? "gold" : "#ffffff"
  //       // ).ofObject()
  //     ),
  //     // this Shape prevents mouse events from reaching the middle of the port
  //     $(go.Shape, {
  //       width: 100,
  //       height: 40,
  //       strokeWidth: 0,
  //       fill: "transparent",
  //       cursor: "grab",
  //     }),
  //     $(
  //       go.TextBlock,
  //       {
  //         margin: 1,
  //         font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
  //         name: "TEXT",
  //         textAlign: "center",
  //         overflow: go.TextBlock.OverflowEllipsis,
  //         maxLines: 10,
  //         wrap: go.TextBlock.WrapFit,
  //         cursor: "pointer", //xx
  //         editable: false, //xx
  //         // cursor: "pointer",
  //         // //hltext functions
  //         // click: function (e, obj) {
  //         //   const node = obj.part;
  //         //   const hltext = node.data.hltext;
  //         //   if (hltext) {
  //         //     // window.location.href = hltext;
  //         //     // window.open(hltext, "_blank");
  //         //     window.open("http://" + hltext, "_blank");
  //         //   }
  //         // },
  //         // mouseEnter: function (e, obj) {
  //         //   const node = obj.part;
  //         //   const hltext = node.data.hltext;
  //         //   if (hltext) {
  //         //     obj.cursor = "pointer";
  //         //     obj.isUnderline = true;
  //         //   }
  //         // },
  //         // mouseLeave: function (e, obj) {
  //         //   obj.isUnderline = false;
  //         // },
  //       },
  //       // this Binding is TwoWay due to the user editing the text with the TextEditingTool
  //       new go.Binding("text").makeTwoWay(),
  //       new go.Binding("stroke", "color"),
  //       //xx
  //       new go.Binding("font", "font").makeTwoWay(),
  //       new go.Binding("textAlign", "textalign").makeTwoWay()
  //     ),
  //     //contextMenu
  //     {
  //       // define a context menu for each node
  //       contextMenu: $(
  //         "ContextMenu", // that has one button
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),

  //             // margin: new go.Margin(6, 10, 6, 3),
  //             // defaultAlignment: go.Spot.Left,
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "状态")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "详细信息")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "上传文件")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "咨询交流")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "资料库")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "AI资讯")
  //         )
  //         // more ContextMenuButtons would go here
  //       ), // end Adornment
  //     }
  //   ),
  //   //top port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Top,
  //     portId: "t",
  //     fromSpot: go.Spot.Top,
  //     toSpot: go.Spot.Top,
  //     fromLinkable: false, //xxx
  //     toLinkable: false, //xx
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //bottom port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Bottom,
  //     portId: "b",
  //     fromSpot: go.Spot.Bottom,
  //     toSpot: go.Spot.Bottom,
  //     fromLinkable: false, //xxx
  //     toLinkable: false, //xx
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //center port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Center,
  //     portId: "c",
  //     fromSpot: go.Spot.Center,
  //     toSpot: go.Spot.Center,
  //     fromLinkable: false, //xxx
  //     toLinkable: false, //xx
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //left port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Left,
  //     portId: "l",
  //     fromSpot: go.Spot.Left,
  //     toSpot: go.Spot.Left,
  //     fromLinkable: false, //xxx
  //     toLinkable: false, //xx
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //right port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Right,
  //     portId: "r",
  //     fromSpot: go.Spot.Right,
  //     toSpot: go.Spot.Right,
  //     fromLinkable: false, //xxx
  //     toLinkable: false, //xx
  //     toLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   })
  // );

  // //dragSelect box
  // diagram.toolManager.dragSelectingTool.box = $(
  //   go.Part,
  //   { layerName: "Tool" },
  //   $(go.Shape, "Cloud", { fill: null, strokeWidth: 1, stroke: "lime" })
  // );

  //test

  // 创建节点右键功能模板的 contextMenu 函数

  function createNodeContextMenu() {
    return $(
      "ContextMenu", // that has one button
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F3FBFF",
          width: 260,
          maxSize: new go.Size(90, 30),

          // margin: new go.Margin(6, 10, 6, 3),
          // defaultAlignment: go.Spot.Left,
        },
        $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "状态")
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F3FBFF",
          width: 260,
          maxSize: new go.Size(90, 30),
        },
        $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "详细信息")
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F3FBFF",
          width: 260,
          maxSize: new go.Size(90, 30),
        },
        $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "上传文件")
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F3FBFF",
          width: 260,
          maxSize: new go.Size(90, 30),
        },
        $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "咨询交流")
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F3FBFF",
          width: 260,
          maxSize: new go.Size(90, 30),
        },
        $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "资料库")
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F3FBFF",
          width: 260,
          maxSize: new go.Size(90, 30),
        },
        $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "AI资讯")
      )
      // more ContextMenuButtons would go here
      // end Adornment
    );

    // define a context menu for each node
  }

  // var white = "rgba(255, 255, 255, 0.6)";
  // var blue = "#1876f2";
  // var grad = "#6c757d";
  // //detailed information tooltip 弹窗信息 x

  // diagram.nodeTemplate.toolTip = $(
  //   "ToolTip", // show some detailed information
  //   { "Border.fill": "white", "Border.stroke": null },
  //   new go.Binding("visible", "", function (data, textBlock) {
  //     var node = textBlock.part;
  //     if (!node) return false;
  //     var key = node.key;
  //     var nodeData = diagram.model.findNodeDataForKey(key);
  //     return (
  //       !!nodeData.titles1 ||
  //       !!nodeData.details1 ||
  //       !!nodeData.titles2 ||
  //       !!nodeData.details2 ||
  //       !!nodeData.titles3 ||
  //       !!nodeData.details3 ||
  //       !!nodeData.titles4 ||
  //       !!nodeData.details4
  //     );
  //   }),
  //   // new go.Binding("visible", !!"titles" || !!"details"),
  //   $(
  //     go.Panel,
  //     "Auto",
  //     $(go.Shape, "RoundedRectangle", { fill: white, stroke: null,parameter1:3 }),
  //     $(
  //       go.Panel,
  //       "Vertical",

  //       {
  //         maxSize: new go.Size(160, NaN),
  //         padding: 5,
  //         defaultAlignment: go.Spot.Left,
  //       }, // limit width but not height

  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "center",
  //         },
  //         new go.Binding("text")
  //       ),
  //       //------xx
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.titles1;
  //         }),
  //         //visibility
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.titles1;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.details1;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.details1;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.titles2;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.titles2;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.details2;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.details2;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.titles3;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.titles3;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.details3;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.details3;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.titles4;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.titles4;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.details4;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.details4;
  //         })
  //       )
  //     )
  //   )
  // );
  //--------------------------------------------

  //--------------------------------------------------
  // Group template

  diagram.groupTemplate = $(
    go.Group,
    "Spot",
    {
      layerName: "Background",
      ungroupable: true,
      locationSpot: go.Spot.Center,
      selectionObjectName: "BODY",
      movable: false, //xx
      selectable: false, //xx
      computesBoundsAfterDrag: true, // allow dragging out of a Group that uses a Placeholder
      handlesDragDropForMembers: true, // don't need to define handlers on Nodes and Links
      mouseDrop: (e, grp) => {
        // add dropped nodes as members of the group
        var ok = grp.addMembers(grp.diagram.selection, true);
        if (!ok) grp.diagram.currentTool.doCancel();
      },
      avoidable: false,
    },
    new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
      go.Point.stringify
    ),

    $(
      go.Panel,
      "Auto",
      { name: "BODY" },
      $(
        go.Shape,
        "RoundedRectangle",
        {
          parameter1: 8,
          fill: "white",
          stroke: "#1B54D9",
          strokeWidth: 1,
          portId: "",
          // cursor: "pointer",
          //   cursor: "grab",    //xx
          fromLinkable: false, //xx
          toLinkable: false, //xxx
          fromLinkableDuplicates: true,
          toLinkableDuplicates: true,
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill"),
        new go.Binding("stroke", "color"),
        new go.Binding("strokeWidth", "thickness"),
        new go.Binding("strokeDashArray", "dash")
      ),

      $(go.Placeholder, {
        background: "transparent",
        margin: 10,
        padding: new go.Margin(16, 0, 0, 0),
        cursor: "grab",
      })
    ),
    $(
      go.TextBlock,

      {
        alignment: go.Spot.Top,
        alignmentFocus: new go.Spot(0.5, 0, 0, -4),
        // alignmentFocus: go.Spot.Bottom,
        font: "12pt system-ui, -apple-system, Arial, sans-serif",
        stroke: "#1B54D9",
        editable: true,
      },
      new go.Binding("text").makeTwoWay(),
      new go.Binding("stroke", "color"),
      new go.Binding("font", "font").makeTwoWay()
    ),
    //Group port
    //top port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Top,
      portId: "t",
      fromSpot: go.Spot.Top,
      toSpot: go.Spot.Top,
      fromLinkable: false, //xxx
      toLinkable: false, //xx
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //bottom port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Bottom,
      portId: "b",
      fromSpot: go.Spot.Bottom,
      toSpot: go.Spot.Bottom,
      fromLinkable: false, //xxx
      toLinkable: false, //xx
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //center port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Center,
      portId: "c",
      fromSpot: go.Spot.Center,
      toSpot: go.Spot.Center,
      fromLinkable: false, //xxx
      toLinkable: false, //xx
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //left port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Left,
      portId: "l",
      fromSpot: go.Spot.Left,
      toSpot: go.Spot.Left,
      fromLinkable: false, //xxx
      toLinkable: false, //xx
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //right port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Right,
      portId: "r",
      fromSpot: go.Spot.Right,
      toSpot: go.Spot.Right,
      fromLinkable: false, //xxx
      toLinkable: false, //xx
      toLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    })
  );

  //   //Group template selection
  //   diagram.groupTemplate.selectionAdornmentTemplate = $(
  //     go.Adornment,
  //     "Spot",
  //     $(
  //       go.Panel,
  //       "Auto",
  //       $(go.Shape, { fill: null, stroke: "#98E1F9", strokeWidth: 2 }),
  //       $(go.Placeholder, { margin: 1.5 })
  //     ),
  //     CMButton({
  //       alignment: go.Spot.TopRight,
  //       alignmentFocus: go.Spot.BottomRight,
  //     })
  //   );

  //   //contextMenu 5 group
  //   diagram.groupTemplate.contextMenu = $(
  //     "ContextMenu",
  //     LightFillButtons(),
  //     DarkColorButtons(),
  //     StrokeOptionsButtons()
  //   );

  // Link template

  diagram.linkTemplate = $(
    go.Link,
    {
      layerName: "Foreground",
      curve: go.Link.JumpGap,
      routing: go.Link.AvoidsNodes,
      corner: 1,
      toShortLength: 6, // assume arrowhead at "to" end, need to avoid bad appearance when path is thick
      relinkableFrom: false, //xx
      relinkableTo: false, //xx
      selectable: false, //xx
      reshapable: true,
      resegmentable: true,
    },
    new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
    new go.Binding("toSpot", "toSpot", go.Spot.parse),
    new go.Binding("fromShortLength", "dir", (dir) => (dir === 2 ? 4 : 0)),
    new go.Binding("toShortLength", "dir", (dir) => (dir >= 1 ? 4 : 0)),
    new go.Binding("points").makeTwoWay(), // TwoWay due to user reshaping with LinkReshapingTool
    new go.Binding(
      "routing",
      "routing",
      go.Binding.parseEnum(go.Link, go.Link.AvoidsNodes)
    ).makeTwoWay(go.Binding.toString), // link routing
    //line background
    //$(go.Shape, { isPanelMain: true, stroke: "#DDF1FF", strokeWidth: 4 }),
    //线
    $(
      go.Shape,
      {
        isPanelMain: true,
        stroke: "#1B54D9",
        strokeWidth: 1,
        name: "PIPE",
        strokeDashArray: [0, 0],
      },
      new go.Binding("stroke", "color"),
      new go.Binding("strokeWidth", "thickness"),
      new go.Binding("strokeDashArray", "dash")
    ),
    //箭头
    $(
      go.Shape,
      {
        fromArrow: "Backward",
        fill: "#1B54D9",
        strokeWidth: 0,
        scale: 4 / 3,
        visible: false,
      },
      new go.Binding("visible", "dir", (dir) => dir === 2),
      new go.Binding("fill", "color"),
      new go.Binding("scale", "thickness", (t) => (2 + t) / 3)
    ),
    $(
      go.Shape,
      {
        toArrow: "Standard",
        //arrow shape stroke
        strokeWidth: 0,
        stroke: "#585858",
        fill: "#1B54D9",
        scale: 4 / 3,
      },
      new go.Binding("visible", "dir", (dir) => dir >= 1),
      new go.Binding("fill", "color"),
      new go.Binding("scale", "thickness", (t) => (2 + t) / 3)
    ),
    //文字
    $(
      go.TextBlock,
      {
        alignmentFocus: new go.Spot(0, 1, -4, 0),
        font: "9pt system-ui, -apple-system, Arial, sans-serif",
        editable: true,
      },
      new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
      new go.Binding("stroke", "color")
    )
  );

  //link templateslink 链接中的样式
  diagram.toolManager.linkingTool.temporaryLink = $(
    go.Link,
    { layerName: "Tool" },
    $(go.Shape, { stroke: "#FF00FF", strokeWidth: 1, strokeDashArray: [4, 2] })
  );

  // myDiagram.model.linkFromPortIdProperty = "fromPort";
  // myDiagram.model.linkToPortIdProperty = "toPort";
  //   //线的选择样式
  //   diagram.linkTemplate.selectionAdornmentTemplate = $(
  //     go.Adornment, // use a special selection Adornment that does not obscure the link path itself
  //     $(
  //       go.Shape,
  //       {
  //         // this uses a pathPattern with a gap in it, in order to avoid drawing on top of the link path Shape
  //         isPanelMain: true,
  //         stroke: "transparent",
  //         strokeWidth: 4,
  //         pathPattern: makeAdornmentPathPattern(2), // == thickness or strokeWidth
  //       },
  //       new go.Binding("pathPattern", "thickness", makeAdornmentPathPattern)
  //     ),
  //     CMButton({ alignmentFocus: new go.Spot(0, 0, -6, -4) })
  //   );

  //   function makeAdornmentPathPattern(w) {
  //     return $(go.Shape, {
  //       stroke: "#98E1F9",
  //       strokeWidth: 1,
  //       strokeCap: "square",
  //       geometryString: "M0 0 M4 2 H3 M4 " + (w + 4).toString() + " H3",
  //     });
  //   }

  //   // Link context menu
  //   // All buttons in context menu work on both click and contextClick,
  //   // in case the user context-clicks on the button.
  //   // All buttons modify the link data, not the Link, so the Bindings need not be TwoWay.

  //   function ArrowButton(num) {
  //     var geo = "M0 0 M16 16 M0 8 L16 8  M12 11 L16 8 L12 5";
  //     if (num === 0) {
  //       geo = "M0 0 M16 16 M0 8 L16 8";
  //     } else if (num === 2) {
  //       geo = "M0 0 M16 16 M0 8 L16 8  M12 11 L16 8 L12 5  M4 11 L0 8 L4 5";
  //     }
  //     return $(go.Shape, {
  //       geometryString: geo,
  //       margin: 2,
  //       background: "transparent",
  //       mouseEnter: (e, shape) => (shape.background = "#98E1F9"),
  //       mouseLeave: (e, shape) => (shape.background = "transparent"),
  //       click: ClickFunction("dir", num),
  //       contextClick: ClickFunction("dir", num),
  //     });
  //   }
  //   //出线位置设置
  //   function AllSidesButton(to) {
  //     var setter = (e, shape) => {
  //       e.handled = true;
  //       e.diagram.model.commit((m) => {
  //         var link = shape.part.adornedPart;
  //         m.set(
  //           link.data,
  //           to ? "toSpot" : "fromSpot",
  //           go.Spot.stringify(go.Spot.AllSides)
  //         );
  //         // re-spread the connections of other links connected with the node
  //         (to ? link.toNode : link.fromNode).invalidateConnectedLinks();
  //       });
  //     };
  //     return $(go.Shape, {
  //       width: 12,
  //       height: 12,
  //       fill: "transparent",
  //       mouseEnter: (e, shape) => (shape.background = "#98E1F9"),
  //       mouseLeave: (e, shape) => (shape.background = "transparent"),
  //       click: setter,
  //       contextClick: setter,
  //     });
  //   }

  //   function SpotButton(spot, to) {
  //     var ang = 0;
  //     var side = go.Spot.RightSide;
  //     if (spot.equals(go.Spot.Top)) {
  //       ang = 270;
  //       side = go.Spot.TopSide;
  //     } else if (spot.equals(go.Spot.Left)) {
  //       ang = 180;
  //       side = go.Spot.LeftSide;
  //     } else if (spot.equals(go.Spot.Bottom)) {
  //       ang = 90;
  //       side = go.Spot.BottomSide;
  //     }
  //     if (!to) ang -= 180;
  //     var setter = (e, shape) => {
  //       e.handled = true;
  //       e.diagram.model.commit((m) => {
  //         var link = shape.part.adornedPart;
  //         m.set(link.data, to ? "toSpot" : "fromSpot", go.Spot.stringify(side));
  //         // re-spread the connections of other links connected with the node
  //         (to ? link.toNode : link.fromNode).invalidateConnectedLinks();
  //       });
  //     };
  //     return $(go.Shape, {
  //       alignment: spot,
  //       alignmentFocus: spot.opposite(),
  //       geometryString: "M0 0 M12 12 M12 6 L1 6 L4 4 M1 6 L4 8",
  //       angle: ang,
  //       background: "transparent",
  //       mouseEnter: (e, shape) => (shape.background = "#98E1F9"),
  //       mouseLeave: (e, shape) => (shape.background = "transparent"),
  //       click: setter,
  //       contextClick: setter,
  //     });
  //   }

  //   //contextMenu 6
  //   diagram.linkTemplate.contextMenu = $(
  //     "ContextMenu",
  //     DarkColorButtons(),
  //     StrokeOptionsButtons(),
  //     $(
  //       "ContextMenuButton",
  //       {
  //         "ButtonBorder.fill": "white",
  //         _buttonFillOver: "#F1F5FE",
  //       },
  //       $(go.Panel, "Horizontal", ArrowButton(0), ArrowButton(1), ArrowButton(2))
  //     ),
  //     $(
  //       "ContextMenuButton",
  //       {
  //         "ButtonBorder.fill": "white",
  //         _buttonFillOver: "#F1F5FE",
  //       },

  //       $(
  //         go.Panel,
  //         "Horizontal",
  //         $(
  //           go.Panel,
  //           "Spot",
  //           AllSidesButton(false),
  //           SpotButton(go.Spot.Top, false),
  //           SpotButton(go.Spot.Left, false),
  //           SpotButton(go.Spot.Right, false),
  //           SpotButton(go.Spot.Bottom, false)
  //         ),
  //         $(
  //           go.Panel,
  //           "Spot",
  //           { margin: new go.Margin(0, 0, 0, 2) },
  //           AllSidesButton(true),
  //           SpotButton(go.Spot.Top, true),
  //           SpotButton(go.Spot.Left, true),
  //           SpotButton(go.Spot.Right, true),
  //           SpotButton(go.Spot.Bottom, true)
  //         )
  //       )
  //     )
  //   );
  //--------------------------------------------------

  return diagram;
}
