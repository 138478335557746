import React, { useContext, useState } from "react";
import { ReactDiagram } from "gojs-react";
import { Canvg } from "canvg";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
//model
import { diagram } from "../../components/diagram/knowledgediagram2/diagrambasedata02";

import "./model1.scss";

import autorenew from "../../img/autorenew_black_24dp.svg";
import backup from "../../img/backup_black_24dp.svg";
import qrcodeb from "../../img/qr_code_black_24dp.svg";
import filedownload from "../../img/file_download_black_24dp.svg";
import favorite from "../../img/favorite_border_black_24dp.svg";
import star from "../../img/star_outline_black_24dp.svg";
import favoritefill from "../../img/favorite_FILL24.svg";
import starfill from "../../img/star_fll_24dp.svg";
import centerfocus from "../../img/center_focus_weak_black_24dp.svg";
import fullscreen from "../../img/fullscreen_black_24dp.svg";
import fullfit from "../../img/fit_screen24.svg";
import gridview from "../../img/grid_view_black_24dp.svg";
import newfile from "../../img/new_window24.svg";

import axios from "axios";
import SearchBox from "../../components/sidebar/SearchBox";
import Navbar from "../../components/navbar/Navbar";
import Leftlayout from "../../components/leftlayout/Leftlayout";
import LoginPopup from "../login/Login";
import NoticePopup from "../../components/popupfiles/noticepopup/noticePopup";
import ChatPopup from "../../components/popupfiles/chatpopup/chatPopup";

import QrCodeModal from "../../components/draggables/qrcodepopup";
import DownFiles from "../../components/draggables/downfilepopup";
import InfoDraggable from "../../components/draggables/infodraggable";
import OpRunEditDraggable from "../../components/draggables/opruneditdraggable";

import UserPopup from "../../components/popupfiles/userpopup/userPopup";
import GroupDraggable from "../../components/draggables/groupdraggable";
import { withTranslation } from "react-i18next";
import ToolPopup from "../../components/popupfiles/toolpopup/toolPopup";
export class ModelDiagramDataNewFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "#FFFFFF",
      backgroundImageUrl: null,      
      fileid: null,
      fileurl: null,
      nodeArray: [],
      linkArray: [],
      filename: "新系统名称",
      isFavoriteFilled: false,
      isStarFilled: false,
      isQrCodeModalOpen: false,
      isDownFilesOpen: false,
      qrCodeImageUrl: null,
      qrCodeImageString: null,
      isInfoCardOpen: true,
      selectedNode: {
        fileid: null,
        key: "",
        text: "",
        title: [],
        detail: [],
        isgroup: false,
        nodeselected: false,
      },
      userId: null,
      vipNo: 0,

      //tooltip  xxxxx
      isTooltipVisible: false,
      tooltipText: "",
      tooltipX: 0,
      tooltipY: 0,
    };
    //xx
    this.diagramRef = React.createRef();
    this.handleSetColorChange = this.handleSetColorChange.bind(this);
    this.handleBackgroundImageChange.bind(this);
    this.handleNewFile = this.handleNewFile.bind(this);
    this.handleSaveFile = this.handleSaveFile.bind(this);
    this.saveData = this.saveData.bind(this);
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
    this.handleStarClick = this.handleStarClick.bind(this);
    this.handleQrcodeClick = this.handleQrcodeClick.bind(this);
    this.handleCloseQrCodeModal = this.handleCloseQrCodeModal.bind(this);
    this.handleDownFilesClick = this.handleDownFilesClick.bind(this);
    this.handleCloseDownFiles = this.handleCloseDownFiles.bind(this);
    // this.handleNodeClick = handleNodeClick.bind(this);
  }

  // setuserid
  setUserId = (userId) => {
    this.setState({ userId });
  };

  // setvipNo
  setVipNo = (vipNo) => {
    this.setState({ vipNo });
  };

  //initdiagram
  initDiagram(backgroundImageUrl) {
    // console.log("Background image URL init", backgroundImageUrl);
    return diagram({ backgroundImageUrl });
  }

  // set background color
  handleSetColorChange = (color) => {
    this.setState({ backgroundColor: color });
   // console.log("backgroundColor hc:", color);
  };

  //set background picture
  handleBackgroundImageChange = (url) => {
    this.setState({ backgroundImage: url });
  };

  //favorite likes and star keeps click events
  //likes
  handleFavoriteClick = async () => {
    // 使用 URLSearchParams 获取 URL 中的参数
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    const { userId } = this.state;

    this.setState(
      (prevState) => ({ isFavoriteFilled: !prevState.isFavoriteFilled }),
      async () => {
        const { isFavoriteFilled } = this.state;
       // console.log("setlikes:", isFavoriteFilled, fileid, userId);

        try {
          if (isFavoriteFilled) {
            // 发送请求更新数据库
            const response = await fetch(
              `${baseUrl}/userapi/setaddfavoritefiles/${userId}`,
              {
                method: "POST",
                body: JSON.stringify({ fileid }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.ok) {
              //console.log("Favorite file updated successfully");
            } else {
              console.error("Failed to update favorite file");
            }
          } else {
            // 发送请求删除数据库中的数据
            const response = await fetch(
              `${baseUrl}/userapi/setdeletefavoritefiles/${userId}`,
              {
                method: "POST",
                body: JSON.stringify({ fileid }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (response.ok) {
              //console.log("Favorite file deleted successfully");
            } else {
              console.error("Failed to delete favorite file");
            }
          }
        } catch (error) {
          console.error("Error while updating favorite file:", error);
        }
      }
    );
  };

  //keeps set or deleted files
  handleStarClick = async () => {
    // this.setState({ isStarFilled: !this.state.isStarFilled });

    // 使用 URLSearchParams 获取 URL 中的参数
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    const { userId } = this.state;

    this.setState(
      (prevState) => ({ isStarFilled: !prevState.isStarFilled }),
      async () => {
        const { isStarFilled } = this.state;
       // console.log("setkeeps:", isStarFilled, fileid, userId);

        try {
          if (isStarFilled) {
            // 发送请求更新数据库
            const response = await fetch(
              `${baseUrl}/userapi/setaddkeepfiles/${userId}`,
              {
                method: "POST",
                body: JSON.stringify({ fileid }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.ok) {
              //console.log("Favorite file updated successfully");
            } else {
              console.error("Failed to update favorite file");
            }
          } else {
            // 发送请求删除数据库中的数据
            const response = await fetch(
              `${baseUrl}/userapi/setdeletekeepfiles/${userId}`,
              {
                method: "POST",
                body: JSON.stringify({ fileid }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (response.ok) {
              //console.log("Favorite file deleted successfully");
            } else {
              console.error("Failed to delete favorite file");
            }
          }
        } catch (error) {
          console.error("Error while updating favorite file:", error);
        }
      }
    );
  };

  //zoom center
  zoomToFitDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    // console.log("diagram1:", diagram);
    diagram.commandHandler.zoomToFit();
  };

  //center root
  centerRootDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    diagram.centerRect(diagram.documentBounds);
  };

  //scale1 cente root
  scaleRootDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    diagram.scale = 0.8;
    setTimeout(() => {
      this.centerRootDiagram();
    }, 0);
  };

  //userid change event
  handleUserIdlocal = async () => {
    const loginuser = localStorage.getItem("user");
   // console.log("loginuser stor current:", loginuser);
    const currentUser = loginuser;
   // console.log("loginuser stor current2:", currentUser);
    if (!currentUser) {
      return;
    }
    try {
      const response = await fetch(
        `${baseUrl}/userapi/uservipgrade/${currentUser}`
      );

      if (!response.ok) {
        // 处理请求失败的情况
        const { message } = await response.json();
        console.error(`Failed to fetch user VIP grade: ${message}`);
        return;
      }

      const { userid, vip_no } = await response.json();
      // console.log("sql userid xxx:", userid);
      // console.log("sql userid xxx:", vip_no);

      // 调用 setUserId 方法，将获取到的 userId 设置到组件状态中
      this.setUserId(userid);
      this.setVipNo(vip_no);

      // 在获取 userid 之后，再获取 fileid 并进行likes检查
      // 使用 URLSearchParams 获取 URL 中的参数
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid");

     // console.log("check likes and keeps:", fileid, userid);
      try {
        const response = await fetch(
          `${baseUrl}/userapi/checkfavoritefile/${userid}/${fileid}`
        );
        const data = await response.json();

        const isFavoriteFilled = data.exists;
        this.setState({ isFavoriteFilled });
      } catch (error) {
        console.error("Error while checking favorite file:", error);
      }

      // 在获取 userid 之后，再获取 fileid 并进行keeps检查
      try {
        const response = await fetch(
          `${baseUrl}/userapi/checkkeepfile/${userid}/${fileid}`
        );
        const data = await response.json();

        const isStarFilled = data.exists;
        this.setState({ isStarFilled });
      } catch (error) {
        console.error("Error while checking favorite file:", error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //filename change event
  handleFileNameChange = (fileNameText) => {
   // console.log("fileNameText change:", fileNameText);
    this.setState({ filename: fileNameText });
    const { filename } = this.state;
    //console.log("fileName change:", filename);
  };

  //handleClose event
  handleClose = () => {
    // console.log("handleClose is called");
    const diagram = this.diagramRef.current.getDiagram();
    const nodes = diagram.model.nodeDataArray;
    diagram.startTransaction("restore matching nodes");

    nodes.forEach((node) => {
      if (node.thickness === 2) {
        diagram.model.setDataProperty(node, "thickness", 1); // 恢复系统初始化的thickness属性
        diagram.model.setDataProperty(node, "color", "#1B54D9"); // 恢复系统初始化的color属性
      }
    });

    diagram.commitTransaction("restore matching nodes");
    // this.props.onClose();
    this.componentDidMount();
  };

  //find text nodes highlighted
  handleSearch = (searchText) => {
    const diagram = this.diagramRef.current.getDiagram();
    const nodes = diagram.model.nodeDataArray;
    // console.log("diagramnodes:", nodes);
    const matchingNodes = nodes.filter((node) =>
      node.text.toLowerCase().includes(searchText.toLowerCase())
    );
    // console.log("diagrammatchingNodes:", matchingNodes);

    diagram.startTransaction("highlight matching nodes");

    matchingNodes.forEach((node) => {
      if (node.text === searchText) {
        //full match
        diagram.model.setDataProperty(node, "thickness", 2);
        diagram.model.setDataProperty(node, "color", "#202020");
      } else {
        //partial match
        diagram.model.setDataProperty(node, "thickness", 2);
        diagram.model.setDataProperty(node, "color", "#5c5c5c");
      }
      // console.log("diagrammupdateNodes ok");
    });
    // this.saveData();
    this.zoomToFitDiagram();
    diagram.commitTransaction("highlight matching nodes");
    // return matchingNodes;
  };

  //create a new diagram file
  handleNewFile = async () => {
    const vipno = this.state.vipNo;

    if (vipno > 1) {
      //create a new diagram
      try {
       // console.log("N1新建文件");
        const response = await axios.post(`${baseUrl}/fileapi/createfile`, {
          filename: this.state.filename, // 将文件名值传递给后端
          userid: this.state.userId,
          // ...
        });
        const { fileid, fileurl } = response.data; // 假设后端返回一个包含ID和链接的对象

        
        // 更新组件状态
        this.setState({ fileid, fileurl }, () => {
          // 状态更新完成后执行的回调函数，访问fileId和fileUrl
          // console.log("新建文件", this.state);
         // console.log("N2新建文件夹fileid", fileid);
          // 保存文件和数据
          this.handleSaveFile();
          // this.saveData();
        });

        const Currentfileid = fileid;
       // console.log("N2新建文件Currentfileid", Currentfileid);
        if (Currentfileid) {
          // 根据返回的链接打开新的页面
          window.open(fileurl, "_blank");
        } else {
          window.open(fileurl, "_self");
        }
        
      } catch (error) {
        console.error(error);
        // 处理错误
      }
    } else {
      return;
    }
  };

  // load data from server datajson file 基本模型加载 模板
  loadData = () => {
   // console.log("L加载数据NO FILEID");
    // 加载数据
    fetch(`${baseUrl}/datajson/nodes`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          // nodeData: data,
          nodeArray: data,
        });
       // console.log("L加载数据nodeArray:", data);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(`${baseUrl}/datajson/links`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          linkArray: data,
          // linkData: data,
        });
       // console.log("L加载数据linkArray:", data);
      })
      .catch((error) => {
        console.log(error);
      });

    //center zoomtofit and loaddata------
    setTimeout(() => {
      this.scaleRootDiagram();
    }, 0);
  };

  //loaddatafileid  有加判断fileid是否存在 加载独立模型
  loadDatafileid = () => {
    // 使用 URLSearchParams 获取 URL 中的参数
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("L加载数据HAVE FILEID", fileid);
    // 如果 URL 中包含 fileid 参数，则使用该参数从服务器获取数据
    if (fileid) {
      fetch(`${baseUrl}/fileapi/${fileid}/load`)
        .then((response) => response.json())
        .then((data) => {
          // 检查每个节点项是否具有 category 属性，如果没有则设置为 "TextNode"
          const nodeArray = data.nodeArray.map((node) => {
            if (!node.category) {
              node.category = "TextNode";
            }
            return node;
          });

          this.setState({
            nodeArray: data.nodeArray,
            linkArray: data.linkArray,
          });
          // 处理返回的数据
         // console.log("LF加载数据 Folder", data.nodeArray);
         // console.log("LF加载数据 Folder", data.linkArray);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });

      setTimeout(() => {
        this.scaleRootDiagram();
      }, 0);
    } else {
      // 如果 URL 中没有 fileid 参数，则加载默认数据
      this.loadData();
    }
  };

  //save create file and data file folder
  handleSaveFile = async () => {
    try {
     // console.log("H1保存新文件数据");
      const diagram = this.diagramRef.current.getDiagram();

      diagram.addModelChangedListener((e) => {
        if (
          e.isTransactionFinished &&
          (e.modelChange === "nodeDataArray" ||
            e.modelChange === "linkDataArray")
        ) {
          this.handleNodeDataChange(e);
        }

        // console.log("diagram.modelnode:", diagram.model.nodeDataArray);
        // console.log("diagram.modellink:", diagram.model.linkDataArray);
      });

      const nodeDatachanged = diagram.model.nodeDataArray;
      const linkDatachanged = diagram.model.linkDataArray;

      // save data to json file--------------
      const { fileid, fileurl } = this.state;
     // console.log("H2diagramfileid:", fileid);

      const data = {
        nodeArray: nodeDatachanged.map((node) => ({ ...node, fileid })),
        linkArray: linkDatachanged.map((link) => ({ ...link, fileid })),
        fileid,
        fileurl,
      };
      // console.log("diagram.modeldata:", data);

      //fileapi saveData  var response
      const response = await axios.post(
        `${baseUrl}/fileapi/${fileid}/save`,
        data
      );
     // console.log("H3文件夹保存NO FILEID");

      //fileid 没有情况下保存到JSON
      if (!fileid) {
        //datajson save from api file
        fetch(`${baseUrl}/datajson/nodes`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data.nodeArray, null, 2),
        })
          .then((response) => {
            // console.log(response);
           // console.log("H4文件夹保存HAVE FILEID");
           // console.log("H4SavedataNodeArray", data.nodeArray);
          })
          .catch((error) => {
            console.log(error);
          });
        fetch(`${baseUrl}/datajson/links`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data.linkArray, null, 2),
        })
          .then((response) => {
            // console.log(response);
           // console.log("H4SavedataLinkArray", data.linkArray);
          })
          .catch((error) => {
            console.log(error);
          });
        // save data to json file end--------------
      }

      //axios post nodedatas and linkdatas mysql

      //nodedatas from diagram
      const postnodes = JSON.stringify(data.nodeArray, null, 2);
      const postnodesarray = Array.from(JSON.parse(postnodes));
      //linkdatas from diagram
      const postlinks = JSON.stringify(data.linkArray, null, 2);
      const postlinksarray = Array.from(JSON.parse(postlinks));

      const items = postnodesarray;
      const linkitems = postlinksarray;
      // console.log("data.postnodes22", items);
      // console.log("data.postlinks22", linkitems);

      // nodes is definition
      const dataTitle = items.map(
        ({
          key,
		  angle,
          loc,
          text,
          figure,
          color,
          size,
          thickness,
          group,
          isGroup,
          fill,
          dash,
          fileid,
        }) => ({
          key: key || null,
		  angle: angle || null,
          loc: loc || null,
          text: text || null,
          figure: figure || null,
          color: color || null,
          size: size || null,
          thickness: thickness || null,
          group: group || null,
          isgroup: isGroup ? "true" : "false",
          fill: fill || null,
          dash: dash || null,
          fileid: fileid,
        })
      );
      // console.log("data.postnodes2", dataTitle);
      // links is definition

      const dataLinks = linkitems.map(
        ({
          from,
          to,
          points,
          text,
          fromSpot,
          toSpot,
          color,
          thickness,
          dash,
          dir,
          routing,
          fileid,
        }) => {
          //-----set routing values --------------------------------
          let routingValue = null;
          if (routing === "Normal") {
            routingValue = "Normal";
          } else if (routing === "Orthogonal") {
            routingValue = "Normal2";
          } else if (routing === "AvoidsNodes") {
            routingValue = "Normal3";
          } else if (routing === "null") {
            routingValue = "Normal4";
          }
          return {
            from: from || null,
            to: to || null,
            points: JSON.stringify(points) || null,
            text: text || null,
            fromspot: fromSpot || null,
            tospot: toSpot || null,
            color: color || null,
            thickness: thickness || null,
            dash: JSON.stringify(dash) || null,
            dir: JSON.stringify(dir) || null,
            routing: routingValue || null,
            fileid: fileid,
          };
        }
      );
      // console.log("data.postlinks2", dataLinks);

      //nodedata key change event (key、 group crash event）
      const newNodes = dataTitle.map((item) => {
        return {
          ...item,
          nodekey: item.key,
          key: undefined,
          nodegroup: item.group,
          group: undefined,
        };
      });

     // console.log("data.postnodes3", newNodes);
      // post nodedatas api-------------------
      fetch(`${baseUrl}/datasql/postnodes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newNodes),
      });

      // post nodedatas api-------------------
      fetch(`${baseUrl}/datasql/postlinks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataLinks),
      })
        .then((response) => {
          if (response.ok) {
            //console.log("Data written to MySQL database");
          } else {
            throw new Error("Network response was not ok");
          }
        })
        .catch((error) => {
          console.error("Error writing data to MySQL database:", error);
        });
      // //save data to database sql end
    } catch (error) {
      console.error(error);
      // 处理错误
    }
  };

  // save model updata data have saved fields
  saveData = async () => {
    //fileapi saveData
    //获取fileid
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("1diagramfileid:", fileid);

   // console.log("2保存数据s");
    //save datajson no filefolder
    const diagram = this.diagramRef.current.getDiagram();
    // console.log("diagram:", diagram);
    diagram.addModelChangedListener((e) => {
      if (
        e.isTransactionFinished &&
        (e.modelChange === "nodeDataArray" || e.modelChange === "linkDataArray")
      ) {
        this.handleNodeDataChange(e);
      }

      // console.log("diagram.modelnode:", diagram.model.nodeDataArray);
      // console.log("diagram.modellink:", diagram.model.linkDataArray);
    });

    var nodeDatachanged = diagram.model.nodeDataArray;
    var linkDatachanged = diagram.model.linkDataArray;

    const nodeDataWithFileid = nodeDatachanged.map((node) =>
      node.fileid ? node : { ...node, fileid }
    );
    const linkDataWithFileid = linkDatachanged.map((link) =>
      link.fileid ? link : { ...link, fileid }
    );

    // const fileid = this.state.fileid;
    // console.log("fileid:", fileid);
    // save data to json file--------------
    const data = {
      nodeArray: nodeDataWithFileid,
      linkArray: linkDataWithFileid,
    };
    // console.log("diagram.modeldata:", data);

    //fileid有值的时候才存入数据库
    if (!fileid) {
     // console.log("4fileid不存在");
      //datajson save from api file
      fetch(`${baseUrl}/datajson/nodes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data.nodeArray, null, 2),
      })
        .then((response) => {
          // console.log(response);
         // console.log("3SavedataNodeArray", data.nodeArray);
        })
        .catch((error) => {
          console.log(error);
        });
      fetch(`${baseUrl}/datajson/links`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data.linkArray, null, 2),
      })
        .then((response) => {
          // console.log(response);

         // console.log("3SavedataLinkArray", data.linkArray);
        })
        .catch((error) => {
          console.log(error);
        });
      // save data to json file end--------------

      //返回
      return;
    }

    //save data to json file folder have fileid
    // save data to json file--------------
   // console.log("SFdiagramfileid:", fileid);
   // console.log("SFdiagram.modeldata:", data);

    //fileapi saveData  var response
    const response = await axios.post(
      `${baseUrl}/fileapi/${fileid}/save`,
      data
    );
   // console.log("H3文件夹保存NO FILEID");

    //axios post nodedatas and linkdatas mysql

    //nodedatas from diagram
    const postnodes = JSON.stringify(data.nodeArray, null, 2);
    const postnodesarray = Array.from(JSON.parse(postnodes));
    //linkdatas from diagram
    const postlinks = JSON.stringify(data.linkArray, null, 2);
    const postlinksarray = Array.from(JSON.parse(postlinks));

    const items = postnodesarray;
    const linkitems = postlinksarray;
    // console.log("data.postnodes22", items);
    // console.log("data.postlinks22", linkitems);

    // nodes is definition
    const dataTitle = items.map(
      ({
        key,
		angle,
        loc,
        text,
        figure,
        color,
        size,
        thickness,
        group,
        isGroup,
        fill,
        dash,
        fileid,
      }) => ({
        key: key || null,
		angle: angle || null,
        loc: loc || null,
        text: text || null,
        figure: figure || null,
        color: color || null,
        size: size || null,
        thickness: thickness || null,
        group: group || null,
        isgroup: isGroup ? "true" : "false",
        fill: fill || null,
        dash: dash || null,
        fileid: fileid,
      })
    );
    // console.log("data.postnodes2", dataTitle);
    // links is definition

    const dataLinks = linkitems.map(
      ({
        from,
        to,
        points,
        text,
        fromSpot,
        toSpot,
        color,
        thickness,
        dash,
        dir,
        routing,
        fileid,
      }) => {
        //-----set routing values --------------------------------
        let routingValue = null;
        if (routing === "go.Link.Normal") {
          routingValue = "Normal";
        } else if (routing === "go.Link.Orthogonal") {
          routingValue = "Normal2";
        } else if (routing === "go.Link.Bezier") {
          routingValue = "Normal3";
        }
        return {
          from: from || null,
          to: to || null,
          points: JSON.stringify(points) || null,
          text: text || null,
          fromspot: fromSpot || null,
          tospot: toSpot || null,
          color: color || null,
          thickness: thickness || null,
          dash: JSON.stringify(dash) || null,
          dir: JSON.stringify(dir) || null,
          routing: routingValue || null,
          fileid: fileid,
        };
      }
    );
    // console.log("data.postlinks2", dataLinks);

    //nodedata key change event (key、 group crash event）
    const newNodes = dataTitle.map((item) => {
      return {
        ...item,
        nodekey: item.key,
        key: undefined,
        nodegroup: item.group,
        group: undefined,
      };
    });

    // console.log("data.postnodes3", newNodes);
    // post nodedatas api-------------------
    fetch(`${baseUrl}/datasql/postnodes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newNodes),
    });

    // post nodedatas api-------------------
    fetch(`${baseUrl}/datasql/postlinks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataLinks),
    })
      .then((response) => {
        if (response.ok) {
          //console.log("SF Data written to MySQL database");
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error writing data to MySQL database:", error);
      });
    //xxxxxxxxxxxxxxxxxxxxxx
  };

  //  handleInfoClick(){
  //   this.setState({ isInfoCardOpen: !this.state.isInfoCardOpen });
  // }

  //qrcode generation and upload 2
  handleCloseQrCodeModal() {
    this.setState({ isQrCodeModalOpen: false });
  }

  //downfiles close
  handleCloseDownFiles() {
    this.setState({ isDownFilesOpen: false });
  }

  //DownFiles click
  handleDownFilesClick() {
    this.setState({ isDownFilesOpen: !this.state.isDownFilesOpen });
  }

  handleQrcodeClick = async (event) => {
    event.preventDefault();
    const url = window.location.href;
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("1diagramfileid:", fileid);
   // console.log("qrcode点击", url);
    fetch(`${baseUrl}/qrcodeapi/qrcode/${fileid}`, {
      method: "POST",
      body: JSON.stringify({ url: url }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.text())
      .then((svg) => {
        const img = document.createElement("img");
        img.src = `data:image/svg+xml;base64,${btoa(svg)}`;
        // document.body.appendChild(img);
        this.setState({ qrCodeImageUrl: img.src });
        this.setState({ qrCodeImageString: svg });
        this.setState({ isQrCodeModalOpen: !this.state.isQrCodeModalOpen });
      })
      .catch((error) => console.error(error));
  };

  //download svg file from diagram
  handleSvgDownload = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("1diagramSvgDownloadfileid:", fileid);

    // 发送请求以获取文件名
    try {
      const response = await fetch(`${baseUrl}/fileapi/getfilename/${fileid}`);
      const data = await response.json();

      if (response.ok && data.success) {
        const filename = data.filename; // 从响应数据中获取文件名
       // console.log("1diagramSvgDownloadfilename:", filename);

        const diagram = this.diagramRef.current.getDiagram();
        if (diagram) {
          const { backgroundColor } = this.state;
          const svg = diagram.makeSvg({
            scale: 1,
            background: backgroundColor,
          });
          //----------------------------------------------------------------
          // 获取 SVG 宽度和高度
          const svgWidth = parseInt(svg.getAttribute("width"));
          const svgHeight = parseInt(svg.getAttribute("height"));

          // 创建包含边距的新 SVG 元素
          const paddedSvg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
          );
          paddedSvg.setAttribute("width", svgWidth + 100); // 加上左右边距
          paddedSvg.setAttribute("height", svgHeight + 100); // 加上上下边距

          // 添加背景矩形
          const backgroundRect = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          backgroundRect.setAttribute("x", "0");
          backgroundRect.setAttribute("y", "0");
          backgroundRect.setAttribute("width", svgWidth + 100);
          backgroundRect.setAttribute("height", svgHeight + 100);
          backgroundRect.setAttribute("fill", backgroundColor);
          paddedSvg.appendChild(backgroundRect);

          // 将原始 SVG 内容放置在新 SVG 中间
          const svgClone = svg.cloneNode(true);
          svgClone.setAttribute("x", "50"); // 左边距
          svgClone.setAttribute("y", "50"); // 上边距
          paddedSvg.appendChild(svgClone);

          const svgString = new XMLSerializer().serializeToString(paddedSvg);

          //----------------------------------------------------------------

		  
          //const svgString = new XMLSerializer().serializeToString(svg);

          // 发起分块下载请求
          const CHUNK_SIZE = 1024 * 1024; // 1 MB
          let offset = 0;
          const blobArray = [];
          while (offset < svgString.length) {
            const chunk = svgString.slice(offset, offset + CHUNK_SIZE);
            const blob = new Blob([chunk], { type: "image/svg+xml" });
            blobArray.push(blob);
            offset += CHUNK_SIZE;
          }

          const blob = new Blob(blobArray, { type: "image/svg+xml" });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = filename + ".svg";
          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      } else {
        console.error("Failed to fetch filename:", data.message);
      }
    } catch (error) {
      console.error("Error fetching filename:", error.message);
    }
  };

  handlePngDownload = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("1diagramSvgDownloadfileid:", fileid);

    try {
      const response = await fetch(`${baseUrl}/fileapi/getfilename/${fileid}`);
      const data = await response.json();

      if (response.ok && data.success) {
        const filename = data.filename; // 从响应数据中获取文件名
       // console.log("1diagramSvgDownloadfilename:", filename);

        const diagram = this.diagramRef.current.getDiagram();
        if (diagram) {
          const { backgroundColor } = this.state;
          const svg = diagram.makeSvg({
            scale: 1,
            background: backgroundColor,
          });
          //----------------------------------------------------------------
          // 获取 SVG 宽度和高度
          const svgWidth = parseInt(svg.getAttribute("width"));
          const svgHeight = parseInt(svg.getAttribute("height"));

          // 创建包含边距的新 SVG 元素
          const paddedSvg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
          );
          paddedSvg.setAttribute("width", svgWidth + 100); // 加上左右边距
          paddedSvg.setAttribute("height", svgHeight + 100); // 加上上下边距

          // 添加背景矩形
          const backgroundRect = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          backgroundRect.setAttribute("x", "0");
          backgroundRect.setAttribute("y", "0");
          backgroundRect.setAttribute("width", svgWidth + 100);
          backgroundRect.setAttribute("height", svgHeight + 100);
          backgroundRect.setAttribute("fill", backgroundColor);
          paddedSvg.appendChild(backgroundRect);

          // 将原始 SVG 内容放置在新 SVG 中间
          const svgClone = svg.cloneNode(true);
          svgClone.setAttribute("x", "50"); // 左边距
          svgClone.setAttribute("y", "50"); // 上边距
          paddedSvg.appendChild(svgClone);

          const svgString = new XMLSerializer().serializeToString(paddedSvg);

          //----------------------------------------------------------------

		  
          //const svgString = new XMLSerializer().serializeToString(svg);
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const canvgInstance = await Canvg.from(ctx, svgString);

          await canvgInstance.render();

          canvas.toBlob((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename + ".png";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, "image/png");
        }
      } else {
        console.error("Failed to fetch filename:", data.message);
      }
    } catch (error) {
      console.error("Error fetching filename:", error.message);
    }
  };

  handleJpgDownload = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("1diagramSvgDownloadfileid:", fileid);

    // 发送请求以获取文件名
    try {
      const response = await fetch(`${baseUrl}/fileapi/getfilename/${fileid}`);
      const data = await response.json();

      if (response.ok && data.success) {
        const filename = data.filename; // 从响应数据中获取文件名
       // console.log("1diagramSvgDownloadfilename:", filename);

        const diagram = this.diagramRef.current.getDiagram();
        if (diagram) {
          const { backgroundColor } = this.state;
          const svg = diagram.makeSvg({
            scale: 1,
            background: backgroundColor,
          });
          //----------------------------------------------------------------
          // 获取 SVG 宽度和高度
          const svgWidth = parseInt(svg.getAttribute("width"));
          const svgHeight = parseInt(svg.getAttribute("height"));

          // 创建包含边距的新 SVG 元素
          const paddedSvg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
          );
          paddedSvg.setAttribute("width", svgWidth + 100); // 加上左右边距
          paddedSvg.setAttribute("height", svgHeight + 100); // 加上上下边距

          // 添加背景矩形
          const backgroundRect = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          backgroundRect.setAttribute("x", "0");
          backgroundRect.setAttribute("y", "0");
          backgroundRect.setAttribute("width", svgWidth + 100);
          backgroundRect.setAttribute("height", svgHeight + 100);
          backgroundRect.setAttribute("fill", backgroundColor);
          paddedSvg.appendChild(backgroundRect);

          // 将原始 SVG 内容放置在新 SVG 中间
          const svgClone = svg.cloneNode(true);
          svgClone.setAttribute("x", "50"); // 左边距
          svgClone.setAttribute("y", "50"); // 上边距
          paddedSvg.appendChild(svgClone);

          const svgString = new XMLSerializer().serializeToString(paddedSvg);

          //----------------------------------------------------------------

		  
          //const svgString = new XMLSerializer().serializeToString(svg);
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const canvgInstance = await Canvg.from(ctx, svgString);

          await canvgInstance.render();

          const url = canvas.toDataURL("image/jpeg");
          const a = document.createElement("a");
          a.href = url;
          a.download = filename + ".jpg";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      } else {
        console.error("Failed to fetch filename:", data.message);
      }
    } catch (error) {
      console.error("Error fetching filename:", error.message);
    }
  };

  //load file backgroundcolor
  loadBackgroundColor = async () => {
    // const url = window.location.href;
    try {
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid");
      // console.log("fileid X", fileid);
      const response = await axios.get(
        `${baseUrl}/fileapi/getbasecolor/${fileid}`
      );
      this.setState({ backgroundColor: response.data.basecolor });
      // console.log("basecolor X", response.data.basecolor);
    } catch (error) {
      console.error(error);
    }
  };

  //load file backgroundImage
loadBackgroundImage = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid");
     // console.log("fileid X", fileid);
      const response = await axios.get(
        `${baseUrl}/fileapi/getimageurls/${fileid}`
      );
  
      if (response.data.imageUrls && response.data.imageUrls[0]) {
        const imageUrl = response.data.imageUrls[0];
       // console.log("imageUrl X", imageUrl);
        const publicIndex = imageUrl.indexOf("public");
        const relativePath = imageUrl.slice(publicIndex + "public".length);
        const imagePath = relativePath.replace(/\\/g, "/");
        const newimagePath = `${baseUrl}${imagePath}`;
       // console.log("baseimageUrl", newimagePath);
        this.setState({ backgroundImageUrl: newimagePath });
      } else {
        // When response.data.imageUrls[0] is null, return null
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  //life cycle
  componentDidMount() {
    // 加载数据
    this.loadDatafileid();
    // console.log("CD加载数据ok");

    //获取用户id
    this.handleUserIdlocal();

    // 获取最新的背景色
    this.loadBackgroundColor();

    // 获取最新的背景图片
    this.loadBackgroundImage();

    //监听node选择
    const diagram = this.diagramRef.current.getDiagram();
    diagram.addDiagramListener("ChangedSelection", this.handleSelectionChanged);
  }

  // componentWillUnmount() {
  //   const diagram = this.diagramRef.current.getDiagram();
  //   diagram.removeDiagramListener("ChangedSelection", this.handleSelectionChanged);
  // };

  //node selection
  handleSelectionChanged = () => {
    const diagram = this.diagramRef.current.getDiagram();
    const selectedNode = diagram.selection.first() || false;
    if (!selectedNode) {
      this.setState({
        selectedNode: {
          fileid: "",
          key: "",
          text: "",
          isgroup: false,
          title: [],
          detail: [],
          nodeselected: false,
        },
      });
      return;
    }
    const isgroup = selectedNode.data.isGroup || false;
    const nodekey = selectedNode.data.key;
    const isnode = !Boolean(selectedNode.data.from);
    // const islink = Boolean(selectedNode.data.from);

      if (isnode && nodekey && !isgroup) {
      //获取fileid
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid") || "";
      const key = selectedNode.data.key;
      const text = selectedNode.data.text || null;
     // console.log(`选定节点的KEY是 ${key}，文本值为 ${text}`);
      const nodekey = key;
      // 向后端发送请求获取 titles 和 details
      axios
        .get(`${baseUrl}/titlesapi/getNodeTitle/${fileid}/${nodekey}`)
        .then((response) => {
          this.setState({
            selectedNode: {
              fileid: fileid,
              key: key,
              text: text,
              isgroup: isgroup,
              title: response.data.titles,
              detail: response.data.details,
              nodeselected: true,
            },
          });

         // console.log(response.data.titles);
         // console.log(response.data.details);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (isnode && nodekey && isgroup) {
      //获取fileid
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid") || "";
      const key = selectedNode.data.key;
      const text = selectedNode.data.text || null;
      this.setState({
        selectedNode: {
          fileid: fileid,
          key: key,
          text: text,
          isgroup: isgroup,
          nodeselected: true,
        },
      });
    } else {
      // console.log("未选中任何节点");
      this.setState({
        selectedNode: {
          fileid: "",
          key: "",
          text: "",
          title: [],
          detail: [],
          isgroup: false,
          nodeselected: false,
        },
      });
    }
  };

  //----------------
  componentDidUpdate() {
    //center zoomtofit after loaddata------
    //xxxx
    // this.centerRootDiagram();
    // console.log("加载center");
  }

  render() {
    // function initDiagram() {
    //   // console.log("初始化");

    //   return diagram();
    // }

    function handleModelChange(changes) {
      // console.log("模型更改:", changes);
    }

    //isFavorite
    const { isFavoriteFilled, isStarFilled } = this.state;

    // //setbackgroundcolor
    // console.log("onColorChange:", this.handleSetColorChange);
    // const { backgroundColor, backgroundImage } = this.state;
    const { backgroundColor, backgroundImageUrl } = this.state;
    const layoutStyle = {
      backgroundColor,

      //backgroundimage html show
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      display: "flex",
    };
	const { t } = this.props;
    // 渲染组件
    return (
      <div className="model-layout" style={layoutStyle}>
        <ReactDiagram
          ref={this.diagramRef}
          initDiagram={this.initDiagram}
          // initDiagram={initDiagram}
          divClassName="diagram-component"
          nodeDataArray={this.state.nodeArray}
          linkDataArray={this.state.linkArray}
          backgroundImageUrl={this.state.backgroundImageUrl}
          onNodeSelect={this.handleNodeSelect}
          // background={backgroundImage}
          // onModelChange={handleModelChange}
        ></ReactDiagram>
        <Navbar handleFileNameChange={this.handleFileNameChange} />
        <Leftlayout
          handleSetColorChange={this.handleSetColorChange}
          // handleBackgroundImageChange={this.handleBackgroundImageChange}
        />
        <LoginPopup />
        <NoticePopup />
        <ChatPopup />
        <ToolPopup />
        <UserPopup />

        <InfoDraggable
          selectedNode={this.state.selectedNode}
          handleSelectionChanged={this.handleSelectionChanged}
          // title={this.state.title}
          // detail={this.state.detail}
        />

        <GroupDraggable />
        <OpRunEditDraggable
          selectedNode={this.state.selectedNode}
          handleSelectionChanged={this.handleSelectionChanged}
        />
        <div className="contentside">
          <ul className="options">
            <li
              className="option tool"
              id="rebulid"
              title={t("adminmodelnewfile.side-option0")}
              onClick={this.handleNewFile}
            >
              <img src={newfile} alt="" />
            </li>
            <li className="option tool" id="rebulid" title={t("adminmodelnewfile.side-option1")}>
              <img src={gridview} alt="" />
            </li>
            <li
              className="option tool"
              id="rebulid"
              title={t("adminmodelnewfile.side-option2")}
              onClick={this.loadDatafileid}
            >
              <img src={autorenew} alt="" />
            </li>
            {/* <li
              className="option tool"
              id="saveButton"
              title={t("adminmodelnewfile.side-option3")}
              onClick={this.saveData}
            >
              <img src={backup} alt="" />
            </li> */}
            <li
              className="option tool showData"
              onClick={this.handleQrcodeClick}
              id="showData"
              title={t("adminmodelnewfile.side-option4")}
            >
              <img src={qrcodeb} alt="" />
            </li>
            {this.state.isQrCodeModalOpen && (
              <QrCodeModal
                qrCodeImageUrl={this.state.qrCodeImageUrl}
                onClose={() => this.handleCloseQrCodeModal()}
              />
            )}
            <li
              className="option tool fileDownload"
              // onClick={this.handleInfoClick}
              id="fileDownload"
              title={t("adminmodelnewfile.side-option5")}
              onClick={this.handleDownFilesClick}
            >
              <img src={filedownload} alt="" />
            </li>
            {this.state.isDownFilesOpen && (
              <DownFiles
                // qrCodeImageUrl={this.state.qrCodeImageUrl}
                onClose={() => this.handleCloseDownFiles()}
                onJpgDownfile={() => this.handleJpgDownload()}
                onPngDownfile={() => this.handlePngDownload()}
                onSvgDownfile={() => this.handleSvgDownload()}
                // onPdfDownfile={() => this.handlePdfDownload()}
              />
            )}
          </ul>
          <ul className="options part2">
            <li
              class="option tool"
              id="rebulid"
              title={t("adminmodelnewfile.side-option6")}
              onClick={this.handleFavoriteClick}
            >
              {isFavoriteFilled ? (
                <img src={favoritefill} alt="" />
              ) : (
                <img src={favorite} alt="" />
              )}
            </li>
            <li
              class="option tool"
              id="backup"
              title={t("adminmodelnewfile.side-option7")}
              onClick={this.handleStarClick}
            >
              {isStarFilled ? (
                <img src={starfill} alt="" />
              ) : (
                <img src={star} alt="" />
              )}
            </li>
            <li
              className="option tool showData"
              id="showData"
              title={t("adminmodelnewfile.side-option8")}
              onClick={this.centerRootDiagram}
            >
              <img src={centerfocus} alt="" />
            </li>
            <li
              className="option tool fileDownload"
              id="fileDownload"
              title={t("adminmodelnewfile.side-option10")}
              onClick={this.zoomToFitDiagram}
            >
              <img src={fullfit} alt="" />
            </li>
            <li
              className="option tool fileDownload"
              id="fileDownload"
              title={t("adminmodelnewfile.side-option9")}
              onClick={this.scaleRootDiagram}
            >
              <img src={fullscreen} alt="" />
            </li>

            <li
              className="option tool fileDownload"
              id="fileDownload"
              title={t("adminmodelnewfile.side-option11")}
            >
              <SearchBox
                handleSearch={this.handleSearch}
                handleClose={this.handleClose}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ModelDiagramDataNewFile);
