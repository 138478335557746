import React, { useState, useRef } from "react";
import search from "../../img/search_black_24dp.svg";
import close from "../../img/close_black_24dp.svg";
import "./searchbox.scss";
import { useTranslation } from "react-i18next";
function SearchBox(props) {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false); // 定义搜索框的状态，初始值为未激活
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);

  // 处理搜索框的点击事件
  const handleSearchClick = () => {
    setIsActive(true);
    if (searchText) {
      props.handleSearch(searchText);
    }
  };

  // 处理关闭按钮的点击事件
  const handleCloseClick = () => {
    setIsActive(false);
    //close change nodes
    setSearchText("");
    inputRef.current.value = "";
    props.handleClose();
  };

  // 处理搜索输入框的输入事件
  const handleInputChange = (e) => {
    setSearchText(e.target.value.trim());
    // console.log(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // console.log("Enter");
      // console.log("searchText:", searchText);
      if (searchText) {
        props.handleSearch(searchText);
      }
    }
  };

  return (
    <div className={`searchBox${isActive ? " active" : ""}`}>
      <div className="search" onClick={handleSearchClick}>
        <img className="searchimg" title={t("searchbox.search-title")} src={search} alt="" />
      </div>
      <div className="searchInput">
        <input
          type="text"
          id="mySearch"
          ref={inputRef}
          autoComplete="off"
		  title={t("searchbox.input-title")}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder={t("searchbox.search-placeholder")}
        />
      </div>
      <div className="close">
        <img className="closeimg" src={close} alt="" title={t("searchbox.close-title")} onClick={handleCloseClick} />
      </div>
    </div>
  );
}

export default SearchBox;
