import React, { useContext, useEffect, useState } from "react";
import baseUrl from "../../configs/baseurl";
import { useLocation } from "react-router-dom";
import ModelDiagramViewNewFile from "../project01/adminModelViewNewFile";
import ModelDiagramViewNewFile2 from "../project02/adminModelViewNewFile";
import ModelDiagramViewNewFile3 from "../project03/adminModelViewNewFile";
import axios from "axios";

import enterkey from "../../img/encrypted_opsz24.svg";

import "./newfileview.scss";

import { AuthContext } from "../../context/authContext";
import NewLoader from "../../components/loaders/newloader";
import { useTranslation } from "react-i18next";
function NewFileView() {
  const { t } = useTranslation();

  const location = useLocation();
  const [fileStatus, setFileStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //diagramModel select
  const [diagramModel, setDiagramModel] = useState(null);
  //获得fileid
  const params = new URLSearchParams(location.search);
  const fileid = params.get("fileid");

  useEffect(() => {
    // 在此处使用 fetch 或其他适当的方法从后端获取 diagrammodel 数据
    const fetchDiagramModel = async () => {
      const response = await fetch(
        `${baseUrl}/fileapi/getFileDiagramModel/${fileid}`
      );
      if (response.ok) {
        const data = await response.json();
        const diagramModel = data.diagrammodel || "001";
        //console.log("diagrammodel xxxx ok", diagramModel);
        setDiagramModel(diagramModel);
      } else {
        console.error("Failed to fetch diagrammodel.");
      }
    };

    fetchDiagramModel();
  }, []);

  //isOriguser
  const [isOriguser, setIsOriguser] = useState(false);

  //vipgrade
  const { userVipGrade } = useContext(AuthContext);
  //console.log("N1新建文件VipGrade", userVipGrade.vip_no);
  const isVip = userVipGrade.vip_no > 1;
  //loading userid
  const userid = userVipGrade.userid;

  //isLoading user is file origuser
  useEffect(() => {
    // // 获取文件的fileid，你可以根据你的路由设置获取它
    // const params = new URLSearchParams(window.location.search);
    // const fileid = params.get("fileid");
    if (fileid) {
      // 发起请求获取origuser
      axios
        .get(`${baseUrl}/fileapi/getFileOrigUser/${fileid}`)
        .then((response) => {
          const origuser = response.data.origuser;
          // console.log("origuser 1", origuser);
          // 判断origuser是否等于userid，并更新状态
          setIsOriguser(origuser === userid);
        })
        .catch((error) => {
          console.error(error);
          // 处理错误
        });
    }
  }, [userid]);

  const [isOpenChecked, setIsOpenChecked] = useState(false);
  const [isPasswordChecked, setIsPasswordChecked] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  // const [overlayVisible, setOverlayVisible] = useState(true);
  // const [overnewfilelayfileVisible, setOvernewfilelayfileVisible] =
  useState(true);

  //get open database
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/fileapi/getFileOpenModel/${fileid}`
        );
        const data = await response.json();

        setIsOpenChecked(data.isOpenChecked);
        setIsPasswordChecked(data.isPasswordChecked);
        setPasswordValue(data.passwordValue);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [fileid]);

  // useEffect(() => {
  //   console.log(isOpenChecked, isPasswordChecked, passwordValue);
  // });

  const shouldShowPasswordOverlay = () => {
    return (
      !isOriguser && isOpenChecked && isPasswordChecked && !isPasswordMatched
    );
  };

  const shouldShowOvernewfilelayfile = () => {
    return (
      (!isOriguser && !isOpenChecked && !isPasswordMatched) ||
      (!isOriguser && isPasswordChecked && isOpenChecked && !isPasswordMatched)
    );
  };

  const handlePasswordChange = (e) => {
    setEnteredPassword(e.target.value);
  };

  const [isPasswordMatched, setIsPasswordMatched] = useState(false);

  const handlePasswordSubmit = () => {
    if (isPasswordChecked && enteredPassword === passwordValue) {
      // setOverlayVisible(false);
      // setOvernewfilelayfileVisible(false);
      setIsPasswordMatched(true);
    } else {
      console.log("密码错误");
    }
  };

  //load date
  useEffect(() => {
    const startTime = Date.now();
    const getFileStatus = async () => {
      try {
        // const params = new URLSearchParams(location.search);
        // const fileid = params.get("fileid");
        // console.log("fileopenstatus:", fileid);
        const response = await fetch(
          `${baseUrl}/fileapi/checkfilestatus/${fileid}`
        );
        const data = await response.json();
        setFileStatus(data.status);

        const minLoadingTime = 500;

        const loadingTime = Date.now() - startTime;
        const waitTime = Math.max(0, minLoadingTime - loadingTime);

        const timer = setTimeout(() => {
          setIsLoading(false);
        }, waitTime);

        return () => clearTimeout(timer);
      } catch (error) {
        console.error("Error while checking file status:", error);
        setIsLoading(false);
      }
    };

    getFileStatus();
  }, [location.search]);

  if (isLoading) {
    return <NewLoader />;
  }

  // if (fileStatus === null) {
  //   return <div>Loading...</div>;
  // }

  if (fileStatus > 0) {
    return (
      <>
        <div
          className="password-overlay"
          style={{ display: shouldShowPasswordOverlay() ? "block" : "none" }}
        >
          <form className="password-form" onSubmit={(e) => e.preventDefault()}>
            <div className="pwenterhead">
              <img src={enterkey} className="keyicon" alt="" />
              <label htmlFor="password">
                {t("newfile.newfileview-label1")}
              </label>
            </div>
            <input
              type="password"
              id="password"
              name="password"
              value={enteredPassword}
              onChange={handlePasswordChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handlePasswordSubmit();
                }
              }}
            />
            <button type="button" onClick={handlePasswordSubmit}>
              {t("newfile.newfileview-button1")}
            </button>
          </form>
        </div>
        <div
          className="overnewfilelayfile"
          style={{ display: shouldShowOvernewfilelayfile() ? "block" : "none" }}
        ></div>
        <div>
          {/* <ModelDiagramViewNewFile /> */}
          {diagramModel === "001" && <ModelDiagramViewNewFile />}
          {diagramModel === "002" && <ModelDiagramViewNewFile2 />}
          {diagramModel === "003" && <ModelDiagramViewNewFile3 />}
        </div>
      </>
    );
  } else {
    return <div>{t("newfile.newfileview-show")}</div>;
  }
}

export default NewFileView;
