const ChatActiveReducer = (state, action) => {
  switch (action.type) {
    case "HIDE": {
      return {
        chatActive: false,
      };
    }
    case "SHOW": {
      return {
        chatActive: true,
      };
    }
    case "TOGGLE": {
      return {
        chatActive: !state.chatActive,
      };
    }
    default:
      return state;
  }
};
export default ChatActiveReducer;
