import * as go from "gojs/release/go-module";
import { GuidedDraggingTool } from "gojs/extensionsJSM/GuidedDraggingTool";
import { DrawCommandHandler } from "gojs/extensionsJSM/DrawCommandHandler";

// import * as go from "../modules/sysmodules/release/go-module";
// import { GuidedDraggingTool } from "../modules/sysmodules/extensionsJSM/GuidedDraggingTool";
// import { DrawCommandHandler } from "../modules/sysmodules/extensionsJSM/DrawCommandHandler";

import { FigureButton } from "gojs/extensionsJSM/Figures";
//--------------------------------------
export function diagram() {
  // console.log("初始化");
  const $ = go.GraphObject.make;
  const diagram = $(go.Diagram, {
    // have mouse wheel events zoom in and out instead of scroll up and down
    "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
    initialAutoScale: go.Diagram.Uniform,
    "linkingTool.direction": go.LinkingTool.ForwardsOnly,
    layout: $(go.LayeredDigraphLayout, {
      isInitial: false,
      isOngoing: false,
      layerSpacing: 50,
    }),
    //-----
    scrollMode: go.Diagram.InfiniteScroll, // allow the diagram to be scrolled beyond content
    padding: 20, // extra space when scrolled all the way
    //grid settings
    // grid: $(
    //   go.Panel,
    //   "Grid", // a simple 10x10 grid
    //   $(go.Shape, "LineH", {
    //     stroke: "rgba(140,140,140,0.2)",
    //     strokeWidth: 0.5,
    //   }),
    //   $(go.Shape, "LineV", {
    //     stroke: "rgba(140,140,140,0.2)",
    //     strokeWidth: 0.5,
    //   })
    // ),            //grid line
    "draggingTool.isGridSnapEnabled": true,
    handlesDragDropForTopLevelParts: true,

    mouseDrop: (e) => {
      // when the selection is dropped in the diagram's background,
      // make sure the selected Parts no longer belong to any Group
      var ok = e.diagram.commandHandler.addTopLevelParts(
        e.diagram.selection,
        true
      );
      if (!ok) e.diagram.currentTool.doCancel();
    },
    commandHandler: $(DrawCommandHandler), // support offset copy-and-paste

    "undoManager.isEnabled": false, // enable undo & redo

    //New Group
    "commandHandler.archetypeGroupData": { isGroup: true, text: "新组合" },
    SelectionGrouped: (e) => {
      var group = e.subject;
      setTimeout(() => {
        // and have the user start editing its text
        e.diagram.commandHandler.editTextBlock();
      });
    },

    //linkRelinked
    LinkRelinked: (e) => {
      // re-spread the connections of other links connected with both old and new nodes
      var oldnode = e.parameter.part;
      oldnode.invalidateConnectedLinks();
      var link = e.subject;
      if (e.diagram.toolManager.linkingTool.isForwards) {
        link.toNode.invalidateConnectedLinks();
      } else {
        link.fromNode.invalidateConnectedLinks();
      }
    },

    //----------------------------------------------------------------
    model: $(go.GraphLinksModel, {
      linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    }),
  });

  // //background picture set
  // diagram.add(
  //   $(
  //     go.Part, // this Part is not bound to any model data
  //     {
  //       layerName: "Background",
  //       position: new go.Point(0, -100),
  //       selectable: false,
  //       pickable: false,
  //     },
  //     $(go.Picture, {
  //       // width: 360,
  //       // height: 380,
  //       // source: backgroundImage,
  //       source: "http://localhost:8800/uploads/backgroudimge001.jpg",
  //       // source: "http://localhost:8800/uploads/backgroudimge001.svg" ,
  //       // source: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Apple_Computer_Logo_rainbow.svg/800px-Apple_Computer_Logo_rainbow.svg.png",
  //     })
  //   )
  // );

  // tooltip hovered node
  var nodeContextMenu = $(
    go.Adornment,
    "Spot",
    { background: "transparent" }, // to help detect when the mouse leaves the area
    $(go.Placeholder),
    $(
      go.Panel,
      "Auto",
      // { alignment: go.Spot.Right, alignmentFocus: new go.Spot(0, 1, 0, 10) , width: 160},
      { alignment: go.Spot.Right, alignmentFocus: go.Spot.Left },
      $(go.Shape, "RoundedRectangle", {
        fill: "#ffffffeb",
        // fill: "#aab8c2",
        stroke: "#9ca2b598",
        strokeWidth: 0.5,
        parameter1: 5,
      }),
      $(
        go.Panel,
        "Vertical",
        {
          maxSize: new go.Size(130, NaN),
          defaultAlignment: go.Spot.Left,
          // background: "#ffffff",
          margin: 10,
        },

        $(
          go.TextBlock,
          {
            font: "8pt sans-serif",
            textAlign: "center",
            stroke: "#393c48",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part.adornedPart;
            if (
              node.data.titles1 ||
              node.data.details1 ||
              node.data.titles2 ||
              node.data.details2 ||
              node.data.titles3 ||
              node.data.details3 ||
              node.data.titles4 ||
              node.data.details4
            ) {
              return node.data.text;
            } else {
              return "";
            }
          })
        ),
        //title
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#1876f2",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            // return nodeData.titles1;
            var title = nodeData.titles1 || "";
            return title + " : ";
          }),
          //show visibility
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles1;
          })
        ),
        //details
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#6c757d",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details1;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details1;
          })
        ),
        //title
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#1876f2",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            var title = nodeData.titles2 || "";
            return title + " : ";
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles2;
          })
        ),
        //details
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#6c757d",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details2;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details2;
          })
        ),
        //title
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#1876f2",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            var title = nodeData.titles3 || "";
            return title + " : ";
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles3;
          })
        ),
        //details
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#6c757d",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details3;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details3;
          })
        ),
        //title
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#1876f2",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            var title = nodeData.titles4 || "";
            return title + " : ";
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles4;
          })
        ),
        //details
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            textAlign: "left",
            stroke: "#6c757d",
            margin: 5,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details4;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details4;
          })
        )
      )
    )
  );

  //-----------------------------------
  //001 define a simple text Node template
  diagram.nodeTemplateMap.add(
    "TextNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        movable: false, //xx
        selectable: false, //xx
        resizable: false, //xx
	rotatable: false, //xx--
        resizeCellSize: new go.Size(10, 10),
        //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave
        click: function (e, obj) {
          const node = obj.part;
          const hltext = node.data.hltext;
          if (hltext) {
            let url = hltext;
            if (
              !hltext.startsWith("https://") &&
              !hltext.startsWith("http://")
            ) {
              url = "https://" + hltext;
            }

            window.open(url, "_blank");
          }
        },
      },
      //picture
      // $(go.Picture, { source: null }, new go.Binding("source", "imageurl")),

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),

      //--------hover node context
      {
        mouseEnter: (e, node) => {
          var key = node.data.key;
         // console.log("Hovered node key: " + key);
          if (
            node.data.titles1 ||
            node.data.details1 ||
            node.data.titles2 ||
            node.data.details2 ||
            node.data.titles3 ||
            node.data.details3 ||
            node.data.titles4 ||
            node.data.details4
          ) {
            nodeContextMenu.adornedObject = node;
            nodeContextMenu.mouseLeave = (ev, cm) => {
              node.removeAdornment("ContextMenuOver");
            };
            node.addAdornment("ContextMenuOver", nodeContextMenu);
          }
        },
      },

      //-----------

      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",
            mouseEnter: function (e, obj) {
              var thicknessValue = parseFloat(obj.part.data.thickness);

              if (isNaN(thicknessValue)) {
                thicknessValue = 1; // 或者任何你想要的默认值
              }

              thicknessValue += 1;

              obj.strokeWidth = thicknessValue;

              var colorValue = obj.part.data.color || "red";
              obj.stroke = colorValue;
              obj.cursor = "pointer";
            },
            mouseLeave: function (e, obj) {
              obj.stroke = obj.part.data.color;
             // obj.fill = obj.part.data.fill;
              obj.strokeWidth = obj.part.data.thickness;
            },
            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
       // $(go.Shape, {
         // width: 100,
         // height: 40,
         // strokeWidth: 0,
         // fill: "transparent",
         // // cursor: "grab",
        // // cursor: "pointer", //  xx
       // }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 20,
            wrap: go.TextBlock.WrapFit,
           // cursor: "pointer", //xx
            editable: false, //xxx

            mouseEnter: function (e, obj) {
              const node = obj.part;
              const hltext = node.data.hltext;
              if (hltext) {
                obj.cursor = "pointer";
                obj.isUnderline = true;
              }
            },
            mouseLeave: function (e, obj) {
              obj.isUnderline = false;
            },
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        ),
        //contextMenu
        {
          // define a context menu for each node
          contextMenu: $(
            "ContextMenu", // that has one button
            $(
              "ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                _buttonFillOver: "#F3FBFF",
                width: 260,
                maxSize: new go.Size(90, 30),

                // margin: new go.Margin(6, 10, 6, 3),
                // defaultAlignment: go.Spot.Left,
              },
              $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "状态")
            ),
            $(
              "ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                _buttonFillOver: "#F3FBFF",
                width: 260,
                maxSize: new go.Size(90, 30),
              },
              $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "详细信息")
            ),
            $(
              "ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                _buttonFillOver: "#F3FBFF",
                width: 260,
                maxSize: new go.Size(90, 30),
              },
              $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "上传文件")
            ),
            $(
              "ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                _buttonFillOver: "#F3FBFF",
                width: 260,
                maxSize: new go.Size(90, 30),
              },
              $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "咨询交流")
            ),
            $(
              "ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                _buttonFillOver: "#F3FBFF",
                width: 260,
                maxSize: new go.Size(90, 30),
              },
              $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "资料库")
            ),
            $(
              "ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                _buttonFillOver: "#F3FBFF",
                width: 260,
                maxSize: new go.Size(90, 30),
              },
              $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "AI资讯")
            )
            // more ContextMenuButtons would go here
          ), // end Adornment
        }
      ),
      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(42, 42),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: true, //xxxx--
        toLinkable: true, //xxx--
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  //002 define a simple picture Node template
  diagram.nodeTemplateMap.add(
    "PictureNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        // movable: false, //xx
        // selectable: false, //xx
        resizable: false, //xx
        //rotatable: false, //xx--
        movable: true, //xx2
        selectable: true, //xx2
        rotatable: true, //xx2--
        resizeCellSize: new go.Size(10, 10),
        //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave
        click: function (e, obj) {
          const node = obj.part;

          // 获取节点数据
          const data = node.data;

          // 将节点数据从原始位置移除
          const index = diagram.model.nodeDataArray.indexOf(data);
          if (index !== -1) {
            diagram.model.nodeDataArray.splice(index, 1);
          }

          // 将节点数据添加到数组的末尾
          diagram.model.nodeDataArray.push(data);

          //background all nodes
          diagram.nodes.each(function (n) {
            n.layerName = "Background";
          });
          //set clicked node foreground
          node.layerName = "Foreground";
        },
      },

      //--------hover node context
      {
        mouseEnter: (e, node) => {
          var key = node.data.key;
          // console.log("Hovered node key: " + key);
          if (
            node.data.titles1 ||
            node.data.details1 ||
            node.data.titles2 ||
            node.data.details2 ||
            node.data.titles3 ||
            node.data.details3 ||
            node.data.titles4 ||
            node.data.details4
          ) {
            nodeContextMenu.adornedObject = node;
            nodeContextMenu.mouseLeave = (ev, cm) => {
              node.removeAdornment("ContextMenuOver");
            };
            node.addAdornment("ContextMenuOver", nodeContextMenu);
          }
        },
      },

      //picture
      $(go.Picture, { source: null }, new go.Binding("source", "imageurl", function (imageurl) {
         return imageurl.replace("https://api.xxplans.com/uploads", "https://api.xxplans.com/api/uploads");
        })),

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),
      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",

            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
        $(go.Shape, {
          width: 100,
          height: 40,
          strokeWidth: 0,
          fill: "transparent",
          // cursor: "grab",
          cursor: "pointer", //  xx
        }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 10,
            wrap: go.TextBlock.WrapFit,
            cursor: "pointer", //xx
            editable: false, //xxx

            // cursor: "pointer",xxx
            click: function (e, obj) {
              const node = obj.part;
              const hltext = node.data.hltext;
              if (hltext) {
                let url = hltext;
                if (
                  !hltext.startsWith("https://") &&
                  !hltext.startsWith("http://")
                ) {
                  url = "http://" + hltext;
                }

                window.open(url, "_blank");
              }
            },
            mouseEnter: function (e, obj) {
              const node = obj.part;
              const hltext = node.data.hltext;
              if (hltext) {
                obj.cursor = "pointer";
                obj.isUnderline = true;
              }
            },
            mouseLeave: function (e, obj) {
              obj.isUnderline = false;
            },
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        ),
        //contextMenu
      ),
      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  //003 define a simple picture Node template
  diagram.nodeTemplateMap.add(
    "StaticPictureNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        // movable: false, //xx
        // selectable: false, //xx
        resizable: false, //xx
        //rotatable: false, //xx--
        movable: false, //xx2
        selectable:false, //xx2
        rotatable: false, //xx2--
        resizeCellSize: new go.Size(10, 10),
        //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave
      // click: function (e, obj) {
        //   const node = obj.part;

        //   // 获取节点数据
        //   const data = node.data;

        //   // 将节点数据从原始位置移除
        //   const index = diagram.model.nodeDataArray.indexOf(data);
        //   if (index !== -1) {
        //     diagram.model.nodeDataArray.splice(index, 1);
        //   }

        //   // 将节点数据添加到数组的末尾
        //   diagram.model.nodeDataArray.push(data);

        //   //background all nodes
        //   diagram.nodes.each(function (n) {
        //     n.layerName = "Background";
        //   });
        //   //set clicked node foreground
        //   node.layerName = "Foreground";

        //   // console.log(
        //   //   "diagram.model.nodeDataArray",
        //   //   diagram.model.nodeDataArray
        //   // );
        // },
      },

      //--------hover node context
      {
        mouseEnter: (e, node) => {
          var key = node.data.key;
          // console.log("Hovered node key: " + key);
          if (
            node.data.titles1 ||
            node.data.details1 ||
            node.data.titles2 ||
            node.data.details2 ||
            node.data.titles3 ||
            node.data.details3 ||
            node.data.titles4 ||
            node.data.details4
          ) {
            nodeContextMenu.adornedObject = node;
            nodeContextMenu.mouseLeave = (ev, cm) => {
              node.removeAdornment("ContextMenuOver");
            };
            node.addAdornment("ContextMenuOver", nodeContextMenu);
          }
        },
      },

      //picture
      $(go.Picture, { source: null }, new go.Binding("source", "imageurl", function (imageurl) {
         return imageurl.replace("https://api.xxplans.com/uploads", "https://api.xxplans.com/api/uploads");
        })),

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),
      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",

            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
        $(go.Shape, {
          width: 100,
          height: 40,
          strokeWidth: 0,
          fill: "transparent",
          // cursor: "grab",
          cursor: "pointer", //  xx
        }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 10,
            wrap: go.TextBlock.WrapFit,
            cursor: "pointer", //xx
            editable: false, //xxx

            // cursor: "pointer",xxx
            click: function (e, obj) {
              const node = obj.part;
              const hltext = node.data.hltext;
              if (hltext) {
                let url = hltext;
                if (
                  !hltext.startsWith("https://") &&
                  !hltext.startsWith("http://")
                ) {
                  url = "http://" + hltext;
                }

                window.open(url, "_blank");
              }
            },
            mouseEnter: function (e, obj) {
              const node = obj.part;
              const hltext = node.data.hltext;
              if (hltext) {
                obj.cursor = "pointer";
                obj.isUnderline = true;
              }
            },
            mouseLeave: function (e, obj) {
              obj.isUnderline = false;
            },
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        ),
        //contextMenu
      ),
      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: false, //xxxx
        toLinkable: false, //xxx
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  //-------------------

  //olde nodetemplate 01
  //  diagram.nodeTemplate = $(
  //   go.Node,
  //   "Auto",
  //   {
  //     locationSpot: go.Spot.Center,
  //     locationObjectName: "SHAPE",
  //     desiredSize: new go.Size(120, 50),
  //     minSize: new go.Size(5, 5),
  //     movable: false, //xx
  //     selectable: false, //xx
  //     resizable: false, //xx
  //     resizeCellSize: new go.Size(10, 10),
  //     //shadow
  //     // isShadowed: true,
  //     // shadowBlur: 2,
  //     // shadowOffset: new go.Point(0, 3),
  //     // shadowColor: "rgba(0, 0, 0, .14)",
  //     // mouseEnter: mouseEnter,
  //     // mouseLeave: mouseLeave
  //   },

  //   //picture
  //   // $(go.Picture, { source: null }, new go.Binding("source", "imageurl")),

  //   // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
  //   new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
  //     go.Point.stringify
  //   ),
  //   new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
  //     go.Size.stringify
  //   ),
  //   $(
  //     go.Panel,
  //     "Auto",
  //     $(
  //       go.Shape,
  //       {
  //         // the border
  //         name: "SHAPE",
  //         fill: "white",
  //         portId: "",

  //         // fromLinkable: true,
  //         // toLinkable: true,
  //         // fromLinkableDuplicates: true,
  //         // toLinkableDuplicates: true, //du node link
  //         // fromSpot: go.Spot.AllSides,
  //         // toSpot: go.Spot.AllSides,
  //         cursor: "grab",
  //       },
  //       new go.Binding("figure"),
  //       new go.Binding("fill"),
  //       new go.Binding("stroke", "color"),
  //       new go.Binding("strokeWidth", "thickness"),
  //       new go.Binding("strokeDashArray", "dash")
  //       // gold if highlighted, white otherwise
  //       // new go.Binding("fill", "isHighlighted", (h) =>
  //       //   h ? "gold" : "#ffffff"
  //       // ).ofObject()
  //     ),
  //     // this Shape prevents mouse events from reaching the middle of the port
  //     $(go.Shape, {
  //       width: 100,
  //       height: 40,
  //       strokeWidth: 0,
  //       fill: "transparent",
  //       // cursor: "grab",
  //       cursor: "pointer", //  xx
  //     }),
  //     $(
  //       go.TextBlock,
  //       {
  //         margin: 1,
  //         font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
  //         name: "TEXT",
  //         textAlign: "center",
  //         overflow: go.TextBlock.OverflowEllipsis,
  //         maxLines: 10,
  //         wrap: go.TextBlock.WrapFit,
  //         cursor: "pointer", //xx
  //         editable: false, //xxx

  //         // cursor: "pointer",
  //         click: function (e, obj) {
  //           const node = obj.part;
  //           const hltext = node.data.hltext;
  //           if (hltext) {
  //             // window.location.href = hltext;
  //             window.open("http://" + hltext, "_blank");
  //             // if (!hltext.startsWith("http")) {
  //             //   hltext = "http://" + hltext;
  //             // }
  //             // window.open(hltext, "_blank");
  //           }
  //         },
  //         mouseEnter: function (e, obj) {
  //           const node = obj.part;
  //           const hltext = node.data.hltext;
  //           if (hltext) {
  //             obj.cursor = "pointer";
  //             obj.isUnderline = true;
  //           }
  //         },
  //         mouseLeave: function (e, obj) {
  //           obj.isUnderline = false;
  //         },
  //       },
  //       // this Binding is TwoWay due to the user editing the text with the TextEditingTool
  //       new go.Binding("text").makeTwoWay(),
  //       new go.Binding("stroke", "color"),
  //       //xx
  //       new go.Binding("font", "font").makeTwoWay(),
  //       new go.Binding("textAlign", "textalign").makeTwoWay()
  //     ),
  //     //contextMenu
  //     {
  //       // define a context menu for each node
  //       contextMenu: $(
  //         "ContextMenu", // that has one button
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),

  //             // margin: new go.Margin(6, 10, 6, 3),
  //             // defaultAlignment: go.Spot.Left,
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "状态")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "详细信息")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "上传文件")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "咨询交流")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "资料库")
  //         ),
  //         $(
  //           "ContextMenuButton",
  //           {
  //             "ButtonBorder.fill": "white",
  //             _buttonFillOver: "#F3FBFF",
  //             width: 260,
  //             maxSize: new go.Size(90, 30),
  //           },
  //           $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "AI资讯")
  //         )
  //         // more ContextMenuButtons would go here
  //       ), // end Adornment
  //     }
  //   ),
  //   //top port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Top,
  //     portId: "t",
  //     fromSpot: go.Spot.Top,
  //     toSpot: go.Spot.Top,
  //     fromLinkable: false, //xxxx
  //     toLinkable: false, //xxx
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //bottom port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Bottom,
  //     portId: "b",
  //     fromSpot: go.Spot.Bottom,
  //     toSpot: go.Spot.Bottom,
  //     fromLinkable: false, //xxxx
  //     toLinkable: false, //xxx
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //center port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Center,
  //     portId: "c",
  //     fromSpot: go.Spot.Center,
  //     toSpot: go.Spot.Center,
  //     fromLinkable: false, //xxxx
  //     toLinkable: false, //xxx
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //left port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Left,
  //     portId: "l",
  //     fromSpot: go.Spot.Left,
  //     toSpot: go.Spot.Left,
  //     fromLinkable: false, //xxxx
  //     toLinkable: false, //xxx
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //right port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Right,
  //     portId: "r",
  //     fromSpot: go.Spot.Right,
  //     toSpot: go.Spot.Right,
  //     fromLinkable: false, //xxxx
  //     toLinkable: false, //xxx
  //     toLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   })
  // );

  var white = "rgba(255, 255, 255, 0.6)";
  var blue = "#1876f2";
  var grad = "#6c757d";
  //detailed information tooltip 弹窗信息 x
  diagram.nodeTemplate.toolTip = $(
    "ToolTip", // show some detailed information
    { "Border.fill": "white", "Border.stroke": null },
    new go.Binding("visible", "", function (data, textBlock) {
      var node = textBlock.part;
      if (!node) return false;
      var key = node.key;
      var nodeData = diagram.model.findNodeDataForKey(key);
      return (
        !!nodeData.titles1 ||
        !!nodeData.details1 ||
        !!nodeData.titles2 ||
        !!nodeData.details2 ||
        !!nodeData.titles3 ||
        !!nodeData.details3 ||
        !!nodeData.titles4 ||
        !!nodeData.details4
      );
    }),
    // new go.Binding("visible", !!"titles" || !!"details"),
    $(
      go.Panel,
      "Auto",
      $(go.Shape, "Rectangle", { fill: white, stroke: null }),
      $(
        go.Panel,
        "Vertical",

        {
          maxSize: new go.Size(160, NaN),
          padding: 5,
          defaultAlignment: go.Spot.Left,
        }, // limit width but not height

        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "center",
          },
          new go.Binding("text")
        ),
        //------xx
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            stroke: blue,
            textAlign: "center",
            margin: 4,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.titles1;
          }),
          //visibility
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles1;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "left",
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details1;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details1;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            stroke: blue,
            textAlign: "center",
            margin: 4,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.titles2;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles2;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "left",
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details2;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details2;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            stroke: blue,
            textAlign: "center",
            margin: 4,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.titles3;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles3;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "left",
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details3;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details3;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            stroke: blue,
            textAlign: "center",
            margin: 4,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.titles4;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles4;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "left",
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details4;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details4;
          })
        )
      )
    )
  );

  // diagram.nodeTemplate.toolTip = $(
  //   "ToolTip", // show some detailed information
  //   { "Border.fill": "white", "Border.stroke": null },
  //   $(
  //     go.Panel,
  //     "Auto",
  //     $(
  //       go.Panel,
  //       "Vertical",

  //       {
  //         maxSize: new go.Size(160, NaN),
  //         padding: 5,
  //         defaultAlignment: go.Spot.Left,
  //       }, // limit width but not height

  //       $(
  //         go.TextBlock,
  //         {
  //           font: "bold 9pt Arial, sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "center",
  //         },
  //         new go.Binding("text")
  //       ),
  //       //------xx
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "8pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "titles")
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "details")
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "8pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "titles2")
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "details2")
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "8pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "titles3")
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "details3")
  //       )
  //     )
  //   )
  // );
  //--------------------------------------------

  //--------------------------------------------------
  // Group template

  diagram.groupTemplate = $(
    go.Group,
    "Spot",
    {
      layerName: "Background",
      ungroupable: true,
      locationSpot: go.Spot.Center,
      selectionObjectName: "BODY",
      movable: false, //xx
      selectable: false, //xx
      computesBoundsAfterDrag: true, // allow dragging out of a Group that uses a Placeholder
      handlesDragDropForMembers: true, // don't need to define handlers on Nodes and Links
      mouseDrop: (e, grp) => {
        // add dropped nodes as members of the group
        var ok = grp.addMembers(grp.diagram.selection, true);
        if (!ok) grp.diagram.currentTool.doCancel();
      },
      avoidable: false,
    },
    new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
      go.Point.stringify
    ),

    $(
      go.Panel,
      "Auto",
      { name: "BODY" },
      $(
        go.Shape,
        "RoundedRectangle",
        {
          parameter1: 8,
          fill: "white",
          stroke: "#1B54D9",
          strokeWidth: 1,
          portId: "",
          // cursor: "pointer",
          cursor: "grab",
          fromLinkable: false, //xxxx
          toLinkable: false, //xxx
          fromLinkableDuplicates: true,
          toLinkableDuplicates: true,
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill"),
        new go.Binding("stroke", "color"),
        new go.Binding("strokeWidth", "thickness"),
        new go.Binding("strokeDashArray", "dash")
      ),

      $(go.Placeholder, {
        background: "transparent",
        margin: 10,
        padding: new go.Margin(16, 0, 0, 0),
        cursor: "grab",
      })
    ),
    $(
      go.TextBlock,

      {
        alignment: go.Spot.Top,
        alignmentFocus: new go.Spot(0.5, 0, 0, -4),
        // alignmentFocus: go.Spot.Bottom,
        font: "12pt system-ui, -apple-system, Arial, sans-serif",
        stroke: "#1B54D9",
        editable: true,
      },
      new go.Binding("text").makeTwoWay(),
      new go.Binding("stroke", "color"),
      new go.Binding("font", "font").makeTwoWay()
    ),
    //Group port
    //top port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Top,
      portId: "t",
      fromSpot: go.Spot.Top,
      toSpot: go.Spot.Top,
      fromLinkable: false, //xxxx
      toLinkable: false, //xxx
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //bottom port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Bottom,
      portId: "b",
      fromSpot: go.Spot.Bottom,
      toSpot: go.Spot.Bottom,
      fromLinkable: false, //xxxx
      toLinkable: false, //xxx
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //center port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Center,
      portId: "c",
      fromSpot: go.Spot.Center,
      toSpot: go.Spot.Center,
      fromLinkable: false, //xxxx
      toLinkable: false, //xxx
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //left port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Left,
      portId: "l",
      fromSpot: go.Spot.Left,
      toSpot: go.Spot.Left,
      fromLinkable: false, //xxxx
      toLinkable: false, //xxx
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //right port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Right,
      portId: "r",
      fromSpot: go.Spot.Right,
      toSpot: go.Spot.Right,
      fromLinkable: false, //xxxx
      toLinkable: false, //xxx
      toLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    })
  );

  // Link template

  diagram.linkTemplate = $(
    go.Link,
    {
      layerName: "Foreground",
      curve: go.Link.Bezier,
      routing: go.Link.Normal, //布尔
      // curve: go.Link.JumpGap,
      // routing: go.Link.Normal, //直线--
      // routing: go.Link.AvoidsNodes,
      corner: 1,
      toShortLength: 6, // assume arrowhead at "to" end, need to avoid bad appearance when path is thick
      relinkableFrom: false, //xxx
      relinkableTo: false, //xxx
      selectable: true, //xxx
      reshapable: true,
      resegmentable: true,
    },
    new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
    new go.Binding("toSpot", "toSpot", go.Spot.parse),
    new go.Binding("fromShortLength", "dir", (dir) => (dir === 2 ? 4 : 0)),
    new go.Binding("toShortLength", "dir", (dir) => (dir >= 1 ? 4 : 0)),
    new go.Binding("points").makeTwoWay(), // TwoWay due to user reshaping with LinkReshapingTool
    new go.Binding(
      "routing",
      "routing",
      go.Binding.parseEnum(go.Link, go.Link.AvoidsNodes)
    ).makeTwoWay(go.Binding.toString), // link routing
    //line background
    //$(go.Shape, { isPanelMain: true, stroke: "#DDF1FF", strokeWidth: 4 }),
    //线
    $(
      go.Shape,
      {
        isPanelMain: true,
        stroke: "#1B54D9",
        strokeWidth: 1,
        name: "PIPE",
        strokeDashArray: [0, 0],
      },
      new go.Binding("stroke", "color"),
      new go.Binding("strokeWidth", "thickness"),
      new go.Binding("strokeDashArray", "dash")
    ),
    //箭头
    $(
      go.Shape,
      {
        fromArrow: "Backward",
        fill: "#1B54D9",
        strokeWidth: 0,
        scale: 4 / 3,
        visible: false,
      },
      new go.Binding("visible", "dir", (dir) => dir === 2),
      new go.Binding("fill", "color"),
      new go.Binding("scale", "thickness", (t) => (2 + t) / 3)
    ),
    $(
      go.Shape,
      {
        toArrow: "Standard",
        //arrow shape stroke
        strokeWidth: 0,
        stroke: "#585858",
        fill: "#1B54D9",
        scale: 4 / 3,
	visible: false,
      },
      new go.Binding("visible", "dir", (dir) => dir >= 1),
      new go.Binding("fill", "color"),
      new go.Binding("scale", "thickness", (t) => (2 + t) / 3)
    ),
    //文字
    $(
      go.TextBlock,
      {
        alignmentFocus: new go.Spot(0, 1, -4, 0),
        font: "9pt system-ui, -apple-system, Arial, sans-serif",
        editable: true,
      },
      new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
      new go.Binding("stroke", "color")
    )
  );

  //link templateslink 链接中的样式
  diagram.toolManager.linkingTool.temporaryLink = $(
    go.Link,
    { layerName: "Tool" },
    $(go.Shape, { stroke: "#FF00FF", strokeWidth: 1, strokeDashArray: [4, 2] })
  );
  //-----------------
  //--------------------------------------------------

  return diagram;
}
