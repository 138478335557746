import "./navbar.scss";
import React, { useState, useContext, useRef, useEffect } from "react";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
import { useLocation } from "react-router-dom";
import {
  Bell,
  Search,
  NavArrowDown,
  NavArrowUp,
  MoreVert,
  Menu,
  Settings,
  ChatLines,
} from "@plansplan/icons";

import axios from "axios";

import logoSvg from "../../images/logo.svg";
// import logotextSvg from "../../images/logo-text.svg";
import strangeuserSvg from "../../images/userimages/strangeuser.svg";
import registereduserSvg from "../../images/userimages/registereduser.svg";
import { LeftLayoutContext } from "../../context/leftLayoutContext";
import { PopupActiveContext } from "../../context/popupActiveContext";
import { DraggableActiveContext } from "../../context/draggableActiveContext";
import { AuthContext } from "../../context/authContext";
import { ChatActiveContext } from "../../context/chatActiveContext";
import { useTranslation } from "react-i18next";
const Navbar = (props) => {
  const { t } = useTranslation();

  //chatActiveContext
  const { dispatchchat } = useContext(ChatActiveContext);

  //获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);

  //isOriguser
  const [isOriguser, setIsOriguser] = useState(false);

  //vipgrade
  // console.log("N1新建文件VipGrade", userVipGrade.vip_no);
  //loading userid
  const vipno = userVipGrade.vip_no;
  const userid = userVipGrade.userid;
  
   // 获取文件的fileid，你可以根据你的路由设置获取它
  const params = new URLSearchParams(window.location.search);
  const fileid = params.get("fileid");

  const url = window.location.href;

  //isLoading user is file origuser
  useEffect(() => {
    
    if (fileid) {
      // 发起请求获取origuser
      axios
        .get(`${baseUrl}/fileapi/getFileOrigUser/${fileid}`)
        .then((response) => {
          const origuser = response.data.origuser;
          // console.log("origuser 1", origuser);
          // 判断origuser是否等于userid，并更新状态
          setIsOriguser(origuser === userid);
        })
        .catch((error) => {
          console.error(error);
          // 处理错误
        });
    }
  }, [userid]);

  const [userInfo, setUserInfo] = useState({
    picurl: null,
  });

  const [userImage, setUserImage] = useState(null);

  //showdropdown for models
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);

  // useEffect(() => {
  //   console.log("handleModel showDropdown 00", showDropdown);
  // });

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
	setShowDropdown2(false);
  };

  const handleDropdownMouseLeave = () => {
    setShowDropdown(false);
  };

  const handleOutsideClick = (event) => {
    if (event.target.closest(".dropdown")) return;
    setShowDropdown(false);
  };

  //----------

  const handleDropdown2Click = () => {
    
    // if (fileid) {
    setShowDropdown2(!showDropdown2);
	setShowDropdown(false);
    // }
  };

  const handleDropdown2MouseLeave = () => {
    setShowDropdown2(false);
  };

  const handleOutside2Click = (event) => {
    if (event.target.closest(".dropdown")) return;
    setShowDropdown2(false);
  };

  //edit model blank
  const handleClickEdit = () => {
    
    //origuser can edit
    if (!url.includes("edit") && isOriguser) {
      
      const editUrl = `/edit1?fileid=${fileid}`;
      let newWindow = window.open(editUrl, `edit_${fileid}`);
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed == "undefined"
      ) {
        newWindow = window.open(editUrl, `edit_${fileid}`);
      } else {
        newWindow.location.href = editUrl;
      }
    }
  };

  //data model blank
  const handleClickData = () => {
   
    //origuser can data edit
    if (!url.includes("data") && isOriguser) {
     
      const dataUrl = `/data1?fileid=${fileid}`;
      let newWindow = window.open(dataUrl, `data_${fileid}`);
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed == "undefined"
      ) {
        newWindow = window.open(dataUrl, `data_${fileid}`);
      } else {
        newWindow.location.href = dataUrl;
      }
    }
  };

  //view model blank
  const handleClickView = () => {
    
    if (!url.includes("view")) {
      
      const viewUrl = `/view1?fileid=${fileid}`;
      let newWindow = window.open(viewUrl, `view_${fileid}`);
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed == "undefined"
      ) {
        newWindow = window.open(viewUrl, `view_${fileid}`);
      } else {
        newWindow.location.href = viewUrl;
      }
    }
  };

  //filesearch click handler
  const handleFileSearchClick = () => {
    setShowDropdown2(false); // 关闭下拉菜单
    props.onFileSearchClick(); // 调用父组件传递的点击事件处理程序
  };

  //user logined
  // const loginuser = localStorage.getItem("user");
  // console.log("loginuser", loginuser);
  // const [currentUser, setCurrentUser] = useState(loginuser);
  const [usertitle, setUsertitle] = useState("New user");

  // const handleLogout = () => {
  //   localStorage.removeItem("user");
  //   setCurrentUser(null); // 在登出时将当前用户设置为 null
  // };

  //title = currentUser
  useEffect(() => {
    if (currentUser) {
      setUsertitle(currentUser);
    } else {
      setUsertitle("New user");
    }
  }, [currentUser]);

  // const handleMenuClick = () => {};

  const [fileNameText, setFileNameText] = useState("");
  const inputRef = useRef(null);

  //leftLayoutContext
  const { dispatch01 } = useContext(LeftLayoutContext);

  //popupActiveContext
  const { dispatch3 } = useContext(PopupActiveContext);

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  // //clear Search
  // const ref = useRef(null);

  // const handleClick = () => {
  //   ref.current.value = "";
  // };

  //loading fileName from sql by ID
  useEffect(() => {
    async function fetchFileName() {
      try {
      
        if (!fileid) {
          return;
        }
       // console.log("fileid X", fileid);
        const response = await axios.get(
          `${baseUrl}/fileapi/getfilename/${fileid}`
        );
        if (response.data.filename === null) {
          // console.log("Filename is null");
          return;
        } else {
          setFileNameText(response.data.filename);
          // console.log("filename X", response.data.filename);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchFileName();
  }, []);

  // 处理搜索输入框的输入事件
  const fileNameInputChange = (e) => {
   if (isOriguser) {
    setFileNameText(e.target.value.trim());
    // console.log(e.target.value);
   }
  };

  const updateFileName = async (fileid, filename) => {
    const response = await axios.put(
      `${baseUrl}/fileapi/updatefilename/${fileid}`,
      {
        filename: filename,
      }
    );
    // console.log("updatedFileName X", filename);
    return response.data;
  };

  const fileNameKeyPress = async (e) => {
    if (e.key === "Enter" && isOriguser) {
      // console.log("Enter");
      e.preventDefault(); // 阻止默认行为
      if (fileNameText) {
        try {
          
          const { fileid: updatedFileId, filename } = await updateFileName(
            fileid,
            fileNameText
          );
          // console.log("updatedFileId", fileid);
          setFileNameText(filename);
          // 清空文件名输入框
          // setFileNameText("");
        } catch (error) {
          console.error(error);
          // TODO: 处理错误
        }
      }
    }
  };

  //modelshow
  const [modelText, setModelText] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();

  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search);
    
    // const mode = searchParams.get("mode");
    // console.log(mode);
    const delay = 1000;
    const timerId = setTimeout(() => {
    if (url.includes("edit")) {
      setModelText(t("navbar.dropdown-edit"));
      setActiveTab("edit");
    } else if (url.includes("data")) {
      setModelText(t("navbar.dropdown-data"));
      setActiveTab("data");
    } else if (url.includes("view")) {
      setModelText(t("navbar.dropdown-view"));
      setActiveTab("view");
    } else {
      setModelText("");
      setActiveTab("show");
    }
    }, delay);

    return () => clearTimeout(timerId);
  }, [location]);

  //userInfo gets updated
  useEffect(() => {
    if (userid) {
      fetch(`${baseUrl}/userapi/getUserInfo/${userid}`)
        .then((response) => response.json())
        .then((data) => {
          setUserInfo({
            picurl: data.pic_url,
          });
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    }
  }, [userid]);

  useEffect(() => {
    if (userInfo.picurl) {
      const imageUrl = userInfo.picurl;
      const publicIndex = imageUrl.indexOf("public");
      const relativePath = imageUrl.slice(publicIndex + "public".length);
      const imagePath = relativePath.replace(/\\/g, "/");
      // const newimagePath = `${backUrl}${imagePath}`;
      // const newimagePath = `${baseUrl}${imagePath}`;    //vps need
      // console.log("upload pic user11", imagePath);

      setUserImage(imagePath, () => {
        // console.log("upload pic ff2", userImage);
      });
    } else {
      setUserImage(null);
    }
  }, [userInfo.picurl]);
  
//fetch function select from server
  const fetchfunctionData = async (fileid) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/getFileFunctionData2/${fileid}`
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error("Failed to fetch file function data.");
      }
    } catch (error) {
      console.error("Error fetching file function data:", error);
    }
    return null;
  };

  //function set
  const isViewModel = window.location.href.includes("view");
  //1 navbar function
  const [isNavbarChecked, setIsNavbarChecked] = useState(true);
  //2 notice function
  const [isNoticeChecked, setIsNoticeChecked] = useState(false);
  //3 chat function
  const [isChatsChecked, setIsChatsChecked] = useState(true);
  //4 tool function
  const [isToolsChecked, setIsToolsChecked] = useState(true);
  //5 filesearch function
  const [isFileSearchChecked, setIsFileSearchChecked] = useState(true);
  //6 infomation function
  const [isInfosChecked, setIsInfosChecked] = useState(true);
  //7 run operation function
  const [isRunOPChecked, setIsRunOPChecked] = useState(false);
  //8 other menu function
  const [isOtherMenuChecked, setIsOtherMenuChecked] = useState(false);
  //9 backgroud settings function
  const [isBackgroudChecked, setIsBackgroudChecked] = useState(false);

  const [navbarContentVisible, setNavbarContentVisible] = useState(true);

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      const data = await fetchfunctionData(fileid);
      // Update state based on fetched data
      if (!data) {
        setIsNavbarChecked(true);
        setIsNoticeChecked(false);
        setIsChatsChecked(true);
        setIsToolsChecked(true);
        setIsFileSearchChecked(true);
        setIsInfosChecked(true);
        setIsRunOPChecked(false);
        setIsOtherMenuChecked(false);
        setIsBackgroudChecked(false);
      } else {
        setIsNavbarChecked(Boolean(data.is_navbar));
        setIsNoticeChecked(Boolean(data.is_notice));
        setIsChatsChecked(Boolean(data.is_chat));
        setIsToolsChecked(Boolean(data.is_tool));
        setIsFileSearchChecked(Boolean(data.is_filesearch));
        setIsInfosChecked(Boolean(data.is_info));
        setIsRunOPChecked(Boolean(data.is_runop));
        setIsOtherMenuChecked(Boolean(data.is_menu));
        setIsBackgroudChecked(Boolean(data.is_backgroud));
        setNavbarContentVisible(Boolean(data?.is_navbar));
      }
    };
    // Call the function to fetch data
    fetchDataAndUpdateState();
  }, [fileid]);  
  
  //show / hide navbar
  const handleShowNavbarClick = () => {
    setNavbarContentVisible(!navbarContentVisible);
  };

  return (
    <>
      <div className={`navbar ${navbarContentVisible ? "" : "hide"}`}>
        <div className="itemLeft">
		{navbarContentVisible && (
            <>
          <div
            className="item"
            id="op-item"
            title={t("navbar.title-menu")}
             onClick={() => {
              if (!isViewModel || (isViewModel && isOtherMenuChecked)) {
                if (vipno > 1) {
                  dispatch01({ type: "TOGGLE" });
                }
                setShowDropdown(false);
                setShowDropdown2(false);
                dispatch05({ type: "NOALLDRAGGABLE" });
                dispatch3({ type: "ALLPOPUPHIDE" });
                dispatchchat({ type: "HIDE" });
              }
            }}
          >
            <Menu className="menu" />
          </div>

          <a href="#" className="logo" id="op-item-logo">
            <img
              src={logoSvg}
              alt=""
              onClick={() => {
                dispatch05({ type: "NOALLDRAGGABLE" });
                dispatch3({ type: "ALLPOPUPHIDE" });
                dispatch01({ type: "HIDE" });
                dispatchchat({ type: "HIDE" });
                setShowDropdown(false);
                setShowDropdown2(false);
              }}
            />
            {/* <img src={logotextSvg} alt="" /> */}
          </a>
          <div
                className="filename"
                id="op-item-filename"
                title={t("navbar.title-filename")}
              >         
            <input
              type="text"
              id="file-name"
              placeholder={t("navbar.placeholder-filename")}
              ref={inputRef}
              value={fileNameText}
              onChange={fileNameInputChange}
              onKeyPress={fileNameKeyPress}
			  onClick={() => {
                dispatch05({ type: "NOALLDRAGGABLE" });
                dispatch3({ type: "ALLPOPUPHIDE" });
                dispatch01({ type: "HIDE" });
               dispatchchat({ type: "HIDE" });
                setShowDropdown(false);
                setShowDropdown2(false);
              }}
              autoComplete="off"
            />
          </div>
          {vipno > 1 && isOriguser ? (
            <>
              <div
                className="modelshow"
                id="op-item"
                onClick={() => {
                  dispatch05({ type: "NOALLDRAGGABLE" });
                  dispatch3({ type: "ALLPOPUPHIDE" });
                  dispatch01({ type: "HIDE" });
                  dispatchchat({ type: "HIDE" });
                  handleDropdownClick();
                }}
              >
                {modelText}
              </div>
            </>
          ) : null}
          {showDropdown && isOriguser && (
            <div
              className="dropdown"
              id="op-item"
              onMouseLeave={handleDropdownMouseLeave}
            >
              <ul>
                <li onClick={handleClickEdit}>{t("navbar.dropdown-edit")}</li>
                <li onClick={handleClickData}>{t("navbar.dropdown-data")}</li>
                <li onClick={handleClickView}>{t("navbar.dropdown-view")}</li>
              </ul>
            </div>
          )}
		  </>
          )}
        </div>
        <div className="nav-right">
          {/* <div className="search">
          <Search className="searchicon" />
          <input type="text" id="mySearch" ref={ref} placeholder="搜索" />
          <span className="clear" onClick={handleClick}></span>
        </div> */}

          <div className="items">
		  {navbarContentVisible && (
              <>
            <div className="item1"></div>

            <div className="buttonuser">
              {currentUser ? (
                /*注册用户*/
                <div
                  className="item"
                  title={usertitle}
                  onClick={() => {
                    dispatch05({ type: "NOALLDRAGGABLE" });
                    dispatch01({ type: "HIDE" });
                    dispatch3({ type: "USERS" });
                    dispatchchat({ type: "HIDE" });
                    setShowDropdown(false);
                    setShowDropdown2(false);
                  }}
                >
                  <img
                    src={
                      userImage
                        ? `${baseUrl}${userImage}?${Math.random()}`
                        : registereduserSvg
                    }
                    alt=""
                    className="strangeavatar"
                  />
                </div>
              ) : (
                /*未注册用户*/
                <div
                  className="item"
                  title={t("navbar.title-user")}
                  onClick={() => dispatch3({ type: "LOGIN" })}
                >
                  <img src={strangeuserSvg} className="strangeavatar" alt="" />
                </div>
              )}
            </div>

            <div
              className="item"
              id="op-item"
              title={t("navbar.title-notice")}
              onClick={() => {
                if (!isViewModel || (isViewModel && isNoticeChecked)) {
                  if (vipno > 1) {
                    dispatch3({ type: "NOTICE" });
                  }
                  setShowDropdown(false);
                  setShowDropdown2(false);
                }
              }}
            >
              <Bell className="icons" />
              {/* <div className="counter">2</div> */}
            </div>
            <div
              className="item"
              id="op-item"
              title={t("navbar.title-chats")}
               onClick={() => {
                if (!isViewModel || (isViewModel && isChatsChecked)) {
                  if (vipno > 0) {
                    dispatch3({ type: "CHATS" });
                  }
                  setShowDropdown(false);
                  setShowDropdown2(false);
                }
              }}
            >
              <ChatLines className="icons" />
              {/* <div className="counter2">5</div> */}
            </div>

            <div className="item"
                 id="op-item"
                 title={t("navbar.title-tools")}
             onClick={() => {
                  if (vipno > 1) {
                    dispatch3({ type: "TOOLS" });
                  }
                  dispatchchat({ type: "HIDE" });
                  setShowDropdown(false);
                  setShowDropdown2(false);
              }}
             >
              <Settings className="icons2" />
            </div>
			</>
            )}

             <div
              className="item"
              title={t("navbar.title-more")}
              onClick={() => {
               //dispatch05({ type: "NOALLDRAGGABLE" });
                dispatch3({ type: "ALLPOPUPHIDE" });
                dispatch01({ type: "HIDE" });
                handleDropdown2Click();
              }}
            >
              <MoreVert className="icons3" />
            </div>
            {showDropdown2 && (
              <div
                className="dropdown2"
                onMouseLeave={handleDropdown2MouseLeave}
              >
                <ul>
                  {activeTab === "edit" && (
				  <>
                    <li onClick={() => dispatch05({ type: "EDITDRAGGABLE" })}>
                      {t("navbar.dropdown2-edit")}
                    </li>
					<li className="downbt" onClick={handleShowNavbarClick}>
                        {navbarContentVisible
                          ? t("navbar.dropdown2-hidenavbar")
                          : t("navbar.dropdown2-shownavbar")}
                      </li>
				  </>
                  )}
                  {activeTab === "data" && (
                    <>
                      <li onClick={() => dispatch05({ type: "DATADRAGGABLE" })}>
                        {t("navbar.dropdown2-data")}
                      </li>
                      <li
                        onClick={() => dispatch05({ type: "GROUPDRAGGABLE" })}
                      >
                        {t("navbar.dropdown2-group")}
                      </li>
                      <li
                        onClick={() => dispatch05({ type: "OPRUNDRAGGABLE" })}
                      >
                        {t("navbar.dropdown2-oprun")}
                      </li>
					  <li className="downbt" onClick={handleShowNavbarClick}>
                        {navbarContentVisible
                          ? t("navbar.dropdown2-hidenavbar")
                          : t("navbar.dropdown2-shownavbar")}
                      </li>
                    </>
                  )}
                  {activeTab === "view" && (
                     <>
                      {/* <li 
                      onClick={handleFileSearchClick}
                      > */}
                      <li
                         onClick={() => {
                          if (
                            !isViewModel ||
                            (isViewModel && isFileSearchChecked)
                          ) {
                            dispatch05({ type: "NOALLDRAGGABLE" });
                            dispatchchat({ type: "HIDE" });
                            handleFileSearchClick();
                          }
                        }}
                      >
                        {t("navbar.dropdown2-filesearch")}
                      </li>
                      <li
                        // onClick={() => {
                        //   dispatch05({ type: "NODEDATADRAGGABLE" });
                        //   props.onCloseFileSearch();
                        // }}
                        onClick={() => {
                          if (!isViewModel || (isViewModel && isInfosChecked)) {
                            dispatch05({ type: "NODEDATADRAGGABLE" });
                            props.onCloseFileSearch();
                            setShowDropdown2(false);
                          }
                        }}
                      >
                        {t("navbar.dropdown2-nodedata")}
                      </li>
                      <li
                        onClick={() => {
                          if (!isViewModel || (isViewModel && isRunOPChecked)) {
                            dispatch05({ type: "OPRUNDRAGGABLE" });
                            dispatchchat({ type: "HIDE" });
                            props.onCloseFileSearch();
                            setShowDropdown2(false);
                          }
                        }}
                      >
                        {t("navbar.dropdown2-oprun")}
                      </li>
					   <li className="downbt" onClick={handleShowNavbarClick}>
                        {navbarContentVisible
                          ? t("navbar.dropdown2-hidenavbar")
                          : t("navbar.dropdown2-shownavbar")}
                      </li>
                    </>
                  )}
                  {activeTab === "show" && (
                    <li onClick={handleFileSearchClick}>{t("navbar.dropdown2-filesearch")}</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={showDropdown ? "overlay1 active" : "overlay1"}
        onClick={handleOutsideClick}
      ></div>
      <div
        className={showDropdown2 ? "overlay2 active" : "overlay2"}
        onClick={handleOutside2Click}
      ></div>
    </>
  );
};

export default Navbar;
