import { TableAxios } from "../datatables/TableAxios";
import { TableBasic } from "../datatables/TableBasic";
import { TableJson } from "../datatables/TableJson";
import Leftlayout from "../leftlayout/Leftlayout";
// import NodeTable1 from "../nodetable/NodeTable1";

const MainDataTable = () => {
  return (
    <div className="maindatatable">
      <div className="layouttable-left">
        <Leftlayout />
      </div>
      <div className="layouttable-right">
        {/* <TableBasic /> */}
        {/* <TableJson /> */}
        <TableAxios />
      </div>
    </div>
  );
};

export default MainDataTable;
