import React, { useState, useContext, useEffect } from "react";
import baseUrl from "../../configs/baseurl";
import axios from "axios";
import "./oprundraggable.scss";
import { ChatBubble, Folder, WarningTriangleOutline } from "@plansplan/icons";
import Draggable from "react-draggable";
import playrun from "../../img/play_arrow_FILL48.svg";
import playstop from "../../img/stop_FILL48.svg";
import playpause from "../../img/pause_FILL48.svg";
import playstoped from "../../img/stop_FILL48_2.svg";
import warnings from "../../img/warning_FILL24.svg";
import infobt from "../../img/info_FILL24.svg";
import closebt from "../../img/close_black_24dp.svg";
import morebt from "../../img/more_horiz48.svg";

import { DraggableActiveContext } from "../../context/draggableActiveContext";
import { useTranslation } from "react-i18next";
export default function OpRunDraggable(props) {
	const { t } = useTranslation();
  const { draggableActive3 } = useContext(DraggableActiveContext);

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isStoping, setIsStoping] = useState(false);
  const [isMoreShow, setIsMoreShow] = useState(false);

  function handleMoreToggle() {
    setIsMoreShow(!isMoreShow);
  }

  function handlePlayToggle() {
    setIsPlaying(!isPlaying);
    if (isStoping === false) {
      setIsStoping(true);
    }
  }

  function handleStopToggle() {
    if (isStoping) {
      setIsPlaying(false);
    }
    setIsStoping(!isStoping);
  }

  function getStatusText(isPlaying, isStoping) {
    let statusText = "";
    let bgColor = "";
    if (isStoping === false) {
      statusText = t("oprundraggable.oprun-status1");
      bgColor = "#6C757D";
    } else if (isPlaying) {
      statusText = t("oprundraggable.oprun-status2");
      bgColor = "#66C9F9";
    } else {
      statusText = t("oprundraggable.oprun-status3");
      bgColor = "#BDC2D4";
    }
    return { statusText, bgColor };
  }

  const { statusText, bgColor } = getStatusText(isPlaying, isStoping);

  return (
    <Draggable cancel=".headicons img, .controlbuttons img,.headcancel img,.linedesc img,.picinput input,.picbuttons button">
      <div
        className={
          draggableActive3 ? "controlinfocard active" : "controlinfocard"
        }
      >
        <div className="controllayout">
          <div className="controlhead">
            <div className="headstatus" style={{ backgroundColor: bgColor }}>
              {statusText}
            </div>
            <div className="headicons">
              <div className="headalarm">
                <img className="headicon" src={warnings} />
                <span className="headnum">2</span>
              </div>
              <div className="headinfo">
                <img className="headicon" src={infobt} />
                <span className="headnum">5</span>
              </div>
            </div>
            <div className="headcancel">
              <img
                className="closeicon"
                src={closebt}
                onClick={() => dispatch05({ type: "OPRUNDRAGGABLE" })}
              />
            </div>
          </div>
          <div className="controltitle">
            <div className="nodedesc">{t("oprundraggable.oprun-nodedesc")}</div>
            <div className="controlop">{t("oprundraggable.oprun-controlop")}</div>
          </div>
          <div className="controlbuttons">
            <div>
              {isPlaying ? (
                <img
                  className="pauseicon"
                  src={playpause}
                  onClick={handlePlayToggle}
                />
              ) : (
                <img
                  className="runicon"
                  src={playrun}
                  onClick={handlePlayToggle}
                />
              )}
            </div>
            <div>
              {isStoping ? (
                <img
                  className="stopicon"
                  src={playstop}
                  onClick={handleStopToggle}
                />
              ) : (
                <img
                  className="stopicon"
                  src={playstoped}
                  onClick={handleStopToggle}
                />
              )}
            </div>
            <div className="timeshow real">
              <div className="realsup">20:10:30</div>
              <div className="realsdown">30%</div>
            </div>
            <div className="timeshow agree">
              <div className="agreesup">32:10:30</div>
              <div className="agreesdown">-20%</div>
            </div>
            <div className="timeshow legal">
              <div className="legalsup">52:10:30</div>
              <div className="legalsdown">-20%</div>
            </div>
          </div>
          <div className="controlline">
            <div className="lineitems">
              <div className="linepic">
                <input
                  type="range"
                  className="timeslider"
                  min="0"
                  max="100"
                  step="1"
                  value="30"
                />
              </div>
              <div className="linedesc">
                <div className="timeinput">20230508 12:30:20</div>
                <div className="bottombotton">
                  <img
                    className="morebtn"
                    src={morebt}
                    onClick={handleMoreToggle}
                  />
                </div>
                <div className="timeinput">20230508 12:30:20</div>
              </div>
            </div>
          </div>
          {isMoreShow ? (
            <div className="controlinputs">
              <div className="cycletimes">
                <div className="timetitle">{t("oprundraggable.oprun-timetitle1")}</div>
                <div className="timeinput">20230508 12:30:20</div>
                <div className="spacecenter"></div>
                <div className="timetitle">{t("oprundraggable.oprun-timetitle2")}</div>
                <div className="timeinput2">10日</div>
              </div>
              <div className="agreetimes">
                <div className="timetitle">{t("oprundraggable.oprun-timetitle3")}</div>
                <div className="timeinput">20230518 12:30:20</div>

                <div className="spacecenter"></div>
                <div className="timetitle">{t("oprundraggable.oprun-timetitle4")}</div>
                <div className="timeinput2">8日</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Draggable>
  );
}
