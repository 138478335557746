import * as go from "gojs/release/go-module";
import { GuidedDraggingTool } from "gojs/extensionsJSM/GuidedDraggingTool";
import { DrawCommandHandler } from "gojs/extensionsJSM/DrawCommandHandler";
// import * as go from "../modules/sysmodules/release/go-module";
// import { GuidedDraggingTool } from "../modules/sysmodules/extensionsJSM/GuidedDraggingTool";
// import { DrawCommandHandler } from "../modules/sysmodules/extensionsJSM/DrawCommandHandler";

import { FigureButton } from "gojs/extensionsJSM/Figures";
//--------------------------------------
export function diagram() {
  const $ = go.GraphObject.make;
  const diagram = $(go.Diagram, {
    // have mouse wheel events zoom in and out instead of scroll up and down
    "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
    initialAutoScale: go.Diagram.Uniform,
    "linkingTool.direction": go.LinkingTool.ForwardsOnly,
    layout: $(go.LayeredDigraphLayout, {
      isInitial: false,
      isOngoing: false,
      layerSpacing: 50,
    }),
    //-----
    scrollMode: go.Diagram.InfiniteScroll, // allow the diagram to be scrolled beyond content
    padding: 20, // extra space when scrolled all the way
    //grid settings
    // grid: $(
    //   go.Panel,
    //   "Grid", // a simple 10x10 grid
    //   $(go.Shape, "LineH", {
    //     stroke: "rgba(140,140,140,0.2)",
    //     strokeWidth: 0.5,
    //   }),
    //   $(go.Shape, "LineV", {
    //     stroke: "rgba(140,140,140,0.2)",
    //     strokeWidth: 0.5,
    //   })
    // ),            //grid line
    "draggingTool.isGridSnapEnabled": true,
    handlesDragDropForTopLevelParts: true,
    mouseDrop: (e) => {
      // when the selection is dropped in the diagram's background,
      // make sure the selected Parts no longer belong to any Group
      var ok = e.diagram.commandHandler.addTopLevelParts(
        e.diagram.selection,
        true
      );
      if (!ok) e.diagram.currentTool.doCancel();
    },
    commandHandler: $(DrawCommandHandler), // support offset copy-and-paste

    "undoManager.isEnabled": true, // enable undo & redo

    //GuidedDraggingTool
    draggingTool: new GuidedDraggingTool(), // defined in GuidedDraggingTool.js
    "draggingTool.isGridSnapEnabled": true,
    "draggingTool.guidelineWidth": 0.5,
    "draggingTool.guidelineSnapDistance": 5,
    "draggingTool.horizontalGuidelineColor": "#11CDCD",
    "draggingTool.verticalGuidelineColor": "#11CDCD",
    "draggingTool.centerGuidelineColor": "#FE4A69",
    // "draggingTool.guidelineDashArray": [4, 2],

    // "draggingTool.guideline": $(
    //   go.Part,
    //   { layerName: "Tool" },
    //   $(go.Shape, { strokeDashArray: [4, 2] })
    // ), //strokeDashArray

    // create a new node by double-clicking in background
    "clickCreatingTool.archetypeNodeData": {
      category: "TextNode", // category  xxx
      text: "新节点",
      font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
      color: "#1B54D9",
      size: "120 50",
      thickness: 1,
      figure: "RoundedRectangle",
      fill: "white",
    },

    //add node by click
    PartCreated: (e) => {
      var node = e.subject; // the newly inserted Node -- now need to snap its location to the grid
      node.location = node.location
        .copy()
        .snapToGridPoint(
          e.diagram.grid.gridOrigin,
          e.diagram.grid.gridCellSize
        );
      setTimeout(() => {
        // and have the user start editing its text
        e.diagram.commandHandler.editTextBlock();
      }, 20);
    },

    //New Group
    "commandHandler.archetypeGroupData": { isGroup: true, text: "新组合" },
    SelectionGrouped: (e) => {
      var group = e.subject;
      setTimeout(() => {
        // and have the user start editing its text
        e.diagram.commandHandler.editTextBlock();
      });
    },

    //linkRelinked
    LinkRelinked: (e) => {
      // re-spread the connections of other links connected with both old and new nodes
      var oldnode = e.parameter.part;
      oldnode.invalidateConnectedLinks();
      var link = e.subject;
      if (e.diagram.toolManager.linkingTool.isForwards) {
        link.toNode.invalidateConnectedLinks();
      } else {
        link.fromNode.invalidateConnectedLinks();
      }
    },

    //savedModel toJson
    // ModelChanged: function (e) {
    //   if (e.isTransactionFinished) {
    //     document.getElementById("savedModel").textContent =
    //       diagram.model.toJson();
    //   }
    // },
    //----------------------------------------------------------------
    model: $(go.GraphLinksModel, {
      linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    }),
  });

  //-----------------------------------
  //001 define a simple text Node template
  diagram.nodeTemplateMap.add(
    "TextNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        resizable: true,
	rotatable: true, //xx--
        // resizeObjectName: "SHAPE",
        resizeCellSize: new go.Size(10, 10),
        //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave
        contextMenu: createTextNodeContextMenu(), // 添加 contextMenu 函数   xxx
        // toolTip: createNodeToolTip(), // 添加 toolTip 函数           xxx
        selectionAdornmentTemplate: createNodeSelectionAdornment(), // 添加 selectionAdornmentTemplate   xxx
        click: function (e, obj) {
          const node = obj.part;

          // 获取节点数据
          const data = node.data;

          // 将节点数据从原始位置移除
          const index = diagram.model.nodeDataArray.indexOf(data);
          if (index !== -1) {
            diagram.model.nodeDataArray.splice(index, 1);
          }

          // 将节点数据添加到数组的末尾
          diagram.model.nodeDataArray.push(data);

          //background all nodes
          diagram.nodes.each(function (n) {
            n.layerName = "Background";
          });
          //set clicked node foreground
          node.layerName = "Foreground";

          // console.log(
          //   "diagram.model.nodeDataArray",
          //   diagram.model.nodeDataArray
          // );
        },     
      },

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),

      //picture
      // $(
      //   go.Picture,
      //   { name: "SHAPE", source: null },
      //   new go.Binding("source", "imageurl")
      // ),

      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",

            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
        $(go.Shape, {
          width: 100,
          height: 40,
          strokeWidth: 0,
          fill: "transparent",
          cursor: "grab",
        }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 20,
            wrap: go.TextBlock.WrapFit,
            editable: true,

            // // cursor: "pointer",
            // click: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     window.location.href = hltext;
            //   }
            // },
            // mouseEnter: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     obj.cursor = "pointer";
            //   }
            // },
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        )
      ),

      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: true,
        toLinkable: true,
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  //002 define a simple picture Node template
  diagram.nodeTemplateMap.add(
    "PictureNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        resizable: true,
	rotatable: true, //xx--
        // resizeObjectName: "SHAPE",
        resizeCellSize: new go.Size(10, 10),
        //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave
        contextMenu: createPictureNodeContextMenu(), // 添加 contextMenu 函数   xxx
       click: function (e, obj) {
          const node = obj.part;

          // 获取节点数据
          const data = node.data;

          // 将节点数据从原始位置移除
          const index = diagram.model.nodeDataArray.indexOf(data);
          if (index !== -1) {
            diagram.model.nodeDataArray.splice(index, 1);
          }

          // 将节点数据添加到数组的末尾
          diagram.model.nodeDataArray.push(data);

          //background all nodes
          diagram.nodes.each(function (n) {
            n.layerName = "Background";
          });
          //set clicked node foreground
          node.layerName = "Foreground";

        },
       },

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),

      //picture
      $(
        go.Picture,
        { name: "SHAPE", source: null },
        new go.Binding("source", "imageurl", function (imageurl) {
         return imageurl.replace("https://api.xxplans.com/uploads", "https://api.xxplans.com/api/uploads");
        })
      ),

      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",

            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
        $(go.Shape, {
          width: 100,
          height: 40,
          strokeWidth: 0,
          fill: "transparent",
          cursor: "grab",
        }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 20,
            wrap: go.TextBlock.WrapFit,
            editable: true,

            // // cursor: "pointer",
            // click: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     window.location.href = hltext;
            //   }
            // },
            // mouseEnter: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     obj.cursor = "pointer";
            //   }
            // },
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        )
      ),

      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: true,
        toLinkable: true,
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  //003 define a simple picture Node template
  diagram.nodeTemplateMap.add(
    "StaticPictureNode",
    $(
      go.Node,
      "Auto",
      {
        locationSpot: go.Spot.Center,
        locationObjectName: "SHAPE",
        desiredSize: new go.Size(120, 50),
        minSize: new go.Size(5, 5),
        resizable: true,
	rotatable: true, //xx--
        // resizeObjectName: "SHAPE",
        resizeCellSize: new go.Size(10, 10),
        //shadow
        // isShadowed: true,
        // shadowBlur: 2,
        // shadowOffset: new go.Point(0, 3),
        // shadowColor: "rgba(0, 0, 0, .14)",
        // mouseEnter: mouseEnter,
        // mouseLeave: mouseLeave
        contextMenu: createPictureNodeContextMenu(), // 添加 contextMenu 函数   xxx
      // click: function (e, obj) {
        //   const node = obj.part;

        //   // 获取节点数据
        //   const data = node.data;

        //   // 将节点数据从原始位置移除
        //   const index = diagram.model.nodeDataArray.indexOf(data);
        //   if (index !== -1) {
        //     diagram.model.nodeDataArray.splice(index, 1);
        //   }

        //   // 将节点数据添加到数组的末尾
        //   diagram.model.nodeDataArray.push(data);

        //   //background all nodes
        //   diagram.nodes.each(function (n) {
        //     n.layerName = "Background";
        //   });
        //   //set clicked node foreground
        //   node.layerName = "Foreground";

        //   // console.log(
        //   //   "diagram.model.nodeDataArray",
        //   //   diagram.model.nodeDataArray
        //   // );
        // },
       },

      // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      new go.Binding("angle").makeTwoWay(),
      new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),

      //picture
      $(
        go.Picture,
        { name: "SHAPE", source: null },
        new go.Binding("source", "imageurl", function (imageurl) {
         return imageurl.replace("https://api.xxplans.com/uploads", "https://api.xxplans.com/api/uploads");
        })
      ),

      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          {
            // the border
            name: "SHAPE",
            fill: "white",
            portId: "",

            // fromLinkable: true,
            // toLinkable: true,
            // fromLinkableDuplicates: true,
            // toLinkableDuplicates: true, //du node link
            // fromSpot: go.Spot.AllSides,
            // toSpot: go.Spot.AllSides,
            cursor: "grab",
          },
          new go.Binding("figure"),
          new go.Binding("fill"),
          new go.Binding("stroke", "color"),
          new go.Binding("strokeWidth", "thickness"),
          new go.Binding("strokeDashArray", "dash")
          // gold if highlighted, white otherwise
          // new go.Binding("fill", "isHighlighted", (h) =>
          //   h ? "gold" : "#ffffff"
          // ).ofObject()
        ),
        // this Shape prevents mouse events from reaching the middle of the port
        $(go.Shape, {
          width: 100,
          height: 40,
          strokeWidth: 0,
          fill: "transparent",
          cursor: "grab",
        }),
        $(
          go.TextBlock,
          {
            margin: 1,
            font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
            name: "TEXT",
            textAlign: "center",
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 20,
            wrap: go.TextBlock.WrapFit,
            editable: true,

            // // cursor: "pointer",
            // click: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     window.location.href = hltext;
            //   }
            // },
            // mouseEnter: function (e, obj) {
            //   const node = obj.part;
            //   const hltext = node.data.hltext;
            //   if (hltext) {
            //     obj.cursor = "pointer";
            //   }
            // },
          },
          // this Binding is TwoWay due to the user editing the text with the TextEditingTool
          new go.Binding("text").makeTwoWay(),
          new go.Binding("stroke", "color"),
          //xx
          new go.Binding("font", "font").makeTwoWay(),
          new go.Binding("textAlign", "textalign").makeTwoWay()
        )
      ),

      //top port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Top,
        portId: "t",
        fromSpot: go.Spot.Top,
        toSpot: go.Spot.Top,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //bottom port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Bottom,
        portId: "b",
        fromSpot: go.Spot.Bottom,
        toSpot: go.Spot.Bottom,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //center port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Center,
        portId: "c",
        fromSpot: go.Spot.Center,
        toSpot: go.Spot.Center,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //left port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Left,
        portId: "l",
        fromSpot: go.Spot.Left,
        toSpot: go.Spot.Left,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      }),
      //right port
      $(go.Shape, "Ellipse", {
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(140,140,140,0.02)",
        cursor: "pointer",
        desiredSize: new go.Size(10, 10),
        alignment: go.Spot.Right,
        portId: "r",
        fromSpot: go.Spot.Right,
        toSpot: go.Spot.Right,
        fromLinkable: true,
        toLinkable: true,
        toLinkableDuplicates: true,
        fromLinkableSelfNode: true,
        toLinkableSelfNode: true,
      })
    )
  );

  //diagram.nodetemplate backup
  // diagram.nodeTemplate = $(
  //   go.Node,
  //   "Auto",
  //   {
  //     locationSpot: go.Spot.Center,
  //     locationObjectName: "SHAPE",
  //     desiredSize: new go.Size(120, 50),
  //     minSize: new go.Size(40, 40),
  //     resizable: true,
  //     // resizeObjectName: "SHAPE",
  //     resizeCellSize: new go.Size(10, 10),
  //     //shadow
  //     // isShadowed: true,
  //     // shadowBlur: 2,
  //     // shadowOffset: new go.Point(0, 3),
  //     // shadowColor: "rgba(0, 0, 0, .14)",
  //     // mouseEnter: mouseEnter,
  //     // mouseLeave: mouseLeave
  //   },

  //   // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
  //   new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
  //     go.Point.stringify
  //   ),
  //   new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
  //     go.Size.stringify
  //   ),

  //   //picture
  //   $(
  //     go.Picture,
  //     { source: null },
  //     new go.Binding("source", "imageurl")
  //     // new go.Binding("source", "imageurl", function(url) {
  //     //   return url || null; // 将imageurl属性的值转换为null，如果它是undefined或空字符串
  //     // })
  //     // new go.Binding("source", "modelData.backgroundImageUrl")
  //   ),

  //   $(
  //     go.Panel,
  //     "Auto",
  //     $(
  //       go.Shape,
  //       {
  //         // the border
  //         name: "SHAPE",
  //         fill: "white",
  //         portId: "",

  //         // fromLinkable: true,
  //         // toLinkable: true,
  //         // fromLinkableDuplicates: true,
  //         // toLinkableDuplicates: true, //du node link
  //         // fromSpot: go.Spot.AllSides,
  //         // toSpot: go.Spot.AllSides,
  //         cursor: "grab",
  //       },
  //       new go.Binding("figure"),
  //       new go.Binding("fill"),
  //       new go.Binding("stroke", "color"),
  //       new go.Binding("strokeWidth", "thickness"),
  //       new go.Binding("strokeDashArray", "dash")
  //       // gold if highlighted, white otherwise
  //       // new go.Binding("fill", "isHighlighted", (h) =>
  //       //   h ? "gold" : "#ffffff"
  //       // ).ofObject()
  //     ),
  //     // this Shape prevents mouse events from reaching the middle of the port
  //     $(go.Shape, {
  //       width: 100,
  //       height: 40,
  //       strokeWidth: 0,
  //       fill: "transparent",
  //       cursor: "grab",
  //     }),
  //     $(
  //       go.TextBlock,
  //       {
  //         margin: 1,
  //         font: "10pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
  //         name: "TEXT",
  //         textAlign: "center",
  //         overflow: go.TextBlock.OverflowEllipsis,
  //         maxLines: 10,
  //         wrap: go.TextBlock.WrapFit,
  //         editable: true,

  //         // // cursor: "pointer",
  //         // click: function (e, obj) {
  //         //   const node = obj.part;
  //         //   const hltext = node.data.hltext;
  //         //   if (hltext) {
  //         //     window.location.href = hltext;
  //         //   }
  //         // },
  //         // mouseEnter: function (e, obj) {
  //         //   const node = obj.part;
  //         //   const hltext = node.data.hltext;
  //         //   if (hltext) {
  //         //     obj.cursor = "pointer";
  //         //   }
  //         // },
  //       },
  //       // this Binding is TwoWay due to the user editing the text with the TextEditingTool
  //       new go.Binding("text").makeTwoWay(),
  //       new go.Binding("stroke", "color"),
  //       //xx
  //       new go.Binding("font", "font").makeTwoWay()
  //     )
  //   ),

  //   //top port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Top,
  //     portId: "t",
  //     fromSpot: go.Spot.Top,
  //     toSpot: go.Spot.Top,
  //     fromLinkable: true,
  //     toLinkable: true,
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //bottom port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Bottom,
  //     portId: "b",
  //     fromSpot: go.Spot.Bottom,
  //     toSpot: go.Spot.Bottom,
  //     fromLinkable: true,
  //     toLinkable: true,
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //center port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Center,
  //     portId: "c",
  //     fromSpot: go.Spot.Center,
  //     toSpot: go.Spot.Center,
  //     fromLinkable: true,
  //     toLinkable: true,
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //left port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Left,
  //     portId: "l",
  //     fromSpot: go.Spot.Left,
  //     toSpot: go.Spot.Left,
  //     fromLinkable: true,
  //     toLinkable: true,
  //     fromLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   }),
  //   //right port
  //   $(go.Shape, "Ellipse", {
  //     fill: "rgba(0,0,0,0)",
  //     stroke: "rgba(140,140,140,0.02)",
  //     cursor: "pointer",
  //     desiredSize: new go.Size(10, 10),
  //     alignment: go.Spot.Right,
  //     portId: "r",
  //     fromSpot: go.Spot.Right,
  //     toSpot: go.Spot.Right,
  //     fromLinkable: true,
  //     toLinkable: true,
  //     toLinkableDuplicates: true,
  //     fromLinkableSelfNode: true,
  //     toLinkableSelfNode: true,
  //   })
  // );

  // //dragSelect box
  // diagram.toolManager.dragSelectingTool.box = $(
  //   go.Part,
  //   { layerName: "Tool" },
  //   $(go.Shape, "Cloud", { fill: null, strokeWidth: 1, stroke: "lime" })
  // );

  // var white = "rgba(255, 255, 255, 0.6)";
  // var blue = "#1876f2";
  // var grad = "#6c757d";

  //--------------------------------------------
  //detailed information tooltip 弹窗信息 x   backup
  // diagram.nodeTemplate.toolTip = $(
  //   "ToolTip", // show some detailed information
  //   { "Border.fill": "white", "Border.stroke": null },
  //   new go.Binding("visible", "", function (data, textBlock) {
  //     var node = textBlock.part;
  //     if (!node) return false;
  //     var key = node.key;
  //     var nodeData = diagram.model.findNodeDataForKey(key);
  //     return (
  //       !!nodeData.titles1 ||
  //       !!nodeData.details1 ||
  //       !!nodeData.titles2 ||
  //       !!nodeData.details2 ||
  //       !!nodeData.titles3 ||
  //       !!nodeData.details3 ||
  //       !!nodeData.titles4 ||
  //       !!nodeData.details4
  //     );
  //   }),
  //   // new go.Binding("visible", !!"titles" || !!"details"),
  //   $(
  //     go.Panel,
  //     "Auto",
  //     $(go.Shape, "Rectangle", { fill: white, stroke: null }),
  //     $(
  //       go.Panel,
  //       "Vertical",

  //       {
  //         maxSize: new go.Size(160, NaN),
  //         padding: 5,
  //         defaultAlignment: go.Spot.Left,
  //       }, // limit width but not height

  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "center",
  //         },
  //         new go.Binding("text")
  //       ),
  //       //------xx
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.titles1;
  //         }),
  //         //show visibility
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.titles1;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.details1;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.details1;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.titles2;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.titles2;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.details2;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.details2;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.titles3;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.titles3;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.details3;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.details3;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "9pt sans-serif",
  //           stroke: blue,
  //           textAlign: "center",
  //           margin: 4,
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.titles4;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.titles4;
  //         })
  //       ),
  //       $(
  //         go.TextBlock,
  //         {
  //           font: "10pt sans-serif",
  //           margin: 3,
  //           stroke: grad,
  //           textAlign: "left",
  //         },
  //         new go.Binding("text", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return nodeData.details4;
  //         }),
  //         new go.Binding("visible", "", function (data, textBlock) {
  //           var node = textBlock.part;
  //           if (!node) return false;
  //           var key = node.key;
  //           var nodeData = diagram.model.findNodeDataForKey(key);
  //           return !!nodeData.details4;
  //         })
  //       )
  //     )
  //   )
  // );
  //--------------------------------------------

  // Node selection adornment x
  // Include four large triangular buttons so that the user can easily make a copy
  // of the node, move it to be in that direction relative to the original node,
  // and add a link to the new node.

  function makeArrowButton(spot, fig) {
    var maker = (e, shape) => {
      e.handled = true;
      e.diagram.model.commit((m) => {
        var selnode = shape.part.adornedPart;
        // create a new node in the direction of the spot
        var p = new go.Point().setRectSpot(selnode.actualBounds, spot);
        p.subtract(selnode.location);
        p.scale(2, 2);
        p.x += Math.sign(p.x) * 60;
        p.y += Math.sign(p.y) * 60;
        p.add(selnode.location);
        p.snapToGridPoint(
          e.diagram.grid.gridOrigin,
          e.diagram.grid.gridCellSize
        );
        // make the new node a copy of the selected node
        var nodedata = m.copyNodeData(selnode.data);
        // add to same group as selected node
        m.setGroupKeyForNodeData(
          nodedata,
          m.getGroupKeyForNodeData(selnode.data)
        );
        m.addNodeData(nodedata); // add to model
        // create a link from the selected node to the new node
        var linkdata = {
          from: selnode.key,
          to: m.getKeyForNodeData(nodedata),
        };
        m.addLinkData(linkdata); // add to model
        // move the new node to the computed location, select it, and start to edit it
        var newnode = e.diagram.findNodeForData(nodedata);
        newnode.location = p;
        e.diagram.select(newnode);
        setTimeout(() => {
          e.diagram.commandHandler.editTextBlock();
        }, 20);
      });
    };
    return $(go.Shape, {
      figure: fig,
      alignment: spot,
      alignmentFocus: spot.opposite(),
      width: spot.equals(go.Spot.Top) || spot.equals(go.Spot.Bottom) ? 30 : 15,
      height: spot.equals(go.Spot.Top) || spot.equals(go.Spot.Bottom) ? 15 : 30,
      fill: "rgba(80,185,254,0.5)",
      strokeWidth: 0,
      isActionable: true, // needed because it's in an Adornment
      click: maker,
      contextClick: maker,
    });
  }

  // create a button that brings up the context menu  x
  function CMButton(options) {
    return $(
      go.Shape,
      {
        fill: "rgba(80,185,254,0.2)",
        stroke: "rgba(80,185,254,0.8)",
        background: "transparent",
        geometryString: "F1 M0 0 M0 4h4v4h-4z M6 4h4v4h-4z M12 4h4v4h-4z M0 12",
        isActionable: true,
        cursor: "context-menu",
        click: (e, shape) => {
          e.diagram.commandHandler.showContextMenu(shape.part.adornedPart);
        },
      },
      options || {}
    );
  }

  //ArrowButton , up left right down
  function createNodeSelectionAdornment() {
    return $(
      go.Adornment,
      "Spot",
      $(go.Placeholder, { padding: 10 }),
      makeArrowButton(go.Spot.Top, "TriangleUp"),
      makeArrowButton(go.Spot.Left, "TriangleLeft"),
      makeArrowButton(go.Spot.Right, "TriangleRight"),
      makeArrowButton(go.Spot.Bottom, "TriangleDown"),
      CMButton({ alignment: new go.Spot(0.75, 0) })
    );
  }

  // //ArrowButton , up left right down
  // diagram.nodeTemplate.selectionAdornmentTemplate = $(
  //   go.Adornment,
  //   "Spot",
  //   $(go.Placeholder, { padding: 10 }),
  //   makeArrowButton(go.Spot.Top, "TriangleUp"),
  //   makeArrowButton(go.Spot.Left, "TriangleLeft"),
  //   makeArrowButton(go.Spot.Right, "TriangleRight"),
  //   makeArrowButton(go.Spot.Bottom, "TriangleDown"),
  //   CMButton({ alignment: new go.Spot(0.75, 0) })
  // );

  // Common context menu button definitions

  // All buttons in context menu work on both click and contextClick,
  // in case the user context-clicks on the button.
  // All buttons modify the node data, not the Node, so the Bindings need not be TwoWay.

  // A button-defining helper function that returns a click event handler.
  // PROPNAME is the name of the data property that should be set to the given VALUE.
  function ClickFunction(propname, value) {
    return (e, obj) => {
      e.handled = true; // don't let the click bubble up
      e.diagram.model.commit((m) => {
        m.set(obj.part.adornedPart.data, propname, value);
      });
    };
  }

  //create a routing value context menu item    xx create

  function RoutingButton(routing, propname, text) {
    if (!propname) propname = "routing";
    return $(
      go.Panel,
      "Horizontal",
      {
        alignment: go.Spot.Left,
        alignmentFocus: go.Spot.Right,
        height: 20,
        cursor: "pointer",
        click: (e, obj) => {
          console.log(`Selected routing option: ${routing}`);
          e.diagram.model.commit((m) => {
            m.set(obj.part.data, propname, routing);
          });
        },
      },

      $(go.TextBlock, {
        text: text || routing || "NULL",
        font: "12px sans-serif",
        margin: new go.Margin(0, 4),
        mouseEnter: (e, shape) => (shape.stroke = "#98E1F9"),
        mouseLeave: (e, shape) => (shape.stroke = "black"),
      })
    );
  }

  //contextMenu routing
  function LinkRoutingButtons() {
    // used by multiple context menus
    return [
      // $(
      //   "ContextMenuButton",
      //   {
      //     "ButtonBorder.fill": "white",
      //     "_buttonFillOver": "#F1F5FE",
      //   },

      //   $(
      //     go.Panel,
      //     "Horizontal",
      //     {
      //       margin: new go.Margin(0, 50, 0, 0), // add margin to the right
      //     },
      //     RoutingButton(null, null, "自由")
      //   ),
      //   $(
      //     go.Panel,
      //     "Horizontal",
      //     {
      //       margin: new go.Margin(0, 0, 0, 50), // add margin to the left
      //     },
      //     RoutingButton("Orthogonal", null, "折线")
      //   )
      // ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          RoutingButton(null, null, "自由"),
          RoutingButton("Orthogonal", null, "折线"),
          RoutingButton("Normal", null, "直线"),
          RoutingButton("AvoidsNodes", null, "避开")
        )
      ),
      // $(
      //   "ContextMenuButton",
      //   {
      //     "ButtonBorder.fill": "white",
      //     "_buttonFillOver": "#F1F5FE",
      //   },

      //   $(
      //     go.Panel,
      //     "Horizontal",
      //     {
      //       margin: new go.Margin(0, 50, 0, 0), // add margin to the right
      //     },
      //     RoutingButton("Normal", null, "直线")
      //   ),
      //   $(
      //     go.Panel,
      //     "Horizontal",
      //     {
      //       margin: new go.Margin(0, 0, 0, 50), // add margin to the left
      //     },
      //     RoutingButton("AvoidsNodes", null, "避开")
      //   )
      // ),
    ];
  }

  // Create a context menu button for setting a data property with a color value.
  //颜色弹窗      分开边框和字体颜色
  function ColorButton(color, propname) {
    if (!propname) propname = "color";
    return $(go.Shape, "Circle", {
      width: 17,
      height: 17,
      stroke: "lightgray",
      strokeWidth: 1,
      fill: color,
      margin: 1,
      background: "transparent",
      mouseEnter: (e, shape) => (shape.stroke = "#98E1F9"),
      mouseLeave: (e, shape) => (shape.stroke = "lightgray"),
      click: ClickFunction(propname, color),
      contextClick: ClickFunction(propname, color),
    });
  }

  //contextMenu 1
  function LightFillButtons() {
    // used by multiple context menus
    return [
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ColorButton("transparent", "fill"),
          ColorButton("white", "fill"),
          ColorButton("#FEF5D8", "fill"),
          ColorButton("#DAF8F1", "fill"),
          ColorButton("#FDEEF2", "fill"),
          ColorButton("#E8DEFF", "fill")
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ColorButton("#66C9F9", "fill"),
          ColorButton("#FBD965", "fill"),
          ColorButton("#FF8730", "fill"),
          ColorButton("#19CCA3", "fill"),
          ColorButton("#F3376B", "fill"),
          ColorButton("#7733FF", "fill")
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ColorButton("#356AE6", "fill"),
          ColorButton("#1B54D9", "fill"),
          ColorButton("#9CA2B5", "fill"),
          ColorButton("#4E5468", "fill"),
          ColorButton("#393C48", "fill"),
          ColorButton("#000000", "fill")
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ColorButton("#3E6B94", "fill"),
          ColorButton("#2BBF6C", "fill"),
          ColorButton("#F8523B", "fill"),
          ColorButton("#0BC9D5", "fill"),
          ColorButton("#57B9E6", "fill"),
          ColorButton("#04A59F", "fill")
        )
      ),
    ];
  }

  //contextMenu 2
  function DarkColorButtons() {
    // used by multiple context menus
    return [
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ColorButton("transparent"),
          ColorButton("white"),
          ColorButton("#FEF5D8"),
          ColorButton("#DAF8F1"),
          ColorButton("#FDEEF2"),
          ColorButton("#E8DEFF")
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ColorButton("#66C9F9"),
          ColorButton("#FBD965"),
          ColorButton("#FF8730"),
          ColorButton("#19CCA3"),
          ColorButton("#F3376B"),
          ColorButton("#7733FF")
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ColorButton("#356AE6"),
          ColorButton("#1B54D9"),
          ColorButton("#9CA2B5"),
          ColorButton("#4E5468"),
          ColorButton("#393C48"),
          ColorButton("#000000")
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ColorButton("#3E6B94"),
          ColorButton("#2BBF6C"),
          ColorButton("#F8523B"),
          ColorButton("#0BC9D5"),
          ColorButton("#57B9E6"),
          ColorButton("#04A59F")
        )
      ),
    ];
  }

  // Create a context menu button for setting a data property with a stroke width value.
  function ThicknessButton(sw, propname) {
    if (!propname) propname = "thickness";
    return $(go.Shape, "LineH", {
      width: 20,
      height: 16,
      strokeWidth: sw,
      margin: 1,
      background: "transparent",
      mouseEnter: (e, shape) => (shape.background = "#98E1F9"),
      mouseLeave: (e, shape) => (shape.background = "transparent"),
      click: ClickFunction(propname, sw),
      contextClick: ClickFunction(propname, sw),
    });
  }

  // Create a context menu button for setting a data property with a stroke dash Array value.
  function DashButton(dash, propname) {
    if (!propname) propname = "dash";
    return $(go.Shape, "LineH", {
      width: 52,
      height: 16,
      strokeWidth: 2,
      strokeDashArray: dash,
      margin: 1,
      background: "transparent",
      mouseEnter: (e, shape) => (shape.background = "#98E1F9"),
      mouseLeave: (e, shape) => (shape.background = "transparent"),
      click: ClickFunction(propname, dash),
      contextClick: ClickFunction(propname, dash),
    });
  }

  //contextMenu 3
  function StrokeOptionsButtons() {
    // used by multiple context menus
    return [
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ThicknessButton(0),
          ThicknessButton(1),
          ThicknessButton(2),
          ThicknessButton(3),
          ThicknessButton(4)
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          ThicknessButton(5),
          ThicknessButton(6),
          ThicknessButton(7),
          ThicknessButton(8)
          // ThicknessButton(9)
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(go.Panel, "Horizontal", DashButton(null), DashButton([2, 4]))
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(go.Panel, "Horizontal", DashButton([4, 4]), DashButton([9, 4, 2, 4]))
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(go.Panel, "Horizontal", DashButton([9, 4]), DashButton([17, 4, 5, 4]))
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },

        $(
          go.Panel,
          "Horizontal",
          DashButton([17, 8]),
          DashButton([9, 4, 2, 4, 2, 4])
        )
      ),
    ];
  }

  // Node context menu 形状设置

  function FigureButton(fig, propname) {
    if (!propname) propname = "figure";
    return $(go.Shape, {
      width: 40,
      height: 32,
      scale: 0.5,
      fill: "rgba(255,255,255,0.05)",
      stroke: "#797979",
      figure: fig,
      margin: 1,
      background: "transparent",
      mouseEnter: (e, shape) => (shape.fill = "#98E1F9"),
      mouseLeave: (e, shape) => (shape.fill = "rgba(255,255,255,0.05)"),
      click: ClickFunction(propname, fig),
      contextClick: ClickFunction(propname, fig),
    });
  }

  // 创建节点模板的 contextMenu 函数
  function createTextNodeContextMenu() {
    return $(
      "ContextMenu",
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },
        $(
          go.Panel,
          "Horizontal",
          FigureButton("Rectangle"),
          FigureButton("RoundedRectangle"),
          FigureButton("Circle"),
          FigureButton("Diamond"),
          FigureButton("Terminator")
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },
        $(
          go.Panel,
          "Horizontal",
          FigureButton("Parallelogram2"),
          FigureButton("ManualOperation"),
          FigureButton("Procedure"),
          FigureButton("Cylinder1"),
          FigureButton("Document")
        )
      ),
      $(
        "ContextMenuButton",
        {
          "ButtonBorder.fill": "white",
          _buttonFillOver: "#F1F5FE",
        },
        $(
          go.Panel,
          "Horizontal",
          FigureButton("Hexagon"),
          FigureButton("Ellipse"),
          FigureButton("StopSign"),
          FigureButton("TriangleDown"),
          FigureButton("Cloud")
        )
      ),
      LightFillButtons(),
      DarkColorButtons(),
      StrokeOptionsButtons()
    );
  }

  // 创建Picture节点模板的 contextMenu 函数------------
  function createPictureNodeContextMenu() {
    return $("ContextMenu", DarkColorButtons());
  }

  //---------------------------------

  // //nodetemplate contextMenu 4   backup
  // diagram.nodeTemplate.contextMenu = $(
  //   "ContextMenu",
  //   $(
  //     "ContextMenuButton",
  //     {
  //       "ButtonBorder.fill": "white",
  //       _buttonFillOver: "#F1F5FE",
  //     },

  //     $(
  //       go.Panel,
  //       "Horizontal",
  //       FigureButton("Rectangle"),
  //       FigureButton("RoundedRectangle"),
  //       FigureButton("Circle"),
  //       FigureButton("Diamond"),
  //       FigureButton("Terminator")
  //     )
  //   ),
  //   $(
  //     "ContextMenuButton",
  //     {
  //       "ButtonBorder.fill": "white",
  //       _buttonFillOver: "#F1F5FE",
  //     },

  //     $(
  //       go.Panel,
  //       "Horizontal",
  //       FigureButton("Parallelogram2"),
  //       FigureButton("ManualOperation"),
  //       FigureButton("Procedure"),
  //       FigureButton("Cylinder1"),
  //       FigureButton("Document")
  //     )
  //   ),
  //   $(
  //     "ContextMenuButton",
  //     {
  //       "ButtonBorder.fill": "white",
  //       _buttonFillOver: "#F1F5FE",
  //     },

  //     $(
  //       go.Panel,
  //       "Horizontal",
  //       FigureButton("Hexagon"),
  //       FigureButton("Ellipse"),
  //       FigureButton("StopSign"),
  //       FigureButton("TriangleDown"),
  //       FigureButton("Cloud")
  //     )
  //   ),
  //   LightFillButtons(),
  //   DarkColorButtons(),
  //   StrokeOptionsButtons()
  // );

  // Group template

  diagram.groupTemplate = $(
    go.Group,
    "Spot",
    {
      layerName: "Background",
      ungroupable: true,
      locationSpot: go.Spot.Center,
      selectionObjectName: "BODY",
      computesBoundsAfterDrag: true, // allow dragging out of a Group that uses a Placeholder
      handlesDragDropForMembers: true, // don't need to define handlers on Nodes and Links
      mouseDrop: (e, grp) => {
        // add dropped nodes as members of the group
        var ok = grp.addMembers(grp.diagram.selection, true);
        if (!ok) grp.diagram.currentTool.doCancel();
      },
      avoidable: false,
    },
    new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
      go.Point.stringify
    ),

    $(
      go.Panel,
      "Auto",
      { name: "BODY" },
      $(
        go.Shape,
        "RoundedRectangle",
        {
          parameter1: 8,
          fill: "white",
          stroke: "#1B54D9",
          strokeWidth: 1,
          portId: "",
          // cursor: "pointer",
          cursor: "grab",
          fromLinkable: true,
          toLinkable: true,
          fromLinkableDuplicates: true,
          toLinkableDuplicates: true,
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill"),
        new go.Binding("stroke", "color"),
        new go.Binding("strokeWidth", "thickness"),
        new go.Binding("strokeDashArray", "dash")
      ),

      $(go.Placeholder, {
        background: "transparent",
        margin: 10,
        padding: new go.Margin(16, 0, 0, 0),
        cursor: "grab",
      })
    ),
    $(
      go.TextBlock,

      {
        alignment: go.Spot.Top,
        alignmentFocus: new go.Spot(0.5, 0, 0, -4),
        // alignmentFocus: go.Spot.Bottom,
        font: "12pt Microsoft YaHei,system-ui, -apple-system, Arial, sans-serif",
        stroke: "#1B54D9",
        maxLines: 1,
        editable: true,
      },
      new go.Binding("text").makeTwoWay(),
      new go.Binding("stroke", "color"),
      //xx
      new go.Binding("font", "font").makeTwoWay()
      // new go.Binding("textAlign", "textalign").makeTwoWay()
    ),
    //Group port
    //top port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Top,
      portId: "t",
      fromSpot: go.Spot.Top,
      toSpot: go.Spot.Top,
      fromLinkable: true,
      toLinkable: true,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //bottom port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Bottom,
      portId: "b",
      fromSpot: go.Spot.Bottom,
      toSpot: go.Spot.Bottom,
      fromLinkable: true,
      toLinkable: true,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //center port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Center,
      portId: "c",
      fromSpot: go.Spot.Center,
      toSpot: go.Spot.Center,
      fromLinkable: true,
      toLinkable: true,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //left port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Left,
      portId: "l",
      fromSpot: go.Spot.Left,
      toSpot: go.Spot.Left,
      fromLinkable: true,
      toLinkable: true,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //right port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.02)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Right,
      portId: "r",
      fromSpot: go.Spot.Right,
      toSpot: go.Spot.Right,
      fromLinkable: true,
      toLinkable: true,
      toLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    })
  );

  //Group template selection
  diagram.groupTemplate.selectionAdornmentTemplate = $(
    go.Adornment,
    "Spot",
    $(
      go.Panel,
      "Auto",
      $(go.Shape, { fill: null, stroke: "#98E1F9", strokeWidth: 2 }),
      $(go.Placeholder, { margin: 1.5 })
    ),
    CMButton({
      alignment: go.Spot.TopRight,
      alignmentFocus: go.Spot.BottomRight,
    })
  );

  //contextMenu 5 group
  diagram.groupTemplate.contextMenu = $(
    "ContextMenu",
    LightFillButtons(),
    DarkColorButtons(),
    StrokeOptionsButtons()
  );

  // Link template

  diagram.linkTemplate = $(
    go.Link,
    {
      layerName: "Foreground",
      curve: go.Link.Bezier,
      routing: go.Link.Normal,
      // curve: go.Link.JumpGap,
      // routing: go.Link.AvoidsNodes,
      corner: 1,
      toShortLength: 6, // assume arrowhead at "to" end, need to avoid bad appearance when path is thick
      relinkableFrom: true,
      relinkableTo: true,
      reshapable: true,
      resegmentable: true,
    },
    new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
    new go.Binding("toSpot", "toSpot", go.Spot.parse),
    new go.Binding("fromShortLength", "dir", (dir) => (dir === 2 ? 4 : 0)),
    new go.Binding("toShortLength", "dir", (dir) => (dir >= 1 ? 4 : 0)),
    new go.Binding("points").makeTwoWay(), // TwoWay due to user reshaping with LinkReshapingTool
    new go.Binding(
      "routing",
      "routing",
      go.Binding.parseEnum(go.Link, go.Link.AvoidsNodes)
    ).makeTwoWay(go.Binding.toString), // link routing
    //line background
    //$(go.Shape, { isPanelMain: true, stroke: "#DDF1FF", strokeWidth: 4 }),
    //线
    $(
      go.Shape,
      {
        isPanelMain: true,
        stroke: "#1B54D9",
        strokeWidth: 1,
        name: "PIPE",
        strokeDashArray: [0, 0],
      },
      new go.Binding("stroke", "color"),
      new go.Binding("strokeWidth", "thickness"),
      new go.Binding("strokeDashArray", "dash")
    ),
    //箭头
    $(
      go.Shape,
      {
        fromArrow: "Backward",
        fill: "#1B54D9",
        strokeWidth: 0,
        scale: 4 / 3,
        visible: false,
      },
      new go.Binding("visible", "dir", (dir) => dir === 2),
      new go.Binding("fill", "color"),
      new go.Binding("scale", "thickness", (t) => (2 + t) / 3)
    ),
    $(
      go.Shape,
      {
        toArrow: "Standard",
        //arrow shape stroke
        strokeWidth: 0,
        stroke: "#585858",
        fill: "#1B54D9",
        scale: 4 / 3,
      },
      new go.Binding("visible", "dir", (dir) => dir >= 1),
      new go.Binding("fill", "color"),
      new go.Binding("scale", "thickness", (t) => (2 + t) / 3)
    ),
    //文字
    $(
      go.TextBlock,
      {
        alignmentFocus: new go.Spot(0, 1, -4, 0),
        font: "9pt system-ui, -apple-system, Arial, sans-serif",
        editable: true,
      },
      new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
      new go.Binding("stroke", "color")
    )
  );

  //link templateslink 链接中的样式
  diagram.toolManager.linkingTool.temporaryLink = $(
    go.Link,
    { layerName: "Tool" },
    $(go.Shape, { stroke: "#FF00FF", strokeWidth: 1, strokeDashArray: [4, 2] })
  );

  // myDiagram.model.linkFromPortIdProperty = "fromPort";
  // myDiagram.model.linkToPortIdProperty = "toPort";
  //线的选择样式
  diagram.linkTemplate.selectionAdornmentTemplate = $(
    go.Adornment, // use a special selection Adornment that does not obscure the link path itself
    $(
      go.Shape,
      {
        // this uses a pathPattern with a gap in it, in order to avoid drawing on top of the link path Shape
        isPanelMain: true,
        stroke: "transparent",
        strokeWidth: 4,
        pathPattern: makeAdornmentPathPattern(2), // == thickness or strokeWidth
      },
      new go.Binding("pathPattern", "thickness", makeAdornmentPathPattern)
    ),
    CMButton({ alignmentFocus: new go.Spot(0, 0, -6, -4) })
  );

  function makeAdornmentPathPattern(w) {
    return $(go.Shape, {
      stroke: "#98E1F9",
      strokeWidth: 1,
      strokeCap: "square",
      geometryString: "M0 0 M4 2 H3 M4 " + (w + 4).toString() + " H3",
    });
  }

  // Link context menu
  // All buttons in context menu work on both click and contextClick,
  // in case the user context-clicks on the button.
  // All buttons modify the link data, not the Link, so the Bindings need not be TwoWay.

  function ArrowButton(num) {
    var geo = "M0 0 M16 16 M0 8 L16 8  M12 11 L16 8 L12 5";
    if (num === 0) {
      geo = "M0 0 M16 16 M0 8 L16 8";
    } else if (num === 2) {
      geo = "M0 0 M16 16 M0 8 L16 8  M12 11 L16 8 L12 5  M4 11 L0 8 L4 5";
    }
    return $(go.Shape, {
      geometryString: geo,
      margin: 2,
      background: "transparent",
      mouseEnter: (e, shape) => (shape.background = "#98E1F9"),
      mouseLeave: (e, shape) => (shape.background = "transparent"),
      click: ClickFunction("dir", num),
      contextClick: ClickFunction("dir", num),
    });
  }
  //出线位置设置
  function AllSidesButton(to) {
    var setter = (e, shape) => {
      e.handled = true;
      e.diagram.model.commit((m) => {
        var link = shape.part.adornedPart;
        m.set(
          link.data,
          to ? "toSpot" : "fromSpot",
          go.Spot.stringify(go.Spot.AllSides)
        );
        // re-spread the connections of other links connected with the node
        (to ? link.toNode : link.fromNode).invalidateConnectedLinks();
      });
    };
    return $(go.Shape, {
      width: 12,
      height: 12,
      fill: "transparent",
      mouseEnter: (e, shape) => (shape.background = "#98E1F9"),
      mouseLeave: (e, shape) => (shape.background = "transparent"),
      click: setter,
      contextClick: setter,
    });
  }

  function SpotButton(spot, to) {
    var ang = 0;
    var side = go.Spot.RightSide;
    if (spot.equals(go.Spot.Top)) {
      ang = 270;
      side = go.Spot.TopSide;
    } else if (spot.equals(go.Spot.Left)) {
      ang = 180;
      side = go.Spot.LeftSide;
    } else if (spot.equals(go.Spot.Bottom)) {
      ang = 90;
      side = go.Spot.BottomSide;
    }
    if (!to) ang -= 180;
    var setter = (e, shape) => {
      e.handled = true;
      e.diagram.model.commit((m) => {
        var link = shape.part.adornedPart;
        m.set(link.data, to ? "toSpot" : "fromSpot", go.Spot.stringify(side));
        // re-spread the connections of other links connected with the node
        (to ? link.toNode : link.fromNode).invalidateConnectedLinks();
      });
    };
    return $(go.Shape, {
      alignment: spot,
      alignmentFocus: spot.opposite(),
      geometryString: "M0 0 M12 12 M12 6 L1 6 L4 4 M1 6 L4 8",
      angle: ang,
      background: "transparent",
      mouseEnter: (e, shape) => (shape.background = "#98E1F9"),
      mouseLeave: (e, shape) => (shape.background = "transparent"),
      click: setter,
      contextClick: setter,
    });
  }

  //contextMenu 6
  diagram.linkTemplate.contextMenu = $(
    "ContextMenu",
    DarkColorButtons(),
    StrokeOptionsButtons(),
    LinkRoutingButtons(),
    $(
      "ContextMenuButton",
      {
        "ButtonBorder.fill": "white",
        _buttonFillOver: "#F1F5FE",
      },
      $(go.Panel, "Horizontal", ArrowButton(0), ArrowButton(1), ArrowButton(2))
    ),
    $(
      "ContextMenuButton",
      {
        "ButtonBorder.fill": "white",
        _buttonFillOver: "#F1F5FE",
      },

      $(
        go.Panel,
        "Horizontal",
        $(
          go.Panel,
          "Spot",
          AllSidesButton(false),
          SpotButton(go.Spot.Top, false),
          SpotButton(go.Spot.Left, false),
          SpotButton(go.Spot.Right, false),
          SpotButton(go.Spot.Bottom, false)
        ),
        $(
          go.Panel,
          "Spot",
          { margin: new go.Margin(0, 0, 0, 2) },
          AllSidesButton(true),
          SpotButton(go.Spot.Top, true),
          SpotButton(go.Spot.Left, true),
          SpotButton(go.Spot.Right, true),
          SpotButton(go.Spot.Bottom, true)
        )
      )
    )
  );
  //--------------------------------------------------

  return diagram;
}
