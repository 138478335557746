import { createContext, useReducer } from "react";
import ChatActiveReducer from "./chatActiveReducer";

const INITIAL_STATE = {
  chatActive: false,
};

export const ChatActiveContext = createContext(INITIAL_STATE);

export const ChatActiveContextProvider = ({ children }) => {
  const [state, dispatchchat] = useReducer(ChatActiveReducer, INITIAL_STATE);
  return (
    <ChatActiveContext.Provider
      value={{
        chatActive: state.chatActive,
        dispatchchat,
      }}
    >
      {children}
    </ChatActiveContext.Provider>
  );
};
