import "./home.scss";
import Navbar from "../../components/navbar/Navbar";
import Leftlayout from "../../components/leftlayout/Leftlayout";
import Sidebar from "../../components/sidebar/Sidebar";
import LoginPopup from "../login/Login";
import NoticePopup from "../../components/popupfiles/noticepopup/noticePopup";
import ChatPopup from "../../components/popupfiles/chatpopup/chatPopup";
import MainLayoutModel from "../../components/mainlayout/mainlayoutmodel";
import UserPopup from "../../components/popupfiles/userpopup/userPopup";
import ToolPopup from "../../components/popupfiles/toolpopup/toolPopup";
const HomeEdit = () => {
  return (
    <div className="home">
      <Navbar />
      {/* <Sidebar /> */}
      <Leftlayout />
      <LoginPopup />
      <NoticePopup />
      <ChatPopup />
      <ToolPopup />
      <UserPopup />
      <MainLayoutModel />
    </div>
  );
};

export default HomeEdit;
