import "./sidebar.scss";
import React, { useState } from "react";
import autorenew from "../../img/autorenew_black_24dp.svg";
import backup from "../../img/backup_black_24dp.svg";
import qrcodeb from "../../img/qr_code_black_24dp.svg";
import filedownload from "../../img/file_download_black_24dp.svg";
import favorite from "../../img/favorite_border_black_24dp.svg";
import favoritefill from "../../img/favorite_FILL24.svg";
import starfill from "../../img/star_fll_24dp.svg";
import star from "../../img/star_outline_black_24dp.svg";
import centerfocus from "../../img/center_focus_weak_black_24dp.svg";
import fullscreen from "../../img/fullscreen_black_24dp.svg";
import fullfit from "../../img/fit_screen24.svg";
import gridview from "../../img/grid_view_black_24dp.svg";

import SearchBox from "./SearchBox";
import { useTranslation } from "react-i18next";
// import { saveDiagramData } from "../../pages/project01/adminModel11";

const Sidebar = () => {
  const { t } = useTranslation();
  const [isFavoriteFilled, setIsFavoriteFilled] = useState(false);
  const [isStarFilled, setIsStarFilled] = useState(false);

  const handleFavoriteClick = () => {
    setIsFavoriteFilled(!isFavoriteFilled);
  };
  const handleStarClick = () => {
    setIsStarFilled(!isStarFilled);
  };

  return (
    <div className="contentside">
      <ul class="options">
        <li class="option tool" id="rebulid" title={t("sidebar.side-option1")} >
          <img src={gridview} alt="" />
        </li>
        <li class="option tool" id="rebulid" title={t("sidebar.side-option2")}  onclick="load()">
          <img src={autorenew} alt="" />
        </li>
        <li class="option tool" id="saveButton" title={t("sidebar.side-option3")} >
          <img src={backup} alt="" />
        </li>
        <li class="option tool showData" id="showData" title={t("sidebar.side-option4")} >
          <img src={qrcodeb} alt="" />
        </li>
        <li class="option tool fileDownload" id="fileDownload" title={t("sidebar.side-option5")}>
          <img src={filedownload} alt="" />
        </li>
      </ul>
      <ul class="options part2">
        <li class="option tool" id="rebulid" title={t("sidebar.side-option6")}  onClick={handleFavoriteClick}>
          {isFavoriteFilled ? (
            <img src={favoritefill} alt="" />
          ) : (
            <img src={favorite} alt="" />
          )}
        </li>
        <li class="option tool" id="backup" title={t("sidebar.side-option7")} onClick={handleStarClick}>
          {isStarFilled ? (
            <img src={starfill} alt="" />
          ) : (
            <img src={star} alt="" />
          )}
        </li>
        <li class="option tool showData" id="showData" title={t("sidebar.side-option8")} >
          <img src={centerfocus} alt="" />
        </li>
        <li class="option tool fileDownload" id="fileDownload" title={t("sidebar.side-option9")} >
          <img src={fullscreen} alt="" />
        </li>
        <li class="option tool fileDownload" id="fileDownload" title={t("sidebar.side-option10")} >
          <img src={fullfit} alt="" />
        </li>
        <li class="option tool fileDownload" id="fileDownload" title={t("sidebar.side-option11")}>
          <SearchBox />
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
