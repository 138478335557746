const PopupActiveReducer = (state, action) => {
  switch (action.type) {
    case "NOTICE": {
      return {
        popupActive1: !state.popupActive1,
        popupActive2: false,
        popupActive3: false,
        popupActive4: false,
        popupActive5: false,
        popupActive6: false,
        popupActive7: false,
      };
    }
    case "LOGIN": {
      return {
        popupActive1: false,
        popupActive2: !state.popupActive2,
        popupActive3: false,
        popupActive4: false,
        popupActive5: false,
        popupActive6: false,
        popupActive7: false,
      };
    }
    case "CHATS": {
      return {
        popupActive1: false,
        popupActive2: false,
        popupActive3: !state.popupActive3,
        popupActive4: false,
        popupActive5: false,
        popupActive6: false,
        popupActive7: false,
      };
    }
    case "TOOLS": {
      return {
        popupActive1: false,
        popupActive2: false,
        popupActive3: false,
        popupActive4: !state.popupActive4,
        popupActive5: false,
        popupActive6: false,
        popupActive7: false,
      };
    }
    case "PUBLISH": {
      return {
        popupActive1: false,
        popupActive2: false,
        popupActive3: false,
        popupActive4: false,
        popupActive5: !state.popupActive5,
        popupActive6: false,
        popupActive7: false,
      };
    }
    case "SAVEFILE": {
      return {
        popupActive1: false,
        popupActive2: false,
        popupActive3: false,
        popupActive4: false,
        popupActive5: false,
        popupActive6: !state.popupActive6,
        popupActive7: false,
      };
    }
    case "USERS": {
      return {
        popupActive1: false,
        popupActive2: false,
        popupActive3: false,
        popupActive4: false,
        popupActive5: false,
        popupActive6: false,
        popupActive7: !state.popupActive7,
      };
    }

    case "ALLPOPUPHIDE": {
      return {
        popupActive1: false,
        popupActive2: false,
        popupActive3: false,
        popupActive4: false,
        popupActive5: false,
        popupActive6: false,
        popupActive7: false,
      };
    }

    default:
      return state;
  }
};
export default PopupActiveReducer;
