import React, { useState, useContext, useEffect, useRef } from "react";
import baseUrl from "../../configs/baseurl";
import "./opruneditdraggable.scss";
import Draggable from "react-draggable";
import playrun from "../../img/play_arrow_FILL48.svg";
import playstop from "../../img/stop_FILL48.svg";
import playpause from "../../img/pause_FILL48.svg";
import playstoped from "../../img/stop_FILL48_2.svg";
import warnings from "../../img/warning_FILL24.svg";
import infobt from "../../img/info_FILL24.svg";
import closebt from "../../img/close_black_24dp.svg";
import morebt from "../../img/more_horiz48.svg";

import { DraggableActiveContext } from "../../context/draggableActiveContext";
import { useTranslation } from "react-i18next";
export default function OpRunEditDraggable(props) {
	const { t } = useTranslation();
  //nodedesc show
  const [nodeDesc, setNodeDesc] = useState("");

  //infocontroller show
  const [infoContent, setInfoContent] = useState("");

  //play and stop status
  const [isPlaying, setIsPlaying] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const [count, setCount] = useState(0);

  //time settings
  const [legalTime, setLegalTime] = useState("00:00:00");
  const [agreeTime, setAgreeTime] = useState("00:00:00");
  const [realTime, setRealTime] = useState("00:00:00");

  //set start and end time
  const [startTime, setStartTime] = useState("NO NUMBER");
  const [endTime, setEndTime] = useState("NO NUMBER");
  //show start and end time
  const [startTime2, setStartTime2] = useState("");
  const [endTime2, setEndTime2] = useState("");
  //time changed
  const [legalTimeChanged, setLegalTimeChanged] = useState(false);
  const [agreeTimeChanged, setAgreeTimeChanged] = useState(false);
  const [startTimeChanged, setStartTimeChanged] = useState(false);
  const [startTime2Changed, setStartTime2Changed] = useState(false);
  // const [nodeChanged, setNodeChanged] = useState(false);

  //legal time settings
  const [dayTime, setDayTime] = useState(0);
  const [hourTime, setHourTime] = useState(0);
  const [minuteTime, setMinuteTime] = useState(0);
  const [secondTime, setSecondTime] = useState(0);
  //agree time settings
  const [dayTime2, setDayTime2] = useState(0);
  const [hourTime2, setHourTime2] = useState(0);
  const [minuteTime2, setMinuteTime2] = useState(0);
  const [secondTime2, setSecondTime2] = useState(0);

  //node or link selection judgment
  const nodeselect = props.selectedNode.nodeselected;
  const nodeisgroup = props.selectedNode.isgroup;
  const nodekey = props.selectedNode.key;

  // 添加一个 state 来存储时间轴的当前进度
  const [sliderValue, setSliderValue] = useState(0);

  //node des properties change
  // useEffect(() => {
  //   console.log(
  //     "isPlaying/nodeselect/nodekey/nodeisgroup:",
  //     isPlaying,
  //     nodeselect,
  //     nodekey,
  //     nodeisgroup
  //   );
  // });

  //node is selected infoshow
  useEffect(() => {
    if (!isPlaying && nodeselect) {
      const actionInfo = t("opruneditdraggable.oprun-actionInfo1"); // 设置信息内容01
      setInfoContent(actionInfo); // 更新状态或变量的值
    } else if (!isPlaying && !nodeselect) {
      const actionInfo = t("opruneditdraggable.oprun-actionInfo2"); // 设置信息内容01
      setInfoContent(actionInfo); // 更新状态或变量的值
    }
  }, [isPlaying, nodeselect]);

  //nodedesc show change
  useEffect(() => {
    // 当props.selectedNode.text变化时，更新节点描述
    if (nodeselect && props.selectedNode.text) {
      setNodeDesc(props.selectedNode.text);
    } else {
      // 获取URL参数中的文件ID
      const params = new URLSearchParams(window.location.search);
      const runfileid = params.get("fileid");

      // 从服务器获取文件名
      fetch(`${baseUrl}/oprunapi/getfilename/${runfileid}`)
        .then((response) => response.json())
        .then((data) => {
          // 更新节点描述
          setNodeDesc(data.filename);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [nodeselect, props.selectedNode.text]);

  //legaltime input settings
  const handleTimeChange = (event) => {
    const { value, id } = event.target;
    if (id === "dayTimeInput") {
      setDayTime(value || 0);
    } else if (id === "hourTimeInput") {
      setHourTime(value || 0);
    } else if (id === "minuteTimeInput") {
      setMinuteTime(value || 0);
    } else if (id === "secondTimeInput") {
      setSecondTime(value || 0);
    }
  };

  // console.log("timechangeinput:", dayTime, hourTime, minuteTime, secondTime);

  //agreetime input settings
  const handleTimeChange2 = (event) => {
    const { value, id } = event.target;
    if (id === "dayTimeInput2") {
      setDayTime2(value || 0);
    } else if (id === "hourTimeInput2") {
      setHourTime2(value || 0);
    } else if (id === "minuteTimeInput2") {
      setMinuteTime2(value || 0);
    } else if (id === "secondTimeInput2") {
      setSecondTime2(value || 0);
    }
  };

  // console.log(
  //   "timechangeinput2:",
  //   dayTime2,
  //   hourTime2,
  //   minuteTime2,
  //   secondTime2
  // );

  // useEffect(() => {
  //   console.log(
  //     "New timechange agree/legal/start:",
  //     agreeTime,
  //     legalTime,
  //     startTime
  //   );
  // }, [legalTime, agreeTime, startTime]);

  //get startTime and endTime
  useEffect(() => {
    if (!nodeselect) {
      // 获取URL参数中的文件ID
      const params = new URLSearchParams(window.location.search);
      const runfileid = params.get("fileid");

      fetch(`${baseUrl}/oprunapi/getfilestarttime/${runfileid}`)
        .then((response) => response.json())
        .then((data) => {
          const fileStartTime = data.starttime;
          const fileEndTime = data.endtime;
          const fileLegalTime = data.legaltime || "00:00:00";
          const fileAgreeTime = data.agreetime || "00:00:00";
          // console.log(
          //   "New 01 getfilestarttime:",
          //   fileStartTime,
          //   fileEndTime,
          //   fileLegalTime,
          //   fileAgreeTime
          // );
          setStartTime2(fileStartTime);
          setEndTime2(fileEndTime);
          setLegalTime(fileLegalTime);
          setAgreeTime(fileAgreeTime);
        })
        .catch((error) => {
          console.error("Error while fetching file start time:", error);
        });

      // return;
    } else if (nodeselect) {
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      console.log("New getnodestarttime show:", fileid, nodekey);
      //get data from sql
      fetch(`${baseUrl}/oprunapi/gettimenode/${fileid}/${nodekey}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            // console.log(
            //   "New 02 getnodestarttime:",
            //   data.agreetime,
            //   data.legaltime,
            //   data.starttime,
            //   data.endtime
            // );
            setAgreeTime(data.agreetime);
            setLegalTime(data.legaltime);
            setStartTime2(data.starttime);
            setEndTime2(data.endtime);
            setStartTimeChanged(true);
          } else {
            console.log("Fetch data not successful:");
          }
        });
    }
  }, [nodeselect, props.selectedNode]);

  // 显示starttime 和 endtime
  // useEffect(() => {
  //   if (startTime !== "NO NUMBER") {
  //     console.log("xxxx  startTime1:", startTime);
  //     const date = new Date(startTime);
  //     const year = date.getFullYear().toString();
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //     const day = date.getDate().toString().padStart(2, "0");
  //     const hour = date.getHours().toString().padStart(2, "0");
  //     const minute = date.getMinutes().toString().padStart(2, "0");
  //     const second = date.getSeconds().toString().padStart(2, "0");
  //     const dateString =
  //       year +
  //       "/" +
  //       month +
  //       "/" +
  //       day +
  //       " " +
  //       hour +
  //       ":" +
  //       minute +
  //       ":" +
  //       second;
  //     setStartTime2(dateString);
  //     console.log("xxxx  startTime01:", dateString);
  //   } else {
  //     const date = new Date();
  //     const year = date.getFullYear().toString();
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //     const day = date.getDate().toString().padStart(2, "0");
  //     const hour = date.getHours().toString().padStart(2, "0");
  //     const minute = date.getMinutes().toString().padStart(2, "0");
  //     const second = date.getSeconds().toString().padStart(2, "0");
  //     const dateString =
  //       year +
  //       "/" +
  //       month +
  //       "/" +
  //       day +
  //       " " +
  //       hour +
  //       ":" +
  //       minute +
  //       ":" +
  //       second;
  //     setStartTime2(dateString);
  //     console.log("xxxx  startTime02:", dateString);
  //   }
  //   console.log("xxxx  startTime001:", startTime);
  //   console.log("xxxx  startTime002:", startTime2);
  //   return;
  //   if (startTime !== "" && legalTime !== "NO NUM") {
  //     const [hours, minutes, seconds] = legalTime.split(":").map(Number);
  //     const legalSeconds = hours * 3600 + minutes * 60 + seconds;

  //     const [startDateString, startTimeString] = startTime2.split(" ");
  //     const [startYear, startMonth, startDay] = startDateString
  //       .split("/")
  //       .map(Number);
  //     const [startHour, startMinute, startSecond] = startTimeString
  //       .split(":")
  //       .map(Number);
  //     const startTimeInMs = new Date(
  //       startYear,
  //       startMonth - 1,
  //       startDay,
  //       startHour,
  //       startMinute,
  //       startSecond
  //     ).getTime();

  //     const endTimeInMs = startTimeInMs + legalSeconds * 1000;
  //     const date = new Date(endTimeInMs);
  //     const year = date.getFullYear().toString();
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //     const day = date.getDate().toString().padStart(2, "0");
  //     const hour = date.getHours().toString().padStart(2, "0");
  //     const minute = date.getMinutes().toString().padStart(2, "0");
  //     const second = date.getSeconds().toString().padStart(2, "0");
  //     const dateString =
  //       year + month + day + " " + hour + ":" + minute + ":" + second;
  //     setEndTime2(dateString);
  //   } else {
  //     setEndTime2("NO NUMBER");
  //   }
  // }, [startTime, legalTime]);

  //---------------------
  // //start time show running start time
  // useEffect(() => {
  //   console.log("New timechange startTime set001:", startTime);
  //   if (startTime !== "NO NUMBER") {
  //     const date = new Date(startTime);
  //     const year = date.getFullYear().toString();
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //     const day = date.getDate().toString().padStart(2, "0");
  //     const hour = date.getHours().toString().padStart(2, "0");
  //     const minute = date.getMinutes().toString().padStart(2, "0");
  //     const second = date.getSeconds().toString().padStart(2, "0");
  //     const dateString =
  //       year +
  //       "/" +
  //       month +
  //       "/" +
  //       day +
  //       " " +
  //       hour +
  //       ":" +
  //       minute +
  //       ":" +
  //       second;
  //     setStartTime2(dateString);
  //   } else if (startTime === "NO NUMBER") {
  //     const date = new Date();
  //     const year = date.getFullYear().toString();
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //     const day = date.getDate().toString().padStart(2, "0");
  //     const hour = date.getHours().toString().padStart(2, "0");
  //     const minute = date.getMinutes().toString().padStart(2, "0");
  //     const second = date.getSeconds().toString().padStart(2, "0");
  //     const dateString =
  //       year +
  //       "/" +
  //       month +
  //       "/" +
  //       day +
  //       " " +
  //       hour +
  //       ":" +
  //       minute +
  //       ":" +
  //       second;
  //     setStartTime2(dateString);
  //     console.log("New timechange startTime005:", dateString);
  //   }
  // }, [startTime, setStartTime2]);

  // //set endtime2 show  xxxx
  // useEffect(() => {
  //   console.log("New timechange startTime:", startTime);
  //   if (startTime2 === "NO NUMBER") {
  //     const date = new Date();
  //     const year = date.getFullYear().toString();
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //     const day = date.getDate().toString().padStart(2, "0");
  //     const hour = date.getHours().toString().padStart(2, "0");
  //     const minute = date.getMinutes().toString().padStart(2, "0");
  //     const second = date.getSeconds().toString().padStart(2, "0");
  //     const dateString =
  //       year +
  //       "/" +
  //       month +
  //       "/" +
  //       day +
  //       " " +
  //       hour +
  //       ":" +
  //       minute +
  //       ":" +
  //       second;
  //     setStartTime2(dateString);
  //   }
  //   console.log("New timechange startTime002:", startTime2);
  //   // return;
  //   if (startTime2 !== "" && legalTime !== "NO NUM") {
  //     const [hours, minutes, seconds] = legalTime.split(":").map(Number);
  //     const legalSeconds = hours * 3600 + minutes * 60 + seconds;

  //     const [startDateString, startTimeString] = startTime2.split(" ");
  //     const [startYear, startMonth, startDay] = startDateString
  //       .split("/")
  //       .map(Number);
  //     const [startHour, startMinute, startSecond] = startTimeString
  //       .split(":")
  //       .map(Number);
  //     const startTimeInMs = new Date(
  //       startYear,
  //       startMonth - 1,
  //       startDay,
  //       startHour,
  //       startMinute,
  //       startSecond
  //     ).getTime();

  //     const endTimeInMs = startTimeInMs + legalSeconds * 1000;
  //     const date = new Date(endTimeInMs);
  //     const year = date.getFullYear().toString();
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //     const day = date.getDate().toString().padStart(2, "0");
  //     const hour = date.getHours().toString().padStart(2, "0");
  //     const minute = date.getMinutes().toString().padStart(2, "0");
  //     const second = date.getSeconds().toString().padStart(2, "0");
  //     const dateString =
  //       year + month + day + " " + hour + ":" + minute + ":" + second;
  //     setEndTime2(dateString);
  //   } else {
  //     setEndTime2("NO NUMBER");
  //   }
  // }, [startTime2, legalTime]);

  //enter update the legaltime
  const handleTimeKeyDown = (event) => {
    if (!isPlaying && nodeselect && event.key === "Enter") {
      const actionInfo = t("opruneditdraggable.oprun-actionInfo3"); // 设置信息内容01
      setInfoContent(actionInfo); // 更新状态或变量的值

      setLegalTime("00:00:00");
      // 计算总秒数
      const totalSeconds =
        parseInt(dayTime, 10) * 24 * 3600 +
        parseInt(hourTime, 10) * 3600 +
        parseInt(minuteTime, 10) * 60 +
        parseInt(secondTime, 10);

      // 将总秒数转换成小时、分钟、秒数的形式
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // console.log("timechange totalSeconds HMS:", hours, minutes, seconds);

      // 将小时、分钟、秒数拼成时间字符串，格式为"HH:mm:ss"
      const newtime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      console.log("timechange totalSeconds legaltime:", newtime);
      // 将时间字符串设置为legalTime的值
      setLegalTime(newtime);
      // 标记 legalTime 已更改
      setLegalTimeChanged(true);

      // 清空时间输入框
      setDayTime("0");
      setHourTime("0");
      setMinuteTime("0");
      setSecondTime("0");
      console.log("Node legaltime updated successfully");
    } else if (event.key === "Enter") {
      const actionInfo = t("opruneditdraggable.oprun-actionInfo4"); // 设置信息内容02
      setInfoContent(actionInfo); // 更新状态或变量的值

      // 清空时间输入框
      setDayTime("0");
      setHourTime("0");
      setMinuteTime("0");
      setSecondTime("0");
    }
  };

  //update legaltime to sql
  useEffect(() => {
    if (!isPlaying && nodeselect && legalTimeChanged) {
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      const nodetext = props.selectedNode.text;

      fetch(`${baseUrl}/oprunapi/setlegaltime/${fileid}/${nodekey}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          legaltime: legalTime,
          nodetext: nodetext,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setLegalTimeChanged(false);
            // 清空时间输入框
            setDayTime("0");
            setHourTime("0");
            setMinuteTime("0");
            setSecondTime("0");
            // console.log("Node legaltime updated successfully");
          }
        });
    }
  }, [isPlaying, nodeselect, legalTimeChanged, props.selectedNode]);

  //enter update the agreetime
  const handleTimeKeyDown2 = (event) => {
    if (!isPlaying && nodeselect && event.key === "Enter") {
      const actionInfo = t("opruneditdraggable.oprun-actionInfo5"); // 设置信息内容03
      setInfoContent(actionInfo); // 更新状态或变量的值

      setAgreeTime("00:00:00");
      // 计算总秒数
      const totalSeconds =
        parseInt(dayTime2, 10) * 24 * 3600 +
        parseInt(hourTime2, 10) * 3600 +
        parseInt(minuteTime2, 10) * 60 +
        parseInt(secondTime2, 10);

      // 将总秒数转换成小时、分钟、秒数的形式
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // console.log("timechange totalSeconds HMS:", hours, minutes, seconds);

      // 将小时、分钟、秒数拼成时间字符串，格式为"HH:mm:ss"
      const newtime2 = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      // console.log("timechange totalSeconds agreetime:", newtime2);
      // 将时间字符串设置为legalTime的值
      setAgreeTime(newtime2);
      // 标记 AgreeTime 已更改
      setAgreeTimeChanged(true);

      // 清空时间输入框
      setDayTime2("0");
      setHourTime2("0");
      setMinuteTime2("0");
      setSecondTime2("0");
      // console.log("Node agreetime updated successfully");
    } else if (event.key === "Enter") {
      const actionInfo = t("opruneditdraggable.oprun-actionInfo6"); // 设置信息内容02
      setInfoContent(actionInfo); // 更新状态或变量的值

      // 清空时间输入框
      setDayTime2("0");
      setHourTime2("0");
      setMinuteTime2("0");
      setSecondTime2("0");
    }
  };

  //update agree time
  useEffect(() => {
    if (!isPlaying && nodeselect && agreeTimeChanged) {
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      const nodetext = props.selectedNode.text;

      fetch(`${baseUrl}/oprunapi/setagreetime/${fileid}/${nodekey}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          agreetime: agreeTime,
          nodetext: nodetext,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setAgreeTimeChanged(false);
            // 清空时间输入框
            setDayTime("0");
            setHourTime("0");
            setMinuteTime("0");
            setSecondTime("0");
            console.log("Node agree time updated successfully");
          }
        });
    }
  }, [isPlaying, nodeselect, agreeTimeChanged, props.selectedNode]);

  // input start time
  const handleStartTimeChange = (event) => {
    if (!isPlaying && nodeselect) {
      const value = event.target.value;
      setStartTime(value);

      const actionInfo = t("opruneditdraggable.oprun-actionInfo7"); // 设置信息内容01
      setInfoContent(actionInfo); // 更新状态或变量的值
    } else if (!isPlaying && !nodeselect) {
      const value = event.target.value;
      setStartTime(value);

      const actionInfo = t("opruneditdraggable.oprun-actionInfo8"); // 设置信息内容01
      setInfoContent(actionInfo); // 更新状态或变量的值
    }
  };

  // input end time
  const handleEndTimeChange = (event) => {
    if (!isPlaying && nodeselect) {
      const value = event.target.value;
      setEndTime(value);

      const actionInfo = t("opruneditdraggable.oprun-actionInfo9"); // 设置信息内容01
      setInfoContent(actionInfo); // 更新状态或变量的值
    } else if (!isPlaying && !nodeselect) {
      const value = event.target.value;
      setEndTime(value);

      const actionInfo = t("opruneditdraggable.oprun-actionInfo10"); // 设置信息内容01
      setInfoContent(actionInfo); // 更新状态或变量的值
    }
  };

  //save start time
  function handleStartTimeKeyDown(event) {
    if (event.key === "Enter") {
      if (!isPlaying && nodeselect) {
        console.log("Node start time updated");
        try {
          saveStartTimeToDatabase(startTime)
            .then(() => {
              const actionInfo = t("opruneditdraggable.oprun-actionInfo11");
              setInfoContent(actionInfo);
            })
            .catch((error) => {
              console.error("Error while saving start time:", error);
            });
        } catch (error) {
          console.error("Error while saving start time:", error);
        }
      } else if (!isPlaying && !nodeselect) {
        console.log("File start time updated");
        try {
          opRunFileNodes()
            .then((result) => {
              if (result.success) {
                return saveStartTimeToDatabase(startTime);
              } else {
                throw new Error("opRunFileNodes failed");
              }
            })
            .then((result) => {
              if (result.success) {
                fileAutoRunStatus();
                const actionInfo = t("opruneditdraggable.oprun-actionInfo12");
                setInfoContent(actionInfo);
              }
            })
            .catch((error) => {
              console.error("Error while saving start time:", error);
            });
        } catch (error) {
          console.error("Error while saving start time:", error);
        }
      }
    }
  }

  async function saveStartTimeToDatabase(startTime) {
    return new Promise((resolve, reject) => {
      // 检查 startTime 是否为有效日期
      if (isNaN(Date.parse(startTime))) {
        // 如果不是有效日期，则将 startTime 设置为当前时间
        startTime = new Date();
      }
      console.log("start time updated 2", nodeselect);
      const fileid = props.selectedNode.fileid;
      const nodekey = props.selectedNode.key;
      const nodetext = props.selectedNode.text;

      let apiUrl;
      if (!nodeselect) {
        console.log("File start time updated2");
        // 获取URL参数中的文件ID
        const params = new URLSearchParams(window.location.search);
        const runfileid = params.get("fileid");
        apiUrl = `${baseUrl}/oprunapi/setfilestarttime/${runfileid}`;
      } else if (nodeselect) {
        console.log("node start time updated2");
        apiUrl = `${baseUrl}/oprunapi/setstarttime/${fileid}/${nodekey}`;
      }
      const requestData = {
        starttime: startTime,
        nodetext: nodetext,
      };

      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      };

      fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setStartTimeChanged(false);
            resolve({ success: true });
            // 清空时间输入框
            console.log("Node start time updated successfully");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // //startTimeChanged is changed
  // useEffect(() => {
  //   if (!isPlaying && !nodeselect && startTimeChanged) {
  //     console.log("时间改变了");
  //   }
  // }, [startTimeChanged]);

  const { draggableActive3 } = useContext(DraggableActiveContext);

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  const [isMoreShow, setIsMoreShow] = useState(true);

  function handleMoreToggle() {
    setIsMoreShow(!isMoreShow);
  }

  const countRef = useRef(
    parseInt(localStorage.getItem("timerCount"), 10) || 0
  );

  useEffect(() => {
    if (isPlaying) {
      const timerId = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!isPlaying && isStopped) {
      setCount(countRef.current);
    }
  }, [isPlaying, isStopped]);

  useEffect(() => {
    localStorage.setItem("timerCount", countRef.current.toString());
  }, [countRef.current]);

  function handlePlayToggle() {
    setIsPlaying(!isPlaying);
    setIsStopped(false);
    //auto model
    fileAutoRunStatus();
  }

  //auto model program
  function fileAutoRunStatus() {
    const fileid = props.selectedNode.fileid;
    const nodekey = props.selectedNode.key;
    //no node selected
    if (!nodeselect) {
      const actionInfo = t("opruneditdraggable.oprun-actionInfo13");
      setInfoContent(actionInfo);
      // //传递nodes到oprunsql
      // opRunFileNodes();
      //run calculate time engine
      getTimeFileAutorun();
    } else if (nodeselect) {
      const actionInfo = t("opruneditdraggable.oprun-actionInfo14"); // 设置信息内容01
      setInfoContent(actionInfo); // 更新状态或变量的值
      console.log("Run Status nodeRun fileid / nodekey:", fileid, nodekey);
    }
  }

  //传递nodes到oprunsql
  function opRunFileNodes() {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(window.location.search);
      const runfileid = params.get("fileid");

      fetch(`${baseUrl}/oprunapi/autorunfilenodes/${runfileid}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ runfileid: runfileid }),
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          //console.log("file运行开始了");
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  // fileautorun 获取时间的函数
  async function getTimeFileAutorun() {
    // 使用 URLSearchParams 获取 URL 中的参数
    const params = new URLSearchParams(window.location.search);
    const runfileid = params.get("fileid");
    console.log("Run Status fileRun fileid2:", runfileid);

    try {
      const response = await fetch(
        `${baseUrl}/oprunapi/autorunfilegettime/${runfileid}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ runfileid: runfileid }),
        }
      );

      if (response.ok) {
        const { startTime, endTime } = await response.json();
        console.log("StartTime:", startTime);
        console.log("EndTime:", endTime);

        // if (!nodekey) {
        //   setStartTime2(startTime);
        //   setEndTime2(endTime);
        // }
      } else {
        throw new Error("Failed to fetch time data");
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  function handleStopToggle() {
    setIsPlaying(false);
    setIsStopped(true);
    setCount(0);
    countRef.current = 0;
    localStorage.removeItem("timerCount");

    const actionInfo = t("opruneditdraggable.oprun-actionInfo15"); // 设置信息内容01
    setInfoContent(actionInfo); // 更新状态或变量的值
  }

  function formatTime(seconds) {
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds % 3600) / 60);
    const second = seconds % 60;
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}:${second.toString().padStart(2, "0")}`;
  }

  function getStatusText(isPlaying, isStopped) {
    let statusText = "";
    let bgColor = "";
    if (isStopped === true) {
      statusText = t("opruneditdraggable.oprun-status1");
      bgColor = "#6C757D";
      //
    } else if (isPlaying) {
      statusText = t("opruneditdraggable.oprun-status2");
      bgColor = "#66C9F9";
    } else {
      statusText = t("opruneditdraggable.oprun-status3");
      bgColor = "#BDC2D4";
    }
    return { statusText, bgColor };
  }

  const { statusText, bgColor } = getStatusText(isPlaying, isStopped);

  // 更新 "timeslider" 的值和进度
  async function updateSliderValue() {
    const params = new URLSearchParams(window.location.search);
    const runfileid = params.get("fileid");
    console.log("Run Status fileRun fileid2:", runfileid);

    try {
      const response = await fetch(
        `${baseUrl}/oprunapi/getfiletimes/${runfileid}`
      );
      const data = await response.json();
      console.log("Response Slider data:", data);

      if (response.ok) {
        const { starttime, endtime } = data;
        const startTimestamp = new Date(starttime).getTime() / 1000; // 将毫秒转换为秒
        const currentTimestamp =
          startTimestamp + convertTimeToSeconds(formatTime(count));
        const endTimestamp = new Date(endtime).getTime() / 1000; // 将毫秒转换为秒
        const progress =
          ((currentTimestamp - startTimestamp) /
            (endTimestamp - startTimestamp)) *
          100;

        const currentTime = new Date(
          new Date(starttime).getTime() +
            convertTimeToSeconds(formatTime(count)) * 1000 // 将秒转换为毫秒
        ).toLocaleString(); // 格式化为本地时间字符串
        //获取运行当前时间
        console.log("New currentTimestamp show:", currentTime);
        setSliderValue(progress);
      } else {
        console.error("Error: Failed to retrieve file times");
      }
    } catch (error) {
      console.error("Error while fetching file times:", error);
    }
  }

  // 辅助函数，将时间转换为秒数
  function convertTimeToSeconds(time) {
    const [hour, minute, second] = time.split(":").map(Number);
    return hour * 3600 + minute * 60 + second;
  }

  // 在倒计时计数变化时更新时间轴进度
  useEffect(() => {
    if (isPlaying) {
      updateSliderValue();
    }
  }, [count, isPlaying]);

  return (
    <Draggable cancel=".headicons img, .controlbuttons img,.headcancel img,.linedesc img,.picinput input,.picbuttons button,.timeinput input,.timeinput2 input">
      <div
        className={
          draggableActive3 ? "controlinfocard2 active" : "controlinfocard2"
        }
      >
        <div className="controllayout">
          <div className="controlhead">
            <div className="headstatus" style={{ backgroundColor: bgColor }}>
              {statusText}
            </div>
            <div className="headicons">
              <div className="headalarm">
                <img className="headicon" src={warnings} />
                <span className="headnum">2</span>
              </div>
              <div className="headinfo">
                <img className="headicon" src={infobt} />
                <span className="headnum">5</span>
              </div>
            </div>
            <div className="headcancel">
              <img
                className="closeicon"
                src={closebt}
                onClick={() => dispatch05({ type: "OPRUNDRAGGABLE" })}
              />
            </div>
          </div>
          <div className="controltitle">
            <div className="nodedesc">{nodeDesc}</div>
            <div className="controlop">{t("opruneditdraggable.oprun-controlop")}</div>
          </div>
          <div className="controlbuttons">
            <div>
              {isPlaying ? (
                <img
                  className="pauseicon"
                  src={playpause}
                  onClick={handlePlayToggle}
                />
              ) : (
                <img
                  className="runicon"
                  src={playrun}
                  onClick={handlePlayToggle}
                />
              )}
            </div>
            <div>
              {isStopped ? (
                <img className="stopicon" src={playstoped} />
              ) : (
                <img
                  className="stopicon"
                  src={playstop}
                  onClick={handleStopToggle}
                />
              )}
            </div>
            <div className="timeshow real">
              <div className="realsup">{formatTime(count)}</div>
              <div className="realsdown">{sliderValue.toFixed(0)}%</div>
            </div>
            <div className="timeshow agree">
              <div className="agreesup">{agreeTime}</div>
              <div className="agreesdown">-20%</div>
            </div>
            <div className="timeshow legal">
              <div className="legalsup">{legalTime}</div>
              <div className="legalsdown">-20%</div>
            </div>
          </div>
          <div className="controlline">
            <div className="lineitems">
              <div className="linepic">
                <input
                  type="range"
                  className="timeslider"
                  min="0"
                  max="100"
                  step="1"
                  value={sliderValue}
                  onChange={(event) => setSliderValue(event.target.value)}
                />
              </div>
              <div className="linedesc">
                <div className="timeinput">{startTime2}</div>
                <div className="bottombotton">
                  <img
                    className="morebtn"
                    src={morebt}
                    onClick={handleMoreToggle}
                  />
                </div>
                <div className="timeinput">{endTime2}</div>
              </div>
            </div>
          </div>
          {isMoreShow ? (
            <div className="controlinputs">
              <div className="cycletimes">
                <div className="timeinput">
                  <div className="timetitle">{t("opruneditdraggable.oprun-timetitle1")}</div>
                  <input
                    type="datetime-local"
                    step="1"
                    id="startTimeInput"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    onKeyDown={handleStartTimeKeyDown}
                  />
                </div>
                <div className="timeinput">
                  <div className="timetitle">{t("opruneditdraggable.oprun-timetitle3")}</div>
                  <input
                    type="datetime-local"
                    step="1"
                    id="endTimeInput"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    // onKeyDown={handleTimeKeyDown4}
                  />
                </div>
              </div>
              <div className="agreetimes">
                <div className="timeinput2 legal">
                  <div className="timetitle legal">{t("opruneditdraggable.oprun-timetitle2")}</div>
                  {/* <input
                    id="legalTimeInput"
                    value={legalTime}
                    onChange={handleTimeChange}
                    onKeyDown={handleTimeKeyDown}
                  /> */}

                  <input
                    id="dayTimeInput"
                    value={dayTime}
                    onChange={handleTimeChange}
                    onKeyDown={handleTimeKeyDown}
                  />
                  <span>{t("opruneditdraggable.oprun-timeday")}</span>
                  <input
                    id="hourTimeInput"
                    value={hourTime}
                    onChange={handleTimeChange}
                    onKeyDown={handleTimeKeyDown}
                  />
                  <span>{t("opruneditdraggable.oprun-timehour")}</span>
                  <input
                    id="minuteTimeInput"
                    value={minuteTime}
                    onChange={handleTimeChange}
                    onKeyDown={handleTimeKeyDown}
                  />
                  <span>{t("opruneditdraggable.oprun-timeminute")}</span>
                  <input
                    id="secondTimeInput"
                    value={secondTime}
                    onChange={handleTimeChange}
                    onKeyDown={handleTimeKeyDown}
                  />
                  <span>{t("opruneditdraggable.oprun-timesecond")}</span>
                </div>

                <div className="timeinput2 agree">
                  <div className="timetitle agree">{t("opruneditdraggable.oprun-timetitle4")}</div>
                  {/* <input
                    id="agreeTimeInput"
                    value={agreeTime}
                    onChange={handleTimeChange}
                    onKeyDown={handleTimeKeyDown}
                  /> */}

                  <input
                    id="dayTimeInput2"
                    value={dayTime2}
                    onChange={handleTimeChange2}
                    onKeyDown={handleTimeKeyDown2}
                  />
                  <span>{t("opruneditdraggable.oprun-timeday")}</span>
                  <input
                    id="hourTimeInput2"
                    value={hourTime2}
                    onChange={handleTimeChange2}
                    onKeyDown={handleTimeKeyDown2}
                  />
                  <span>{t("opruneditdraggable.oprun-timehour")}</span>
                  <input
                    id="minuteTimeInput2"
                    value={minuteTime2}
                    onChange={handleTimeChange2}
                    onKeyDown={handleTimeKeyDown2}
                  />
                  <span>{t("opruneditdraggable.oprun-timeminute")}</span>
                  <input
                    id="secondTimeInput2"
                    value={secondTime2}
                    onChange={handleTimeChange2}
                    onKeyDown={handleTimeKeyDown2}
                  />
                  <span>{t("opruneditdraggable.oprun-timesecond")}</span>
                </div>
              </div>
              <div className="infodesc">{t("opruneditdraggable.oprun-infodesc")}{infoContent}</div>
            </div>
          ) : null}
        </div>
      </div>
    </Draggable>
  );
}
