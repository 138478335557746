import "./login.scss";
import { Qrcode2, Wechat22, Qq2, Weibo2 } from "@plansplan/iconsx3";
import { Bkj12 } from "@plansplan/iconsx2";
import { Bkj22 } from "@plansplan/iconsx2";

import { useContext, useState } from "react";
import baseUrl from "../../configs/baseurl";
import { PopupActiveContext } from "../../context/popupActiveContext";
import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";
// import axios from "axios";

//loginpopup open popup
const LoginPopup = () => {
	const { t, i18n } = useTranslation();
//cn
        const showlanguageCN =
    i18n.language === "zh-cn" || i18n.language === "zh" ? "cn" : null;
  //userlogin status
  const { setCurrentUser, login } = useContext(AuthContext);

  const { popupActive2 } = useContext(PopupActiveContext);

  //popupActiveContext
  const { dispatch3 } = useContext(PopupActiveContext);

  // 定义一个空对象用于存储用户信息

  //register api call
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  //register success
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const handleRegisterSuccess = () => {
    setRegisterSuccess(true);
  };

  //login input
  const [isLogin, setIsLogin] = useState(true);

  //switch register and login
  const handleSwitchForm = () => {
    setName("");
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setPhoneNo("");
    setLoginname("");
    setLoginpassword("");
    setShowLoginMessage("");
    setLoginError("");
    setRegisterSuccess(false);
    setShowPassword(false);
    setIsLogin(!isLogin);
  };

  const [loginname, setLoginname] = useState("");
  const [loginpassword, setLoginpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [loginError, setLoginError] = useState("");

  //submit form to server
  const handleSubmitRegister = async (event) => {
    event.preventDefault();
    const password1 = document.getElementById("password1").value;
    const password2 = document.getElementById("password2").value;
    const errorElement = document.getElementById("password2-error");

    if (password1 !== password2) {
      errorElement.innerText = t("login.loginmenu-innertext4");
      // 清空输入框
      setName("");
      setPassword("");
      setConfirmPassword("");
      setEmail("");
      setPhoneNo("");
      return;
    } else {
      errorElement.innerText = "";
    }
    // 提交表单
   // console.log("提交注册表单");

    try {
      const response = await fetch(`${baseUrl}/userapi/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, password }),
      });
      const data = await response.json();
      if (data.success) {
       // console.log("注册成功");
        // 注册成功后清空输入框
        setName("");
        setPassword("");
        setConfirmPassword("");
        setEmail("");
        setPhoneNo("");
        setRegisterSuccess(true);
        setTimeout(() => {
          setIsLogin(false);
        }, 1500); // 等待一秒钟再将 setIsLogin 设置为 false
      } else {
       // console.log("注册失败");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //login form to server
  const handleSubmitLogin = async (event) => {
    //clear msg
    setShowLoginMessage(false);
    setLoginError("");

   // console.log("用户登录");
    event.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/userapi/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ loginname, loginpassword }),
      });
      const data = await response.json();
      if (response.ok) {
        const {
          userid,
          name,
          email,
          phone_no,
          desc_user,
          vip_no,
          pic_url,
          date_user,
          status_user,
        } = data;
        const user = {
          userid,
          name,
          email,
          phone_no,
          desc_user,
          vip_no,
          pic_url,
          date_user,
          status_user,
        };
        setShowLoginMessage(true);
        setLoginError("");
        setLoginpassword("");

        //auto close login screen
        setTimeout(() => {
          dispatch3({ type: "LOGIN" });
        }, 500);

        const myuser = user.name;
        //console.log("登录成功用户", myuser);
        // console.log("登录成功用户user", user);
        // //only username
        login(myuser);
        // login(user);
      } else {
       // console.log("data.message", data.message);
        setLoginError(data.message);
        setLoginpassword("");
      }
      // Redirect the user to the home page or dashboard
    } catch (error) {
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        setLoginError(t("login.loginmenu-loginError"));
      } else {
        console.error(error);
      }
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById("loginpassword");
    setShowPassword(!showPassword);
    if (showPassword) {
      passwordInput.type = "password";
    } else {
      passwordInput.type = "text";
      passwordInput.value = loginpassword;
    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNoChange = (event) => {
    setPhoneNo(event.target.value);
  };

  //
  const handleLoginNameChange = (event) => {
    setLoginname(event.target.value);
  };

  const handleLoginPasswordChange = (event) => {
    setLoginpassword(event.target.value);
  };

  return (
    <>
      <div
        className={
          popupActive2 ? "popuplayout login active" : "popuplayout login"
        }
      >
        <div className="popupbody login">
          <div className="sign-content">
            {/*---------------------------------------------------------------- */}
            <input
              type="radio"
              name="sliderlogin"
              checked={isLogin}
              id="pwfree"
            />
            <input
              type="radio"
              name="sliderlogin"
              checked={!isLogin}
              id="pwlogin"
            />
            <nav>
              <div className="loginmenu">
                <label
                  htmlFor="pwfree"
                  title={t("login.loginmenu-title1")}
                  className="pwfree"
                  onClick={handleSwitchForm}
                >
                  {t("login.loginmenu-label1")}
                </label>
                <label
                  htmlFor="pwlogin"
                  title={t("login.loginmenu-title2")}
                  className="pwlogin"
                  onClick={handleSwitchForm}
                >
                  {t("login.loginmenu-label2")}
                </label>
              </div>
              <div className="sliderlogin"></div>
            </nav>
            <section>
              <div className={`popupitems pwfree ${!isLogin ? "active" : ""}`}>
                <form onSubmit={handleSubmitRegister}>
                  <div className="login-item  register">
                    <div className="input-account register">
                      <input
                        type="text"
                        placeholder={t("login.loginmenu-placeholder1")}
                        id="name"
                        value={name}
                        onChange={handleNameChange}
                        required
                        autoComplete="off"
                        pattern="[\s\S]*"
                        tabindex="0"
                        onBlur={(event) => {
                          const account = event.target.value;
                          const errorText =
                            event.target.parentElement.querySelector(
                              ".accounterror-msg"
                            );
                          const successText =
                            event.target.parentElement.querySelector(
                              ".accountsuccess-msg"
                            );
                          if (account.length < 5) {
                            errorText.innerText = "账号长度不小于5个字符!";
                            successText.innerText = "";
                          } else {
                            fetch(`${baseUrl}/userapi/checkusername`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                name: account,
                              }),
                            })
                              .then((res) => res.json())
                              .then((data) => {
                                // console.log("data.exists", data.exists);
                                if (data.exists) {
                                  errorText.innerText = t("login.loginmenu-innertext2");
                                  successText.innerText = "";
                                } else {
                                  errorText.innerText = "";
                                  successText.innerText = t("login.loginmenu-innertext3");
                                }
                              })
                              .catch((error) => console.error(error));
                          }
                        }}
                      />
                      <div className="accounterror-msg"></div>
                      {!registerSuccess && (
                        <div className="accountsuccess-msg"></div>
                      )}
                      {registerSuccess && (
                        <div className="success-msg">{t("login.loginmenu-success-msg")}</div>
                      )}
                    </div>
                  </div>
                  <div className="login-item register">
                    <div className="input-password register">
                      <input
                        type="password"
                        id="password1"
                        placeholder={t("login.loginmenu-placeholder2")}
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        autoComplete="off"
                        pattern="[\s\S]*"
                        tabindex="0"
                        onBlur={(event) => {
                          const password = event.target.value;
                          const regex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
                          const errorMsg =
                            document.getElementById("password1-error");
                          if (!regex.test(password)) {
                            errorMsg.textContent =t("login.loginmenu-error-msg");
                          } else {
                            errorMsg.textContent = "";
                          }
                        }}
                      />
                      <div className="error-msg" id="password1-error"></div>
                    </div>
                  </div>
                  <div className="login-item  register">
                    <div className="input-password register">
                      <input
                        type="password"
                        id="password2"
                        placeholder={t("login.loginmenu-placeholder3")}
                        autoComplete="off"
                        pattern="[\s\S]*"
                        tabindex="0"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                      />
                      <div className="pserror-msg" id="password2-error"></div>
                    </div>
                  </div>
                  <div className="login-item setpw register">
                    <span>{t("login.loginmenu-register")}</span>
                  </div>
                  <div className="login-item button register">
                    <button
                      type="submit"
                      title={t("login.loginmenu-submitbtntitle")}
                      className="submitbutton"
                    >
                      {t("login.loginmenu-submitbtn")}
                    </button>
                  </div>
                </form>
              </div>
              {/* 登录界面 */}
              <div className={`popupitems pwlogin ${isLogin ? "active" : ""}`}>
                <form onSubmit={handleSubmitLogin}>
                  <div className="login-item">
                    {/* 请输入账号或邮箱 */}
                    <div className="input-account">
                      <input
                        type="text"
                        placeholder={t("login.loginmenu-placeholder4")}
                        value={loginname}
                        autoComplete="off" 
                        pattern="[\s\S]*"
                        tabindex="0" 
                        onChange={handleLoginNameChange}
                        onBlur={(event) => {
                          const account = event.target.value;
                          const errorText =
                            event.target.parentElement.querySelector(
                              ".accounterror2-msg"
                            );
                          if (account.length < 5) {
                            errorText.innerText = t("login.loginmenu-innertext1");
                          } else {
                            errorText.innerText = "";
                          }
                        }}
                      />
                      <div className="accounterror2-msg"></div>
                      {loginError && (
                        <div className="showloginerror-msg">{loginError}</div>
                      )}
                      {showLoginMessage && (
                        <div className="showlogin-msg">
                          {t("login.loginmenu-login-msg")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="login-item">
                    {/* 请输入密码 */}
                    <div className="input-password">
                      <input
                        type="password"
                        id="loginpassword"
                        placeholder={t("login.loginmenu-placeholder2")}
                        value={loginpassword}
                        autoComplete="off"
                        pattern="[\s\S]*"
                        tabindex="0"
                        onChange={handleLoginPasswordChange}
                      />
                      <div
                        className={
                          showPassword ? "showicons  off" : "showicons"
                        }
                        onClick={togglePasswordVisibility}
                      >
                        <Bkj12 />
                      </div>
                      <div
                        className={showPassword ? "showicons" : "showicons off"}
                        onClick={togglePasswordVisibility}
                      >
                        <Bkj22 />
                      </div>
                    </div>
                  </div>
                  <div className="login-item forget" title={t("login.loginmenu-forget-title")}>
                    <span>{t("login.loginmenu-forgetbtn")}</span>
                  </div>

                  <div className="login-item button">
                    {/* 登录按钮 */}
                    <button title={t("login.loginmenu-submitbtn2title")} className="submitbutton">
                      {t("login.loginmenu-submitbtn2")}
                    </button>
                  </div>
                  <div className="login-item hide">
                    <span>{t("login.loginmenu-register")}</span>
                  </div>
                </form>
              </div>
            </section>

            {/*---------------------------------------------------------------- */}
          </div>
          <div className="css-line"></div>
          {/* social */}
          <div className="footsign signsocial">
            <span className="social-title">
              {t("login.loginmenu-social-title")}
            </span>
            <div className="social-group">
              {showlanguageCN && (
                <>
                  <div className="social-item">
                    <Qrcode2 className="icons3" />
                    <span>{t("login.loginmenu-social1")}</span>
                  </div>
                  <div className="social-item">
                    <Wechat22 className="icons3" />
                    <span>{t("login.loginmenu-social2")}</span>
                  </div>
                  <div className="social-item">
                    <Qq2 className="icons3" />
                    <span>{t("login.loginmenu-social3")}</span>
                  </div>
                  <div className="social-item">
                    <Weibo2 className="icons3" />
                    <span>{t("login.loginmenu-social4")}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={popupActive2 ? "overlay active" : "overlay"}
        onClick={() => dispatch3({ type: "LOGIN" })}
      ></div>
    </>
  );
};

export default LoginPopup;
