import "./home.scss";
import Navbar from "../../components/navbar/Navbar";
import Leftlayout from "../../components/leftlayout/Leftlayout";
import Sidebar from "../../components/sidebar/Sidebar";
import LoginPopup from "../login/Login";
import NoticePopup from "../../components/popupfiles/noticepopup/noticePopup";
import ChatPopup from "../../components/popupfiles/chatpopup/chatPopup";
import MainLayoutView from "../../components/mainlayout/mainlayoutview";
import UserPopup from "../../components/popupfiles/userpopup/userPopup";
import ToolPopup from "../../components/popupfiles/toolpopup/toolPopup";
const HomeView = () => {
  return (
    <div className="home">
      <Navbar />
      <Sidebar />
      <Leftlayout />
      <LoginPopup />
      <NoticePopup />
      <ChatPopup />
      <ToolPopup />
      <UserPopup />
      <MainLayoutView />
    </div>
  );
};

export default HomeView;
