//gojs testing
import React, { useRef, useEffect, useState } from "react";

import Draggable from "react-draggable";

//datas json
import nodedatas from "./indexdatas01/nodedatas.json";
import linkdatas from "./indexdatas01/linkdatas.json";

// import * as go from "gojs";
// import * as go from "../node_modules/gojs/release/go-module";
// import * as go from "../../components/modules/reactmodules/go-module";
import * as go from "../../../node_modules/gojs/release/go-module";

// import { GuidedDraggingTool } from "gojs/extensionsJSM/GuidedDraggingTool";
import { DrawCommandHandler } from "gojs/extensionsJSM/DrawCommandHandler";
// import { FigureButton } from "gojs/extensionsJSM/Figures";

import { ZoomSlider } from "gojs/extensionsJSM/ZoomSlider";

import { ReactDiagram } from "gojs-react";

import "./model1.scss"; // contains .diagram-component CSS

// ...

/**
 * This function is responsible for setting up the diagram's initial properties and any templates.
 */

// node selection style
function onSelectionChanged(node) {
  var icon = node.findObject("SHAPE");
  if (icon !== null) {
    //判断node的选择状态
    if (node.isSelected) icon.fill = "#ECF2FF";
    else icon.fill = "#f9fbff";
  }
}

function initDiagram() {
  const $ = go.GraphObject.make;
  const diagram = $(go.Diagram, {
    // have mouse wheel events zoom in and out instead of scroll up and down
    "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
    initialAutoScale: go.Diagram.Uniform,
    "linkingTool.direction": go.LinkingTool.ForwardsOnly,
    layout: $(go.LayeredDigraphLayout, {
      isInitial: false,
      isOngoing: false,
      layerSpacing: 50,
    }),

    //-----
    scrollMode: go.Diagram.InfiniteScroll, // allow the diagram to be scrolled beyond content
    padding: 20, // extra space when scrolled all the way

    "draggingTool.isGridSnapEnabled": true,
    handlesDragDropForTopLevelParts: true,

    mouseDrop: (e) => {
      // when the selection is dropped in the diagram's background,
      // make sure the selected Parts no longer belong to any Group
      var ok = e.diagram.commandHandler.addTopLevelParts(
        e.diagram.selection,
        true
      );
      if (!ok) e.diagram.currentTool.doCancel();
    },
    commandHandler: $(DrawCommandHandler), // support offset copy-and-paste

    "undoManager.isEnabled": false, // enable undo & redo

    //New Group
    "commandHandler.archetypeGroupData": { isGroup: true, text: "新组合" },
    SelectionGrouped: (e) => {
      var group = e.subject;
      setTimeout(() => {
        // and have the user start editing its text
        e.diagram.commandHandler.editTextBlock();
      });
    },

    //linkRelinked
    LinkRelinked: (e) => {
      // re-spread the connections of other links connected with both old and new nodes
      var oldnode = e.parameter.part;
      oldnode.invalidateConnectedLinks();
      var link = e.subject;
      if (e.diagram.toolManager.linkingTool.isForwards) {
        link.toNode.invalidateConnectedLinks();
      } else {
        link.fromNode.invalidateConnectedLinks();
      }
    },

    //----------------------------------------------------------------
    model: $(go.GraphLinksModel, {
      linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    }),
  });

  //background picture set
  diagram.add(
    $(
      go.Part, // this Part is not bound to any model data
      {
        layerName: "Background",
        position: new go.Point(0, -100),
        selectable: false,
        pickable: false,
      },
      $(go.Picture, {
        width: 360,
        height: 380,
        source:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Apple_Computer_Logo_rainbow.svg/800px-Apple_Computer_Logo_rainbow.svg.png",
      })
    )
  );

  // define a simple Node template
  diagram.nodeTemplate = $(
    go.Node,
    "Auto",
    {
      locationSpot: go.Spot.Center,
      locationObjectName: "SHAPE",
      desiredSize: new go.Size(120, 50),
      minSize: new go.Size(40, 40),

      //--------------v
      movable: false,
      selectable: false,
      resizable: false,
      resizeCellSize: new go.Size(10, 10),
      //-------------xx
      // isShadowed: true, //shadow
      // shadowBlur: 2,
      // shadowOffset: new go.Point(0, 3),
      // shadowColor: "rgba(0, 0, 0, .14)",
      //-------------xx
    },
    // these Bindings are TwoWay because the DraggingTool and ResizingTool modify the target properties
    new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
      go.Point.stringify
    ),
    new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
      go.Size.stringify
    ),
    $(
      go.Panel,
      "Auto",
      $(
        go.Shape,
        {
          // the border
          name: "SHAPE",
          fill: "white",
          portId: "",
        },
        new go.Binding("figure"),
        new go.Binding("fill"),
        new go.Binding("stroke", "color"),
        new go.Binding("strokeWidth", "thickness"),
        new go.Binding("strokeDashArray", "dash")
      ),
      // this Shape prevents mouse events from reaching the middle of the port
      $(go.Shape, {
        width: 100,
        height: 40,
        strokeWidth: 0,
        fill: "transparent",
        //--------------v
        cursor: "pointer",
        //--------------v
      }),
      $(
        go.TextBlock,
        {
          margin: 1,
          textAlign: "center",
          overflow: go.TextBlock.OverflowEllipsis,
          maxLines: 10,
          wrap: go.TextBlock.WrapFit,
          //--------------v
          cursor: "pointer",
          //--------------v
          editable: false,
        },
        // this Binding is TwoWay due to the user editing the text with the TextEditingTool
        new go.Binding("text").makeTwoWay(),
        new go.Binding("stroke", "color")
      ),

      //contextMenu
      {
        // define a context menu for each node
        contextMenu: $(
          "ContextMenu", // that has one button
          $(
            "ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              _buttonFillOver: "#F3FBFF",
              width: 260,
              maxSize: new go.Size(90, 30),

              // margin: new go.Margin(6, 10, 6, 3),
              // defaultAlignment: go.Spot.Left,
            },
            $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "状态")
          ),
          $(
            "ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              _buttonFillOver: "#F3FBFF",
              width: 260,
              maxSize: new go.Size(90, 30),
            },
            $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "详细信息")
          ),
          $(
            "ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              _buttonFillOver: "#F3FBFF",
              width: 260,
              maxSize: new go.Size(90, 30),
            },
            $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "上传文件")
          ),
          $(
            "ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              _buttonFillOver: "#F3FBFF",
              width: 260,
              maxSize: new go.Size(90, 30),
            },
            $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "咨询交流")
          ),
          $(
            "ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              _buttonFillOver: "#F3FBFF",
              width: 260,
              maxSize: new go.Size(90, 30),
            },
            $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "资料库")
          ),
          $(
            "ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              _buttonFillOver: "#F3FBFF",
              width: 260,
              maxSize: new go.Size(90, 30),
            },
            $(go.TextBlock, { font: "10pt", stroke: "#6c757d" }, "AI资讯")
          )
          // more ContextMenuButtons would go here
        ), // end Adornment
      }
    ),
    //top port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      //   cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Top,
      portId: "t",
      fromSpot: go.Spot.Top,
      toSpot: go.Spot.Top,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //bottom port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      //   cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Bottom,
      portId: "b",
      fromSpot: go.Spot.Bottom,
      toSpot: go.Spot.Bottom,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //center port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      //   cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Center,
      portId: "c",
      fromSpot: go.Spot.Center,
      toSpot: go.Spot.Center,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //left port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      //   cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Left,
      portId: "l",
      fromSpot: go.Spot.Left,
      toSpot: go.Spot.Left,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //right port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      //   cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Right,
      portId: "r",
      fromSpot: go.Spot.Right,
      toSpot: go.Spot.Right,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      toLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    })
  );

  // //dragSelect box
  // diagram.toolManager.dragSelectingTool.box = $(
  //   go.Part,
  //   { layerName: "Tool" },
  //   $(go.Shape, "Cloud", { fill: null, strokeWidth: 1, stroke: "lime" })
  // );

  var white = "rgba(255, 255, 255, 0.6)";
  var blue = "#1876f2";
  var grad = "#6c757d";
  //detailed information tooltip 弹窗信息 x
  diagram.nodeTemplate.toolTip = $(
    "ToolTip", // show some detailed information
    { "Border.fill": "white", "Border.stroke": null },
    new go.Binding("visible", "", function (data, textBlock) {
      var node = textBlock.part;
      if (!node) return false;
      var key = node.key;
      var nodeData = diagram.model.findNodeDataForKey(key);
      return (
        !!nodeData.titles ||
        !!nodeData.details ||
        !!nodeData.titles2 ||
        !!nodeData.details2 ||
        !!nodeData.titles3 ||
        !!nodeData.details3 ||
        !!nodeData.titles4 ||
        !!nodeData.details4
      );
    }),
    // new go.Binding("visible", !!"titles" || !!"details"),
    $(
      go.Panel,
      "Auto",
      $(go.Shape, "Rectangle", { fill: white, stroke: null }),
      $(
        go.Panel,
        "Vertical",

        {
          maxSize: new go.Size(160, NaN),
          padding: 5,
          defaultAlignment: go.Spot.Left,
        }, // limit width but not height

        $(
          go.TextBlock,
          {
            font: "10pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "center",
          },
          new go.Binding("text")
        ),
        //------xx
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            stroke: blue,
            textAlign: "center",
            margin: 4,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.titles;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "10pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "left",
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            stroke: blue,
            textAlign: "center",
            margin: 4,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.titles2;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles2;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "10pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "left",
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details2;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details2;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            stroke: blue,
            textAlign: "center",
            margin: 4,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.titles3;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles3;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "10pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "left",
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details3;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details3;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "9pt sans-serif",
            stroke: blue,
            textAlign: "center",
            margin: 4,
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.titles4;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.titles4;
          })
        ),
        $(
          go.TextBlock,
          {
            font: "10pt sans-serif",
            margin: 3,
            stroke: grad,
            textAlign: "left",
          },
          new go.Binding("text", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return nodeData.details4;
          }),
          new go.Binding("visible", "", function (data, textBlock) {
            var node = textBlock.part;
            if (!node) return false;
            var key = node.key;
            var nodeData = diagram.model.findNodeDataForKey(key);
            return !!nodeData.details4;
          })
        )
      )
    )
  );

  // Group template

  diagram.groupTemplate = $(
    go.Group,
    "Spot",
    {
      layerName: "Background",
      ungroupable: true,
      locationSpot: go.Spot.Center,
      selectionObjectName: "BODY",
      movable: false,
      selectable: false,
      computesBoundsAfterDrag: true, // allow dragging out of a Group that uses a Placeholder
      handlesDragDropForMembers: true, // don't need to define handlers on Nodes and Links
      mouseDrop: (e, grp) => {
        // add dropped nodes as members of the group
        var ok = grp.addMembers(grp.diagram.selection, true);
        if (!ok) grp.diagram.currentTool.doCancel();
      },
      avoidable: false,
    },
    new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
      go.Point.stringify
    ),

    $(
      go.Panel,
      "Auto",
      { name: "BODY" },
      $(
        go.Shape,
        {
          parameter1: 10,
          fill: "white",
          strokeWidth: 1,
          portId: "",
          // cursor: "pointer",
          fromLinkable: false,
          toLinkable: false,
          fromLinkableDuplicates: true,
          toLinkableDuplicates: true,
          fromSpot: go.Spot.AllSides,
          toSpot: go.Spot.AllSides,
        },
        new go.Binding("fill"),
        new go.Binding("stroke", "color"),
        new go.Binding("strokeWidth", "thickness"),
        new go.Binding("strokeDashArray", "dash")
      ),

      $(go.Placeholder, {
        background: "transparent",
        margin: 10,
        padding: new go.Margin(16, 0, 0, 0),
        cursor: "grab",
      })
    ),
    $(
      go.TextBlock,

      {
        alignment: go.Spot.Top,
        alignmentFocus: new go.Spot(0.5, 0, 0, -4),
        // alignmentFocus: go.Spot.Bottom,
        font: "bold 12pt sans-serif",
        editable: false,
      },
      new go.Binding("text").makeTwoWay(),
      new go.Binding("stroke", "color")
    ),
    //Group port
    //top port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Top,
      portId: "t",
      fromSpot: go.Spot.Top,
      toSpot: go.Spot.Top,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //bottom port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Bottom,
      portId: "b",
      fromSpot: go.Spot.Bottom,
      toSpot: go.Spot.Bottom,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //center port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Center,
      portId: "c",
      fromSpot: go.Spot.Center,
      toSpot: go.Spot.Center,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //left port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Left,
      portId: "l",
      fromSpot: go.Spot.Left,
      toSpot: go.Spot.Left,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      fromLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    }),
    //right port
    $(go.Shape, "Ellipse", {
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(140,140,140,0.05)",
      cursor: "pointer",
      desiredSize: new go.Size(10, 10),
      alignment: go.Spot.Right,
      portId: "r",
      fromSpot: go.Spot.Right,
      toSpot: go.Spot.Right,
      //--------------v
      fromLinkable: false,
      toLinkable: false,
      toLinkableDuplicates: true,
      fromLinkableSelfNode: true,
      toLinkableSelfNode: true,
    })
  );

  // Link template 链接

  diagram.linkTemplate = $(
    go.Link,
    {
      layerName: "Foreground",
      curve: go.Link.JumpGap,
      routing: go.Link.AvoidsNodes,
      corner: 1,
      toShortLength: 6, // assume arrowhead at "to" end, need to avoid bad appearance when path is thick
      relinkableFrom: false,
      relinkableTo: false,
      selectable: false,
      reshapable: true,
      resegmentable: true,
    },
    new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
    new go.Binding("toSpot", "toSpot", go.Spot.parse),
    new go.Binding("fromShortLength", "dir", (dir) => (dir === 2 ? 4 : 0)),
    new go.Binding("toShortLength", "dir", (dir) => (dir >= 1 ? 4 : 0)),
    new go.Binding("points").makeTwoWay(), // TwoWay due to user reshaping with LinkReshapingTool
    //$(go.Shape, { isPanelMain: true, stroke: "#DDF1FF", strokeWidth: 4 }),
    $(
      go.Shape,
      {
        isPanelMain: true,
        stroke: "#585858",
        strokeWidth: 2,
        name: "PIPE",
        strokeDashArray: [4, 2],
      },
      new go.Binding("stroke", "color"),
      new go.Binding("strokeWidth", "thickness"),
      new go.Binding("strokeDashArray", "dash")
    ),
    $(
      go.Shape,
      {
        fromArrow: "Backward",
        strokeWidth: 0,
        scale: 4 / 3,
        visible: false,
      },
      new go.Binding("visible", "dir", (dir) => dir === 2),
      new go.Binding("fill", "color"),
      new go.Binding("scale", "thickness", (t) => (2 + t) / 3)
    ),
    $(
      go.Shape,
      { toArrow: "Standard", strokeWidth: 0, stroke: "#585858", scale: 4 / 3 },
      new go.Binding("visible", "dir", (dir) => dir >= 1),
      new go.Binding("fill", "color"),
      new go.Binding("scale", "thickness", (t) => (2 + t) / 3)
    ),
    $(
      go.TextBlock,
      { alignmentFocus: new go.Spot(0, 1, -4, 0), editable: false },
      new go.Binding("text").makeTwoWay(), // TwoWay due to user editing with TextEditingTool
      new go.Binding("stroke", "color")
    )
  );

  //link templateslink
  diagram.toolManager.linkingTool.temporaryLink = $(
    go.Link,
    { layerName: "Tool" },
    $(go.Shape, { stroke: "#FF00FF", strokeWidth: 1, strokeDashArray: [4, 2] })
  );

  //--------------v
  //loop animation
  // Animate the flow in the pipes
  var animation = new go.Animation();
  animation.easing = go.Animation.EaseLinear;
  diagram.links.each((link) =>
    animation.add(link.findObject("PIPE"), "strokeDashOffset", 20, 0)
  );
  // Run indefinitely
  animation.runCount = Infinity;
  animation.start();

  //---------------------------------------

  //---------------------------------------xx

  // Show the diagram's model in JSON format
  function save() {
    document.getElementById("mySavedModel").value = diagram.model.toJson();
    diagram.isModified = false;
  }
  function load() {
    diagram.model = go.Model.fromJson(
      document.getElementById("mySavedModel").value
    );

    //zoom the diagram
    zoomSlider: new ZoomSlider(diagram);

    // Setup zoom to fit button
    document
      .getElementById("zoomToFit")
      .addEventListener("click", () => diagram.commandHandler.zoomToFit());

    document.getElementById("centerRoot").addEventListener("click", () => {
      diagram.scale = 1;
      diagram.commandHandler.scrollToPart(diagram.findNodeForKey(1));
    });

    // the Search functionality highlights all of the nodes that have at least one data property match a RegExp
    function searchDiagram() {
      // called by button
      var input = document.getElementById("mySearch");
      if (!input) return;
      diagram.focus();

      diagram.startTransaction("highlight search");

      if (input.value) {
        // search four different data properties for the string, any of which may match for success
        // create a case insensitive RegExp from what the user typed
        var safe = input.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        var regex = new RegExp(safe, "i");
        var results = diagram.findNodesByExample(
          { text: regex },
          { details: regex }
        );
        diagram.highlightCollection(results);
        // try to center the diagram at the first node that was found
        if (results.count > 0) diagram.centerRect(results.first().actualBounds);
      } else {
        // empty string only clears highlighteds collection
        diagram.clearHighlighteds();
      }

      diagram.commitTransaction("highlight search");
    }
  }

  //svg file
  // When the blob is complete, make an anchor tag for it and use the tag to initiate a download
  // Works in Chrome, Firefox, Safari, Edge, IE11
  function myCallbackSvg(blob) {
    var url = window.URL.createObjectURL(blob);
    var filename = "downSVGFile.svg";

    var a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = filename;

    // IE 11
    if (window.navigator.msSaveBlob !== undefined) {
      window.navigator.msSaveBlob(blob, filename);
      return;
    }

    document.body.appendChild(a);
    requestAnimationFrame(() => {
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  function makeSvg() {
    var svg = diagram.makeSvg({ scale: 1, background: "white" });
    var svgstr = new XMLSerializer().serializeToString(svg);
    var blob = new Blob([svgstr], { type: "image/svg+xml" });
    myCallbackSvg(blob);
  }

  //-----------------------------------------xxx

  return diagram;
}

/**
 * This function handles any changes to the GoJS model.
 * It is here that you would make any updates to your React state, which is dicussed below.
 */
function handleModelChange(changes) {
  //change alert x1
  //   alert("GoJS model changed!");
}

// render function...
function DataModel11() {
  // const [selectedNode, setSelectedNode] = React.useState(null);
  const diagramRef = React.useRef(null);

  // React.useEffect(() => {
  //   const diagram = makeWorkflow(setSelectedNode);
  //   diagram.div = diagramRef.current;
  // }, []);

  return (
    <div className="model-layout">
      <ReactDiagram
        initDiagram={initDiagram}
        divClassName="diagram-component"
        nodeDataArray={nodedatas}
        linkDataArray={linkdatas}
        onModelChange={handleModelChange}
      />
    </div>
  );
}

export default DataModel11;
