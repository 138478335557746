const DraggableActiveReducer = (state, action) => {
  switch (action.type) {
    case "EDITDRAGGABLE": {
      return {
        draggableActive1: !state.draggableActive1,
        draggableActive2: false,
        draggableActive3: false,
        draggableActive4: false,
        draggableActive5: false,
        draggableActive6: false,
      };
    }
    case "DATADRAGGABLE": {
      return {
        draggableActive1: false,
        draggableActive2: !state.draggableActive2,
        draggableActive3: false,
        draggableActive4: false,
        draggableActive5: false,
        draggableActive6: false,
      };
    }
    case "OPRUNDRAGGABLE": {
      return {
        draggableActive1: false,
        draggableActive2: false,
        draggableActive3: !state.draggableActive3,
        draggableActive4: false,
        draggableActive5: false,
        draggableActive6: false,
      };
    }
    case "GROUPDRAGGABLE": {
      return {
        draggableActive1: false,
        draggableActive2: false,
        draggableActive3: false,
        draggableActive4: !state.draggableActive4,
        draggableActive5: false,
        draggableActive6: false,
      };
    }
    case "NODEDATADRAGGABLE": {
      return {
        draggableActive1: false,
        draggableActive2: false,
        draggableActive3: false,
        draggableActive4: false,
        draggableActive5: !state.draggableActive5,
        //draggableActive6: false,
      };
    }
    case "CHATDRAGGABLE": {
      return {
        draggableActive1: false,
        draggableActive2: false,
        draggableActive3: false,
        draggableActive4: false,
        //draggableActive5: false,
        draggableActive6: !state.draggableActive6,
      };
    }
    case "NOALLDRAGGABLE": {
      return {
        draggableActive1: false,
        draggableActive2: false,
        draggableActive3: false,
        draggableActive4: false,
        draggableActive5: false,
        draggableActive6: false,
      };
    }

    default:
      return state;
  }
};
export default DraggableActiveReducer;
