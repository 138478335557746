import { useState, useEffect } from "react";
import baseUrl from "../../configs/baseurl";
import MUIDataTable from "mui-datatables";
import axios from "axios";
//mui-datatables theme
import zhCN from "date-fns/locale/zh-CN";
import "./nodestable.scss";
//editor
import Preview from "../../img/preview_black_24dp.svg";
import Edit from "../../img/edit_black_24dp.svg";
import Delete from "../../img/delete_black_24dp.svg";

const NodesTable = () => {
  // hooks
  const [nodes, setNodes] = useState([]);
  // axios
  const endpoint = `${baseUrl}/datasql/getnodes`;

  const getData = async () => {
    await axios.get(endpoint).then((response) => {
      const data = response.data;
      console.log(data);
      setNodes(data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  // columns
  const columns = [
    //id 1
    // {
    //   name: "id",
    //   lable: "ID",
    // },
    //key 2
    {
      name: "nodekey",
      lable: "节点",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          let textAlign = "center";

          return <div style={{ textAlign }}>{value}</div>;
        },
        //--
      },
    },
    //text 3
    {
      name: "text",
      lable: "内容",
    },
    //loc 4
    {
      name: "loc",
      lable: "位置",
      options: {
        display: false,
        filter: false,
      },
    },
    //figure 5
    {
      name: "figure",
      lable: "形状",
    },
    //color 6
    {
      name: "color",
      lable: "颜色",
    },
    //fill 7
    {
      name: "fill",
      lable: "填充",
    },
    //size 8
    {
      name: "size",
      lable: "尺寸",
      options: {
        display: false,
      },
    },
    //thickness 9
    {
      name: "thickness",
      lable: "粗度",
      options: {
        display: false,
      },
    },
    //group 10
    {
      name: "nodegroup",
      lable: "组别",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          let textAlign = "center";

          return <div style={{ textAlign }}>{value}</div>;
        },
      },
    },
    //isgroup 11
    {
      name: "isgroup",
      lable: "组",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          let textAlign = "center";

          return <div style={{ textAlign }}>{value}</div>;
        },
      },
    },
    //dash 12
    {
      name: "dash",
      lable: "圆角",
    },
    //image 13
    {
      name: "image",
      lable: "图片",
      options: {
        print: false,
      },
      //   options:{
      //     customBodyRender: () => <div><img src={nodes.image} className="image" alt='test' /></div>
      // }
    },
    //action 14
    {
      name: "ACTION",
      lable: "设定",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          return (
            <div className="tableCell">
              <ul>
                <li class="icon-table" id="preview">
                  <img src={Preview} alt="" />
                </li>
                <li class="icon-table edit" id="edit">
                  <img src={Edit} alt="" />
                </li>
                <li
                  class="icon-table delete"
                  id="delete"
                  // onClick={() => deleteContact(user.id)}
                >
                  <img src={Delete} alt="" />
                </li>
              </ul>
            </div>
          );
        },
      },
    },
    //status 15
    {
      name: "status",
      lable: "状态",
      options: {
        filter: false,
        empty: true,
        print: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //   console.log("deletedstatus", value);
          //   const rowData = tableMeta.rowData;
          //   const status = rowData[14];
          //   console.log("deletedstatus2", status);
          //   const buttonLabel = value === 'Active' ? 'Deactivate' : 'Activate';
          return (
            <div className="tableCell status">
              <ul>
                <li
                  class={
                    value === "Active"
                      ? "icon-table-deleted"
                      : "icon-table-deleted active"
                  }
                  id="deleted"
                  // onClick={() => deleteContact(user.id)}
                ></li>
              </ul>
            </div>
          );
        },
      },
    },
  ];

  // render data
  const options = {
    children: {
      // key 值必须与列的 name 值相同
      nodekey: "节点",
      text: "内容",
      loc: "位置",
      figure: "形状",
      color: "颜色",
      fill: "填充",
      size: "尺寸",
      thickness: "粗度",
      nodegroup: "组别",
      isgroup: "组",
      dash: "圆角",
      image: "图片",
      ACTION: "设定",
      status: "状态",
    },
    textLabels: {
      body: {
        noMatch: "没有找到匹配项",
        toolTip: "排序",
      },
      pagination: {
        next: "下一页",
        previous: "上一页",
        rowsPerPage: "每页显示行数:",
        displayRows: " 总行数",
      },
      toolbar: {
        search: "搜索",
        downloadCsv: "下载 CSV",
        print: "打印",
        viewColumns: "显示列",
        filterTable: "筛选表格",
      },
      filter: {
        all: "全部",
        title: "筛选",
        reset: "重置",
      },
      viewColumns: {
        title: "显示列",
        titleAria: "选择要显示的列",
      },
      selectedRows: {
        text: "行 已选择",
        delete: "删除",
        deleteAria: "删除所选行",
      },
    },

    //--------------
    rows: {
      height: "15px",
    },
    page: 0,
    rowsPerPage: 6,
    rowsPerPageOptions: [5, 6],
    columnTitle: (column) => column.label,
  };

  return (
    <div className="datatable">
      <MUIDataTable
        title={"Nodesdatas"}
        columns={columns}
        data={nodes}
        options={options}
        language={zhCN}
      />
    </div>
  );
};

export default NodesTable;
