import Leftlayout from "../leftlayout/Leftlayout";
import TitleTable from "../titletable/TitleTable";

const MainTitleTable = () => {
  return (
    <div className="mainTitletable">
      <div className="layouttable-left">
        <Leftlayout />
      </div>
      <div className="layouttable-right">
        <TitleTable />
      </div>
    </div>
  );
};

export default MainTitleTable;
