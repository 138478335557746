import React, { useContext, useState } from "react";
import { ReactDiagram } from "gojs-react";
import { Canvg } from "canvg";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
//model
import { diagram } from "../../components/diagram/diagrambaseview01";

import "./model1.scss";
import fullfit from "../../img/fit_screen24.svg";
import fullscreen from "../../img/fullscreen_black_24dp.svg";
import axios from "axios";
import Navbar from "../../components/navbar/Navbar";
import { withTranslation } from "react-i18next";
export class ModelDiagramPreViewNewFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "#FFFFFF",
      backgroundImageUrl: null,
      fileid: null,
      fileurl: null,
      nodeArray: [],
      linkArray: [],
      filename: "新系统名称",
      isFavoriteFilled: false,
      isStarFilled: false,
      isInfoCardOpen: true,
      isQrCodeModalOpen: false,
      isDownFilesOpen: false,
      qrCodeImageUrl: null,
      qrCodeImageString: null,
      userId: null,
      vipNo: 0,
      lastOffsetX: 0,
      lastOffsetY: 0,
    };
    //xx
    this.diagramRef = React.createRef();
    this.handleSetColorChange = this.handleSetColorChange.bind(this);
    this.handleNewFile = this.handleNewFile.bind(this);
    this.handleSaveFile = this.handleSaveFile.bind(this);
    this.saveData = this.saveData.bind(this);
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
    this.handleStarClick = this.handleStarClick.bind(this);
    this.handleQrcodeClick = this.handleQrcodeClick.bind(this);
    this.handleCloseQrCodeModal = this.handleCloseQrCodeModal.bind(this);
    this.handleDownFilesClick = this.handleDownFilesClick.bind(this);
    this.handleCloseDownFiles = this.handleCloseDownFiles.bind(this);
  }

  // setuserid
  setUserId = (userId) => {
    this.setState({ userId });
  };

  // setvipNo
  setVipNo = (vipNo) => {
    this.setState({ vipNo });
  };

  //initdiagram
  initDiagram(backgroundImageUrl) {
    // console.log("Background image URL init", backgroundImageUrl);
    return diagram({ backgroundImageUrl });
  }

  // set background color
  handleSetColorChange = (color) => {
    this.setState({ backgroundColor: color });
   // console.log("backgroundColor view:", color);
  };

  //favorite likes and star keeps click events
  //likes
  handleFavoriteClick = async () => {
    // 使用 URLSearchParams 获取 URL 中的参数
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    const { userId } = this.state;

    this.setState(
      (prevState) => ({ isFavoriteFilled: !prevState.isFavoriteFilled }),
      async () => {
        const { isFavoriteFilled } = this.state;
       // console.log("setlikes:", isFavoriteFilled, fileid, userId);

        try {
          if (isFavoriteFilled) {
            // 发送请求更新数据库
            const response = await fetch(
              `${baseUrl}/userapi/setaddfavoritefiles/${userId}`,
              {
                method: "POST",
                body: JSON.stringify({ fileid }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.ok) {
             // console.log("Favorite file updated successfully");
            } else {
              console.error("Failed to update favorite file");
            }
          } else {
            // 发送请求删除数据库中的数据
            const response = await fetch(
              `${baseUrl}/userapi/setdeletefavoritefiles/${userId}`,
              {
                method: "POST",
                body: JSON.stringify({ fileid }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (response.ok) {
             // console.log("Favorite file deleted successfully");
            } else {
              console.error("Failed to delete favorite file");
            }
          }
        } catch (error) {
          console.error("Error while updating favorite file:", error);
        }
      }
    );
  };

  //keeps set or deleted files
  handleStarClick = async () => {
    // this.setState({ isStarFilled: !this.state.isStarFilled });

    // 使用 URLSearchParams 获取 URL 中的参数
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    const { userId } = this.state;

    this.setState(
      (prevState) => ({ isStarFilled: !prevState.isStarFilled }),
      async () => {
        const { isStarFilled } = this.state;
       // console.log("setkeeps:", isStarFilled, fileid, userId);

        try {
          if (isStarFilled) {
            // 发送请求更新数据库
            const response = await fetch(
              `${baseUrl}/userapi/setaddkeepfiles/${userId}`,
              {
                method: "POST",
                body: JSON.stringify({ fileid }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.ok) {
             // console.log("Favorite file updated successfully");
            } else {
              console.error("Failed to update favorite file");
            }
          } else {
            // 发送请求删除数据库中的数据
            const response = await fetch(
              `${baseUrl}/userapi/setdeletekeepfiles/${userId}`,
              {
                method: "POST",
                body: JSON.stringify({ fileid }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (response.ok) {
              //console.log("Favorite file deleted successfully");
            } else {
              console.error("Failed to delete favorite file");
            }
          }
        } catch (error) {
          console.error("Error while updating favorite file:", error);
        }
      }
    );
  };

  //zoom center
  zoomToFitDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    // console.log("diagram1:", diagram);
    diagram.commandHandler.zoomToFit();
  };

  //center root
  centerRootDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    diagram.centerRect(diagram.documentBounds);
  };

//
  performZoomAndCenter = () => {
    this.zoomToFitDiagram();
    setTimeout(() => {
      this.centerRootDiagram();
    }, 0);
  };

  //scale1 cente root
  scaleRootDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    diagram.scale = 0.8;
    setTimeout(() => {
      // this.zoomToFitDiagram();
      this.centerRootDiagram();
    }, 0);
  };

  //userid change event
  handleUserIdlocal = async () => {
    const loginuser = localStorage.getItem("user");
    // console.log("loginuser stor current:", loginuser);
    const currentUser = loginuser;
   // console.log("loginuser stor current2:", currentUser);
   if (!currentUser) {
      return;
    }

    try {
      const response = await fetch(
        `${baseUrl}/userapi/uservipgrade/${currentUser}`
      );

      if (!response.ok) {
        // 处理请求失败的情况
        const { message } = await response.json();
        console.error(`Failed to fetch user VIP grade: ${message}`);
        return;
      }

      const { userid, vip_no } = await response.json();
      // console.log("sql userid xxx:", userid);
      // console.log("sql userid xxx:", vip_no);

      // 调用 setUserId 方法，将获取到的 userId 设置到组件状态中
      this.setUserId(userid);
      this.setVipNo(vip_no);

      // 在获取 userid 之后，再获取 fileid 并进行likes检查
      // 使用 URLSearchParams 获取 URL 中的参数
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid");

     // console.log("check likes and keeps:", fileid, userid);
      try {
        const response = await fetch(
          `${baseUrl}/userapi/checkfavoritefile/${userid}/${fileid}`
        );
        const data = await response.json();

        const isFavoriteFilled = data.exists;
        this.setState({ isFavoriteFilled });
      } catch (error) {
        console.error("Error while checking favorite file:", error);
      }

      // 在获取 userid 之后，再获取 fileid 并进行keeps检查
      try {
        const response = await fetch(
          `${baseUrl}/userapi/checkkeepfile/${userid}/${fileid}`
        );
        const data = await response.json();

        const isStarFilled = data.exists;
        this.setState({ isStarFilled });
      } catch (error) {
        console.error("Error while checking favorite file:", error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //create a new diagram file
  handleNewFile = async () => {
    const vipno = this.state.vipNo;

    if (vipno > 1) {
      //create a new diagram
      try {
       // console.log("N1新建文件");
        const response = await axios.post(`${baseUrl}/fileapi/createfile`, {
          filename: this.state.filename, // 将文件名值传递给后端
          userid: this.state.userId,
          // ...
        });
        const { fileid, fileurl } = response.data; // 假设后端返回一个包含ID和链接的对象

        // console.log("N返回fileid", fileid);
        // console.log("N返回fileurl", fileurl);
        // console.log("N返回data", response.data.nodeArray);
        // console.log("N返回data", response.data.linkArray);

        // 更新组件状态
        this.setState({ fileid, fileurl }, () => {
          // 状态更新完成后执行的回调函数，访问fileId和fileUrl
          // console.log("新建文件", this.state);
         // console.log("N2新建文件夹fileid", fileid);
          // 保存文件和数据
          this.handleSaveFile();
          // this.saveData();
        });

        const Currentfileid = fileid;
       // console.log("N2新建文件Currentfileid", Currentfileid);
        if (Currentfileid) {
          // 根据返回的链接打开新的页面
          window.open(fileurl, "_blank");
        } else {
          window.open(fileurl, "_self");
        }

        // const params = new URLSearchParams(window.location.search);
        // const Currentfileid = params.get("fileid");
        // console.log("N2新建文件Currentfileid", Currentfileid);
        // if (Currentfileid) {
        //   // 根据返回的链接打开新的页面
        //   window.open(fileurl, "_blank");
        // } else {
        //   window.open(fileurl, "_self");
        // };
      } catch (error) {
        console.error(error);
        // 处理错误
      }
    } else {
      return;
    }
  };

  // load data from server datajson file 基本模型加载 模板
  loadData = () => {
   // console.log("L加载数据NO FILEID");
    // 加载数据
    fetch(`${baseUrl}/datajson/nodes`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          // nodeData: data,
          nodeArray: data,
        });
        //console.log("L加载数据nodeArray:", data);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(`${baseUrl}/datajson/links`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          linkArray: data,
          // linkData: data,
        });
       // console.log("L加载数据linkArray:", data);
      })
      .catch((error) => {
        console.log(error);
      });

    //center zoomtofit and loaddata------
    setTimeout(() => {
      this.zoomToFitDiagram();
      // this.scaleRootDiagram();
    }, 0);
  };

  //loaddatafileid  有加判断fileid是否存在 加载独立模型
loadDatafileid = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
    // console.log("加载数据HAVE FILEID", fileid);

    if (fileid) {
      try {
        const response = await fetch(`${baseUrl}/fileapi/${fileid}/load`);
        const data = await response.json();

        const nodeArray = data.nodeArray.map((node) => {
          if (!node.category) {
            node.category = "TextNode";
          }
          return node;
        });

        this.setState({
          nodeArray: data.nodeArray,
          linkArray: data.linkArray,
        });

        // console.log("加载数据 Folder", data.nodeArray);
        // console.log("加载数据 Folder", data.linkArray);

        this.performZoomAndCenter();
      } catch (error) {
        console.error("Error loading data: ", error);
      }
    } else {
      this.loadData();
    }
  };

  //save create file and data file folder
  handleSaveFile = async () => {
    try {
     // console.log("H1保存新文件数据");
      const diagram = this.diagramRef.current.getDiagram();

      diagram.addModelChangedListener((e) => {
        if (
          e.isTransactionFinished &&
          (e.modelChange === "nodeDataArray" ||
            e.modelChange === "linkDataArray")
        ) {
          this.handleNodeDataChange(e);
        }

        // console.log("diagram.modelnode:", diagram.model.nodeDataArray);
        // console.log("diagram.modellink:", diagram.model.linkDataArray);
      });

      const nodeDatachanged = diagram.model.nodeDataArray;
      const linkDatachanged = diagram.model.linkDataArray;

      // save data to json file--------------
      const { fileid, fileurl } = this.state;
     // console.log("H2diagramfileid:", fileid);

      const data = {
        nodeArray: nodeDatachanged.map((node) => ({ ...node, fileid })),
        linkArray: linkDatachanged.map((link) => ({ ...link, fileid })),
        fileid,
        fileurl,
      };
      // console.log("diagram.modeldata:", data);

      //fileapi saveData  var response
      const response = await axios.post(
        `${baseUrl}/fileapi/${fileid}/save`,
        data
      );
     // console.log("H3文件夹保存NO FILEID");

      //fileid 没有情况下保存到JSON
      if (!fileid) {
        //datajson save from api file
        fetch(`${baseUrl}/datajson/nodes`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data.nodeArray, null, 2),
        })
          .then((response) => {
            // console.log(response);
           // console.log("H4文件夹保存HAVE FILEID");
           // console.log("H4SavedataNodeArray", data.nodeArray);
          })
          .catch((error) => {
            console.log(error);
          });
        fetch(`${baseUrl}/datajson/links`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data.linkArray, null, 2),
        })
          .then((response) => {
            // console.log(response);
           // console.log("H4SavedataLinkArray", data.linkArray);
          })
          .catch((error) => {
            console.log(error);
          });
        // save data to json file end--------------
      }

      //axios post nodedatas and linkdatas mysql

      //nodedatas from diagram
      const postnodes = JSON.stringify(data.nodeArray, null, 2);
      const postnodesarray = Array.from(JSON.parse(postnodes));
      //linkdatas from diagram
      const postlinks = JSON.stringify(data.linkArray, null, 2);
      const postlinksarray = Array.from(JSON.parse(postlinks));

      const items = postnodesarray;
      const linkitems = postlinksarray;
      // console.log("data.postnodes22", items);
      // console.log("data.postlinks22", linkitems);

      // nodes is definition
      const dataTitle = items.map(
        ({
          key,
          loc,
          text,
          figure,
          color,
          size,
          thickness,
          group,
          isGroup,
          fill,
          dash,
          fileid,
        }) => ({
          key: key || null,
          loc: loc || null,
          text: text || null,
          figure: figure || null,
          color: color || null,
          size: size || null,
          thickness: thickness || null,
          group: group || null,
          isgroup: isGroup ? "true" : "false",
          fill: fill || null,
          dash: dash || null,
          fileid: fileid,
        })
      );
      // console.log("data.postnodes2", dataTitle);
      // links is definition

      const dataLinks = linkitems.map(
        ({
          from,
          to,
          points,
          text,
          fromSpot,
          toSpot,
          color,
          thickness,
          dash,
          dir,
          routing,
          fileid,
        }) => {
          //-----set routing values --------------------------------
          let routingValue = null;
          if (routing === "Normal") {
            routingValue = "Normal";
          } else if (routing === "Orthogonal") {
            routingValue = "Normal2";
          } else if (routing === "AvoidsNodes") {
            routingValue = "Normal3";
          } else if (routing === "null") {
            routingValue = "Normal4";
          }
          return {
            from: from || null,
            to: to || null,
            points: JSON.stringify(points) || null,
            text: text || null,
            fromspot: fromSpot || null,
            tospot: toSpot || null,
            color: color || null,
            thickness: thickness || null,
            dash: JSON.stringify(dash) || null,
            dir: JSON.stringify(dir) || null,
            routing: routingValue || null,
            fileid: fileid,
          };
        }
      );
      // console.log("data.postlinks2", dataLinks);

      //nodedata key change event (key、 group crash event）
      const newNodes = dataTitle.map((item) => {
        return {
          ...item,
          nodekey: item.key,
          key: undefined,
          nodegroup: item.group,
          group: undefined,
        };
      });

     // console.log("data.postnodes3", newNodes);
      // post nodedatas api-------------------
      fetch(`${baseUrl}/datasql/postnodes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newNodes),
      });

      // post nodedatas api-------------------
      fetch(`${baseUrl}/datasql/postlinks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataLinks),
      })
        .then((response) => {
          if (response.ok) {
           // console.log("Data written to MySQL database");
          } else {
            throw new Error("Network response was not ok");
          }
        })
        .catch((error) => {
          console.error("Error writing data to MySQL database:", error);
        });
      // //save data to database sql end
    } catch (error) {
      console.error(error);
      // 处理错误
    }
  };

  // save model updata data have saved fields
  saveData = async () => {
    //fileapi saveData
    //获取fileid
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("1diagramfileid:", fileid);

   // console.log("2保存数据s");
    //save datajson no filefolder
    const diagram = this.diagramRef.current.getDiagram();
    // console.log("diagram:", diagram);
    diagram.addModelChangedListener((e) => {
      if (
        e.isTransactionFinished &&
        (e.modelChange === "nodeDataArray" || e.modelChange === "linkDataArray")
      ) {
        this.handleNodeDataChange(e);
      }

      // console.log("diagram.modelnode:", diagram.model.nodeDataArray);
      // console.log("diagram.modellink:", diagram.model.linkDataArray);
    });

    var nodeDatachanged = diagram.model.nodeDataArray;
    var linkDatachanged = diagram.model.linkDataArray;

    const nodeDataWithFileid = nodeDatachanged.map((node) =>
      node.fileid ? node : { ...node, fileid }
    );
    const linkDataWithFileid = linkDatachanged.map((link) =>
      link.fileid ? link : { ...link, fileid }
    );

    // const fileid = this.state.fileid;
    // console.log("fileid:", fileid);
    // save data to json file--------------
    const data = {
      nodeArray: nodeDataWithFileid,
      linkArray: linkDataWithFileid,
    };
    // console.log("diagram.modeldata:", data);

    //fileid有值的时候才存入数据库
    if (!fileid) {
     // console.log("4fileid不存在");
      //datajson save from api file
      fetch(`${baseUrl}/datajson/nodes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data.nodeArray, null, 2),
      })
        .then((response) => {
          // console.log(response);
         // console.log("3SavedataNodeArray", data.nodeArray);
        })
        .catch((error) => {
          console.log(error);
        });
      fetch(`${baseUrl}/datajson/links`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data.linkArray, null, 2),
      })
        .then((response) => {
          // console.log(response);

         // console.log("3SavedataLinkArray", data.linkArray);
        })
        .catch((error) => {
          console.log(error);
        });
      // save data to json file end--------------

      //返回
      return;
    }

    //save data to json file folder have fileid
    // save data to json file--------------
   // console.log("SFdiagramfileid:", fileid);
   // console.log("SFdiagram.modeldata:", data);

    //fileapi saveData  var response
    const response = await axios.post(
      `${baseUrl}/fileapi/${fileid}/save`,
      data
    );
   // console.log("H3文件夹保存NO FILEID");

    //axios post nodedatas and linkdatas mysql

    //nodedatas from diagram
    const postnodes = JSON.stringify(data.nodeArray, null, 2);
    const postnodesarray = Array.from(JSON.parse(postnodes));
    //linkdatas from diagram
    const postlinks = JSON.stringify(data.linkArray, null, 2);
    const postlinksarray = Array.from(JSON.parse(postlinks));

    const items = postnodesarray;
    const linkitems = postlinksarray;
    // console.log("data.postnodes22", items);
    // console.log("data.postlinks22", linkitems);

    // nodes is definition
    const dataTitle = items.map(
      ({
        key,
        loc,
        text,
        figure,
        color,
        size,
        thickness,
        group,
        isGroup,
        fill,
        dash,
        fileid,
      }) => ({
        key: key || null,
        loc: loc || null,
        text: text || null,
        figure: figure || null,
        color: color || null,
        size: size || null,
        thickness: thickness || null,
        group: group || null,
        isgroup: isGroup ? "true" : "false",
        fill: fill || null,
        dash: dash || null,
        fileid: fileid,
      })
    );
    // console.log("data.postnodes2", dataTitle);
    // links is definition

    const dataLinks = linkitems.map(
      ({
        from,
        to,
        points,
        text,
        fromSpot,
        toSpot,
        color,
        thickness,
        dash,
        dir,
        routing,
        fileid,
      }) => {
        //-----set routing values --------------------------------
        let routingValue = null;
        if (routing === "go.Link.Normal") {
          routingValue = "Normal";
        } else if (routing === "go.Link.Orthogonal") {
          routingValue = "Normal2";
        } else if (routing === "go.Link.Bezier") {
          routingValue = "Normal3";
        }
        return {
          from: from || null,
          to: to || null,
          points: JSON.stringify(points) || null,
          text: text || null,
          fromspot: fromSpot || null,
          tospot: toSpot || null,
          color: color || null,
          thickness: thickness || null,
          dash: JSON.stringify(dash) || null,
          dir: JSON.stringify(dir) || null,
          routing: routingValue || null,
          fileid: fileid,
        };
      }
    );
    // console.log("data.postlinks2", dataLinks);

    //nodedata key change event (key、 group crash event）
    const newNodes = dataTitle.map((item) => {
      return {
        ...item,
        nodekey: item.key,
        key: undefined,
        nodegroup: item.group,
        group: undefined,
      };
    });

    // console.log("data.postnodes3", newNodes);
    // post nodedatas api-------------------
    fetch(`${baseUrl}/datasql/postnodes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newNodes),
    });

    // post nodedatas api-------------------
    fetch(`${baseUrl}/datasql/postlinks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataLinks),
    })
      .then((response) => {
        if (response.ok) {
         // console.log("SF Data written to MySQL database");
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error writing data to MySQL database:", error);
      });
    //xxxxxxxxxxxxxxxxxxxxxx
  };

  //qrcode generation and upload 2
  handleCloseQrCodeModal() {
    this.setState({ isQrCodeModalOpen: false });
  }

  //downfiles close
  handleCloseDownFiles() {
    this.setState({ isDownFilesOpen: false });
  }

  //DownFiles click
  handleDownFilesClick() {
    this.setState({ isDownFilesOpen: !this.state.isDownFilesOpen });
  }

  handleQrcodeClick = async (event) => {
    event.preventDefault();
    const url = window.location.href;
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("1diagramfileid:", fileid);
   // console.log("qrcode点击", url);
    fetch(`${baseUrl}/qrcodeapi/qrcode/${fileid}`, {
      method: "POST",
      body: JSON.stringify({ url: url }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.text())
      .then((svg) => {
        const img = document.createElement("img");
        img.src = `data:image/svg+xml;base64,${btoa(svg)}`;
        // document.body.appendChild(img);
        this.setState({ qrCodeImageUrl: img.src });
        this.setState({ qrCodeImageString: svg });
        this.setState({ isQrCodeModalOpen: !this.state.isQrCodeModalOpen });
      })
      .catch((error) => console.error(error));
  };

  //load file backgroundcolor
  loadBackgroundColor = async () => {
    // const url = window.location.href;
    try {
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid");
     // console.log("fileid X", fileid);
      const response = await axios.get(
        `${baseUrl}/fileapi/getbasecolor/${fileid}`
      );
      this.setState({ backgroundColor: response.data.basecolor });
     // console.log("basecolor X", response.data.basecolor);
    } catch (error) {
      console.error(error);
    }
  };

  //mousemove diagram movement
  handleMouseMove = (e) => {
    const diagram = this.diagramRef.current.getDiagram();
    const offsetX = e.movementX * 0.3;
    const offsetY = e.movementY * 0.3;

    const newPosition = diagram.position.copy();
    newPosition.x += offsetX;
    newPosition.y += offsetY;
    diagram.position = newPosition;
  };

  //load file backgroundImage
loadBackgroundImage = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const fileid = params.get("fileid");
      // console.log("fileid X", fileid);
      const response = await axios.get(
        `${baseUrl}/fileapi/getimageurls/${fileid}`
      );

      if (response.data.imageUrls && response.data.imageUrls[0]) {
        const imageUrl = response.data.imageUrls[0];
        // console.log("imageUrl X", imageUrl);
        const publicIndex = imageUrl.indexOf("public");
        const relativePath = imageUrl.slice(publicIndex + "public".length);
        const imagePath = relativePath.replace(/\\/g, "/");
        const newimagePath = `${baseUrl}${imagePath}`;
        // console.log("baseimageUrl", newimagePath);
        this.setState({ backgroundImageUrl: newimagePath });
      } else {
        // When response.data.imageUrls[0] is null, return null
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  //life cycle
  componentDidMount() {
    // 加载数据

    this.loadDatafileid();
    // console.log("CD加载数据ok");

    //获取用户id
    this.handleUserIdlocal();

    // 获取最新的背景色
    this.loadBackgroundColor();

    // 获取最新的背景图片
    this.loadBackgroundImage();

    // //
    // this.zoomToFitDiagram();

    //mousemove diagram movement
    const diagram = this.diagramRef.current.getDiagram();
    const diagramDiv = diagram.div;

    diagramDiv.addEventListener("mousemove", this.handleMouseMove);
  }

  // componentWillUnmount() {
  //   const diagram = this.diagramRef.current.getDiagram();
  //   const diagramDiv = diagram.div;

  //   diagramDiv.removeEventListener("mousemove", this.handleMouseMove);
  // }

  componentDidUpdate() {
    //center zoomtofit after loaddata------
    // this.centerRootDiagram();
    // console.log("加载center");
  }

  render() {
    // function initDiagram() {
    //   // console.log("初始化");

    //   return diagram();
    // }

    function handleModelChange(changes) {
      // console.log("模型更改:", changes);
    }

    // //setbackgroundcolor
    // console.log("onColorChange:", this.handleSetColorChange);
    const { backgroundColor, backgroundImageUrl } = this.state;
    const layoutStyle = {
      backgroundColor,

      //backgroundimage html show
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      display: "flex",
    };
	const { t } = this.props;
    // 渲染组件
    return (
      <div className="model-layout" style={layoutStyle}>
        <ReactDiagram
          ref={this.diagramRef}
          initDiagram={this.initDiagram}
          // initDiagram={initDiagram}
          divClassName="diagram-component"
          nodeDataArray={this.state.nodeArray}
          linkDataArray={this.state.linkArray}
          backgroundImageUrl={this.state.backgroundImageUrl}
          // onModelChange={handleModelChange}
          onMouseMove={this.handleMouseMove}
        ></ReactDiagram>
        {/* <Navbar handleFileNameChange={this.handleFileNameChange} /> */}
        <div className="contentside">
          <ul className="options part2">
            <li
              className="option tool fileDownload"
              id="fileDownload"
              title={t("adminmodelnewfile.side-option10")}
              onClick={this.zoomToFitDiagram}
            >
              <img src={fullscreen} alt="" />
              {/* <img src={fullfit} alt="" /> */}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ModelDiagramPreViewNewFile);
