import { createContext, useReducer } from "react";
import LeftLayoutReducer from "./leftLayoutReducer";

const INITIAL_STATE = {
  leftLayout: true,
};

export const LeftLayoutContext = createContext(INITIAL_STATE);

export const LeftLayoutContextProvider = ({ children }) => {
  const [state, dispatch01] = useReducer(LeftLayoutReducer, INITIAL_STATE);
  return (
    <LeftLayoutContext.Provider
      value={{
        leftLayout: state.leftLayout,
        dispatch01,
      }}
    >
      {children}
    </LeftLayoutContext.Provider>
  );
};
