import ClientModel11 from "../../pages/project01/clientModel11";
const MainLayoutView = () => {
  return (
    <div className="mainlayout">
      <ClientModel11 />
    </div>
  );
};

export default MainLayoutView;
