import Leftlayout from "../leftlayout/Leftlayout";
import LinksTable from "../relatetable/LinksTable";

const MainLinkTable= () => {
    return ( 
        <div className="mainlinktable">
            <div className="layouttable-left">
            <Leftlayout/>
            </div>
            <div className="layouttable-right">
            <LinksTable/>
            </div>
        </div>
        );
}
 
export default MainLinkTable;