import React, { useState, useEffect } from "react";
import search from "../../img/search_black_24dp.svg";
import close from "../../img/close_black_24dp.svg";
import qrscanner from "../../img/qr_code_scanner_opsz24.svg";
import favorite from "../../img/favorite_border_black_24dp.svg";
import favoritefill from "../../img/favorite_FILL24.svg";
import starfill from "../../img/star_fll_24dp.svg";
import star from "../../img/star_outline_black_24dp.svg";
import nodatasad from "../../img/nodatasad.svg";
import dataloading from "../../img/loading_2.gif";
import registereduserSvg from "../../images/userimages/registereduser.svg";
import "./filesearch.scss";

import axios from "axios";
import jsQR from "jsqr";

import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
import frontUrl from "../../configs/fronturl";
import { useTranslation } from "react-i18next";
function FileSearch(props) {
  const { t } = useTranslation();
  const [searchKeywords, setSearchKeywords] = useState("");
  //  const [isStarFilled, setIsStarFilled] = useState(false);
  const [fileItems, setFileItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleKeywordSearch = async () => {
    try {
      // 将输入的关键词按空格拆分成数组
      const keywordsArray = searchKeywords.split(" ");

      await new Promise((resolve) => setTimeout(resolve, 500));

      // 发送搜索请求到后端
      const response = await axios.get(
        `${baseUrl}/fileapi/searchFiles?keywords=${JSON.stringify(
          keywordsArray
        )}`
      );

      // 提取搜索结果中的 origuser 列表
      const origuserList = response.data.map((item) => item.origuser);

      // 发起请求获取用户头像和用户名
      const userImages = await Promise.all(
        origuserList.map(async (origuser) => {
          const userResponse = await fetch(
            `${baseUrl}/fileapi/getUserImage/${origuser}`
          );
          if (userResponse.ok) {
            const userData = await userResponse.json();
            return userData;
          } else {
            return null;
          }
        })
      );

      // 合并搜索结果和用户头像/用户名
      const fileItemsWithUserImages = response.data.map((item, index) => ({
        ...item,
        userImage: userImages[index]?.pic_url,
        userName: userImages[index]?.userName,
      }));

      setFileItems(fileItemsWithUserImages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setSearchKeywords(event.target.value);
    // console.log("event.target.value", event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleKeywordSearch();
    }
  };

  const handleClearInput = () => {
    setSearchKeywords(""); // 清空输入框内容
  };

  useEffect(() => {
    // 在组件挂载时获取文件列表数据
    async function fetchFileItems() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        const response = await fetch(`${baseUrl}/fileapi/getFileItems`); // 发起获取文件列表数据的请求
        if (response.ok) {
          const data = await response.json();

          // 提取 origuser 值
          const origuserList = data.map((item) => item.origuser);

         // console.log("origuserList", origuserList);

          // 发起请求获取用户头像 URL
          const userImages = await Promise.all(
            origuserList.map(async (origuser) => {
              const userResponse = await fetch(
                `${baseUrl}/fileapi/getUserImage/${origuser}`
              );
              if (userResponse.ok) {
                const userData = await userResponse.json();
                return userData;
              } else {
                return null;
              }
            })
          );

          // 合并文件列表数据和用户头像 URL
          const fileItemsWithUserImages = data.map((item, index) => ({
            ...item,
            userImage: userImages[index]?.pic_url,
            userName: userImages[index]?.userName,
          }));

          setFileItems(fileItemsWithUserImages); // 设置包含用户头像的文件列表数据到 state
        } else {
          console.error("Failed to fetch file items");
        }
      } catch (error) {
        console.error(error);
      } finally {
      setIsLoading(false);
    }
    }

    fetchFileItems();
  }, []);

//scan qr-code
  const handleScannerClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, image.width, image.height);
          const imageData = context.getImageData(
            0,
            0,
            image.width,
            image.height
          );
          const code = jsQR(imageData.data, image.width, image.height);
          if (code && code.data.startsWith("http")) {
            console.log("code.data", code.data);
              window.location.href = code.data;
           // window.open(code.data, "_blank");
          } else {
           // alert("未识别到有效的网址二维码。");
          }
        };
        image.src = event.target.result;
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  return (
    <>
      <div className="filesearchBox">
        <div className="search">
          <img
            className="searchimg"
            src={search}
            alt=""
            onClick={handleKeywordSearch}
          />
        </div>
        <div className="searchInput">
          <input
            className="inputkey"
            type="text"
            placeholder={t("filesearch.search-placeholder")}
            value={searchKeywords}
            onChange={handleInputChange}
            onKeyPress={handleEnterKeyPress}
            autoComplete="off"
          />
        </div>
        <div className="searchright">
          <div className="scanner" onClick={handleScannerClick}>
            <img className="scannerimg" src={qrscanner} alt="" />
          </div>
          <div className="close">
            <img
              className="closeimg"
              src={close}
              alt=""
              onClick={handleClearInput}
            />
          </div>
        </div>
      </div>
      <div className="searchcontent">
        <div className="contentlayout">
          <div className="contentitems">
          {isLoading ? (
              <div className="loadingmessage">
                <img src={dataloading} alt="Loading..." />
                {t("filesearch.search-loadingmessage")}
              </div>
            ) : fileItems.length === 0 ? (
              <div className="noresults">
                <img src={nodatasad} alt="No Data" />
                {t("filesearch.search-noresults")}
              </div>
            ) : (
            fileItems.map((item, index) => (
              <div className="fileitemslayout" key={item.fileid}>
                <div className="fileitemshow">
                  <iframe
                    src={`${frontUrl}/view3?fileid=${item.fileid}`}
                    // title={`Preview of File ${item.fileid}`}



                    frameBorder="0"
                    width="100%"
                    height="100%"
                    scrolling="no"
                  ></iframe>
                </div>
                <a
                  href={`${frontUrl}/view1?fileid=${item.fileid}`}
                  target="_blank" // 在新标签页中打开链接
                  className="fileitemname"
                  title={t("filesearch.search-filenametitle")}
                >
                  {index + 1} . {item.filename}
                </a>
                <div className="fileiteminfo">
                  <div className="fileinfolike">
                    <img src={favoritefill} alt="" />

                    {/* {isFavoriteFilled ? (
                      <img src={favoritefill} alt="" />
                    ) : (
                      <img src={favorite} alt="" />
                    )} */}
                  </div>
                  <span className="filelikenum">{item.filelike}</span>
                  <div className="fileinfokeep">
                    <img src={starfill} alt="" />
                  </div>
                  <span className="filekeepnum">{item.filekeep}</span>
                  <div className="fileinfouser">
                    <img
                      src={
                        item.userImage
                          ? `${baseUrl}${item.userImage}?${Math.random()}`
                          : registereduserSvg
                      }
                      alt=""
                      className="fileuserimage "
                      title={item.userName}
                    />
                  </div>
                </div>
              </div>
            ))
           )}
          </div>
        </div>
      </div>
      <div className="overlayfile" onDoubleClick={props.onOverlayClick}></div>
    </>
  );
}

export default FileSearch;
