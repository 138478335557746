import React, { useState } from "react";
import baseUrl from "../../configs/baseurl";
import Draggable from "react-draggable";
import "./qrcodepopup.scss";

import closeButton2 from "../../img/close_black_24_2.svg";
import clouddownload from "../../img/cloud_download_black_24dp.svg";
import { useTranslation } from "react-i18next";
//
function copySvgToClipboard(svgString) {
  console.log("svgString", svgString);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const svgImg = new Image();

  svgImg.onload = function () {
    canvas.width = svgImg.width;
    canvas.height = svgImg.height;
    ctx.drawImage(svgImg, 0, 0);

    canvas.toBlob((blob) => {
      navigator.clipboard
        .write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ])
        .then(() => {
          console.log("QR Code image copied to clipboard!");
        })
        .catch((error) => {
          console.error("Failed to copy QR Code image to clipboard:", error);
        });
    }, "image/png");
  };

  svgImg.src = "data:image/svg+xml," + encodeURIComponent(svgString);
}

//
async function downloadQrCode() {
  const qrCodeImg = document.querySelector(".qrcodepic");
  const filename = await getFileName(); // 获取文件名

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = qrCodeImg.naturalWidth;
  canvas.height = qrCodeImg.naturalHeight;
  context.drawImage(qrCodeImg, 0, 0);

  const link = document.createElement("a");
  link.href = canvas.toDataURL("image/png");
  link.download = filename; // 使用文件名作为下载文件的名称
  link.click();
}

async function getFileName() {
  const params = new URLSearchParams(window.location.search);
  const fileid = params.get("fileid");

  let filename = "Qrcode.png"; // 默认文件名

  if (window.location.href.includes("view")) {
    filename = filename.replace(".png", "V.png");
  } else if (window.location.href.includes("data")) {
    filename = filename.replace(".png", "D.png");
  } else if (window.location.href.includes("edit")) {
    filename = filename.replace(".png", "E.png");
  }

  try {
    const response = await fetch(`${baseUrl}/fileapi/getfilename/${fileid}`);
    const data = await response.json();

    if (response.ok && data.success) {
      filename = data.filename + filename; // 使用从服务器获取的文件名并添加后缀
      // console.log("QrCodeImagefilename", filename);
    }
  } catch (error) {
    console.error("Failed to get filename:", error);
  }

  return filename;
}

export default function QrCodeModal(props) {
	const { t } = useTranslation();
  function handleQrcodeCopy() {
    // console.log("QRCodePic");
    // console.log("QrCodeImageString", props.qrCodeImageString);

    copySvgToClipboard(props.qrCodeImageString);
  }
  return (
    <Draggable cancel=".qrcodecard img,.qrcodebuttons.qrcodebutton">
      <div className="qrcodecard">
        <img className="qrcodepic" title={t("qrcodepopup.qrcode-title")} src={props.qrCodeImageUrl} alt="QR Code" />
        {/* <button className="qrcodebutton copy" onClick={handleQrcodeCopy}>
          复制
        </button> */}
        <div className="qrcodebuttons">
          <button
            className="qrcodebutton download"
            title={t("qrcodepopup.qrcode-title1")}
            onClick={downloadQrCode}
          >
            <img className="clouddownload" src={clouddownload} alt="" />
          </button>
          <button
            className="qrcodebutton close"
            title={t("qrcodepopup.qrcode-title2")}
            onClick={props.onClose}
          >
            <img className="closeButton2" src={closeButton2} alt="" />
          </button>
        </div>
      </div>
    </Draggable>
  );
}
