const iconList = {
    iconlist: [
      {
        id: 1,
        title: "图标01",
      },
      {
        id: 2,
        title: "图标02",
      },
      {
        id: 3,
        title: "图标03",
      },
      {
        id: 4,
        title: "图标04",
      },
      {
        id: 5,
        title: "图标05",
      },
      {
        id: 6,
        title: "图标06",
      },
    ],
    getList: function () {
      return (
        (localStorage.getItem("theList") &&
          JSON.parse(localStorage.getItem("theList"))) ||
        this.iconlist
      );
    },
    saveList: (iconlist) => {
      localStorage.setItem("theList", JSON.stringify(iconlist));
    },
  };
  
  export default iconList;
  