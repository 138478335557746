import Navbar from "../../components/navbar/Navbar";
// import Leftlayout from "../../components/leftlayout/Leftlayout";
// import Sidebar from "../../components/sidebar/Sidebar";
import LoginPopup from "../login/Login";
import NoticePopup from "../../components/popupfiles/noticepopup/noticePopup";
import ChatPopup from "../../components/popupfiles/chatpopup/chatPopup";
import MainLinkTable from "../../components/mainlayout/mainlinktable";

const LinkTable = () => {
  return (
    <div className="LinkTable">
      <Navbar />
      {/* <Leftlayout /> */}
      <LoginPopup />
      <NoticePopup />
      <ChatPopup />
      <MainLinkTable />
    </div>
  );
};

export default LinkTable;
