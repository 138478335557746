import React from "react";
import { ReactDiagram } from "gojs-react";
import { diagram } from "../../components/diagram/diagrambase01";

import "./model1.scss";

import autorenew from "../../img/autorenew_black_24dp.svg";
import backup from "../../img/backup_black_24dp.svg";
import qrcode from "../../img/qr_code_black_24dp.svg";
import filedownload from "../../img/file_download_black_24dp.svg";
import favorite from "../../img/favorite_border_black_24dp.svg";
import star from "../../img/star_outline_black_24dp.svg";
import centerfocus from "../../img/center_focus_weak_black_24dp.svg";
import fullscreen from "../../img/fullscreen_black_24dp.svg";
import fullfit from "../../img/fit_screen24.svg";
import gridview from "../../img/grid_view_black_24dp.svg";
import newfile from "../../img/new_window24.svg";

import axios from "axios";
import SearchBox from "../../components/sidebar/SearchBox";

export class ModelDiagram1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nodeArray: [],
      linkArray: [],
    };
    //xx
    this.diagramRef = React.createRef();
  }

  //zoom center
  zoomToFitDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    // console.log("diagram1:", diagram);
    diagram.commandHandler.zoomToFit();
  };

  //center root
  centerRootDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    diagram.centerRect(diagram.documentBounds);
  };

  //scale1 cente root
  scaleRootDiagram = () => {
    const diagram = this.diagramRef.current.getDiagram();
    diagram.scale = 0.8;
    setTimeout(() => {
      this.centerRootDiagram();
    }, 0);
  };

  //handleClose event
  handleClose = () => {
    // console.log("handleClose is called");
    const diagram = this.diagramRef.current.getDiagram();
    const nodes = diagram.model.nodeDataArray;
    diagram.startTransaction("restore matching nodes");

    nodes.forEach((node) => {
      if (node.thickness === 3) {
        diagram.model.setDataProperty(node, "thickness", 1); // 恢复系统初始化的thickness属性
        diagram.model.setDataProperty(node, "color", "#1B54D9"); // 恢复系统初始化的color属性
      }
    });

    diagram.commitTransaction("restore matching nodes");
    // this.props.onClose();
    this.componentDidMount();
  };

  //find text nodes highlighted
  handleSearch = (searchText) => {
    const diagram = this.diagramRef.current.getDiagram();
    const nodes = diagram.model.nodeDataArray;
    // console.log("diagramnodes:", nodes);
    const matchingNodes = nodes.filter((node) =>
      node.text.toLowerCase().includes(searchText.toLowerCase())
    );
    // console.log("diagrammatchingNodes:", matchingNodes);

    diagram.startTransaction("highlight matching nodes");

    matchingNodes.forEach((node) => {
      if (node.text === searchText) {
        //full match
        diagram.model.setDataProperty(node, "thickness", 3);
        diagram.model.setDataProperty(node, "color", "red");
      } else {
        //partial match
        diagram.model.setDataProperty(node, "thickness", 3);
        diagram.model.setDataProperty(node, "color", "orange");
      }
      // console.log("diagrammupdateNodes ok");
    });
    // this.saveData();
    this.zoomToFitDiagram();
    diagram.commitTransaction("highlight matching nodes");
    // return matchingNodes;
  };

  // //find node center root
  // centerRootDiagram = () => {
  //   const diagram = this.diagramRef.current.getDiagram();
  //   // console.log("diagram1:", diagram);
  //   diagram.scale = 1;
  //   diagram.commandHandler.scrollToPart(diagram.findNodeForKey(-1));
  // };

  //create a new diagram file
  handleNewFile = async () => {
    try {
      console.log("新建文件");
      const response = await axios.post(
        "http://localhost:8800/api/fileapi/createfile",
        {
          // 可以向后端发送其他需要的数据
          // ...
        }
      );
      const { fileid, fileurl } = response.data; // 假设后端返回一个包含ID和链接的对象
      
      console.log(fileid);
      console.log(fileurl);
      // 根据返回的链接打开新的页面
      window.open(fileurl, "_blank");
    } catch (error) {
      console.error(error);
      // 处理错误
    }
  };

  // load data from server
  loadData = () => {
    // console.log("加载数据prom");
    // 加载数据
    fetch("http://localhost:8800/api/datajson/nodes")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          // nodeData: data,
          nodeArray: data,
        });
        // console.log("加载数据prom:",this.state);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch("http://localhost:8800/api/datajson/links")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          linkArray: data,
          // linkData: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    //center zoomtofit and loaddata------
    setTimeout(() => {
      // this.centerRootDiagram();
      // this.zoomToFitDiagram();
      this.scaleRootDiagram();
    }, 0);
  };

  // save model data
  saveData = () => {
    // console.log("保存数据");
    const diagram = this.diagramRef.current.getDiagram();
    diagram.addModelChangedListener((e) => {
      if (
        e.isTransactionFinished &&
        (e.modelChange === "nodeDataArray" || e.modelChange === "linkDataArray")
      ) {
        this.handleNodeDataChange(e);
      }

      // console.log("diagram.modelnode:", diagram.model.nodeDataArray);
      // console.log("diagram.modellink:", diagram.model.linkDataArray);
    });

    var nodeDatachanged = diagram.model.nodeDataArray;
    var linkDatachanged = diagram.model.linkDataArray;

    // save data to json file--------------
    const data = {
      nodeArray: nodeDatachanged,
      linkArray: linkDatachanged,
    };
    fetch("http://localhost:8800/api/datajson/nodes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.nodeArray, null, 2),
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    fetch("http://localhost:8800/api/datajson/links", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.linkArray, null, 2),
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    // save data to json file end--------------

    //axios post nodedatas and linkdatas mysql
    //nodedatas from diagram
    const postnodes = JSON.stringify(data.nodeArray, null, 2);
    const postnodesarray = Array.from(JSON.parse(postnodes));
    // console.log("data.postnodes", postnodes);
    // console.log("data.postnodesarray", postnodesarray);
    //linkdatas from diagram
    const postlinks = JSON.stringify(data.linkArray, null, 2);
    const postlinksarray = Array.from(JSON.parse(postlinks));
    // console.log("data.postlinks", postlinks);
    // console.log("data.postlinksarray", postlinksarray);

    // //nodes isArray
    // if (Array.isArray(postnodesarray)) {
    //   console.error("postnodes is an array!");
    // } else {
    //   console.error("postnodes is not an array!");
    // }
    // //links isArray
    // if (Array.isArray(postlinksarray)) {
    //   console.error("postlinks is an array!");
    // } else {
    //   console.error("postlinks is not an array!");
    // }

    const items = postnodesarray;
    const linkitems = postlinksarray;
    // console.log("data.postnodes22", items);
    // console.log("data.postnodes22", linkitems);

    // nodes is definition
    const dataTitle = items.map(
      ({
        key,
        loc,
        text,
        figure,
        color,
        size,
        thickness,
        group,
        isGroup,
        fill,
        dash,
      }) => ({
        key: key || null,
        loc: loc || null,
        text: text || null,
        figure: figure || null,
        color: color || null,
        size: size || null,
        thickness: thickness || null,
        group: group || null,
        isgroup: isGroup ? "true" : "false",
        fill: fill || null,
        dash: dash || null,
      })
    );
    // console.log("data.postnodes2", dataTitle);
    // links is definition

    const dataLinks = linkitems.map(
      ({
        from,
        to,
        points,
        text,
        fromSpot,
        toSpot,
        color,
        thickness,
        dash,
        dir,
        routing,
      }) => {
        //-----set routing values --------------------------------
        let routingValue = null;
        if (routing === "go.Link.Normal") {
          routingValue = "Normal";
        } else if (routing === "go.Link.Orthogonal") {
          routingValue = "Normal2";
        } else if (routing === "go.Link.Bezier") {
          routingValue = "Normal3";
        }
        return {
          from: from || null,
          to: to || null,
          points: JSON.stringify(points) || null,
          text: text || null,
          fromspot: fromSpot || null,
          tospot: toSpot || null,
          color: color || null,
          thickness: thickness || null,
          dash: JSON.stringify(dash) || null,
          dir: JSON.stringify(dir) || null,
          routing: routingValue || null,
        };
      }
    );
    // console.log("data.postlinks2", dataLinks);

    //nodedata key change event (key、 group crash event）
    const newNodes = dataTitle.map((item) => {
      return {
        ...item,
        nodekey: item.key,
        key: undefined,
        nodegroup: item.group,
        group: undefined,
      };
    });

    // console.log("data.postnodes3", newNodes);
    // post nodedatas api-------------------
    fetch("http://localhost:8800/api/datasql/postnodes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newNodes),
    });

    // post nodedatas api-------------------
    fetch("http://localhost:8800/api/datasql/postlinks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataLinks),
    })
      .then((response) => {
        if (response.ok) {
          // console.log("Data written to MySQL database");
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error writing data to MySQL database:", error);
      });
  };

  //life cycle
  componentDidMount() {
    // 加载数据

    this.loadData();
    // console.log("加载数据ok");
  }
  componentDidUpdate() {
    //center zoomtofit after loaddata------
    this.centerRootDiagram();
    // console.log("加载center");
  }

  render() {
    function initDiagram() {
      // console.log("初始化");

      return diagram();
    }

    function handleModelChange(changes) {
      // console.log("模型更改:", changes);
    }

    // 渲染组件
    return (
      <div className="model-layout">
        <ReactDiagram
          ref={this.diagramRef}
          initDiagram={initDiagram}
          divClassName="diagram-component"
          nodeDataArray={this.state.nodeArray}
          linkDataArray={this.state.linkArray}
          onModelChange={handleModelChange}
        ></ReactDiagram>
        <div className="contentside">
          <ul class="options">
            <li class="option tool" id="rebulid" onClick={this.handleNewFile}>
              <img src={newfile} alt="" />
            </li>
            <li class="option tool" id="rebulid">
              <img src={gridview} alt="" />
            </li>
            <li class="option tool" id="rebulid" onClick={this.loadData}>
              <img src={autorenew} alt="" />
            </li>
            <li class="option tool" id="saveButton" onClick={this.saveData}>
              <img src={backup} alt="" />
            </li>
            <li class="option tool showData" id="showData">
              <img src={qrcode} alt="" />
            </li>
            <li class="option tool fileDownload" id="fileDownload">
              <img src={filedownload} alt="" />
            </li>
          </ul>
          <ul class="options part2">
            <li class="option tool" id="rebulid" onclick="load()">
              <img src={favorite} alt="" />
            </li>
            <li class="option tool" id="backup" onclick="save()">
              <img src={star} alt="" />
            </li>
            <li
              class="option tool showData"
              id="showData"
              onClick={this.centerRootDiagram}
            >
              <img src={centerfocus} alt="" />
            </li>
            <li
              class="option tool fileDownload"
              id="fileDownload"
              onClick={this.zoomToFitDiagram}
            >
              <img src={fullfit} alt="" />
            </li>
            <li
              class="option tool fileDownload"
              id="fileDownload"
              onClick={this.scaleRootDiagram}
            >
              <img src={fullscreen} alt="" />
            </li>

            <li class="option tool fileDownload" id="fileDownload">
              <SearchBox
                handleSearch={this.handleSearch}
                handleClose={this.handleClose}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ModelDiagram1;
