import React, { useState, useContext, useEffect } from "react";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
import axios from "axios";
import "./datadraggable.scss";

import folderbt from "../../img/folder2_opsz24.svg";
import audiodatabt from "../../img/library_music2_opsz24.svg";
import videodatabt from "../../img/video2_library_opsz24.svg";
import databt from "../../img/library_list2_opsz24.svg";
import picturebt from "../../img/photo2_library_opsz24.svg";
import aidatabt from "../../img/ai3_opsz24.svg";

import Draggable from "react-draggable";
import closebt from "../../img/close_black_24dp.svg";
import morebt from "../../img/more_horiz48.svg";
import audioplay from "../../img/volume_opsz24.svg";
import audiostop from "../../img/volume_opsz24_2.svg";

import { AuthContext } from "../../context/authContext";
import { DraggableActiveContext } from "../../context/draggableActiveContext";

import { useTranslation } from "react-i18next";

export default function DataDraggable(props) {
  const { t } = useTranslation();

  const { draggableActive5 } = useContext(DraggableActiveContext);

  //   //获取userid
  //   const { currentUser, userVipGrade } = useContext(AuthContext);
  //   //loading userid
  //   //   const vipno = userVipGrade.vip_no;
  //   const userid = userVipGrade.userid;

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  //showcontent
  const [showInfodragContent, setShowInfodragContent] = useState(true);

 // const fileid = props.selectedNode.fileid;
  const nodekey = props.selectedNode.key;
  const nodeselect = props.selectedNode.nodeselected;
  //   const nodeisgroup = props.selectedNode.isgroup;
  //   const linkselect = props.selectedLink.linkselected;
  // console.log("nodeselected xxx:", nodekey, nodeselect);
  const params = new URLSearchParams(window.location.search);
  const fileid = params.get("fileid");
  //showcontent
  const handleInfoDoubleClick = () => {
    setShowInfodragContent(!showInfodragContent);
  };

  const handleInfoClick = () => {
    setShowInfodragContent(true);
  };

  //get nodedata content
  const [nodedata, setNodedata] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNodedata = async () => {
    // const { fileid, nodekey, nodeselected } = props.selectedNode;

    if (nodeselect && draggableActive5 && !loading) {
      try {
        const response = await fetch(
          `${baseUrl}/titlesapi/getNodedata/${fileid}/${nodekey}`
        );
        const data = await response.json();
        setNodedata(data);
      } catch (error) {
        console.error("Error fetching nodedata:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setNodedata([]);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchNodedata();
    }, 1000); // 1秒延迟
    return () => clearTimeout(delay);
  }, [fileid, nodekey, nodeselect]);

  //get filename
  const [fileNameText, setFileNameText] = useState("");

  //loading fileName from sql by ID
  useEffect(() => {
    async function fetchFileName() {
      try {
       // const params = new URLSearchParams(window.location.search);
       // const fileid = params.get("fileid");
        if (!fileid) {
          return;
        }
        // console.log("fileid X", fileid);
        const response = await axios.get(
          `${baseUrl}/fileapi/getfilename/${fileid}`
        );
        if (response.data.filename === null) {
          // console.log("Filename is null");
          return;
        } else {
          setFileNameText(response.data.filename);
          // console.log("filename X", response.data.filename);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchFileName();
  }, [fileid]);

//
  const [selectedNodeText, setSelectedNodeText] = useState("");

  useEffect(() => {
    const fetchDataWithDelay = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));

      const newText =
        props.selectedNode.text ||
        fileNameText ||
        t("datadraggable.nodedata-dataheadtitle1");

      // 更新 state
      setSelectedNodeText(newText);
    };
    fetchDataWithDelay();
  }, [props.selectedNode.text, fileNameText, t]);
  
  //audio files
  const [audioList, setAudioList] = useState([]);

  useEffect(() => {
    if (fileid && nodekey) {
      // Fetch audio list when the component mounts
      fetchAudioList();
    } else if (fileid && !nodekey) {
      setAudioList([]);
    }
  }, [fileid, nodekey]);

  const fetchAudioList = async () => {
    if (fileid && nodekey) {
      try {
        const response = await axios.get(
          `${baseUrl}/datasql/getaudiolist/${fileid}/${nodekey}`
        );
        // console.log("audioList", response.data);
        // 直接使用后端返回的 id
        setAudioList(response.data.audioList);
      } catch (error) {
        console.error("Error fetching audio list:", error);
      }
    }
  };

  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [audioElements, setAudioElements] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayAudio = (audioUrl, aud_id) => {
    const publicIndex = audioUrl.indexOf("public");
    const relativePath = audioUrl.slice(publicIndex + "public".length);
    const newAudioPath = relativePath.replace(/\\/g, "/");
    //const fullAudioPath = `${backUrl}${newAudioPath}`;
	const fullAudioPath = `${baseUrl}${newAudioPath}`;

    // 暂停当前正在播放的音频
    if (currentlyPlaying && currentlyPlaying !== aud_id) {
      const currentlyPlayingAudio = audioElements[currentlyPlaying];
      if (currentlyPlayingAudio) {
        currentlyPlayingAudio.pause();
      }
    }

    // 如果点击的是当前正在播放的音频，则暂停播放
    if (currentlyPlaying === aud_id) {
      const currentAudioElement = audioElements[aud_id];
      if (currentAudioElement) {
        currentAudioElement.pause();
        setCurrentlyPlaying(null);
        setIsPlaying(false);
      }
    } else {
      // 创建新的 Audio 元素并播放
      const newAudioElement = new Audio(fullAudioPath);

      // 添加 ended 事件监听器
      newAudioElement.addEventListener("ended", () => {
        handleStopAudio(aud_id);
        setCurrentlyPlaying(null);
        setIsPlaying(false);
      });

      // 使用 setTimeout 添加延迟
      setTimeout(() => {
        newAudioElement.play();
        setAudioElements((prevAudioElements) => ({
          ...prevAudioElements,
          [aud_id]: newAudioElement,
        }));
        setCurrentlyPlaying(aud_id);
        setIsPlaying(true);
      }, 300);
    }
  };

  const handleStopAudio = (aud_id) => {
    // 停止当前正在播放的音频
    if (currentlyPlaying === aud_id) {
      const currentAudioElement = audioElements[aud_id];
      if (currentAudioElement) {
        currentAudioElement.pause();
        setCurrentlyPlaying(null);
        setIsPlaying(false);
      }
    }
  };

// auto play first audio
  const fetchAudioListAndPlayFirst = async () => {
    if (fileid && nodekey && nodeselect) {
      try {
        const response = await axios.get(
          `${baseUrl}/datasql/getaudiolist/${fileid}/${nodekey}`
        );
        const audioList = response.data.audioList;

        if (audioList.length > 0) {
          const firstAudio = audioList[0];
          setTimeout(async () => {
            handlePlayAudio(firstAudio.aud_url, firstAudio.aud_id);
          }, 500); 
        }
      } catch (error) {
        console.error("Error fetching audio list:", error);
      }
    }
  };

  useEffect(() => {
    fetchAudioListAndPlayFirst();
  }, [fileid, nodekey, nodeselect]);

  return (
    <>
      <Draggable cancel=".datadraggabletitle,.dataitems,.audiolist,.bottombotton,.closeicon">
        <div className={draggableActive5 ? "datacard active" : "datacard"}>
          <div className="datahead">
            <div
              className="datadraggabletitle"
              onDoubleClick={handleInfoDoubleClick}
            >
              {selectedNodeText}
            </div>
            <img
              className="closeicon"
              src={closebt}
              onClick={() => dispatch05({ type: "NODEDATADRAGGABLE" })}
            />
          </div>

          {showInfodragContent ? (
             <>
              <div className="nodeaudiolist">
                {audioList.length > 0 && (
                  <div className="audiolist">
                    <label>{t("datadraggable.nodedata-dataitemslabel0")}</label>
                    <ul className="listitems">
                      {audioList.map((audio) => (
                        <li
                          className="listitem"
                          key={audio.aud_id}
                          onClick={() =>
                            handlePlayAudio(audio.aud_url, audio.aud_id)
                          }
                        >
                          {audio.aud_title}
                          {!isPlaying && (
                            <img className="closeicon2" src={audiostop} />
                          )}
                          {isPlaying && currentlyPlaying === audio.aud_id && (
                            <img
                              className="closeicon3"
                              src={audioplay}
                              alt=""
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {nodedata.length > 0 && (
                <div className="dataitems">
                  <div className="nodedatalist">
                    <label>{t("datadraggable.nodedata-dataitemslabel1")}</label>
                    {nodedata.map((item) =>
                      item.data_detail ? (
                        <div className="nodedataitem" key={item.id}>
                          <div className="datatitle">{item.data_title} :</div>
                          <div className="datadetail">{item.data_detail}</div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              )}

              <div className="nodepiclist"></div>
              <div className="nodenoticelist"></div>
              <div className="info-bottom">
                <div className="bottom-left">
                  <div className="speakicons">
                    <img src={databt} className="icons6" alt="" />
                  </div>
                  <div className="speakicons">
                    <img src={picturebt} className="icons6" alt="" />
                  </div>
                  <div className="speakicons">
                    <img src={audiodatabt} className="icons6" alt="" />
                  </div>
                  <div className="speakicons">
                    <img src={videodatabt} className="icons6" alt="" />
                  </div>
                  <div className="speakicons">
                    <img src={folderbt} className="icons6" alt="" />
                  </div>
                  <div className="speakicons">
                    <img src={aidatabt} className="icons6" alt="" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="bottombotton" onClick={handleInfoClick}>
              <img
                className="morebtn"
                src={morebt}
                // onClick={handleMoreToggle}
              />
            </div>
          )}
        </div>
      </Draggable>
    </>
  );
}
