import React, { useRef, useState, useEffect } from "react";
import "./spinwheel1.scss"; // Include your CSS here
import frontUrl from "../../configs/fronturl";
import ImageModal from "./ImageModal"; // 导入新组件

const SpinWheel = () => {
  const [angle, setAngle] = useState(0);
  const [item, setItem] = useState("");
  const wheelRef = useRef(null);

  const items = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];
  const weights = [11, 11, 11, 11, 11, 11, 11, 11, 12];

  const clickBtn = () => {
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
    const randomValue = Math.random() * totalWeight;
    let accumulatedWeight = 0;
    let selectedItem = 0;

    for (let i = 0; i < weights.length; i++) {
      accumulatedWeight += weights[i];
      if (accumulatedWeight >= randomValue) {
        selectedItem = i;
        break;
      }
    }

    const spins = Math.floor(Math.random() * 5 + 6);
    const newAngle = 360 * spins - 40 * selectedItem + 40;

    // Rotate the wheel
    wheelRef.current.style.transition = "transform 12s ease-out";
    wheelRef.current.style.transform = `rotate(${angle + newAngle}deg)`;

    setTimeout(() => {
      setAngle((prevAngle) => prevAngle + newAngle);
      //   setAngle(updatedAngle);
      //   setAngle((prevAngle) => prevAngle + newAngle);
      setTimeout(() => {
        console.log("sw", selectedItem, items[selectedItem]);
        setItem(items[selectedItem]);
      }, 500);
      //   console.log(`${frontUrl}/images/${items[selectedItem]}.jpg`);
    }, 12000); // Update the item after the rotation finishes
  };

  useEffect(() => {
    // Debugging: Check when item changes
    console.log("swim", item);
  }, [item]);

  const handleDoubleClick = () => {
    if (item) {
      setItem(""); // Clear the item to hide the image
      wheelRef.current.style.transition = ""; // Reset the transition for future spins
    }
  };

  return (
    <div className="main">
      <div className="box">
        <div className="lottery" ref={wheelRef}>
          {items.map((_, index) => (
            <div
              key={index}
              className="block"
              style={{ transform: `rotate(${index * 40}deg) skewY(50deg)` }}
            />
          ))}
        </div>
        <div className="btn" onClick={clickBtn}></div>
        <ImageModal item={item} onClose={handleDoubleClick} />{" "}
        {/* 使用新组件 */}
      </div>
    </div>
  );
};

export default SpinWheel;
