import ModelDiagram1 from "../../pages/project01/adminModelEdit";
const MainLayoutModel = () => {
  return (
    <div className="mainlayout">
      <ModelDiagram1 />
    </div>
  );
};

export default MainLayoutModel;
