const LeftLayoutReducer = (state, action) => {
    switch (action.type) {
      case "HIDE": {
        return {
          leftLayout: true,
        };
      }
      case "SHOW": {
        return {
          leftLayout: false,
        };
      }
      case "TOGGLE": {
        return {
          leftLayout: !state.leftLayout,
        };
      }
      default:
        return state;
    }
  };
  export default LeftLayoutReducer;
  
