import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./authContext";
import baseUrl from "../configs/baseurl";

import enTranslation from "../locales/en.json";
import zhTranslation from "../locales/zh.json";
import zhTWTranslation from "../locales/zh-TW.json";

// 获取浏览器语言
const browserLanguage = navigator.language.toLowerCase();

const LanguageWrapper = () => {
  const { currentUser, userVipGrade } = useContext(AuthContext);

  const fetchUserLanguage = async () => {
    const userid = userVipGrade.userid;
    if (!userid) {
      return null;
    }
    // console.log("i18j-userid", userid);
    try {
      const response = await axios.get(
        `${baseUrl}/userapi/getuserlanguage/${userid}`
      );

      return response.data.language || null;
    } catch (error) {
      console.error("Error fetching user language:", error.message);
      return null;
    }
  };

  useEffect(() => {
    const initI18n = async () => {
      const storedLanguage = await fetchUserLanguage();
      // console.log("storedLanguage33", storedLanguage);

      let newbrowserLanguage;

      if (browserLanguage.includes("en")) {
        newbrowserLanguage = "en";
      } else if (browserLanguage.includes("zh")) {
        if (browserLanguage.includes("tw") || browserLanguage.includes("hk")) {
          newbrowserLanguage = "zh-TW";
        } else {
          newbrowserLanguage = "zh";
        }
      }

      let defaultLanguage = storedLanguage || browserLanguage;

      i18n.use(initReactI18next).init({
        resources: {
          en: { translation: enTranslation },
          zh: { translation: zhTranslation },
          "zh-TW": { translation: zhTWTranslation },
        },
        lng: defaultLanguage,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
      });
    };

    initI18n();
  }, [browserLanguage, fetchUserLanguage]);

  return null;
};

export default LanguageWrapper;
