import React, { useCallback, useEffect, useState, useContext } from "react";
import baseUrl from "../../configs/baseurl";
import Draggable from "react-draggable";
import "./downfilepopup.scss";
import { AuthContext } from "../../context/authContext";
import closeButton2 from "../../img/close_black_24_2.svg";
import jpgformat from "../../img/format-icon-jpg1.svg";
import pdfformat from "../../img/format-icon-pdf1.svg";
import pngformat from "../../img/format-icon-png1.svg";
import svgformat from "../../img/format-icon-svg1.svg";
import { useTranslation } from "react-i18next";
export default function DownFiles(props) {
	const { t } = useTranslation();

//获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);
  //loading userid
  const vipno = userVipGrade.vip_no;
  const userid = userVipGrade.userid;

//download svg format
const handleSvgDownClick = useCallback(() => {
  setTimeout(() => {
    const userdown = vipno >= 1;
    if (userdown) {
      props.onSvgDownfile();
    }
   }, 1000);
  }, [vipno, props.onSvgDownfile]);

  useEffect(() => {
    return () => {
    };
  }, []);

  return (
    <Draggable cancel=".downfiles button">
      <div className="downfilecard">
        <div className="downfiles">
          <button
            className="downfile jpgformat"
            title={t("downfiles.downfile-jpg")}
            onClick={props.onJpgDownfile}
          >
            <img className="jpgicon" src={jpgformat} alt="" />
          </button>
          <button
            className="downfile pngformat"
            title={t("downfiles.downfile-png")}
            onClick={props.onPngDownfile}
          >
            <img className="pngicon" src={pngformat} alt="" />
          </button>
        </div>
        <div className="downfiles">
          <button
            className="downfile svgformat"
            title={t("downfiles.downfile-svg")}
            onClick={handleSvgDownClick}
          >
            <img className="svgicon" src={svgformat} alt="" />
          </button>
          <button
            className="downfile pdfformat"
            title={t("downfiles.downfile-pdf")}
            onClick={props.onPdfDownfile}
          >
            <img className="pdficon" src={pdfformat} alt="" />
          </button>
        </div>
        <div className="downfiles">
          <button
            className="downfile closebt"
            title={t("downfiles.downfile-close")}
            onClick={props.onClose}
          >
            <img className="closeicon" src={closeButton2} alt="" />
          </button>
        </div>
      </div>
    </Draggable>
  );
}
