import "./chatpopup.scss";
import account from "../../../img/account_circle_black_24dp.svg";
import registereduserSvg from "../../../images/userimages/registereduser.svg";
import React, { useState, useContext, useEffect } from "react";
import backUrl from "../../../configs/backurl";
import baseUrl from "../../../configs/baseurl";
import { PopupActiveContext } from "../../../context/popupActiveContext";
import { DraggableActiveContext } from "../../../context/draggableActiveContext";
import { ChatActiveContext } from "../../../context/chatActiveContext";
import { AuthContext } from "../../../context/authContext";

import { useTranslation } from "react-i18next";

const ChatPopup = () => {
  const { t } = useTranslation();

  //popupActiveContext
  const { dispatch3 } = useContext(PopupActiveContext);

  const { popupActive3 } = useContext(PopupActiveContext);

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  //chatActiveContext
  const { dispatchchat } = useContext(ChatActiveContext);

  //获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);

  //loading userid
  const vipno = userVipGrade.vip_no;
  const userid = userVipGrade.userid;

  const params = new URLSearchParams(window.location.search);
  const fileid = params.get("fileid");

  const [recentChatData, setRecentChatData] = useState([]);

  const transformImageUrl = (imageUrl) => {
    if (imageUrl) {
      const publicIndex = imageUrl.indexOf("public");
      const relativePath = imageUrl.slice(publicIndex + "public".length);
      const imagePath = relativePath.replace(/\\/g, "/");
      return `${baseUrl}${imagePath}?${Math.random()}`;
    }
    return registereduserSvg; // 或者默认的图片地址
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/titlesapi/getChatData/${fileid}`
      );
      const data = await response.json();

      // 按照 chat_modifydate 降序排序
      const sortedData = data.sort(
        (a, b) => new Date(b.chat_modifydate) - new Date(a.chat_modifydate)
      );

      // 获取最新的6行数据
      const latestData = sortedData.slice(0, 6);

      // 更新 recentChatData 直接包含用户信息
      const updatedRecentChatData = await Promise.all(
        latestData.map(async (message) => {
          if (message.userid) {
            try {
              const response = await fetch(
                `${baseUrl}/userapi/getUserInfo/${message.userid}`
              );
              const userData = await response.json();

              return {
                chatcontent: message.chatcontent,
                chat_modifydate: message.chat_modifydate,
                userInfo: {
                  picurl: userData.pic_url,
                  name: userData.name,
                },
              };
            } catch (error) {
              console.error("Error fetching user info:", error);
            }
          }

          return {
            chatcontent: message.chatcontent,
            chat_modifydate: message.chat_modifydate,
            userInfo: {
              picurl: null,
              name: null,
            },
          };
        })
      );

      setRecentChatData(updatedRecentChatData);
    } catch (error) {
      console.error("获取聊天数据失败", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fileid]);

  //time difference
  const calculateTimeDifference = (chatModifyDate) => {
    // console.log("chatModifyDate", chatModifyDate);
    const chatDate = new Date(chatModifyDate);
    const now = new Date();
    const timeDifference = now - chatDate;

    // 转换为分钟
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    if (minutesDifference < 1) {
      return "刚刚";
    } else if (minutesDifference < 60) {
      return `${minutesDifference}分钟前`;
    } else if (minutesDifference < 1440) {
      const hoursDifference = Math.floor(minutesDifference / 60);
      return `${hoursDifference}小时前`;
    } else {
      const daysDifference = Math.floor(minutesDifference / 1440);
      return `${daysDifference}天前`;
    }
  };

  return (
    <>
      <div
        className={
          popupActive3 ? "popuplayout-chat active" : "popuplayout-chat"
        }
      >
        <div className="popupbody chat">
          <div className="chat-title">
            <span className="title-name">{t("chatPopup.chat-titlename")}</span>
            <span className="clear">{t("chatPopup.chat-clear")}</span>
          </div>
          <div className="chat-lists">
            <div className="lists-content">
              {recentChatData.map((item, index) => (
                <div key={index} className="chat-list">
                  <div className="list-left">
                    <img
                      src={transformImageUrl(item.userInfo.picurl)}
                      alt=""
                      className="strangeavatar"
                    />
                  </div>
                  <div className="list-right">
                    <div className="right-up">{item.chatcontent}</div>
                    <div className="right-down">
                      {calculateTimeDifference(item.chat_modifydate)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="chat-footer">
            <span
              onClick={() => {
                if (vipno > 0) {
                  dispatch3({ type: "CHATS" });
                  dispatch05({ type: "NOALLDRAGGABLE" });
                  dispatchchat({ type: "SHOW" });
                }
              }}
            >
              {t("chatPopup.chat-footer")}
            </span>
          </div>
        </div>
      </div>
      <div
        className={popupActive3 ? "overlay active" : "overlay"}
        onClick={() => dispatch3({ type: "CHATS" })}
      ></div>
    </>
  );
};

export default ChatPopup;
