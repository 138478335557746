// import "../popupstyle/popup.scss";
import "./userpopup.scss";
import { Vip22, Pp2 } from "@plansplan/iconsx3";
import registereduserSvg from "../../../images/userimages/registereduser.svg";
import {
  Yjbd2,
  Sjbd2,
  Hyzx2,
  Wjgl2,
  Ddgl2,
  Lpzx2,
  Tudl2,
  Edit12,
} from "@plansplan/iconsx2";

import arrowback from "../../../img/arrow_back24.svg";
import morebtn from "../../../img/more_h24.svg";

import myfiles from "../../../img/folder_open24 _file.svg";
import mykeeps from "../../../img/star_outline24_file.svg";
import mylikes from "../../../img/favorite_border24dp_file.svg";
import groupfiles from "../../../img/folder_copy24.svg";
import photocamera3 from "../../../img/photo_camera483.svg";

import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";

import baseUrl from "../../../configs/baseurl";
import backUrl from "../../../configs/backurl";
import frontUrl from "../../../configs/fronturl";
import { PopupActiveContext } from "../../../context/popupActiveContext";
import { AuthContext } from "../../../context/authContext";

import { useTranslation } from "react-i18next";

const UserPopup = () => {
  const { t } = useTranslation();

  const { popupActive7 } = useContext(PopupActiveContext);

  const { dispatch3 } = useContext(PopupActiveContext);

  const { currentUser, userVipGrade, logout } = useContext(AuthContext);

  const [vipNo, setVipNo] = useState(null);

  const userid = userVipGrade.userid;

  const vipno = userVipGrade.vip_no;

  const [userInfo, setUserInfo] = useState({
    username: "",
    // password: "",
    phone: "",
    email: "",
    desc: "",
    picurl: null,
  });

  const [userImage, setUserImage] = useState(null);

  const [originalUserInfo, setOriginalUserInfo] = useState({}); // 保存原始用户信息
  const [isEditing, setIsEditing] = useState(false);

  const usernameInputRef = useRef(null);

  useEffect(() => {
    // 当获取到新的用户信息时，更新原始用户信息
    setOriginalUserInfo(userInfo);
  }, [userInfo]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  // check email and username
  const handleInputBlur = async (event) => {
    const { name, value } = event.target;

    try {
      if (name === "email" && value.trim() !== "" && !emailRegex.test(value)) {
        alert(t("userPopup.alert1"));
      }

      if (name === "username" && value.trim() !== "") {
        const response = await axios.get(
          `${baseUrl}/userapi/checkUsername/${userid}/${value}`
        );
        // console.log("response.data.duplicate", response.data.duplicate);
        if (response.data.duplicate) {
          // alert("该用户名已存在，请选择其他用户名");
          usernameInputRef.current.classList.add("error-input");
          usernameInputRef.current.focus();
          // 重置输入框的值或采取其他适当的措施
        } else {
          // 如果不重复，移除类名以还原输入框的样式
          usernameInputRef.current.classList.remove("error-input");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateButtonClick = async () => {
    if (isEditing) {
      try {
        const response = await axios.put(
          `${baseUrl}/userapi/updateUserInfo/${userid}`,
          userInfo
        );
        if (response.data.success) {
          // alert("用户信息已更新");
          setIsEditing(false);
        } else {
          alert(t("userPopup.alert2"));
        }
      } catch (error) {
        console.error("Error updating user info:", error);
      }
    }
  };

  // 取消编辑时，恢复原始用户信息
  const handleCancelEdit = () => {
    setUserInfo(originalUserInfo);
    setIsEditing(false);
    //load user info
    if (userid) {
      fetch(`${baseUrl}/userapi/getUserInfo/${userid}`)
        .then((response) => response.json())
        .then((data) => {
          setUserInfo({
            username: data.name,
            phone: data.phone_no,
            email: data.email,
            desc: data.desc_user,
            picurl: data.pic_url,
          });
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    }
  };

  const handleLogout = () => {
    logout();
    // closePopup();
    setTimeout(() => {
      dispatch3({ type: "USERS" });
    }, 200);
  };

  //userInfo gets updated
  useEffect(() => {
    if (userid) {
      fetch(`${baseUrl}/userapi/getUserInfo/${userid}`)
        .then((response) => response.json())
        .then((data) => {
          setUserInfo({
            username: data.name,
            phone: data.phone_no,
            email: data.email,
            desc: data.desc_user,
            picurl: data.pic_url,
          });
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    }
  }, [userid]);

  //vip_no get
  useEffect(() => {
    //console.log("userid user", userid);
    if (userid) {
      fetch(`${baseUrl}/userapi/getvipno/${userid}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setVipNo(data.vip_no);
        })
        .catch((error) => {
          console.error("Error fetching VIP number:", error);
          // 可以设置一个错误状态或者显示错误信息给用户
        });
    }
  }, [userid]);

  //show file view
  const [showFilePopupBody, setShowFilePopupBody] = useState(true);
  const [showFileManage, setShowFileManage] = useState(false);
  const [showUserCenter, setShowUserCenter] = useState(false);

  const toggleFilePopupBody = () => {
    setShowFilePopupBody(false);
    setShowFileManage(true);
    setShowUserCenter(false);
  };

  const toggleUserPopupBody = () => {
    setShowFilePopupBody(false);
    setShowFileManage(false);
    setShowUserCenter(true);
  };

  const returnUserPopupBody = () => {
    setShowFilePopupBody(true);
    setShowFileManage(false);
    setShowUserCenter(false);
  };

  //show filelist view
  const [isFileOrigListVisible, setIsFileOrigListVisible] = useState(true);
  const [isFileGroupListVisible, setIsFileGroupListVisible] = useState(false);
  const [isFileLikeListVisible, setIsFileLikeListVisible] = useState(false);
  const [isFileKeepListVisible, setIsFileKeepListVisible] = useState(false);

  const handleToggleFileOrigList = () => {
    setIsFileOrigListVisible(!isFileOrigListVisible);
  };
  const handleToggleFileGroupList = () => {
    setIsFileGroupListVisible(!isFileGroupListVisible);
  };
  const handleToggleFileLikeList = () => {
    setIsFileLikeListVisible(!isFileLikeListVisible);
  };
  const handleToggleFileKeepList = () => {
    setIsFileKeepListVisible(!isFileKeepListVisible);
  };

  //刷新用户文件
  const [fetchUserFilesData, setFetchUserFilesData] = useState(false);

  const handleFetchUserFiles = () => {
    setFetchUserFilesData(true);
  };

  //是否获取用户files
  useEffect(() => {
    if (userid) {
      // console.log("user file get1:", userid);
      // 调用接口获取用户文件数据
      fetchUserFiles(userid);

      //调用接口获取用户收藏文件数据
      fetchUserKeepFiles(userid);

      //调用接口获取用户喜好文件数据
      fetchUserLikeFiles(userid);

      //调用接口获取用户集合文件数据
      fetchUserGroupFiles(userid);
    }
  }, [userid]);

  // //是否获取用户files
  // useEffect(() => {
  //   if (showFilePopupBody) {
  //     // console.log("user file get1:", userid);
  //     // 调用接口获取用户文件数据
  //     fetchUserFiles(userid);

  //     //调用接口获取用户收藏文件数据
  //     fetchUserKeepFiles(userid);

  //     //调用接口获取用户喜好文件数据
  //     fetchUserLikeFiles(userid);
  //   }
  // }, [showFilePopupBody, userid]);

  //刷新用户files
  useEffect(() => {
    if (fetchUserFilesData && userid) {
      // console.log("user file get2:", userid);
      // 调用接口获取用户文件数据
      fetchUserFiles(userid);

      //调用接口获取用户收藏文件数据
      fetchUserKeepFiles(userid);

      //调用接口获取用户喜好文件数据
      fetchUserLikeFiles(userid);

      //调用接口获取用户集合文件数据
      fetchUserGroupFiles(userid);

      setFetchUserFilesData(false); // 重置为 false，避免重复触发获取数据
    }
  }, [fetchUserFilesData, userid]);

  //获取用户文件
  const [fileData, setFileData] = useState([]);
  const fetchUserFiles = async (userid) => {
    if (userid) {
      try {
        const response = await fetch(
          `${baseUrl}/userapi/getuserfiles/${userid}`
        );
        const data = await response.json();
        setFileData(data.files);
      } catch (error) {
        console.error("Error while fetching user files:", error);
      }
    }
  };

  //获取用户收藏keeps文件
  const [fileKeepData, setFileKeepData] = useState([]);
  const [keepFilesNum, setKeepFilesNum] = useState(0);
  const fetchUserKeepFiles = async (userid) => {
    if (userid) {
      try {
        const response = await fetch(
          `${baseUrl}/userapi/getuserkeepfiles/${userid}`
        );
        const data = await response.json();
        setFileKeepData(data.keepFiles);
        setKeepFilesNum(data.keepFilesNum);
      } catch (error) {
        console.error("Error while fetching user files:", error);
      }
    }
  };

  //获取用户收藏likes文件
  const [fileLikeData, setFileLikeData] = useState([]);
  const [likeFilesNum, setLikeFilesNum] = useState(0);
  const fetchUserLikeFiles = async (userid) => {
    if (userid) {
      try {
        const response = await fetch(
          `${baseUrl}/userapi/getuserlikefiles/${userid}`
        );
        const data = await response.json();
        setFileLikeData(data.likeFiles);
        setLikeFilesNum(data.likeFilesNum);
      } catch (error) {
        console.error("Error while fetching user files:", error);
      }
    }
  };

  //获取用户集合groups文件
  const [fileGroupData, setFileGroupData] = useState([]);
  const [groupFilesNum, setGroupFilesNum] = useState(0);
  const fetchUserGroupFiles = async (userid) => {
    if (userid) {
      try {
        const response = await fetch(
          `${baseUrl}/userapi/getGroupNames/${userid}`
        );
        const data = await response.json();
        setFileGroupData(data.groupData);
        setGroupFilesNum(data.groupData.length);
      } catch (error) {
        console.error("Error while fetching user files:", error);
      }
    }
  };

  //dropdown show
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleMoreBtnClick = (index) => {
    setActiveDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  //morebtn1 show
  const [hoveredItem1, setHoveredItem1] = useState(null);

  const handleItemHover1 = (index1) => {
    setHoveredItem1(index1);
    setActiveDropdown(null);
  };

  //morebtn2 show
  const [hoveredItem2, setHoveredItem2] = useState(null);

  const handleItemHover2 = (index2) => {
    setHoveredItem2(index2);
    // setActiveDropdown(null);
  };

  //morebtn3 show
  const [hoveredItem3, setHoveredItem3] = useState(null);

  const handleItemHover3 = (index3) => {
    setHoveredItem3(index3);
    // setActiveDropdown(null);
  };

  //view open window
  const handleViewClick = (fileid) => {
    const url = `${frontUrl}/view1?fileid=${fileid}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  //edit open window
  const handleEditClick = (fileid) => {
    const url = `${frontUrl}/edit1?fileid=${fileid}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  //group open window
  const handleGroupClick = (groupid) => {
    const url = `${frontUrl}/group1?groupid=${groupid}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  //delete file user from sql
  const handleDeleteClick = async (userid, fileid) => {
    try {
      const response = await fetch(
        `${baseUrl}/userapi/deletefile/${userid}/${fileid}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        // 删除成功，刷新文件列表
        fetchUserFiles(userid);
      } else {
        console.error("Failed to delete file");
      }
    } catch (error) {
      console.error("Error while deleting file:", error);
    }
  };

  //
  const handleClearUpFile = async (userid, fileid) => {
    try {
      const response = await fetch(
        `${baseUrl}/userapi/clearupfile/${userid}/${fileid}`,
        {
          method: "DELETE",
        }
      );
      const data = await response.json();
      // 处理成功删除的情况
      if (data.success) {
        handleFetchUserFiles();

        // 进行相应的提示或操作
        console.log("File deleted successfully.");
      }
    } catch (error) {
      console.error("Error while deleting file:", error);
    }
  };

  //upload userimage
  const handleImageUpload = async (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    setUserImage(null);
    try {
      const response = await axios.post(
        `${baseUrl}/userapi/uploadpic/${userid}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("picurl-1", response.data.picurl);
      const userpicurl = response.data.picurl;
      if (userpicurl) {
        const imageUrl = userpicurl;
        const publicIndex = imageUrl.indexOf("public");
        const relativePath = imageUrl.slice(publicIndex + "public".length);
        const imagePath = relativePath.replace(/\\/g, "/");
        // console.log("upload pic user1", imagePath);
        setUserImage(imagePath);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    if (userInfo.picurl) {
      const imageUrl = userInfo.picurl;
      const publicIndex = imageUrl.indexOf("public");
      const relativePath = imageUrl.slice(publicIndex + "public".length);
      const imagePath = relativePath.replace(/\\/g, "/");
      // const newimagePath = `${imagePath}`; //相对地址
      // const newimagePath = `${backUrl}${imagePath}`;
      // const newimagePath = `${baseUrl}${imagePath}`;    //vps need
      // console.log("upload pic user11", imagePath);

      setUserImage(imagePath);
    } else {
      setUserImage(null);
    }
  }, [userInfo.picurl]);

  return (
    <>
      <div
        className={popupActive7 ? "popuplayoutover active" : "popuplayoutover"}
      >
        <div className="popupclear">
          <div
            className="popupuseravatar"
            onClick={() => {
              dispatch3({ type: "USERS" });
              returnUserPopupBody();
            }}
          >
            <img
              src={
                userImage
                  ? `${baseUrl}${userImage}?${Math.random()}`
                  : registereduserSvg
              }
              className="strangeavatar"
              alt=""
            />
          </div>
        </div>
        <div className="popuplayout">
          {/* showusermain */}
          {showFilePopupBody && (
            <div className="popupbody">
              <div className="popupitems">
                <div className="popupitem">
                  <div className="popuppercent100">
                    <div className="user-name">
                      <span>{currentUser}</span>
                      <div className="popupicons">
                        <Edit12
                          className="icons3"
                          onClick={toggleUserPopupBody}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="popupitem">
                  <div className="popuppercent50">
                    <div className="user-vip">
                      {vipNo !== null && <span>VIP {vipNo}</span>}
                    </div>
                  </div>
                  <div className="popuppercent50">
                    <div className="user-vipup">
                      <span>{t("userPopup.user-vipup")}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popupitems">
                <div className="popupitem">
                  <div className="popuppercent100">
                    <div className="popupiconlist">
                      <div className="user-coin">
                        <div className="popupicons">
                          <Vip22 className="icons3" />
                          <span className="numbers vips">10</span>
                        </div>

                        <div className="popupicons">
                          <Pp2 className="icons3" />
                          <span className="numbers pps">52</span>
                        </div>
                      </div>
                      <div className="user-com">
                        <div className="popupicons">
                          <Sjbd2 className="icons3" />
                        </div>
                        <div className="popupicons">
                          <Yjbd2 className="icons3" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popupitems">
                <div className="popupitem">
                  <div className="popuppercent25">
                    <div className="user-files">
                      <span>{t("userPopup.user-files")}</span>
                      <span className="numbers files">{fileData.length}</span>
                    </div>
                  </div>
                  <div className="popuppercent25">
                    <div className="user-fans">
                      <span>{t("userPopup.user-fans")}</span>
                      <span className="numbers fans">0</span>
                    </div>
                  </div>
                  <div className="popuppercent25">
                    <div className="user-saves">
                      <span>{t("userPopup.user-saves")}</span>
                      <span className="numbers saves">{keepFilesNum}</span>
                    </div>
                  </div>
                  <div className="popuppercent25">
                    <div className="user-trends">
                      <span>{t("userPopup.user-trends")}</span>
                      <span className="numbers trends">0</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popupitems">
                <div className="popupitem">
                  <div className="popuppercent100">
                    <div className="user-center">
                      <div className="popupicons">
                        <Hyzx2 className="icons3" />
                      </div>
                      <span onClick={toggleUserPopupBody}>
                        {t("userPopup.user-center")}
                      </span>
                    </div>
                  </div>
                  <div className="popuppercent100">
                    <div className="user-file-admin">
                      <div className="popupicons">
                        <Wjgl2 className="icons3" />
                      </div>
                      <span onClick={toggleFilePopupBody}>
                        {t("userPopup.user-file-admin")}
                      </span>
                    </div>
                  </div>
                  <div className="popuppercent100">
                    <div className="user-order">
                      <div className="popupicons">
                        <Ddgl2 className="icons3" />
                      </div>
                      <span>{t("userPopup.user-order")}</span>
                    </div>
                  </div>
                  <div className="popuppercent100">
                    <div className="user-business">
                      <div className="popupicons">
                        <Lpzx2 className="icons3" />
                      </div>
                      <span>{t("userPopup.user-business")}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popupitem">
                <div className="popuppercent100">
                  <div className="user-quit" onClick={handleLogout}>
                    <div className="popupicons">
                      <Tudl2 className="icons3" />
                    </div>
                    <span>{t("userPopup.user-quit")}</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* showFileManage */}
          {showFileManage && (
            <div className="filemanage">
              <div className="filelayout files">
                <div className="returnbtn" onClick={returnUserPopupBody}>
                  <img className="returnicon" src={arrowback} />
                </div>
                <span
                  className="filetitle"
                  title={t("userPopup.user-file-admin")}
                  onClick={handleFetchUserFiles}
                >
                  {t("userPopup.user-file-admin")}
                </span>
                <div className="popupitems">
                  <div className="popupitem">
                    <div className="popuppercent50">
                      <div className="user-vip files">
                        <div className="files users">
                          <img className="filesusericon" src={myfiles} />
                        </div>
                        <span
                          className="listname"
                          onClick={handleToggleFileOrigList}
                        >
                          {t("userPopup.user-files")}
                        </span>
                      </div>
                    </div>
                    <div className="popuppercent50">
                      <div className="user-vipup files">
                        <span>{fileData.length}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* origfiles */}
                {isFileOrigListVisible && (
                  <div className="filelist users">
                    {fileData.map((file, index1) => (
                      <div
                        key={file.fileid}
                        title={file.filename}
                        className={`fileitem ${
                          hoveredItem1 === index1 ? "hovered" : ""
                        }`}
                        onMouseEnter={() => handleItemHover1(index1)}
                        onMouseLeave={() => handleItemHover1(null)}
                      >
                        <div
                          className="fileinfo"
                          onClick={() => handleViewClick(file.fileid)}
                        >
                          <span className="fileindex">{index1 + 1}</span>
                          <span className="filenames">{file.filename}</span>
                        </div>
                        <div
                          className={`morebtn ${
                            hoveredItem1 === index1 ? "active" : ""
                          }`}
                          onClick={() => handleMoreBtnClick(index1)}
                        >
                          <img className="moreicon" src={morebtn} />
                        </div>
                        {activeDropdown === index1 && (
                          <div className="filedropdown">
                            <ul>
                              {/* <li onClick={() => handleViewClick(file.fileid)}>
                            查看
                          </li> */}
                              <li onClick={() => handleEditClick(file.fileid)}>
                                {t("userPopup.filedropdown-edit")}
                              </li>
                              <li
                                onClick={() =>
                                  handleDeleteClick(userid, file.fileid)
                                }
                              >
                                {t("userPopup.filedropdown-delete")}
                              </li>

                              {vipno > 8 ? (
                                <>
                                  <li
                                    onClick={() =>
                                      handleClearUpFile(userid, file.fileid)
                                    }
                                  >
                                    {t("userPopup.filedropdown-clear")}
                                  </li>
                                </>
                              ) : null}
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* groupfiles */}
              <div className="filelayout likes">
                <div className="popupitems">
                  <div className="popupitem">
                    <div className="popuppercent50">
                      <div className="user-vip files">
                        <div className="files likes">
                          <img className="fileslikeicon" src={groupfiles} />
                        </div>
                        <span
                          className="listname"
                          onClick={handleToggleFileGroupList}
                        >
                          {t("userPopup.user-group")}
                        </span>
                      </div>
                    </div>
                    <div className="popuppercent50">
                      <div className="user-vipup files">
                        <span>{groupFilesNum}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {isFileGroupListVisible && (
                  <div className="filelist likes">
                    {fileGroupData.map((file, index4) => (
                      <div
                        key={file.groupid}
                        title={file.groupname}
                        className={`fileitem ${
                          hoveredItem3 === index4 ? "hovered" : ""
                        }`}
                        onMouseEnter={() => handleItemHover3(index4)}
                        onMouseLeave={() => handleItemHover3(null)}
                      >
                        <div
                          className="fileinfo"
                          onClick={() => handleGroupClick(file.groupid)}
                        >
                          <span className="fileindex">{index4 + 1}</span>
                          <span className="filenames">{file.groupname}</span>
                        </div>
                        <div
                          className={`morebtn ${
                            hoveredItem3 === index4 ? "active" : ""
                          }`}
                        >
                          <img className="moreicon" src={morebtn} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* keepfile */}
              <div className="filelayout keeps">
                <div className="popupitems">
                  <div className="popupitem">
                    <div className="popuppercent50">
                      <div className="user-vip files">
                        <div className="files keeps">
                          <img className="fileskeepicon" src={mykeeps} />
                        </div>
                        <span
                          className="listname"
                          onClick={handleToggleFileKeepList}
                        >
                          {t("userPopup.user-keeps")}
                        </span>
                      </div>
                    </div>
                    <div className="popuppercent50">
                      <div className="user-vipup files">
                        <span>{keepFilesNum}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {isFileKeepListVisible && (
                  <div className="filelist keeps">
                    {fileKeepData.map((file, index2) => (
                      <div
                        key={file.fileid}
                        title={file.filename}
                        className={`fileitem ${
                          hoveredItem2 === index2 ? "hovered" : ""
                        }`}
                        onMouseEnter={() => handleItemHover2(index2)}
                        onMouseLeave={() => handleItemHover2(null)}
                      >
                        <div
                          className="fileinfo"
                          onClick={() => handleViewClick(file.fileid)}
                        >
                          <span className="fileindex">{index2 + 1}</span>
                          <span className="filenames">{file.filename}</span>
                        </div>
                        <div
                          className={`morebtn ${
                            hoveredItem2 === index2 ? "active" : ""
                          }`}
                        >
                          <img className="moreicon" src={morebtn} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* likefiles */}
              <div className="filelayout likes">
                <div className="popupitems">
                  <div className="popupitem">
                    <div className="popuppercent50">
                      <div className="user-vip files">
                        <div className="files likes">
                          <img className="fileslikeicon" src={mylikes} />
                        </div>
                        <span
                          className="listname"
                          onClick={handleToggleFileLikeList}
                        >
                          {t("userPopup.user-likes")}
                        </span>
                      </div>
                    </div>
                    <div className="popuppercent50">
                      <div className="user-vipup files">
                        <span>{likeFilesNum}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {isFileLikeListVisible && (
                  <div className="filelist likes">
                    {fileLikeData.map((file, index3) => (
                      <div
                        key={file.fileid}
                        title={file.filename}
                        className={`fileitem ${
                          hoveredItem3 === index3 ? "hovered" : ""
                        }`}
                        onMouseEnter={() => handleItemHover3(index3)}
                        onMouseLeave={() => handleItemHover3(null)}
                      >
                        <div
                          className="fileinfo"
                          onClick={() => handleViewClick(file.fileid)}
                        >
                          <span className="fileindex">{index3 + 1}</span>
                          <span className="filenames">{file.filename}</span>
                        </div>
                        <div
                          className={`morebtn ${
                            hoveredItem3 === index3 ? "active" : ""
                          }`}
                        >
                          <img className="moreicon" src={morebtn} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="space"></div>
              </div>
            </div>
          )}
          {/* showUserCenter */}
          {showUserCenter && (
            <div className="filemanage">
              <div className="filelayout files">
                <div className="returnbtn" onClick={returnUserPopupBody}>
                  <img className="returnicon" src={arrowback} />
                </div>
                <span
                  className="filetitle"
                  title={t("userPopup.user-center")}
                  // onClick={handleFetchUserFiles}
                >
                  {t("userPopup.user-center")}
                </span>
                <div className="popupitems">
                  <div className="popupitem">
                    <div className="popuppercent80">
                      <div className="user-vip files">
                        <span className="infotitle">
                          {t("userPopup.user-infotitle")}
                        </span>
                        {isEditing ? (
                          <span className="userinfo">
                            <input
                              type="text"
                              name="username"
                              placeholder={t("userPopup.user-infoplaceholder")}
                              autoComplete="off"
                              value={userInfo.username}
                              onChange={handleInputChange}
                              ref={usernameInputRef}
                              onBlur={handleInputBlur}
                            />
                          </span>
                        ) : (
                          <span
                            className="userinfo username"
                            onClick={() => setIsEditing(true)}
                          >
                            {userInfo.username}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="popupitem">
                    <div className="popuppercent80">
                      <div className="user-vip files">
                        <span className="infotitle">
                          {t("userPopup.user-infopwtitle")}
                        </span>
                        {/* {isEditing ? (
                        <span className="userinfo">
                          <input
                            type="password"
                            name="password"
                            placeholder={t("userPopup.user-infopwplaceholder")}
                            autoComplete="off"
                            value={userInfo.password}
                            onChange={handleInputChange}
                          />
                        </span>
                      ) : (
                        <span
                          className="userinfo password"
                          onClick={() => setIsEditing(true)}
                        >
                          {userInfo.password}
                        </span>
                      )} */}
                      </div>
                    </div>
                  </div>
                  <div className="popupitem">
                    <div className="popuppercent80">
                      <div className="user-vip files">
                        <span className="infotitle">
                          {t("userPopup.user-infophonetitle")}
                        </span>
                        {isEditing ? (
                          <span className="userinfo">
                            <input
                              type="text"
                              name="phone"
                              placeholder={t(
                                "userPopup.user-infophoneplaceholder"
                              )}
                              autoComplete="off"
                              value={userInfo.phone}
                              onChange={handleInputChange}
                            />
                          </span>
                        ) : (
                          <span
                            className="userinfo phone"
                            onClick={() => setIsEditing(true)}
                          >
                            {userInfo.phone}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="popupitem">
                    <div className="popuppercent80">
                      <div className="user-vip files">
                        <span className="infotitle">
                          {t("userPopup.user-infomailtitle")}
                        </span>
                        {isEditing ? (
                          <span className="userinfo">
                            <input
                              type="text"
                              name="email"
                              placeholder={t(
                                "userPopup.user-infomailplaceholder"
                              )}
                              autoComplete="off"
                              value={userInfo.email}
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                            />
                          </span>
                        ) : (
                          <span
                            className="userinfo email"
                            onClick={() => setIsEditing(true)}
                          >
                            {userInfo.email}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="popupitem">
                    <div className="popuppercent80">
                      <div className="user-vip files">
                        <span className="infotitle">
                          {t("userPopup.user-infodesctitle")}
                        </span>
                        {/* <span className="userinfo desc">
                        {userInfo.desc ? (
                          userInfo.desc
                        ) : (
                          <textarea
                            placeholder="输入描述"
                            rows="3"
                            cols="25"
                            onClick={(e) => e.stopPropagation()}
                          ></textarea>
                        )}
                      </span> */}
                        {isEditing ? (
                          <span className="userinfo desc">
                            <textarea
                              name="desc"
                              placeholder={t(
                                "userPopup.user-infodescplaceholder"
                              )}
                              rows="2"
                              cols="25"
                              value={userInfo.desc}
                              autoComplete="off"
                              onChange={handleInputChange}
                            />
                          </span>
                        ) : (
                          <span
                            className="userinfo desc"
                            onClick={() => setIsEditing(true)}
                          >
                            {userInfo.desc}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="popupitem">
                    <div className="popuppercent80">
                      <div className="user-vip files icon">
                        <span className="infotitle">
                          {t("userPopup.user-infoimagetitle")}
                        </span>
                        <div className="backpicitems">
                          <img
                            src={
                              userImage
                                ? `${baseUrl}${userImage}?${Math.random()}`
                                : photocamera3
                            }
                            alt={t("userPopup.user-infoimagealt")}
                          />

                          {/* {userImage ? (
                          <img src={userImage} alt="上传的头像" />
                        ) : (
                          <img src={photocamera2} alt="默认图片" />
                        )} */}
                        </div>
                        <label className="userbtn">
                          {t("userPopup.user-infoimagebtn")}
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="popupitem">
                    <div className="popuppercent80">
                      <div className="userbuttons">
                        <button
                          title={t("userPopup.user-infoupdatetitle")}
                          className="userupdate"
                          onClick={handleUpdateButtonClick}
                        >
                          {t("userPopup.user-infoupdatebtn")}
                        </button>

                        <button
                          title={t("userPopup.user-infodeletebtn")}
                          className="userdelete"
                          onClick={handleCancelEdit}
                        >
                          {t("userPopup.user-infodeletebtn")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={popupActive7 ? "overlay active" : "overlay"}
        onClick={() => {
          returnUserPopupBody();
          dispatch3({ type: "USERS" });
        }}
      ></div>
    </>
  );
};

export default UserPopup;
