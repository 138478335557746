import { createContext, useReducer } from "react";
import PopupActiveReducer from "./popupActiveReducer";

const INITIAL_STATE = {
  popupActive1: false,
  //noticepopup
  popupActive2: false,
  //loginpopup
  popupActive3: false,
  //chatpopup
  popupActive4: false,
  //toolpopup
  popupActive5: false,
  //publishpopup
  popupActive6: false,
  //savefilepopup
  popupActive7: false,
  //userpopup
};

export const PopupActiveContext = createContext(INITIAL_STATE);

export const PopupActiveContextProvider = ({ children }) => {
  const [state, dispatch3] = useReducer(PopupActiveReducer, INITIAL_STATE);
  return (
    <PopupActiveContext.Provider
      value={{
        popupActive1: state.popupActive1,
        popupActive2: state.popupActive2,
        popupActive3: state.popupActive3,
        popupActive4: state.popupActive4,
        popupActive5: state.popupActive5,
        popupActive6: state.popupActive6,
        popupActive7: state.popupActive7,
        dispatch3,
      }}
    >
      {children}
    </PopupActiveContext.Provider>
  );
};
