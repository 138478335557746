import { createContext, useState, useEffect, useContext } from "react";
import baseUrl from ".././configs/baseurl";

export const AuthContext = createContext({
  currentUser: null,
  login: () => {},
  logout: () => {},
  userVipGrade: {
    userid: "",
    vip_no: "",
    status_user: "",
    pic_url: "",
  },
  setUserVipGrade: () => {},
});

const AuthContextProvider = (props) => {
  const loginuser = localStorage.getItem("user");
  // console.log("loginuser stor:", loginuser);
  const [currentUser, setCurrentUser] = useState(loginuser);
  //   const [currentUser, setCurrentUser] = useState(null);

  const [userVipGrade, setUserVipGrade] = useState({
    userid: "",
    vip_no: "",
    status_user: "",
    pic_url: "",
  });

  //loginuser
  const loginHandler = (loginuser) => {
    // console.log("loginuser", loginuser);
    setCurrentUser(loginuser);
    localStorage.setItem("user", loginuser);
  };

  //logoutuser
  const logoutHandler = () => {
    setCurrentUser(null);
    localStorage.removeItem("user");
  };

  // 获取用户 VIP 等级和状态
  const getUserVipGrade = async () => {
    try {
      // 向 /uservipgrade/:username 接口发送 GET 请求
      const response = await fetch(
        `${baseUrl}/userapi/uservipgrade/${currentUser}`
      );

      // 如果请求成功，解析返回的 JSON 数据并将用户信息传递给 children 组件
      if (response.ok) {
        const data = await response.json();
        setUserVipGrade(data);
      } else {
        // 如果请求失败，输出错误信息
        console.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // 在登录时获取用户 VIP 等级和状态
    if (currentUser) {
      getUserVipGrade();
     // console.log("currentUservip:", currentUser);
    }
  }, [currentUser]);

 // console.log("user:", userVipGrade.userid);
 // console.log("vipno:", userVipGrade.vip_no);
 // console.log("status:", userVipGrade.status_user);
 // console.log("userpic:", userVipGrade.pic_url);

  const contextValue = {
    currentUser: currentUser,
    login: loginHandler,
    logout: logoutHandler,
    userVipGrade: userVipGrade,
    setUserVipGrade: setUserVipGrade,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { currentUser, userVipGrade, setUserVipGrade } =
    useContext(AuthContext);

  const getUserID = () => {
    return userVipGrade.userid;
  };

  return {
    currentUser,
    userVipGrade,
    setUserVipGrade,
    getUserID,
  };
};

export default AuthContextProvider;
