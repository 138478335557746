import "./toolpopup.scss";
import arrowback from "../../../img/arrow_back24.svg";
import arrowback2 from "../../../img/arrow_back24blue.svg";
import exterior from "../../../img/brightness_opsz24.svg";
import exterior2 from "../../../img/brightness_opsz24blue.svg";
import release from "../../../img/near_me_opsz24.svg";
import release2 from "../../../img/near_me_opsz24blue.svg";
import selects from "../../../img/widgets_opsz24.svg";
import selects2 from "../../../img/widgets_opsz24blue.svg";
import visibility from "../../../img/visibility_24.svg";
import visibilityOff from "../../../img/visibility_off24.svg";
import languages from "../../../img/language_opsz24.svg";
import languages2 from "../../../img/language_opsz24blue.svg";

import axios from "axios";
import { AuthContext } from "../../../context/authContext";

import React, { useState, useContext, useEffect } from "react";
import baseUrl from "../../../configs/baseurl";
import { PopupActiveContext } from "../../../context/popupActiveContext";

import { useTranslation } from "react-i18next";

const ToolPopup = () => {
  //获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);

  //loading userid
  const vipno = userVipGrade.vip_no;
  const userid = userVipGrade.userid;

  const { t, i18n } = useTranslation();
  const systemlanguage = i18n.language;
  //   console.log("systemlanguage", systemlanguage);
  const handleLanguageChange = async (language) => {
    try {
      // 发送 HTTP 请求到后端，将语言值保存到数据库
      const response = await axios.post(
        `${baseUrl}/userapi/saveuserlanguage/${userid}`,
        {
          language,
        }
      );

      // 切换前端语言
      i18n.changeLanguage(language);
    } catch (error) {
      console.error("Error saving language:", error.message);
    }
  };

  //isOriguser
  const [isOriguser, setIsOriguser] = useState(false);

  // 获取文件的fileid，你可以根据你的路由设置获取它
  const params = new URLSearchParams(window.location.search);
  const fileid = params.get("fileid");

  //isLoading user is file origuser
  useEffect(() => {
    if (fileid) {
      // 发起请求获取origuser
      axios
        .get(`${baseUrl}/fileapi/getFileOrigUser/${fileid}`)
        .then((response) => {
          const origuser = response.data.origuser;
          // console.log("origuser 1", origuser);
          // 判断origuser是否等于userid，并更新状态
          setIsOriguser(origuser === userid);
        })
        .catch((error) => {
          console.error(error);
          // 处理错误
        });
    }
  }, [userid]);

  const { popupActive4 } = useContext(PopupActiveContext);
  //popupActiveContext
  const { dispatch3 } = useContext(PopupActiveContext);

  //
  const [islanguageHovered, setIslanguageHovered] = useState(false);
  const [isselectsHovered, setIsselectsHovered] = useState(false);
  const [isreleaseHovered, setIsreleaseHovered] = useState(false);
  const [isexteriorHovered, setIsexteriorHovered] = useState(false);
  const [isreturnHovered, setIsreturnHovered] = useState(false);
  //languageHovered
  const handlelanguageMouseEnter = () => {
    setIslanguageHovered(true);
  };

  const handlelanguageMouseLeave = () => {
    setIslanguageHovered(false);
  };
  //selectsHovered
  const handleselectsMouseEnter = () => {
    setIsselectsHovered(true);
  };

  const handleselectsMouseLeave = () => {
    setIsselectsHovered(false);
  };
  //release
  const handlereleaseMouseEnter = () => {
    setIsreleaseHovered(true);
  };

  const handlereleaseMouseLeave = () => {
    setIsreleaseHovered(false);
  };
  //exterior
  const handleexteriorMouseEnter = () => {
    setIsexteriorHovered(true);
  };

  const handleexteriorMouseLeave = () => {
    setIsexteriorHovered(false);
  };
  //returns
  const handlereturnMouseEnter = () => {
    setIsreturnHovered(true);
  };

  const handlereturnMouseLeave = () => {
    setIsreturnHovered(false);
  };

  //PopupVisible
  const [isPopupVisible, setPopupVisible] = useState(true);
  const [isLanguageListVisible, setLanguageListVisible] = useState(false);
  const [isPublishListVisible, setPublishListVisible] = useState(false);
  const [isFunctionListVisible, setFunctionListVisible] = useState(false);

  const handleLanguageClick = () => {
    setIsreturnHovered(false);
    setPopupVisible(false);
    setLanguageListVisible(true);
  };

  const handleReturnClick = () => {
    setPopupVisible(true);
    setLanguageListVisible(false);
    setPublishListVisible(false);
	setFunctionListVisible(false);
    setIsPasswordVisible(false);
    //
    setIslanguageHovered(false);
    setIsselectsHovered(false);
    setIsreleaseHovered(false);
    setIsexteriorHovered(false);
  };

  //publish
  const handlePublishClick = async () => {
    if (isOriguser) {
      setIsreturnHovered(false);
      setPopupVisible(false);
      setPublishListVisible(true);

      try {
        const response = await fetch(
          `${baseUrl}/fileapi/getFileOpenModel/${fileid}`
        );
        const data = await response.json();

        const isOpenChecked = data.isOpenChecked;
        const isPasswordChecked = data.isPasswordChecked;
        const passwordValue = data.passwordValue;

        setIsOpenChecked(isOpenChecked);
        setIsPasswordChecked(isPasswordChecked);
        setPasswordValue(passwordValue);

        // setPublishListVisible(true);
      } catch (error) {
        console.error("Error fetching file open model:", error);
      }
    }
  };
  
  //functions set
  const handleFunctonClick = async () => {
    if (isOriguser) {
      setIsreturnHovered(false);
      setPopupVisible(false);
      setPublishListVisible(false);
      setFunctionListVisible(true);
    }
  };

  //updateState--isOpenChecked
  const [isOpenChecked, setIsOpenChecked] = useState(true);

  const handleCheckboxOpenChange = () => {
    const newIsOpenChecked = !isOpenChecked;
    // console.log("newIsOpenChecked", newIsOpenChecked);
    setIsOpenChecked(newIsOpenChecked);

    updateFileOpenModel(fileid, newIsOpenChecked);
  };

  const updateFileOpenModel = async (fileid, isOpenChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateFileOpenModel/${fileid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isOpenChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("Fileopenmodel updated successfully.");
      } else {
        console.error("Failed to update fileopenmodel.");
      }
    } catch (error) {
      console.error("Error updating fileopenmodel:", error);
    }
  };

  //updateState--isPasswordChecked
  const [isPasswordChecked, setIsPasswordChecked] = useState(false);

  const handleCheckboxPasswordChange = () => {
    setIsPasswordVisible(false);
    const newIsPasswordChecked = !isPasswordChecked;
    //console.log("newIsPasswordChecked", newIsPasswordChecked);
    setIsPasswordChecked(newIsPasswordChecked);

    updateUsePassword(fileid, newIsPasswordChecked);
  };

  const updateUsePassword = async (fileid, isPasswordChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUsePassword/${fileid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isPasswordChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("usepassword updated successfully.");
      } else {
        console.error("Failed to update usepassword.");
      }
    } catch (error) {
      console.error("Error updating usepassword:", error);
    }
  };

  //pwword value update
  const [passwordValue, setPasswordValue] = useState("");

  const handlePasswordChange = (e) => {
    setPasswordValue(e.target.value);
  };

  //password visibility
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  // pwaddbutton 点击事件处理程序
  const handleSetPassword = async () => {
    try {
      // 发送请求将 passwordValue 保存到数据库
      await fetch(`${baseUrl}/fileapi/saveFilePassword/${fileid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filepassword: passwordValue,
        }),
      });

      // 清空密码输入框
      // setPasswordValue("");
      setIsPasswordVisible(false);
    } catch (error) {
      console.error("Error setting password:", error);
    }
  };

  // pwclearbutton 点击事件处理程序
  const handleClearPassword = async () => {
    try {
      // 发送请求删除数据库中的 filepassword
      await fetch(`${baseUrl}/fileapi/clearFilePassword/${fileid}`, {
        method: "DELETE",
      });

      // 清空密码输入框
      setPasswordValue("");
      setIsPasswordVisible(false);
    } catch (error) {
      console.error("Error clearing password:", error);
    }
  };
  
  //function set
  //navbar function
  const [isNavbarChecked, setIsNavbarChecked] = useState(true);
  //notice function
  const [isNoticeChecked, setIsNoticeChecked] = useState(false);
  //chat function
  const [isChatsChecked, setIsChatsChecked] = useState(true);
  //tool function
  const [isToolsChecked, setIsToolsChecked] = useState(false);
  //filesearch function
  const [isFileSearchChecked, setIsFileSearchChecked] = useState(true);
  //infomation function
  const [isInfosChecked, setIsInfosChecked] = useState(false);
  //run operation function
  const [isRunOPChecked, setIsRunOPChecked] = useState(false);
  //other menu function
  const [isOtherMenuChecked, setIsOtherMenuChecked] = useState(false);
  //backgroud settings function
  const [isBackgroudChecked, setIsBackgroudChecked] = useState(false);

  // 定义状态切换的处理函数
  const handleCheckboxNavbarChange = () => {
    const newIsNavbarChecked = !isNavbarChecked;
    setIsNavbarChecked(newIsNavbarChecked);
    updateUseNavbar(fileid, newIsNavbarChecked);
  };

  const handleCheckboxNoticeChange = () => {
    const newIsNoticeChecked = !isNoticeChecked;
    setIsNoticeChecked(newIsNoticeChecked);
    updateUseNotice(fileid, newIsNoticeChecked);
  };

  const handleCheckboxChatsChange = () => {
    const newIsChatsChecked = !isChatsChecked;
    setIsChatsChecked(newIsChatsChecked);
    updateUseChats(fileid, newIsChatsChecked);
  };

  const handleCheckboxToolsChange = () => {
    const newIsToolsChecked = !isToolsChecked;
    setIsToolsChecked(newIsToolsChecked);
    updateUseTools(fileid, newIsToolsChecked);
  };

  const handleCheckboxFileSearchChange = () => {
    const newIsFileSearchChecked = !isFileSearchChecked;
    setIsFileSearchChecked(newIsFileSearchChecked);
    updateUseFileSearch(fileid, newIsFileSearchChecked);
  };

  const handleCheckboxInfosChange = () => {
    const newIsInfosChecked = !isInfosChecked;
    setIsInfosChecked(newIsInfosChecked);
    updateUseInfos(fileid, newIsInfosChecked);
  };

  const handleCheckboxRunOPChange = () => {
    const newIsRunOPChecked = !isRunOPChecked;
    setIsRunOPChecked(newIsRunOPChecked);
    updateUseRunOP(fileid, newIsRunOPChecked);
  };

  const handleCheckboxOtherMenuChange = () => {
    const newIsOtherMenuChecked = !isOtherMenuChecked;
    setIsOtherMenuChecked(newIsOtherMenuChecked);
    updateUseOtherMenu(fileid, newIsOtherMenuChecked);
  };

  const handleCheckboxBackgroudChange = () => {
    const newIsBackgroudChecked = !isBackgroudChecked;
    setIsBackgroudChecked(newIsBackgroudChecked);
    updateUseBackgroud(fileid, newIsBackgroudChecked);
  };

  //update file functionality fileid
  const updateUseNavbar = async (fileid, isNavbarChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseNavbar/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isNavbarChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("usenavbar updated successfully.");
      } else {
        console.error("Failed to update usenavbar.");
      }
    } catch (error) {
      console.error("Error updating usenavbar:", error);
    }
  };

  const updateUseNotice = async (fileid, isNoticeChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseNotice/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isNoticeChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("usenotice updated successfully.");
      } else {
        console.error("Failed to update usenotice.");
      }
    } catch (error) {
      console.error("Error updating usenotice:", error);
    }
  };

  const updateUseChats = async (fileid, isChatsChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseChats/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isChatsChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("usechats updated successfully.");
      } else {
        console.error("Failed to update usechats.");
      }
    } catch (error) {
      console.error("Error updating usechats:", error);
    }
  };

  const updateUseTools = async (fileid, isToolsChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseTools/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isToolsChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("usetools updated successfully.");
      } else {
        console.error("Failed to update usetools.");
      }
    } catch (error) {
      console.error("Error updating usetools:", error);
    }
  };

  const updateUseFileSearch = async (fileid, isFileSearchChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseFileSearch/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isFileSearchChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("usefilesearch updated successfully.");
      } else {
        console.error("Failed to update usefilesearch.");
      }
    } catch (error) {
      console.error("Error updating usefilesearch:", error);
    }
  };

  const updateUseInfos = async (fileid, isInfosChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseInfos/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isInfosChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("useinfos updated successfully.");
      } else {
        console.error("Failed to update useinfos.");
      }
    } catch (error) {
      console.error("Error updating useinfos:", error);
    }
  };

  const updateUseRunOP = async (fileid, isRunOPChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseRunOP/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isRunOPChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("userunop updated successfully.");
      } else {
        console.error("Failed to update userunop.");
      }
    } catch (error) {
      console.error("Error updating userunop:", error);
    }
  };

  const updateUseOtherMenu = async (fileid, isOtherMenuChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseOtherMenu/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isOtherMenuChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("useothermenu updated successfully.");
      } else {
        console.error("Failed to update useothermenu.");
      }
    } catch (error) {
      console.error("Error updating useothermenu:", error);
    }
  };

  const updateUseBackgroud = async (fileid, isBackgroudChecked) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/updateUseBackgroud/${fileid}/${userid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isBackgroudChecked,
          }),
        }
      );

      if (response.ok) {
        // console.log("usebackgroud updated successfully.");
      } else {
        console.error("Failed to update usebackgroud.");
      }
    } catch (error) {
      console.error("Error updating usebackgroud:", error);
    }
  };

  //fetch function from server
  const fetchfunctionData = async (fileid, userid) => {
    try {
      const response = await fetch(
        `${baseUrl}/fileapi/getFileFunctionData/${fileid}/${userid}`
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error("Failed to fetch file function data.");
      }
    } catch (error) {
      console.error("Error fetching file function data:", error);
    }
    return null;
  };

  // useEffect(() => {
  const fetchDataAndUpdateState = async () => {
    const data = await fetchfunctionData(fileid, userid);

    // Update state based on fetched data
    if (data) {
      setIsNavbarChecked(Boolean(data.is_navbar));
      setIsNoticeChecked(Boolean(data.is_notice));
      setIsChatsChecked(Boolean(data.is_chat));
      setIsToolsChecked(Boolean(data.is_tool));
      setIsFileSearchChecked(Boolean(data.is_filesearch));
      setIsInfosChecked(Boolean(data.is_info));
      setIsRunOPChecked(Boolean(data.is_runop));
      setIsOtherMenuChecked(Boolean(data.is_menu));
      setIsBackgroudChecked(Boolean(data.is_backgroud));
    }
  };

  //   // Call the function to fetch data
  //   fetchDataAndUpdateState();
  // }, [fileid, userid]);

  const handleFunctionListClick = () => {
   if (isOriguser) {
    handleFunctonClick();
    fetchDataAndUpdateState();
   }
  };

  return (
    <>
      <div
        className={
          popupActive4 ? "popuplayout-tool active" : "popuplayout-tool"
        }
      >
        {/* toolselect */}
        {isPopupVisible && (
          <div className="popupbody tool">
            <div
              className="tool-list"
              onMouseEnter={handlelanguageMouseEnter}
              onMouseLeave={handlelanguageMouseLeave}
              onClick={handleLanguageClick}
            >
              <div className="listicon">
                <img
                  src={islanguageHovered ? languages2 : languages}
                  className="toolicon"
                  alt=""
                />
              </div>
              <span className="list-name">{t("toolpopup.list-name1")}</span>
            </div>
            <div
              className="tool-list"
              onMouseEnter={handleselectsMouseEnter}
              onMouseLeave={handleselectsMouseLeave}
			  onClick={handleFunctionListClick}
            >
              <div className="listicon">
                <img
                  src={isselectsHovered ? selects2 : selects}
                  className="toolicon"
                  alt=""
                />
              </div>
              <span className="list-name">{t("toolpopup.list-name2")}</span>
            </div>
            <div
              className="tool-list"
              onMouseEnter={handlereleaseMouseEnter}
              onMouseLeave={handlereleaseMouseLeave}
              onClick={handlePublishClick}
            >
              <div className="listicon">
                <img
                  src={isreleaseHovered ? release2 : release}
                  className="toolicon"
                  alt=""
                />
              </div>
              <span className="list-name">{t("toolpopup.list-name3")}</span>
            </div>
            <div
              className="tool-list"
              onMouseEnter={handleexteriorMouseEnter}
              onMouseLeave={handleexteriorMouseLeave}
            >
              <div className="listicon">
                <img
                  src={isexteriorHovered ? exterior2 : exterior}
                  className="toolicon"
                  alt=""
                />
              </div>
              <span className="list-name">{t("toolpopup.list-name4")}</span>
            </div>
          </div>
        )}

        {/* language */}
        {isLanguageListVisible && (
          <div className="listshow language">
            <div className="tool-list">
              <div
                className="returnbtn"
                onMouseEnter={handlereturnMouseEnter}
                onMouseLeave={handlereturnMouseLeave}
                onClick={handleReturnClick}
              >
                <img
                  src={isreturnHovered ? arrowback2 : arrowback}
                  className="returnicon"
                  alt=""
                />
              </div>
              <div className="listicon">
                <img src={languages} className="toolicon" alt="" />
              </div>
            </div>
            <div className="tool-listitem">
              <span
                className={
                  systemlanguage === "en" ? "list-item active" : "list-item"
                }
                onClick={() => handleLanguageChange("en")}
              >
                {t("toolpopup.list-language1")}
              </span>
              <span
                className={
                  systemlanguage === "zh" ? "list-item active" : "list-item"
                }
                onClick={() => handleLanguageChange("zh")}
              >
                {t("toolpopup.list-language2")}
              </span>
              <span
                className={
                  systemlanguage === "zh-TW" ? "list-item active" : "list-item"
                }
                onClick={() => handleLanguageChange("zh-TW")}
              >
                {t("toolpopup.list-language3")}
              </span>
            </div>
          </div>
        )}

        {/* publish */}
        {isPublishListVisible && (
          <div className="listshow publish">
            <div className="tool-list">
              <div
                className="returnbtn"
                onMouseEnter={handlereturnMouseEnter}
                onMouseLeave={handlereturnMouseLeave}
                onClick={handleReturnClick}
              >
                <img
                  src={isreturnHovered ? arrowback2 : arrowback}
                  className="returnicon"
                  alt=""
                />
              </div>
              <div className="listicon">
                <img src={release} className="toolicon" alt="" />
              </div>
            </div>
            <div className="tool-publishlistitem">
              <div className="item-container">
                <span className="select-item">
                  {isOpenChecked
                    ? t("toolpopup.select-item11")
                    : t("toolpopup.select-item12")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isOpenChecked}
                    onChange={handleCheckboxOpenChange}
                    id="checkbox"
                  />
                  <label htmlFor="checkbox" className="checkbox"></label>
                </div>
              </div>

              <div className="item-container">
                <span className="select-item">
                  {isPasswordChecked
                    ? t("toolpopup.select-item21")
                    : t("toolpopup.select-item22")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isPasswordChecked}
                    onChange={handleCheckboxPasswordChange}
                    id="checkbox2"
                  />
                  <label htmlFor="checkbox2" className="checkbox"></label>
                </div>
              </div>
              {isPasswordChecked && (
                <>
                  <div className="password-input-container">
                    <input
                      className="password-input"
                      type={isPasswordVisible ? "text" : "password"}
                      value={passwordValue}
                      onChange={handlePasswordChange}
                      placeholder={t("toolpopup.password-input")}
                    />
                    <div
                      className="password-toggle"
                      onClick={togglePasswordVisibility}
                    >
                      <img
                        src={isPasswordVisible ? visibility : visibilityOff}
                        className="toolicon"
                        alt=""
                      />
                    </div>
                  </div>
                   <div className="button-group">
                    <button
                      className="pwaddbutton"
                      title={t("toolpopup.button-title1")}
                      onClick={handleSetPassword}
                    >
                      {t("toolpopup.button-btn1")}
                    </button>
                    <button
                      className="pwclearbutton"
                      title={t("toolpopup.button-title2")}
                      onClick={handleClearPassword}
                    >
                      {t("toolpopup.button-btn2")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
		
		{/* function */}
        {isFunctionListVisible && (
          <div className="listshow function">
            <div className="tool-list">
              <div
                className="returnbtn"
                onMouseEnter={handlereturnMouseEnter}
                onMouseLeave={handlereturnMouseLeave}
                onClick={handleReturnClick}
              >
                <img
                  src={isreturnHovered ? arrowback2 : arrowback}
                  className="returnicon"
                  alt=""
                />
              </div>
              <div className="listicon">
                <img src={selects} className="toolicon" alt="" />
              </div>
            </div>
            <div className="tool-functionlistitem">
              {/* navbar */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item1")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isNavbarChecked}
                    onChange={handleCheckboxNavbarChange}
                    id="checkbox20"
                  />
                  <label htmlFor="checkbox20" className="checkbox"></label>
                </div>
              </div>
              {/* notice */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item2")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isNoticeChecked}
                    onChange={handleCheckboxNoticeChange}
                    id="checkbox21"
                  />
                  <label htmlFor="checkbox21" className="checkbox"></label>
                </div>
              </div>
              {/* chat */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item3")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isChatsChecked}
                    onChange={handleCheckboxChatsChange}
                    id="checkbox22"
                  />
                  <label htmlFor="checkbox22" className="checkbox"></label>
                </div>
              </div>
              {/* tool */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item4")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isToolsChecked}
                    onChange={handleCheckboxToolsChange}
                    id="checkbox23"
                  />
                  <label htmlFor="checkbox23" className="checkbox"></label>
                </div>
              </div>
              {/* more filesearch */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item5")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isFileSearchChecked}
                    onChange={handleCheckboxFileSearchChange}
                    id="checkbox24"
                  />
                  <label htmlFor="checkbox24" className="checkbox"></label>
                </div>
              </div>
              {/* more information */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item6")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isInfosChecked}
                    onChange={handleCheckboxInfosChange}
                    id="checkbox25"
                  />
                  <label htmlFor="checkbox25" className="checkbox"></label>
                </div>
              </div>
              {/* more run op */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item7")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isRunOPChecked}
                    onChange={handleCheckboxRunOPChange}
                    id="checkbox26"
                  />
                  <label htmlFor="checkbox26" className="checkbox"></label>
                </div>
              </div>
              {/* menu menu */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item8")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isOtherMenuChecked}
                    onChange={handleCheckboxOtherMenuChange}
                    id="checkbox27"
                  />
                  <label htmlFor="checkbox27" className="checkbox"></label>
                </div>
              </div>
              {/* menu back set */}
              <div className="item-container">
                <span className="select-item">
                  {t("toolpopup.function-select-item9")}
                </span>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isBackgroudChecked}
                    onChange={handleCheckboxBackgroudChange}
                    id="checkbox28"
                  />
                  <label htmlFor="checkbox28" className="checkbox"></label>
                </div>
              </div>
            </div>
          </div>
        )}
		
      </div>
      <div
        className={popupActive4 ? "overlay active" : "overlay"}
        onClick={() => {
          dispatch3({ type: "TOOLS" });
          handleReturnClick();
        }}
      ></div>
    </>
  );
};

export default ToolPopup;
