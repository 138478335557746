import "./leftlayout.scss";
import React, { useState, useContext, useEffect } from "react";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
import { LeftLayoutContext } from "../../context/leftLayoutContext";

import pageList from "../datefile/dataPage";
import nodeList from "../datefile/dataNode";
import formList from "../datefile/dataForm";
import iconList from "../datefile/dataIcon";
import chartList from "../datefile/dataChart";
import { ListItem } from "../styledfile/styles";

import logoSvg from "../../images/logo.svg";

import home from "../../img/home_white_24dp.svg";
import dashboardC from "../../img/dashboard_customize_white_24dp.svg";
import calendar from "../../img/calendar_view_month_white_24dp.svg";
import photoI from "../../img/photo_filter_white_24dp.svg";
import analytics from "../../img/analytics_white_24dp.svg";
import chat from "../../img/chat_white_24dp.svg";
import folder from "../../img/folder_special_white_24dp.svg";

import chevronR from "../../img/chevron_right_white_24dp.svg";
import chevronB from "../../img/expand_more_white_24dp.svg";

import photocamera2 from "../../img/photo_camera482.svg";

import { AuthContext } from "../../context/authContext";

import axios from "axios";
import { useTranslation } from "react-i18next";
function Leftlayout({ handleSetColorChange }) {
  const { t } = useTranslation();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");

  //vipgrade
  const { userVipGrade } = useContext(AuthContext);
  // console.log("N1新建文件VipGrade", userVipGrade.vip_no);
  const vipno = userVipGrade.vip_no;

 const userid = userVipGrade.userid;
  //console.log("userid leftlayout", userid);

  const [isUser, setIsUser] = useState(false);

//user isuser
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
    if (fileid) {
      fetch(`${baseUrl}/fileapi/getFileOrigUser/${fileid}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const origuser = data.origuser;
          //console.log("origuser leftlayout", origuser);
          setIsUser(userid === origuser);
        })
        .catch((error) => {
          console.error("Error fetching file origuser:", error);
          setIsUser(false); // 设置为默认值
        });
    }
  }, [userid]);

  useEffect(() => {
    // 获取fileid
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
    if (!fileid) {
      return;
    }
    // 从后端API获取背景图片路径
    fetch(`${baseUrl}/fileapi/getimageurls/${fileid}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("fetch complete");
        if (data.success) {
          const imageUrl = data.imageUrls[0];
          if (!imageUrl) {
            setBackgroundImageUrl(null);
            return;
          }else{
          const publicIndex = imageUrl.indexOf("public");
          const relativePath = imageUrl.slice(publicIndex + "public".length);
          const imagePath = relativePath.replace(/\\/g, "/");  
          const newimagePath = `${imagePath}`;
        //const newimagePath = `${backUrl}${imagePath}`;
        
          setBackgroundImageUrl(imagePath, () => {
           // console.log("upload pic ff2", backgroundImageUrl);
          });
         // console.log("upload pic ff1", newimagePath);
        }
       }
      })
      .catch((error) => console.error(error));
  }, [backgroundImageUrl]);

  // useEffect(() => {
  //   if (backgroundImageUrl !== "") {
  //     console.log("upload pic ff2", backgroundImageUrl);
  //   }
  // }, [backgroundImageUrl]);

  //update background pic
  const handlePicUpdate = async () => {
    //delete pic
    const fileInput = document.getElementById("picfile");
    const pichex = document.querySelector("#pichex");
    const selectedFile = fileInput.files[0];

    if (!selectedFile) {
      alert("请选择文件");
      return;
    }

    const formData = new FormData();
    formData.append("image", fileInput.files[0]);
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");
   // console.log("upload storage ff1", fileid);
    try {
      const response = await fetch(`${baseUrl}/fileapi/uploadpic/${fileid}`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();

      if (data.success) {
        const fileid = data.fileid;
        const picurl = data.picurl;
       // console.log("upload storage f url", fileid, picurl);
        // 保存图片地址到后端数据库
        const result = await fetch(
          `${baseUrl}/fileapi/setbasepicurl/${fileid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ basepicurl: picurl }),
          }
        );

        const resultData = await result.json();
        // console.log("upload storage r", resultData);
        if (resultData.success) {
          // alert("图片上传成功并保存到后端数据库！");

          //update basepic show
          const imageUrl = picurl;
          const publicIndex = imageUrl.indexOf("public");
          const relativePath = imageUrl.slice(publicIndex + "public".length);
          const imagePath = relativePath.replace(/\\/g, "/");
          //porps backgroundimage
          // handleBackgroundImageChange(imagePath);
          setBackgroundImageUrl(imagePath, () => {
           // console.log("upload pic fff2", backgroundImageUrl);
          });
         // console.log("upload pic fff1", imagePath);

          //show basepic input
          pichex.value = imagePath;
        } else {
          alert("保存图片地址到后端数据库失败！");
        }
      } else {
        alert("上传图片失败！");
      }
    } catch (error) {
      console.error(error);
      alert("上传图片失败！");
    }
  };

  const handlePicDelete = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    try {
      const response = await fetch(
        `${baseUrl}/fileapi/deletebasepic/${fileid}`,
        {
          method: "DELETE",
        }
      );
      const data = await response.json();

      if (data.success) {
        setBackgroundImageUrl(null);
        // 清空图片显示和输入框的值
        const pichex = document.querySelector("#pichex");
        const picfile = document.querySelector("#picfile");
        pichex.value = "";
        picfile.value = "";
        // alert("删除背景图片成功！");
      } else {
        // alert("删除背景图片失败！");
      }
    } catch (error) {
      console.error(error);
      // alert("删除背景图片失败！");
    }
  };

  //background color settings
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");

  const handleColorChange = (e) => {
    const color = e.target.value;
    setBackgroundColor(color);
    // onColorChange(color);
    // props.handleSetColorChange(color);
    // console.log("setcolor", color);
  };

  // 处理关闭按钮的点击事件
  const handleColorUpdate = () => {
    const color = backgroundColor;
    handleSetColorChange(color);
    // console.log("setcolor", color);

    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid");

    axios
      .put(`${baseUrl}/fileapi/updatebasecolor/${fileid}`, {
        basecolor: color,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // console.log("handleSetColorChange:", handleSetColorChange); // 检查是否接收到 handleSetColorChange

  //leftLayoutContext
  const { leftLayout } = useContext(LeftLayoutContext);

  //switch
  const [switchToggle1, setSwitchToggle1] = useState(false);
  const [switchToggle2, setSwitchToggle2] = useState(true);
  const [switchToggle3, setSwitchToggle3] = useState(true);
  const [switchToggle4, setSwitchToggle4] = useState(true);
  const [switchToggle5, setSwitchToggle5] = useState(true);

  const ToggleSwitch1 = () => {
    setSwitchToggle1(!switchToggle1);
    setSwitchToggle2(true);
    setSwitchToggle3(true);
    setSwitchToggle4(true);
    setSwitchToggle5(true);
  };
  const ToggleSwitch2 = () => {
    setSwitchToggle2(!switchToggle2);
    setSwitchToggle1(true);
    setSwitchToggle3(true);
    setSwitchToggle4(true);
    setSwitchToggle5(true);
  };
  const ToggleSwitch3 = () => {
    setSwitchToggle3(!switchToggle3);
    setSwitchToggle1(true);
    setSwitchToggle2(true);
    setSwitchToggle4(true);
    setSwitchToggle5(true);
  };
  const ToggleSwitch4 = () => {
    setSwitchToggle4(!switchToggle4);
    setSwitchToggle1(true);
    setSwitchToggle2(true);
    setSwitchToggle3(true);
    setSwitchToggle5(true);
  };
  const ToggleSwitch5 = () => {
    setSwitchToggle5(!switchToggle5);
    setSwitchToggle1(true);
    setSwitchToggle2(true);
    setSwitchToggle3(true);
    setSwitchToggle4(true);
  };
  // const ToggleSwitch1 = () => {
  //   switchToggle1 ? setSwitchToggle1(false) : setSwitchToggle1(true);
  // };
  // const ToggleSwitch2 = () => {
  //   switchToggle2 ? setSwitchToggle2(false) : setSwitchToggle2(true);
  // };
  // const ToggleSwitch3 = () => {
  //   switchToggle3 ? setSwitchToggle3(false) : setSwitchToggle3(true);
  // };
  // const ToggleSwitch4 = () => {
  //   switchToggle4 ? setSwitchToggle4(false) : setSwitchToggle4(true);
  // };
  // const ToggleSwitch5 = () => {
  //   switchToggle5 ? setSwitchToggle5(false) : setSwitchToggle5(true);
  // };

  //list data
  const listPage = pageList.getList();
  const listNode = nodeList.getList();
  const listForm = formList.getList();
  const listIcon = iconList.getList();
  const listChart = chartList.getList();

  return (
    <div className={leftLayout ? "content-left" : "content-left show"}>
      <div className="leftbar-content">
        {/* list pages */}
        <div className={switchToggle1 ? "lists" : "lists active"}>
          <div className="list-title" onClick={ToggleSwitch1}>
            <div className="title-left">
              <img src={home} alt="" />
              <span>{t("leftlayout.list-title1")}</span>
            </div>
            <div className="title-right">
              <img
                src={chevronR}
                className={switchToggle1 ? "list-arrow" : "list-arrow-hidden"}
                alt=""
              />
              <img
                src={chevronB}
                className={switchToggle1 ? "list-arrow-hidden" : "list-arrow"}
                alt=""
              />
            </div>
          </div>
          <div className={switchToggle1 ? "list-sub" : "list-sub active"}>
            {listPage.map((item, i) => (
              <ListItem>
                <span>{item.title}</span>
              </ListItem>
            ))}
          </div>
        </div>
        {/* list nodes */}
        <div className={switchToggle2 ? "lists" : "lists active"}>
          <div className="list-title" onClick={ToggleSwitch2}>
            <div className="title-left">
              <img src={dashboardC} alt="" />
              <span>{t("leftlayout.list-title2")}</span>
            </div>
            <div className="title-right">
              <img
                src={chevronR}
                className={switchToggle2 ? "list-arrow" : "list-arrow-hidden"}
                alt=""
              />
              <img
                src={chevronB}
                className={switchToggle2 ? "list-arrow-hidden" : "list-arrow"}
                alt=""
              />
            </div>
          </div>
          <div className={switchToggle2 ? "list-sub" : "list-sub active"}>
            {listNode.map((item, i) => (
              <ListItem>
                <span>{item.title}</span>
              </ListItem>
            ))}
          </div>
        </div>
        {/* list forms */}
        <div className={switchToggle3 ? "lists" : "lists active"}>
          <div className="list-title" onClick={ToggleSwitch3}>
            <div className="title-left">
              <img src={calendar} alt="" />
              <span>{t("leftlayout.list-title3")}</span>
            </div>
            <div className="title-right">
              <img
                src={chevronR}
                className={switchToggle3 ? "list-arrow" : "list-arrow-hidden"}
                alt=""
              />
              <img
                src={chevronB}
                className={switchToggle3 ? "list-arrow-hidden" : "list-arrow"}
                alt=""
              />
            </div>
          </div>
          <div className={switchToggle3 ? "list-sub" : "list-sub active"}>
            {listForm.map((item, i) => (
              <ListItem>
                <span>{item.title}</span>
              </ListItem>
            ))}
          </div>
        </div>
        {/* list icons */}
        <div className={switchToggle4 ? "lists" : "lists active"}>
          <div className="list-title" onClick={ToggleSwitch4}>
            <div className="title-left">
              <img src={photoI} alt="" />
              <span>{t("leftlayout.list-title4")}</span>
            </div>
            <div className="title-right">
              <img
                src={chevronR}
                className={switchToggle4 ? "list-arrow" : "list-arrow-hidden"}
                alt=""
              />
              <img
                src={chevronB}
                className={switchToggle4 ? "list-arrow-hidden" : "list-arrow"}
                alt=""
              />
            </div>
          </div>
          <div className={switchToggle4 ? "list-sub" : "list-sub active"}>
            {listIcon.map((item, i) => (
              <ListItem>
                <span>{item.title}</span>
              </ListItem>
            ))}
          </div>
        </div>

        {/* list charts */}
        <div className={switchToggle5 ? "lists" : "lists active"}>
          <div className="list-title" onClick={ToggleSwitch5}>
            <div className="title-left">
              <img src={analytics} alt="" />
              <span>{t("leftlayout.list-title5")}</span>
            </div>
            <div className="title-right">
              <img
                src={chevronR}
                className={switchToggle5 ? "list-arrow" : "list-arrow-hidden"}
                alt=""
              />
              <img
                src={chevronB}
                className={switchToggle5 ? "list-arrow-hidden" : "list-arrow"}
                alt=""
              />
            </div>
          </div>
          <div className={switchToggle5 ? "list-sub" : "list-sub active"}>
            {listChart.map((item, i) => (
              <ListItem>
                <span>{item.title}</span>
              </ListItem>
            ))}
          </div>
        </div>
        {/* list chats */}
        <div className="lists chats">
          <div className="list-title">
            <div className="title-left">
              <img src={chat} alt="" />
              <span>{t("leftlayout.list-title6")}</span>
            </div>
          </div>
        </div>
        {/* list files */}
        <div className="lists files">
          <div className="list-title">
            <div className="title-left">
              <img src={folder} alt="" />
              <span>{t("leftlayout.list-title7")}</span>
            </div>
          </div>
        </div>
      </div>

      {vipno > 1 ? (
        <>
          <div className="backcolor">
            <label>{t("leftlayout.file-backcolortitle")}</label>
            <div className="backcoloritems">
              <div
                className="backcoloritem"
                style={{ backgroundColor: "#FFFFFF" }}
                onClick={() => setBackgroundColor("#FFFFFF")}
              />
              <div
                className="backcoloritem"
                style={{ backgroundColor: "#f7f8fa" }}
                onClick={() => setBackgroundColor("#f7f8fa")}
              />
              <div
                className="backcoloritem"
                style={{ backgroundColor: "#F4F6FB" }}
                onClick={() => setBackgroundColor("#F4F6FB")}
              />
              <div
                className="backcoloritem"
                style={{ backgroundColor: "#DAF8F1" }}
                onClick={() => setBackgroundColor("#DAF8F1")}
              />
            </div>

            <div className="backcoloritems">
              <div
                className="backcoloritem"
                style={{ backgroundColor: "#FEF5D8" }}
                onClick={() => setBackgroundColor("#FEF5D8")}
              />
              <div
                className="backcoloritem"
                style={{ backgroundColor: "#FDEEF2" }}
                onClick={() => setBackgroundColor("#FDEEF2")}
              />
              <div
                className="backcoloritem"
                style={{ backgroundColor: "#393C48" }}
                onClick={() => setBackgroundColor("#393C48")}
              />
              <div
                className="backcoloritem"
                style={{ backgroundColor: backgroundColor }}
              >
                <input
                  className="backcoloriteminput"
                  type="color"
                  value={backgroundColor}
                  onChange={handleColorChange}
                />
              </div>
            </div>
            <div className="colorinput">
              <input
                id="colorhex"
                className="colorhex"
                type="text"
                value={backgroundColor}
              />
              <button
                title={t("leftlayout.file-backcolorbtntitle")}
                className="colorupdate"
                onClick={() => {
                  if (isUser) {
                    handleColorUpdate();
                  }
                }}
              >
                {t("leftlayout.file-backcolorbtn")}
              </button>
            </div>
          </div>

          <div className="backpic">
            <label>{t("leftlayout.file-backimagetitle")}</label>

            <div className="backpicitems">
              {backgroundImageUrl ? (
                <img
                  // src={backgroundImageUrl}
                  src={`${baseUrl}${backgroundImageUrl}`}
                  alt={t("leftlayout.file-backimagealt1")}
                />
              ) : (
                <img src={photocamera2} alt={t("leftlayout.file-backimagealt2")} />
              )}
              {/* <img src={file ? "":""}/> */}
            </div>

            <div className="picinput">
              {/* <input
                id="pichex"
                className="pichex"
                type="text"
                autocomplete="off"
              /> */}
              <label className="piclabel" htmlFor="picfile">
                {t("leftlayout.file-backpiclabel")}
              </label>
              <input
                id="picfile"
                className="picfile"
                type="file"
                // accept=".svg"
                accept=".png, .jpg, .jpeg, .gif, .svg"
              />
              <div className="picbuttons">
                <button
                  title={t("leftlayout.file-backimagebtntitle")}
                  className="picupdate"
                  onClick={() => {
                    if (isUser) {
                      handlePicUpdate();
                    }
                  }}
                >
                  {t("leftlayout.file-backimagebtn")}
                </button>
                <button title={t("leftlayout.file-backimagebtntitle1")} className="picdisable">
                  {t("leftlayout.file-backimagebtn1")}
                </button>
                <button
                  title={t("leftlayout.file-backimagebtntitle2")}
                  className="picdelete"
                  onClick={() => {
                    if (isUser) {
                      handlePicDelete();
                    }
                  }}
                >
                  {t("leftlayout.file-backimagebtn2")}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="leftbar-img">
        <img src={logoSvg} alt="" />
      </div>
    </div>
  );
}

export default Leftlayout;
