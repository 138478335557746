import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { LeftLayoutContextProvider } from "./context/leftLayoutContext";
import { ChatActiveContextProvider } from "./context/chatActiveContext";
import { PopupActiveContextProvider } from "./context/popupActiveContext";
import { DraggableActiveContextProvider } from "./context/draggableActiveContext";
import "./style/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PopupActiveContextProvider>
      <DraggableActiveContextProvider>
        <LeftLayoutContextProvider>
          <ChatActiveContextProvider>
          <App />
          </ChatActiveContextProvider>
        </LeftLayoutContextProvider>
      </DraggableActiveContextProvider>
    </PopupActiveContextProvider>
  </React.StrictMode>
);
