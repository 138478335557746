import { useState, useRef, useEffect } from "react";
import baseUrl from "../../configs/baseurl";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./wrapperpic.scss";

import addPhoto from "../../img/add_photo24.svg";
import arrowLeft from "../../img/chevron_left24.svg";
import arrowRight from "../../img/chevron_right24.svg";
import { useTranslation } from "react-i18next";
const Carousel = (props) => {
	const { t } = useTranslation();
  const [showLeftIcon, setShowLeftIcon] = useState(false);
  const [showRightIcon, setShowRightIcon] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  const carouselRef = useRef(null);
  const inputRef = useRef(null);

  const [fileidImageUrls, setFileidImageUrls] = useState([]);

  const handleMouseDown = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid") || "";
    // 从后端获取图片url数组
    fetch(`${baseUrl}/imageapi/imageNames/${fileid}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setFileidImageUrls(data.map((name) => `${baseUrl}/imageapi/${name}`));
        }
      });
  }, []);

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartX(e.pageX || e.touches[0].pageX);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX || e.touches[0].pageX;
    const dragDistance = (x - startX) * 1.5;
    carouselRef.current.scrollLeft = scrollLeft - dragDistance;
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleScroll = () => {
    const maxScrollLeft =
      carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
    setShowLeftIcon(carouselRef.current.scrollLeft > 0);
    setShowRightIcon(carouselRef.current.scrollLeft < maxScrollLeft);
  };

  // useEffect(() => {
  //   carouselRef.current.addEventListener("scroll", handleScroll);
  //   return () =>
  //     carouselRef.current.removeEventListener("scroll", handleScroll);
  // }, []);

  const handleArrowClick = (direction) => {
    const firstImgWidth =
      carouselRef.current.querySelector("img").clientWidth + 14;
    const distance = direction === "left" ? -firstImgWidth : firstImgWidth;
    carouselRef.current.scrollLeft += distance;
  };

  const handleImageUpload = (event) => {
    //获取fileid
    const params = new URLSearchParams(window.location.search);
    const fileid = params.get("fileid") || "";
    console.log("Wrapperdiagramfileid:", fileid);

    const formData = new FormData();
    formData.append("image", event.target.files[0]);

    fetch(`${baseUrl}/imageapi/uploadImage/${fileid}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setFileidImageUrls([
            ...fileidImageUrls,
            `${baseUrl}/imageapi/${data.imageName}`,
          ]);
        } else {
          console.error(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="carousel" onMouseDown={handleMouseDown}>
      <div className="upload-wrapper">
        <input
          type="file"
          id="file-input"
          ref={inputRef}
          accept="image/*"
          onChange={handleImageUpload}
        />
        <label htmlFor="file-input">
          <span className="uploadicon" title={t("wrapperpic.carousel-title")}>
            <img src={addPhoto} alt="" />
          </span>
        </label>
      </div>
      <div className="wrapper">
        <div
          className="icon"
          title={t("wrapperpic.carousel-title1")}
          onClick={() => handleArrowClick("left")}
        >
          <img src={arrowLeft} alt="" />
        </div>
        <div
          className="carousel"
          ref={carouselRef}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          onMouseMove={handleDrag}
          onTouchMove={handleDrag}
          onMouseUp={handleDragEnd}
          onTouchEnd={handleDragEnd}
        >
          {fileidImageUrls.map((url, index) => (
            <img
              key={`img-${index}`}
              src={url}
              alt={`img-${index}`}
              draggable="false"
            />
          ))}
        </div>

        <div
          className="icon"
          title={t("wrapperpic.carousel-title2")}
          onClick={() => handleArrowClick("right")}
        >
          <img src={arrowRight} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
