import styled from "styled-components";

export const ListItem = styled.div`
  color: #9298ad;
  padding: 11px 10px;
  &:last-child {
    border-bottom: none;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  span:hover {
    color: #ffffffbf;
    // font-weight: bold;
  }
  //   background: white;
`;
