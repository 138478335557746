import Navbar from "../../components/navbar/Navbar";
// import Leftlayout from "../../components/leftlayout/Leftlayout";
// import Sidebar from "../../components/sidebar/Sidebar";
import LoginPopup from "../login/Login";
import NoticePopup from "../../components/popupfiles/noticepopup/noticePopup";
import ChatPopup from "../../components/popupfiles/chatpopup/chatPopup";
import MainTitleTable from "../../components/mainlayout/maintitletable";

const TitleTable = () => {
  return (
    <div className="title-table">
      <Navbar />
      {/* <Leftlayout /> */}
      <LoginPopup />
      <NoticePopup />
      <ChatPopup />
      <MainTitleTable />
    </div>
  );
};

export default TitleTable;
