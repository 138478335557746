import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";

export const TableAxios = () => {
  // hooks
  const [products, setProducts] = useState([]);
  // axios
  const endpoint = "http://fakestoreapi.com/products";

  const getData = async () => {
    await axios.get(endpoint).then((response) => {
      const data = response.data;
      console.log(data);
      setProducts(data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  // columns
  const columns = [
    {
      name: "id",
      lable: "ID",
    },
    {
      name: "title",
      lable: "TITLE",
    },
    {
      name: "category",
      lable: "CATEGORY",
    },
    {
      name: "price",
      lable: "PRICE",
    },
    {
      name: "image",
      lable: "IMAGE",
      //   options:{
      //     customBodyRender: () => <div><img src={products.image} className="image" alt='test' /></div>
      // }
    },
  ];
  // render data

  const options = {
    page: "5",
    rowsPerPage: "8",
  };

  return (
    <div className="datatable">
      <MUIDataTable
        title={"data axios"}
        columns={columns}
        data={products}
        options={options}
      />
    </div>
  );
};
