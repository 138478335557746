import { createContext, useReducer } from "react";
import DraggableActiveReducer from "./draggableActiveReducer";

const INITIAL_STATE = {
  draggableActive1: false,
  //editdraggable
  draggableActive2: false,
  //infodraggable
  draggableActive3: false,
  //oprundraggable
  draggableActive4: false,
  //groupdraggable
  draggableActive5: false,
  //nodedatadraggable
  draggableActive6: false,
  //chatdraggable
};

export const DraggableActiveContext = createContext(INITIAL_STATE);

export const DraggableActiveContextProvider = ({ children }) => {
  const [state, dispatch05] = useReducer(DraggableActiveReducer, INITIAL_STATE);
  return (
    <DraggableActiveContext.Provider
      value={{
        draggableActive1: state.draggableActive1,
        draggableActive2: state.draggableActive2,
        draggableActive3: state.draggableActive3,
        draggableActive4: state.draggableActive4,
        draggableActive5: state.draggableActive5,
        draggableActive6: state.draggableActive6,
        dispatch05,
      }}
    >
      {children}
    </DraggableActiveContext.Provider>
  );
};
