import React, { useState, useContext, useEffect, useRef } from "react";
import baseUrl from "../../configs/baseurl";
import backUrl from "../../configs/backurl";
import axios from "axios";
import "./chatdraggable.scss";
import Draggable from "react-draggable";
import closebt from "../../img/close_black_24dp.svg";
import morebt from "../../img/more_horiz48.svg";

import iconbt from "../../img/sentiment_24dp.svg";
import linkbt from "../../img/link_opsz24.svg";
import sendbt from "../../img/near2_me_opsz24blue.svg";
// import sendbt from "../../img/send_24.svg";
import more2bt from "../../img/more2_v24.svg";

import registereduserSvg from "../../images/userimages/registereduser.svg";

import { AuthContext } from "../../context/authContext";
import { DraggableActiveContext } from "../../context/draggableActiveContext";
import { ChatActiveContext } from "../../context/chatActiveContext";
import { useTranslation } from "react-i18next";

export default function ChatDraggable(props) {
  const { t } = useTranslation();

  const { chatActive } = useContext(ChatActiveContext);
  const { draggableActive6 } = useContext(DraggableActiveContext);

  //获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);
  //loading userid
  const vipno = userVipGrade.vip_no;
  const userid = userVipGrade.userid;

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  //chatActiveContext
  const { dispatchchat } = useContext(ChatActiveContext);

  //showcontent
  const [showInfodragContent, setShowInfodragContent] = useState(true);

  // const fileid = props.selectedNode.fileid;
  const nodekey = props.selectedNode.key;
  const nodeselect = props.selectedNode.nodeselected;
  //   const nodeisgroup = props.selectedNode.isgroup;
  //   const linkselect = props.selectedLink.linkselected;
  // console.log("nodeselected xxx:", nodekey, nodeselect);
  const params = new URLSearchParams(window.location.search);
  const fileid = params.get("fileid");

  //showcontent
  const handleInfoDoubleClick = () => {
    setShowInfodragContent(!showInfodragContent);
  };

  const handleInfoClick = () => {
    setShowInfodragContent(true);
  };

  //get nodedata content
  const [nodedata, setNodedata] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNodedata = async () => {
    // const { fileid, nodekey, nodeselected } = props.selectedNode;

    if (nodeselect && !loading) {
      try {
        const response = await fetch(
          `${baseUrl}/titlesapi/getNodedata/${fileid}/${nodekey}`
        );
        const data = await response.json();
        setNodedata(data);
        forceRedraw(".datadraggabletitle");
      } catch (error) {
        console.error("Error fetching nodedata:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setNodedata([]);
    }
  };

  // 强制重绘函数
  const forceRedraw = (selector) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => {
      element.style.display = "none";
      void element.offsetHeight; // 引发重绘
      element.style.display = "flex"; // 恢复原始样式
    });
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchNodedata();
    }, 1000); // 1秒延迟
    return () => clearTimeout(delay);
  }, [fileid, nodekey, nodeselect]);

  //get filename
  const [fileNameText, setFileNameText] = useState("");

  //loading fileName from sql by ID
  useEffect(() => {
    async function fetchFileName() {
      try {
        // const params = new URLSearchParams(window.location.search);
        // const fileid = params.get("fileid");
        if (!fileid) {
          return;
        }
        // console.log("fileid X", fileid);
        const response = await axios.get(
          `${baseUrl}/fileapi/getfilename/${fileid}`
        );
        if (response.data.filename === null) {
          // console.log("Filename is null");
          return;
        } else {
          setFileNameText(response.data.filename);
          // console.log("filename X", response.data.filename);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchFileName();
  }, [fileid]);

  //
  const [selectedNodeText, setSelectedNodeText] = useState("");

  useEffect(() => {
    const fetchDataWithDelay = async () => {
      // 等待一段时间，例如 1000 毫秒（1 秒）
      await new Promise((resolve) => setTimeout(resolve, 500));

      // 在这里执行任何必要的逻辑来获取 selectedNode.text
      // 这可能涉及到一些条件检查、计算等等
      const newText =
        props.selectedNode.text ||
        fileNameText ||
        t("datadraggable.nodedata-dataheadtitle1");

      // 更新 state
      setSelectedNodeText(newText);
    };

    fetchDataWithDelay();
  }, [props.selectedNode.text, fileNameText, t]);

  //save chatcontent
  const [chatContent, setChatContent] = useState("");

  const handleSendClick = async () => {
    if (!chatContent || !fileid || !userid) {
      //   console.error("请确保chatContent不为空");
      return;
    }

    const currentTimestamp = new Date().toISOString();

    // console.log(
    //   "chatContent10:",
    //   chatContent,
    //   fileid,
    //   userid,
    //   nodekey,
    //   selectedNodeText
    // );

    const apiUrl = `${baseUrl}/titlesapi/saveChatcontent/${fileid}/${userid}`;

    const data = {
      chatContent,
      nodekey,
      currentTimestamp,
      selectedNodeText,
    };
    if (vipno > 0) {
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          // Refresh chat data after successful save
          fetchData();
          setChatContent("");
          // console.log('聊天内容成功保存到数据库');
        } else {
          console.error("保存聊天内容失败");
        }
      } catch (error) {
        console.error("发生错误", error);
      }
    }
  };

  //chat data refresh

  const [chatData, setChatData] = useState([]);
  const [mergedContent, setMergedContent] = useState([]);

  const transformImageUrl = (imageUrl) => {
    if (imageUrl) {
      const publicIndex = imageUrl.indexOf("public");
      const relativePath = imageUrl.slice(publicIndex + "public".length);
      const imagePath = relativePath.replace(/\\/g, "/");
      return `${baseUrl}${imagePath}?${Math.random()}`;
    }
    return registereduserSvg; // 或者默认的图片地址
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/titlesapi/getChatData/${fileid}`
      );
      const data = await response.json();

      // 按照 chat_modifydate 排序
      const sortedData = data.sort(
        (a, b) => new Date(a.chat_modifydate) - new Date(b.chat_modifydate)
      );

      setChatData(sortedData);
    } catch (error) {
      console.error("获取聊天数据失败", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fileid]);

  //   console.log("chatData", chatData);

  // 在这里处理图片和用户信息的更新
  useEffect(() => {
    const updateMergedContent = async () => {
      const updatedMergedContent = await Promise.all(
        chatData.map(async (message) => {
          if (message.userid) {
            try {
              const response = await fetch(
                `${baseUrl}/userapi/getUserInfo/${message.userid}`
              );
              const userData = await response.json();

              return {
                content: message.chatcontent,
                modifyDate: message.chat_modifydate,
                isReply: message.userid !== userid,
                chatKeyword: message.chatkeyword,
                userInfo: {
                  picurl: userData.pic_url,
                  name: userData.name,
                },
              };
            } catch (error) {
              console.error("Error fetching user info:", error);
            }
          }

          return {
            content: message.chatcontent,
            modifyDate: message.chat_modifydate,
            isReply: message.userid !== userid,
            chatKeyword: message.chatkeyword,
            userInfo: {
              picurl: null,
              name: null,
            },
          };
        })
      );

      setMergedContent(updatedMergedContent);
    };

    updateMergedContent();
  }, [chatData, userid]);

  //up oweruser pic
  const [userInfo, setUserInfo] = useState({
    picurl: null,
  });
  const [fileuserInfo, setFileUserInfo] = useState({
    picurl: null,
  });

  // loadusericon and fileusericon
  const [loaduserImage, setLoadUserImage] = useState(null);
  const [fileuserImage, setFileUserImage] = useState(null);

  //userInfo gets updated
  useEffect(() => {
    if (userid) {
      fetch(`${baseUrl}/userapi/getUserInfo/${userid}`)
        .then((response) => response.json())
        .then((data) => {
          setUserInfo({
            picurl: data.pic_url,
          });
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    }
  }, [userid]);

  //change image load
  useEffect(() => {
    if (userInfo.picurl) {
      const imageUrl = userInfo.picurl;
      const publicIndex = imageUrl.indexOf("public");
      const relativePath = imageUrl.slice(publicIndex + "public".length);
      const imagePath = relativePath.replace(/\\/g, "/");
      // const newimagePath = `${backUrl}${imagePath}`;
      //const newimagePath = `${baseUrl}${imagePath}`;    //vps need
      //console.log("upload pic user11", imagePath);

      setLoadUserImage(imagePath, () => {
        // console.log("upload pic ff2", loaduserImage);
      });
    } else {
      setLoadUserImage(null);
    }
  }, [userInfo.picurl]);

  //get fileuser pic
  useEffect(() => {
    const fetchFileUserImage = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/titlesapi/getFileUserImage/${fileid}`
        );
        const data = await response.json();

        setFileUserInfo({
          picurl: data.pic_url,
        });
      } catch (error) {
        console.error("获取文件用户图片失败", error);
      }
    };

    fetchFileUserImage();
  }, [fileid]);

  //change fileuserimage load
  useEffect(() => {
    if (fileuserInfo.picurl) {
      const imageUrl = fileuserInfo.picurl;
      const publicIndex = imageUrl.indexOf("public");
      const relativePath = imageUrl.slice(publicIndex + "public".length);
      const imagePath = relativePath.replace(/\\/g, "/");
      // const newimagePath = `${backUrl}${imagePath}`;
      //const newimagePath = `${baseUrl}${imagePath}`;    //vps need
      //console.log("upload pic user11", imagePath);

      setFileUserImage(imagePath, () => {
        // console.log("upload pic ff2", loaduserImage);
      });
    } else {
      setFileUserImage(null);
    }
  }, [userInfo.picurl]);

  //scroll to bottom
  const chatsLayoutRef = useRef(null);

  useEffect(() => {
    // 在 chatsLayout 加载后，滚动到最下方
    const chatsLayout = chatsLayoutRef.current;
    if (chatsLayout) {
      chatsLayout.scrollTop = chatsLayout.scrollHeight;
    }
  }, [mergedContent]);

  //

  return (
    <>
      <Draggable cancel=".chatdraggabletitle,.chatusers,.chatslayout,.chatsend,.leftbtn,.rightbtn,.bottombotton,.closeicon">
        <div className={chatActive ? "chatcard active" : "chatcard"}>
          <div className="chathead" onDoubleClick={handleInfoDoubleClick}>
            <div className="chatdraggabletitle">
              {selectedNodeText}
              {/* {props.selectedNode.text ||
                fileNameText ||
                t("datadraggable.nodedata-dataheadtitle1")} */}
            </div>
            <img
              className="closeicon"
              src={closebt}
              onClick={() => dispatchchat({ type: "TOGGLE" })}
            />
          </div>

          {showInfodragContent ? (
            <>
              <div className="chatusers">
                <div className="usericons">
                  <div className="usericon">
                    <img
                      src={
                        loaduserImage
                          ? `${baseUrl}${loaduserImage}?${Math.random()}`
                          : // ? `${baseUrl}${userImage}?${Math.random()}`   //vps need
                            registereduserSvg
                      }
                      alt=""
                      className="strangeavatar"
                    />
                  </div>
                  <div className="usericon">
                    <img
                      src={
                        fileuserImage
                          ? `${baseUrl}${fileuserImage}?${Math.random()}`
                          : // ? `${baseUrl}${userImage}?${Math.random()}`   //vps need
                            registereduserSvg
                      }
                      alt=""
                      className="strangeavatar"
                    />
                  </div>
                  {/* <div className="usericon"></div> */}
                </div>
              </div>
              <div ref={chatsLayoutRef} className="chatslayout">
                {/* 显示每一行的回复内容 */}
                {mergedContent.map((item, index) => (
                  <div
                    key={index}
                    className={`replyContainer ${
                      item.isReply ? "leftContent" : "rightContent"
                    }`}
                  >
                    {item.isReply && (
                      <div className="chatreply">
                        <div className="replyleft">
                          <div className="replyusericon">
                            <img
                              src={transformImageUrl(item.userInfo.picurl)}
                              alt=""
                              className="strangeavatar"
                            />
                          </div>
                        </div>
                        <div className="replyright">
                          <div className="replyuser">
                            <div className="replyusername">
                              {item.userInfo.name}
                            </div>
                            <div className="replyuserunit"></div>
                          </div>
                          <div className="replycontent">{item.content}</div>
                          {item.chatKeyword && (
                            <div className="replykewords">
                              {item.chatKeyword}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {!item.isReply && (
                      <div className="chatanswer">
                        <div className="answerleft">
                          <div className="answercontent">{item.content}</div>
                          {item.chatKeyword && (
                            <div className="answerkewords">
                              {item.chatKeyword}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="chatsend">
                <textarea
                  className="chatinput"
                  placeholder={t("chatdraggable.chatinput-placeholder")}
                  value={chatContent}
                  onChange={(e) => setChatContent(e.target.value)}
                ></textarea>
              </div>
              <div className="chatbtn">
                <div className="leftbtn">
                  <img className="iconicon" src={iconbt} />
                  <img className="linkicon" src={linkbt} />
                </div>
                <div className="rightbtn">
                  <div className="sendbtn" onClick={handleSendClick}>
                    <img className="sendicon" src={sendbt} />
                  </div>
                  <img className="moreicon" src={more2bt} />
                </div>
              </div>
            </>
          ) : (
            <div className="bottombotton" onClick={handleInfoClick}>
              <img
                className="morebtn"
                src={morebt}
                // onClick={handleMoreToggle}
              />
            </div>
          )}
        </div>
      </Draggable>
    </>
  );
}
