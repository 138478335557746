import React, { useState, useContext, useEffect } from "react";
import baseUrl from "../../configs/baseurl";
import axios from "axios";
import "./groupdraggable.scss";
import Draggable from "react-draggable";
import draggablehandle from "../../img/arrow_upward20.svg";
import closebt from "../../img/close_black_24dp.svg";
import morebt from "../../img/more_horiz48.svg";
import { AuthContext } from "../../context/authContext";
import { DraggableActiveContext } from "../../context/draggableActiveContext";
import { useTranslation } from "react-i18next";
export default function GroupDraggable(props) {
  const { t } = useTranslation();
  const { draggableActive4 } = useContext(DraggableActiveContext);

  //draggableActiveContext
  const { dispatch05 } = useContext(DraggableActiveContext);

  const [groupname, setGroupname] = useState("");
  const [isLoading, setIsLoading] = useState(false); // 添加isLoading状态

  //showcontent
  const [showGroupdragContent, setShowGroupdragContent] = useState(true);

  //showcontent
  const handleGroupDoubleClick = () => {
    setShowGroupdragContent(!showGroupdragContent);
  };

  const handleGroupClick = () => {
    setShowGroupdragContent(true);
  };

  //获取userid
  const { currentUser, userVipGrade } = useContext(AuthContext);

  //group list show
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCount, setSelectedCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([t("groupdraggable.group-Option2")]);
  const [selectGroupId, setSelectGroupId] = useState(null);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const handleOptionClick = (label, groupid) => {
    setGroupList([]);
    setSelectedOption(label);
    setSelectGroupId(groupid); // 在这里设置 selectGroupId
    handleGroupFiles(groupid); // 获取group 的groupfiles

    // 检查是否选择了 "无选择"，如果是则清空 groupList
    if (!groupid) {
      setGroupList([]);
    }
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  // 更新所选文件的 fileId 集合和显示的文件列表
  const handleCheckboxChange = (event, fileId, filename) => {
    const isChecked = event.target.checked;
  //  console.log("Checkbox changed:", filename);

    // 计算已选择的数量
    const newSelectedCount = isChecked ? selectedCount + 1 : selectedCount - 1;
    setSelectedCount(newSelectedCount);
    // 更新所选文件的 fileId 集合
    setSelectedFileIds((prevSelectedFileIds) => {
      if (isChecked) {
        return [...prevSelectedFileIds, fileId];
      } else {
        return prevSelectedFileIds.filter((id) => id !== fileId);
      }
    });

    // 更新显示的文件列表
    if (isChecked) {
      setSelectedFiles((prevSelectedFiles) => [
        ...prevSelectedFiles,
        { fileId, filename },
      ]);
    } else {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter(
          (selectedFile) => selectedFile.fileId !== fileId
        )
      );
    }
  };

  // 处理更新按钮点击事件
  const handleUpdateGroup = () => {
    if (selectGroupId && selectedFileIds.length > 0) {
      const sortedFileIds = selectedFiles.map(
        (selectedFile) => selectedFile.fileId
      );

      const updatedGroupData = {
        groupId: selectGroupId,
        fileIds: sortedFileIds.join(","), // 将 fileId 集合用逗号隔开
        groupnum: sortedFileIds.length,
      };

      // 发送更新请求到后端
      axios
        .post(`${baseUrl}/userapi/updateGroupFiles/${userid}`, updatedGroupData)
        .then((response) => {
          // 更新成功后，重新获取最新的数据并更新 groupList
          handleGroupFiles(selectGroupId);
        })
        .catch((error) => {
          console.error("Error updating group files:", error);
        });
    }
  };

  //move selected files
  const handleMoveUp = (index) => {
    if (index > 0) {
      const updatedFiles = [...selectedFiles];
      const temp = updatedFiles[index];
      updatedFiles[index] = updatedFiles[index - 1];
      updatedFiles[index - 1] = temp;
      setSelectedFiles(updatedFiles);
    }
  };

  //   // 处理下拉列表选择变化的函数
  //   const handleDropdownChange = (event) => {
  //     setSelectedOption(event.target.value);
  //   };

  //   //isOriguser
  //   const [isOriguser, setIsOriguser] = useState(false);

  //loading userid
  //   const vipno = userVipGrade.vip_no;
  const userid = userVipGrade.userid;
  //   console.log("userid11", userid);

  //create new user group
  const handleCreateGroup = () => {
    setIsLoading(true); // 开始加载

    // 构建要发送到后端的数据
    const requestData = {
      groupname,
      userid,
    };

    // 发送POST请求到后端
    axios
      .post(`${baseUrl}/userapi/createGroup`, requestData)
      .then((response) => {
        setIsLoading(false); // 停止加载
        setGroupname(""); // 清空输入

        // 更新下拉列表
        fetchGroupNames(); // 可以根据您的实现来获取最新的下拉列表数据

        // // 处理成功响应，例如显示成功消息
        // console.log("Group created successfully");
      })
      .catch((error) => {
        setIsLoading(false); // 停止加载
        // 处理错误响应，例如显示错误消息
        console.error("Error creating group:", error);
      });
  };

  // 构建要发送到后端的数据
  const requestData = {
    groupname,
    userid,
  };

  //clear groupname input
  const handleCancelGroupName = () => {
    setGroupname("");
  };

  //获取groupname数组
  const fetchGroupNames = () => {
    axios
      .get(`${baseUrl}/userapi/getGroupNames/${userid}`)
      .then((response) => {
        const data = response.data;
       // console.log("data.groupnames22", data.groupData);
        // 提取 groupnames 和 groupids
        const groupnames = data.groupData.map((group) => group.groupname);
        const groupids = data.groupData.map((group) => group.groupid);

        // 创建一个数组，每个元素包含 groupname 和相应的 groupid
        const dropdownOptionsWithIds = [
          { label: t("groupdraggable.group-Option2"), value: "" },
          ...groupnames.map((groupname, index) => ({
            label: groupname,
            value: groupids[index],
          })),
        ];

        setDropdownOptions(dropdownOptionsWithIds);
      })
      .catch((error) => {
        console.error("Error fetching groupnames:", error);
      });
  };

  useEffect(() => {
    // 初始化时获取groupname数组
    fetchGroupNames();
  }, [userid]);

  useEffect(() => {
    // 发起异步请求，获取 user_files_table 数据
    axios
      .get(`${baseUrl}/userapi/getUserfilelist/${userid}`)
      .then((response) => {
        const data = response.data;
        // data 中已包含去重后的文件ID列表
        const fileIds = data.fileIds;
        // console.log("data11", fileIds);

        // 根据每个 fileid 获取文件名
        const promises = fileIds.map((fileId) =>
          axios
            .get(`${baseUrl}/fileapi/getfilename/${fileId}`)
            .then((fileResponse) => {
              if (fileResponse.data.success) {
                // 提取正确的 filename
                return fileResponse.data.filename;
              } else {
                // 如果获取失败，返回一个默认值或者处理错误情况
                return "Unknown"; // 这里使用了一个默认值
              }
            })
            .catch((error) => {
              console.error();
              return "Unknown"; // 这里处理了请求失败的情况
            })
        );

        // 并行请求文件名
        Promise.all(promises)
          .then((filenames) => {
            // 生成最终的文件列表
            const fileList = fileIds.map((fileId, index) => ({
              fileId,
              filename: filenames[index],
            }));
            // console.log("fileList11", fileList);
            setFileList(fileList);
          })
          .catch((error) => {
            console.error("Error fetching filenames:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user_files_table data:", error);
      });
  }, [userid]);

  // 获取 groupfiles 的值并 show files
const handleGroupFiles = (selectGroupId) => {
    if (selectGroupId) {
      axios
        .get(`${baseUrl}/userapi/getGroupFiles/${userid}/${selectGroupId}`)
        .then((response) => {
          const data = response.data;
          // 检查数据是否为空
          if (!data || !data.groupfiles) {
            // 数据为空，清空 groupcontent
            setGroupList([]);
            return;
          }

          // 解析 groupfiles 为文件 ID 数组
          const fileIds = data.groupfiles.split(",");

          // 使用 Promise.all 循环查询每个文件 ID 对应的文件名
          const promises = fileIds.map((fileId) => {
            return axios
              .get(`${baseUrl}/fileapi/getfilename/${fileId}`)
              .then((response) => response.data.filename)
              .catch((error) => {
                console.error(
                  `Error fetching filename for file ${fileId}:`,
                  error
                );
                return "Unknown"; // 返回默认值 "Unknown" 或其他您选择的默认值
              });
          });

          // 使用 Promise.all 等待所有文件名都被获取
          Promise.all(promises)
            .then((filenames) => {
              // 设置 groupList 为文件名集合
              setGroupList(filenames);
            })
            .catch((error) => {
              console.error("Error fetching filenames:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching groupfiles:", error);
        });
    }
  };

  // 处理删除按钮点击事件
  const handleDeleteGroup = () => {
    if (selectGroupId) {
      axios
        .delete(`${baseUrl}/userapi/deleteGroup/${userid}/${selectGroupId}`)
        .then((response) => {
         // console.log("Group deleted successfully");
          // 删除成功后清空 groupcontent
          setGroupList([]);

          // 更新下拉列表
          fetchGroupNames(); // 可以根据您的实现来获取最新的下拉列表数据

          // 清空选中的选项
          setSelectedOption("");
          setSelectGroupId("");
        })
        .catch((error) => {
          console.error("Error deleting group:", error);
        });
    }
  };

  const handleClearSelect = () => {
    setGroupList([]);
    setSelectedOption("集合选择");
  };

  return (
    <>
      <Draggable cancel=".infodraggabletitle,.groupinput,.inforenewbutton,.custom-dropdown,.infocancelbutton,.groupcontent,.userfilelist,.bottombotton">
        <div className={draggableActive4 ? "groupcard active" : "groupcard"}>
          <div className="infohead">
            <p
              className="infodraggabletitle"
              onDoubleClick={handleGroupDoubleClick}
            >
              {t("groupdraggable.group-headtitle")}
            </p>
            <img
              className="closeicon"
              src={closebt}
              onClick={() => {
                dispatch05({ type: "GROUPDRAGGABLE" });
                handleClearSelect();
              }}  
            />
          </div>
          {/* groupcontent */}
          {showGroupdragContent ? (
            <div className="groupitems">
              <div className="creategroup">
                <label>{t("groupdraggable.group-label1")}</label>
                <input
                  className="groupinput"
                  placeholder={t("groupdraggable.group-inputplaceholder")}
                  rows="1"
                  cols="25"
                  autoComplete="off"
                  value={groupname}
                  onChange={(e) => setGroupname(e.target.value)}
                  disabled={isLoading} // 在加载状态下禁用输入字段
                />
                <div className="button-group">
                  <button
                    className="inforenewbutton"
                    title={t("groupdraggable.group-btntitle1")}
                    onClick={handleCreateGroup}
                    disabled={isLoading}
                  >
                    {t("groupdraggable.group-btn1")}
                  </button>
                  <button
                    className="infocancelbutton"
                    title={t("groupdraggable.group-btntitle2")}
                    onClick={handleCancelGroupName}
                  >
                    {t("groupdraggable.group-btn2")}
                  </button>
                </div>
              </div>
              {/* 集合选择 */}
              <div className="groupitems">
                <div className="grouptitle">
                  <label>{t("groupdraggable.group-label2")}</label>
                </div>
                {/* showgroupfilelist */}
                <div className="groupcontent">
                  {/* <h2>已选择的文件:</h2> */}

                  <ul>
                    {groupList.map((filename, index) => (
                      <li key={index}>
                        <span className="selectfilename">{filename}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* grouplist */}
                <div
                  className="custom-dropdown"
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className="custom-dropdown-toggle"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {selectedOption || t("groupdraggable.group-Option1")}
                  </div>
                  {isDropdownOpen && (
                  <div className="dropdown-menu"> 
                   <div className="custom-dropdown-options">
                      {dropdownOptions.map((option, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            handleOptionClick(option.label, option.value)
                          }
                          className={`custom-dropdown-option ${
                            selectedOption === option.label ? "selected" : ""
                          }`}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                   </div>
                  )}
                </div>
                <div className="grouptitle">
                  <label>{t("groupdraggable.group-label3")}</label>
                </div>
                {/* showlist */}
                <div className="selectcontent">
                  {/* <h2>已选择的文件:</h2> */}

                  <ul>
                    {selectedFiles.map((selectedFile, index) => (
                      <li key={index}>
                        <span className="selectfilename">
                          {selectedFile.filename}
                        </span>
                        <img
                          className="draggablebtn"
                          title={t("groupdraggable.group-btntitle3")}
                          src={draggablehandle}
                          onClick={() => handleMoveUp(index)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                {/* userfilelist */}
                <div className="userfiletitle">
                  <label>{t("groupdraggable.group-label4")}</label>
                  <span className="filesnum">
                    {selectedCount}/{fileList.length}
                  </span>
                </div>
                <div className="userfilelist">
                  <ul>
                    {fileList.map((file) => (
                      <li key={file.fileId}>
                        <input
                          type="checkbox"
                          className="filecheckbox"
                          name={`fileCheckbox-${file.fileId}`}
                          //   value={file.fileId}
                          // onChange={handleCheckboxChange}
                          value={file.fileId}
                          //   checked={file.selected}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event,
                              file.fileId,
                              file.filename
                            )
                          }
                        />
                        <label className="showfilename">{file.filename}</label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="groupupdate">
                  <div className="button-group">
                    <button
                      className="inforenewbutton"
                      title={t("groupdraggable.group-btntitle4")}
                      onClick={handleUpdateGroup}
                    >
                      {t("groupdraggable.group-btn4")}
                    </button>
                    <button
                      className="infocancelbutton"
                      title={t("groupdraggable.group-btntitle5")}
                      onClick={handleDeleteGroup}
                    >
                      {t("groupdraggable.group-btn5")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="bottombotton" onClick={handleGroupClick}>
              <img
                className="morebtn"
                src={morebt}
                // onClick={handleMoreToggle}
              />
            </div>
          )}
        </div>
      </Draggable>
    </>
  );
}
