const nodeList = {
    nodelist: [
      {
        id: 1,
        title: "新节点01",
      },
      {
        id: 2,
        title: "新节点02",
      },
      {
        id: 3,
        title: "新节点03",
      },
      {
        id: 4,
        title: "新节点04",
      },
      {
        id: 5,
        title: "新节点05",
      },
      {
        id: 6,
        title: "新节点06",
      },
    ],
    getList: function () {
      return (
        (localStorage.getItem("theList") &&
          JSON.parse(localStorage.getItem("theList"))) ||
        this.nodelist
      );
    },
    saveList: (nodelist) => {
      localStorage.setItem("theList", JSON.stringify(nodelist));
    },
  };
  
  export default nodeList;
  